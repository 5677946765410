
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";

@Options({
  components: {
    NavBar,
    Footer
  }
})
export default class TermsOfService extends Vue {
  isLoggedIn = false;
  loaded = false;
  dateOfUpdate = "29 July 2023";

  content = [
    {
      title: "Introduction",
      texts: [
        `Welcome to Thoth.
        \nThoth operates ${this.getStyledLink(
          "https://thoth.art"
        )} (hereinafter referred to as "Service").
        \nThese Terms of Service ("Terms", "Terms of Service") govern your use of our web pages located at ${this.getStyledLink(
          "https://thoth.art"
        )}
        \nOur Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages. Please read it here:  ${this.getStyledLink(
          "https://thoth.art/privacy"
        )}.
        \nYour agreement with us includes these Terms and our Privacy Policy ("Agreements"). You acknowledge that you have read and understood Agreements, and agree to be bound by them.
        \nIf you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at ${this.getStyledLink(
          "hello@thoth.art",
          true
        )} so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.
        \nThank you for being responsible.`
      ]
    },
    {
      title: "License",
      texts: [
        `Information about our usage license can be found on our license page, located here: ${this.getStyledLink(
          "https://thoth.art/license"
        )}`
      ]
    },
    {
      title: "Communications",
      texts: [
        `By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at ${this.getStyledLink(
          "hello@thoth.art",
          true
        )}.`
      ]
    },
    {
      title: "Purchases",
      texts: [
        `If you wish to purchase any product or service made available through Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.
        \nYou represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
        \nWe may employ the use of third-party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.
        \nWe reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
        \nWe reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.`
      ]
    },
    {
      title: "Contests, Sweepstakes and Promotions",
      texts: [
        `Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.`
      ]
    },
    {
      title: "Paid Services and Payment Information",
      texts: [
        `Thoth, in its sole discretion and at any time, may modify the fees or benefits associated with the Paid Services from time to time with reasonable advance notice of material changes; provided, however, that no advance notice will be required for temporary promotions, including temporary reductions in the fees associated with the Paid Services.
        \nYou may submit your debit card, credit card, or other payment information ("Payment Information") via our Services to purchase the Paid Services. We use third-party service providers to process your Payment Information. If you submit your Payment Information, you agree to pay all costs that you incur, and you give us permission to charge you when payment is due for an amount that includes these costs and any applicable taxes and fees.`
      ]
    },
    {
      title: "Content",
      texts: [
        `Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.
        \nBy posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.
        \nYou retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third-party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.
        \nThoth has the right but not the obligation to monitor and edit all Content provided by users.`
      ]
    },
    {
      title: "Prohibited Uses",
      texts: [
        `You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
        \n(a) In any way that violates any applicable national or international law or regulation.
        \n(b) For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
        \n(c) To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.
        \n(d) To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
        \n(e) In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
        \n(f) To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.
        \nAdditionally, you agree not to:
        \n(a) Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party's use of Service, including their ability to engage in real time activities through Service.
        \n(b) Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.
        \n(c) Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.
        \n(d) Use any device, software, or routine that interferes with the proper working of Service.
        \n(e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
        \n(f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
        \n(g) Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
        \n(h) Take any action that may damage or falsify Company rating.
        \n(i) Otherwise attempt to interfere with the proper working of Service.`
      ]
    },
    {
      title: "Analytics",
      texts: [
        `We may use third-party Service Providers to monitor and analyze the use of our Service.
        \n${this.getStyledSubtitle(
          "Google Analytics"
        )} \nGoogle Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
        \nFor more information on the privacy practices of Google, please visit the Google Privacy Terms web page: ${this.getStyledLink(
          "https://policies.google.com/privacy?hl=en"
        )}.
        \nWe also encourage you to review the Google's policy for safeguarding your data: ${this.getStyledLink(
          "https://support.google.com/analytics/answer/6004245"
        )}.`
      ]
    },
    {
      title: "No Use By Minors",
      texts: [
        `Service is intended only for access and use by individuals at least thirteen (13) years old. By accessing or using any of Company, you warrant and represent that you are at least thirteen (13) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least thirteen (13) years old, you are prohibited from both the access and usage of Service.`
      ]
    },
    {
      title: "Accounts",
      texts: [
        `When you create an account with us, you guarantee that you are above the age of thirteen (13), and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.
        \nYou are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
        \nWe reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders at our sole discretion.`
      ]
    },
    {
      title: "Intellectual Property",
      texts: [
        `Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Thoth and its licensors. Service is protected by copyright, trademark, and other laws of Singapore. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Thoth.`
      ]
    },
    {
      title: "Copyright Policy",
      texts: [
        `We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights ("Infringement") of any person or entity.
        \nIf you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to ${this.getStyledLink(
          "hello@thoth.art",
          true
        )}, with the subject line: "Copyright Infringement" and include in your claim a detailed description of the alleged Infringement.
        \nYou may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.`
      ]
    },
    {
      title: "Error Reporting and Feedback",
      texts: [
        `You may provide us either directly at ${this.getStyledLink(
          "hello@thoth.art",
          true
        )} or via third-party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service ("Feedback"). You acknowledge and agree that:
        \n(i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback;
        \n(ii) Company may have development ideas similar to the Feedback;
        \n(iii) Feedback does not contain confidential information or proprietary information from you or any third-party; and
        \n(iv) Company is not under any obligation ofconfidentiality with respect to the Feedback.
        \nIn the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.`
      ]
    },
    {
      title: "Links to Other Websites",
      texts: [
        `Our Service may contain links to third-party web sites or services that are not owned or controlled by Thoth.
        \nThoth has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
        \nYou acknowledge and agree that Thoth shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party web sites or services.
        \nWe strongly advise you to read the terms of service and privacy policies of any third-party web sites or services that you visit.`
      ]
    },
    {
      title: "Content Warranty Disclaimer",
      texts: [
        `Thoth cannot scrutinize all text, images, videos, documents, audio, code, scripts or other information (collectively, the "Content") and cannot guarantee that it will be free of
        \n(i) malware, contaminants, code, scripts, routines that may (a) harm your machine or systems, or (b) remove, transfer, disclose or alter any information therein, or
        \n(ii) materials you may find objectionable or inappropriate. Thoth disclaims any responsibility or liability related to third-party Content (including yours, for which you are solely liable) and reserves the right to remove it for any reason.
        \nThoth is not responsible for any incorrect or inaccurate Content or data posted on the Website and/or the Service, whether caused by Users or by any of the equipment or programming associated with or utilised in the Service, nor for the conduct of any User.
        \nThoth does not assume any responsibility for any errors, omissions, interruptions, deletions, defects, delays in operation or transmissions, communication line failure, theft or destruction or unauthorised access to, or alteration of User communications or any communications by us to any Users.
        \nThoth is not responsible for any problems or technical malfunction of any communication network or lines, computer on-line systems, servers or providers, computer equipment, software or failure of email on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to Users or to any other person's computer related to or resulting and/or in connection with the Website and/or the Service.`
      ]
    },
    {
      title: "Warranties",
      texts: [
        `The Website and the Service are provided on an "As-Is" and "As Available" basis. Neither Thoth nor its Affiliates, suppliers, officers, employees or agents ("Thoth Parties"), make any warranty of any kind (express, implied, statutory or otherwise) in relation to the Website and/or the Service and, to the maximum extent permitted by applicable law, Thoth specifically disclaims all warranties, including availability, service uptime, merchantability, fitness for a particular purpose or non-infringement or ability of the Service to integrate or interoperate with other technologies. Unplanned system outages may occur, and Thoth does not warrant that the Website and/or the Service will run uninterrupted or error-free, or that any defects will be corrected. Thoth shall not be liable for the accuracy, reliability, correctness or usefulness of the Website and/or the Service or the results thereof and disclaims all liability for harm or damages caused by any third-party content. The User bears the entire risk as to the use of the Website and/or the Service.
        \nFor the purpose of this clause, "Affiliates" means, in relation to Thoth, any entity that controls, is under the control of, or is under common control with, Thoth, where control means the direct or indirect ownership of more than 50 per cent of the voting capital or similar right of ownership of that party or the legal power to direct or cause the direction of the general management and policies of that party, whether through the ownership of voting capital, by contract or otherwise.`
      ]
    },
    {
      title: "Liability",
      texts: [
        `To the maximum extent permitted by applicable law, in no event will Thoth Parties be liable for any special, indirect, moral, consequential, incidental, punitive or exemplary damages, the use or inability to use the services, computer malfunction or failure, server down time, failure of the service to operate with other programs, loss of profits, reputation, use or revenue, loss or corruption of data, or interruption of business, regardless if the Thoth Party was aware of the likelihood of such damages occurring, and whether such liability is based on contract, tort, negligence, strict liability, products liability or otherwise. For the avoidance of any doubt, under no circumstances may any Thoth Party be liable for any claims of any kind and nature that may be asserted, granted, or imposed against, directly or indirectly, arising from, or in connection with, your Content, your failure to comply with the Terms of Service or use of your account. Except as prohibited by law, if there is liability found on the part of Thoth, it will be limited to the amount paid for the Service, and under no circumstances will there be consequential or punitive damages.`
      ]
    },
    {
      title: "Termination",
      texts: [
        `We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.
        \nIf you wish to terminate your account, you may simply discontinue using Service, or email us at
         ${this.getStyledLink(
           "hello@thoth.art",
           true
         )}, with the subject line "Account Termination".
        \nAll provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.`
      ]
    },
    {
      title: "Governing Law",
      texts: [
        `These Terms shall be governed and construed in accordance with the laws of Singapore without regard to its conflict of law provisions.
        \nOur failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.`
      ]
    },
    {
      title: "Changes to Service",
      texts: [
        `We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.`
      ]
    },
    {
      title: "Amendments to Terms",
      texts: [
        `We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.
        \nYour continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.
        \nBy continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.`
      ]
    },
    {
      title: "Waiver and Severability",
      texts: [
        `No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.
        \nIf any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.`
      ]
    },
    {
      title: "Acknowledgement",
      texts: [
        `By using Service or other services provided by us, you acknowledge that you have read these terms of service and agree to be bound by them.`
      ]
    },
    {
      title: "Contact Us",
      texts: [
        `Please send your feedback, comments, requests for technical support:
        \nBy email: ${this.getStyledLink("hello@thoth.art", true)}.`
      ]
    }
  ];

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
  getStyledLink(link: string, email = false) {
    const href = email ? `mailto:${link}` : link;
    return `<a href="${href}" class="link--thoth">${link}</a>`;
  }
  getStyledSubtitle(subtitle: string) {
    return `<span style="font-weight: 600">${subtitle}</span><br />`;
  }
}
