
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
interface PeopleType {
  majorTitle?: string;
  image: string;
  title: string;
  subtitle?: string;
}
@Options({
  inject: ["mq"],
  computed: {
    mobile() {
      return this.mq.current === "sm" || this.mq.current === "md"
        ? true
        : false;
    }
  }
})
export default class PeopleTypes extends Vue {
  currentType = 0;
  people: PeopleType[] = [
    {
      majorTitle: "For Online Webinars",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-04.png",
      title: "Summarize At Speed",
      subtitle:
        "Transform live discussions into real-time social media content. Great for sharing on-the-go."
    },
    {
      majorTitle: "For Learning Content",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-05.png",
      title: "Index Topics At Scale",
      subtitle:
        "Turn long transcripts into visuals with a punch. An image literally speaks a thousand words."
    },
    {
      majorTitle: "For Strategy Workshops",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-06.png",
      title: "Structure For Context",
      subtitle:
        "Our AI cuts through complexity for impact. Build consensus with meaningful takeaways."
    },
    {
      majorTitle: "For Meeting Reports",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-07.png",
      title: "Synthesize For Nuance",
      subtitle:
        "Create shared libraries of team discussions, to align on decisions and strategize for action."
    }
  ];
  changeCurrentType(i) {
    this.currentType = i;
  }
}
