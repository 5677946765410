
import { Vue, Options } from "vue-class-component";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";

@Options({
  components: {
    ThothButton
  }
})
export default class BetaMission extends Vue {
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  ButtonColour = ButtonColour;

  images: { [id: string]: string } = {
    missionIlloOne: STATIC_ASSET_IMAGES_BASE_URL + "/mission-illo-1.png",
    missionIlloTwo: STATIC_ASSET_IMAGES_BASE_URL + "/mission-illo-2.png"
  };

  viewCareers() {
    window.open(
      "https://thoth-ai.notion.site/Careers-at-Thoth-166c5280c1d74870ad593920dd994a5c",
      "_blank"
    );
  }
}
