
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
export enum ButtonColour {
  Brand,
  Destructive,
  Grey,
  White
}
export enum ButtonEmphasis {
  Contained,
  Outlined,
  Text
}
export enum ButtonSize {
  XS,
  S,
  M,
  L
}
@Options({
  props: {
    text: {
      type: String,
      required: true
    },
    emphasis: {
      type: Number as PropType<ButtonEmphasis>,
      required: false,
      default: ButtonEmphasis.Contained
    },
    colour: {
      type: Number as PropType<ButtonColour>,
      required: false,
      default: ButtonColour.Brand
    },
    size: {
      type: Number as PropType<ButtonSize>,
      required: false,
      default: ButtonSize.M
    },
    disabled: {
      type: Boolean,
      required: false
    },
    iconBefore: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: false
    }
  }
})
export default class ThothButton extends Vue {
  // Props
  text!: string;
  emphasis!: ButtonEmphasis;
  colour!: ButtonColour;
  size!: ButtonSize;
  disabled?: boolean;
  iconBefore?: string;
  email?: string;

  // Data
  getButtonCSSProperties(
    borderRadius: number,
    vPadding: number,
    hPadding: number,
    fontSize: number,
    lineHeight: number
  ) {
    return {
      borderRadius: `${borderRadius}px`,
      padding: `${vPadding}px ${hPadding}px`,
      font: `normal 600 ${fontSize}px/${lineHeight}px "Baton Turbo"`
    };
  }
  buttonProperties = {
    [ButtonSize.XS]: this.getButtonCSSProperties(10, 4, 8, 12, 16),
    [ButtonSize.S]: this.getButtonCSSProperties(12, 6, 12, 14, 20),
    [ButtonSize.M]: this.getButtonCSSProperties(16, 10, 20, 16, 20),
    [ButtonSize.L]: this.getButtonCSSProperties(16, 14, 24, 16, 20)
  };
  get buttonStyle() {
    return {
      ...this.buttonProperties[this.size],
      textTransform: "uppercase"
    };
  }
  getButtonClass() {
    return `${ButtonColour[this.colour].toLowerCase()} ${ButtonEmphasis[
      this.emphasis
    ].toLowerCase()}`;
  }
}
