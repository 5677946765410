
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import Modal from "@/components/common/Modal.vue";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { TYPEFORM_FEEDBACK_FORM } from "@/services";

@Options({
  props: {
    userEmail: {
      type: String as PropType<string>,
      required: true
    }
  },
  components: {
    Modal
  }
})
export default class HelpFloatingButton extends Vue {
  userEmail!: string;

  openMiniModal = false;
  modalSetTimeout = 0;
  icons: { [id: string]: string } = {
    helpFloatIcon: STATIC_ASSET_ICONS_BASE_URL + "/help-floating-icon.svg",
    closeFloatIcon: STATIC_ASSET_ICONS_BASE_URL + "/close-floating-icon.svg",
    modalQuestion: STATIC_ASSET_ICONS_BASE_URL + "/help-modal-question.svg",
    modalChat: STATIC_ASSET_ICONS_BASE_URL + "/help-modal-chat.svg"
  };

  newTab(str: string) {
    window.open(str, "_blank");
  }

  modalOptions = [
    {
      icon: this.icons.modalChat,
      text: "Submit Feedback",
      link: TYPEFORM_FEEDBACK_FORM + `?email=${this.userEmail}`
    },
    {
      icon: this.icons.modalQuestion,
      text: "Get in Touch"
    }
  ];
  openMiniModalHandler() {
    if (!this.openMiniModal) {
      this.openMiniModal = true;
      this.modalSetTimeout = setTimeout(() => {
        this.openMiniModal = false;
        this.modalSetTimeout = 0;
      }, 10000);
    } else {
      if (this.modalSetTimeout) {
        window.clearTimeout(this.modalSetTimeout);
        this.modalSetTimeout = 0;
      }
      this.openMiniModal = false;
    }
  }

  getInTouchModalText = `Drop us an email at <span class="link--thoth">hello@thoth.art</span> — We’ll get back to you soon!`;
  get modalElement() {
    return document.querySelector("#get-in-touch");
  }
  openModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }
  closeModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).hide();
    }
  }
}
