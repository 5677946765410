import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_PreviewModes = _resolveComponent("PreviewModes")
  const _component_UpgradeInfographic = _resolveComponent("UpgradeInfographic")
  const _component_FrequentlyAskedQuestions = _resolveComponent("FrequentlyAskedQuestions")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_NavBar, { "logged-in": _ctx.isLoggedIn }, null, 8, ["logged-in"]),
    _createVNode(_component_PreviewModes, { "logged-in": _ctx.isLoggedIn }, null, 8, ["logged-in"]),
    _createVNode(_component_UpgradeInfographic),
    _createVNode(_component_FrequentlyAskedQuestions),
    _createVNode(_component_Footer)
  ], 64))
}