
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { LOCAL_ACCESS_KEY, LOCAL_REFRESH_KEY } from "@/services";
import {
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL
} from "@/commons";
import { RESET_STORE_ACTION } from "@/store/constants";
@Options({
  components: {
    ThothButton
  }
})
export default class MobileBlocker extends Vue {
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  ButtonColour = ButtonColour;

  images: { [id: string]: string } = {
    logo: STATIC_ASSET_ICONS_BASE_URL + "/navbar-thoth-logo-blue.svg",
    mobile: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-02.png"
  };

  logout() {
    if (localStorage.getItem(LOCAL_ACCESS_KEY)) {
      localStorage.removeItem(LOCAL_ACCESS_KEY);
    }
    if (localStorage.getItem(LOCAL_REFRESH_KEY)) {
      localStorage.removeItem(LOCAL_REFRESH_KEY);
    }
    this.$store.dispatch(RESET_STORE_ACTION);
    if (this.$route.name === "Home") {
      location.reload();
    } else {
      this.$router.push({ name: "Home" });
    }
  }
}
