
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { Infographic, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";

@Options({
  props: {
    infographics: {
      type: Array as PropType<Infographic[]>,
      required: true
    },
    activeInfographic: {
      type: Number,
      required: true
    },
    makeActiveInfographic: {
      type: Function as PropType<() => void>,
      required: true
    }
  }
})
export default class LayoutEdits extends Vue {
  icons: { [id: string]: string } = {
    activeLayout: STATIC_ASSET_ICONS_BASE_URL + "/active-layout-check-icon.svg"
  };
}
