<template>
  <!-- Popup Form -->
  <div v-if="showPopup" class="popup-overlay" @click.self="showPopup = false">
    <div class="popup-content" @click.stop>
      <button class="close" @click="showPopup = false">×</button>
      <h2 class="text-block-38">Get Your Free PDF</h2>
      <form @submit.prevent="submitForm">
        <div class="input-group">
          <input type="text" placeholder="First Name" v-model="firstName" required />
          <p v-if="submitted && !firstName" class="error">
            First name is required.
          </p>
        </div>
        <div class="input-group">
          <input type="text" placeholder="Last Name" v-model="lastName" required />
          <p v-if="submitted && !lastName" class="error">
            Last name is required.
          </p>
        </div>
        <div class="input-group">
          <input type="email" placeholder="Enter your email address" v-model="email" required />
          <p v-if="submitted && !email" class="error">
            Email is required.
          </p>
        </div>
        <div class="input-group">
          <input type="checkbox" id="privacyCheck" v-model="agreedToPrivacy" />
          <label class="text-block-57" for="privacyCheck">I agree to how my data is used by the website in
            accordance with the
            <a href="https://thoth.art/privacy" target="_blank" class="link-6">privacy policy</a>.</label>

        </div>
        <p v-if="submitted && !agreedToPrivacy" class="error">
          You must agree to the privacy policy.
        </p>
        <button type="submit" class="btn-submit ">SEND ME</button>
      </form>
    </div>
  </div>

  <div class="navbar w-nav" data-collapse="medium" data-animation="default" data-duration="400" data-doc-height="1"
    data-easing="ease" data-easing2="ease">
    <div class="div-block-65">
      <div class="container">
        <router-link :to="`/${eventName}`" aria-current="page" class="brand w-nav-brand">
          <img v-if="eventConfig && eventConfig.partnerLockup" :src="eventConfig.partnerLockup" alt="Event Logo"
            width="180" class="image-41" />
          <span v-else>Event Logo</span>
        </router-link>
        <div class="right_side_navbar">
          <nav role="navigation" class="nav-menu w-nav-menu">
            <router-link :to="{ path: `/${eventName}` }" :class="{
              'navlinks w-nav-link': true,
              'w--current': isActiveRoute(`/${eventName}`)
            }">
              Highlights
            </router-link>



            <router-link :to="{ path: `/${eventName}/agenda` }" :class="{
              'navlinks w-nav-link': true,
              'w--current': isActiveRoute(`/${eventName}/agenda`)
            }">
              Summaries
            </router-link>
            <router-link :to="{ path: `/${eventName}/about` }" :class="{
              'navlinks w-nav-link': true,
              'w--current': isActiveRoute(`/${eventName}/about`)
            }">
              About
            </router-link>
          </nav>
          <div class="iconButtonParent">
            <router-link :to="`/${eventName}/likeinfographics`">
              <div class="iconButton">
                <img class="icon" alt="" src="../images/Like.svg" />
              </div>
            </router-link>
            <button @click="showPopup = true" class="mail-button w-button" id="pdfButton">
              <span> Get a Compiled PDF </span>
              <img src="../images/download.svg" alt="PDF Icon" class="button-icon" style="margin-left: 4px;" />
            </button>
          </div>
        </div>


        <div class="menu-button w-nav-button" @click="toggleMobileMenu">
          <img src="../images/5f89831818dcb71775e1b3ea_hamburger-white.svg" width="30" alt="" class="image-15" />
        </div>
      </div>

      <!-- Mobile Menu Popup -->
      <div class="navbarmobile">
        <div class="leftHandPanel">
          <img class="logoIcon" alt="" :src="eventConfig.partnerLockup" />
        </div>
        <div class="links">
          <router-link :to="{ path: `/${eventName}` }"
            :class="isActiveRoute(`/${eventName}`) ? 'navigation' : 'navigation1'">
            <div class="navigation3">Highlights</div>
          </router-link>
          <router-link :to="{ path: `/${eventName}/agenda` }"
            :class="isActiveRoute(`/${eventName}/agenda`) ? 'navigation' : 'navigation1'">
            <div class="navigation3">Visual summaries</div>
          </router-link>
          <router-link :to="{ path: `/${eventName}/about` }"
            :class="isActiveRoute(`/${eventName}/about`) ? 'navigation' : 'navigation1'">
            <div class="navigation3">About</div>
          </router-link>
          <router-link :to="{ path: `/${eventName}/likeinfographics` }"
            :class="isActiveRoute(`/${eventName}/likeinfographics`) ? 'navigation' : 'navigation1'">
            <div class="navigation3">Liked Infographics</div>
          </router-link>
          <a @click="showPopup = true" :class="showPopup ? 'navigation' : 'navigation1'">
            <div class="navigation3">Get a compiled PDF</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { MONDAY_BOARD_ID, MONDAY_API_KEY } from "@/services";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
export default {
  inject: ["eventConfig"],
  computed: {
    eventName() {
      return this.$route.params.eventName; // fallback to a default
    }
  },
  data() {
    return {
      isDropdownOpen: false, // State to manage dropdown visibility
      isMobileMenuOpen: false, // State to manage mobile menu popup
      showPopup: false,
      firstName: "",
      lastName: "",
      email: "",
      agreedToPrivacy: false,
      submitted: false,
      submitting: false,
    };
  },
  methods: {
    async submitForm() {
      this.submitted = true;
      if (this.submitting) {
        return;  // Prevent multiple submissions
      }
      if (this.validateForm()) {
        this.submitting = true;
        try {
          // Prepare the column values
          const columnValues = JSON.stringify({
            lead_email: {
              email: this.email,
              text: this.email,
            },
          });

          // Construct the GraphQL mutation
          const query = `
      mutation {
        create_item(
          board_id: ${MONDAY_BOARD_ID},
          item_name: "${this.firstName} ${this.lastName}",
          column_values: "${columnValues.replace(/"/g, '\\"')}"
        ) {
          id
        }
      }
    `;
          // Make the API request to Monday.com
          const response = await fetch("https://api.monday.com/v2", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${MONDAY_API_KEY}`,
            },
            body: JSON.stringify({ query }),
          });
          const data = await response.json();
          if (data.errors) {
            console.error("API Error:", data.errors);
            return new Response(
              JSON.stringify({
                error: "Failed to create item in Monday.com",
                details: data.errors,
              }),
              {
                status: 500,
              },
            );
          }
          toastr.success('Form submission was successful!');
          this.showPopup = false;

          return new Response(
            JSON.stringify({
              message: "Form submission was successful",
              itemId: data.data.create_item.id,
            }),
            { status: 200, headers: { "Content-Type": "application/json" } },
          );

        } catch (error) {
          console.error("Error pushing to Monday.com:", error.message);
          toastr.warning("Opps, something went wrong. Please try again later");
          return new Response("Failed to subscribe, please try again later", {
            status: 500,
          });
        } finally {
          // Set a timeout before allowing another submission
          setTimeout(() => {
            this.submitting = false;  // Reset the submitting state after 3 seconds
          }, 3000);
        }
      }
    },
    validateForm() {
      return this.firstName && this.lastName && this.email && this.agreedToPrivacy;
    },
    isActiveRoute(routePath) {
      return this.$route.path === routePath;
    },
    toggleMobileMenu(event) {
      event.stopPropagation(); // Prevents the event from bubbling up to document
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      if (this.isMobileMenuOpen) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleClickOutside);
        });
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeDropdown();
        this.isMobileMenuOpen = false; // Ensure the mobile menu is closed
        document.removeEventListener("click", this.handleClickOutside);
      }
    },

    openDropdown() {
      this.isDropdownOpen = true;
    },

    closeDropdown() {
      this.isDropdownOpen = false;
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>


<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  max-width: 400px;
}

.button-icon {
  width: 16px;
  /* Adjust size as needed */
  height: 16px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.input-group {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  gap: 10px;
}

.icon {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.iconButton {
  width: 36px;
  border-radius: 12px;
  background-color: #363636;
  border: 1px solid #fff;
  box-sizing: border-box;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
}

.iconButtonParent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: 'DM Sans';
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
}

input[type="text"]:focus,
input[type="email"]:focus {
  border-color: var(--primary-color);
}

.btn-submit {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.link-privacy {
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #0066cc;
  text-decoration: none;
}

.link-privacy:hover {
  text-decoration: underline;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 50px;
  max-width: 100%;
  flex-wrap: nowrap;
}

.nav-menu {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
}

.dropdown {
  position: relative;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
}


.menu-button {
  cursor: pointer;
}

/* Mobile menu popup */
.mobile-menu-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu-list {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
  max-width: 300px;
}

/* Animation for the mobile menu */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.navbarmobile {
  display: none;
}

/* Adjustments for responsiveness */
@media (max-width: 1024px) {
  .nav-menu {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .logoIcon {
    /* width: 162.7px; */
    position: relative;
    height: 28px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .leftHandPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .navigation1 {
    position: relative;
    line-height: 130%;
    font-weight: 400;
    text-decoration: none;
  }

  .navigation {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--highlight-color);
    font-weight: 300;
    text-decoration: none;
  }

  .navigation3 {
    position: relative;
    line-height: 130%;
    white-space: nowrap;
  }

  .navigation2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 4px;
    white-space: nowrap;
  }

  .links {
    align-self: stretch;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0px;
    gap: 20px;
    white-space: nowrap;
  }

  .navbarmobile {
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    left: 0px;
    background-color: #111;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 24px 0px;
    box-sizing: border-box;
    gap: 12px;
    text-align: left;
    font-size: 16px;
    color: #fff;
    font-family: 'DM Sans';
  }
}
</style>
