
import { Vue } from "vue-class-component";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
export default class Steps extends Vue {
  steps = [
    {
      notif: "Step 1",
      title: "Upload",
      subtitle: "Upload up to 5 pages of text.",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/steps/Step1.png"
    },
    {
      notif: "Step 2",
      title: "Preview",
      subtitle: "Preview your infographic in less than 1 minute.",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/steps/Step2.png"
    },
    {
      notif: "Step 3",
      title: "Customize",
      subtitle: "Quickly edit colour, text, and layout.",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/steps/Step3.png"
    }
  ];
}
