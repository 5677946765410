
import { EditMode, STATIC_ASSET_ICONS_BASE_URL, VersionData } from "@/commons";
import UIkit from "uikit";
import {
  CURRENT_VERSION_GETTER,
  PROJECT_VERSIONS_GETTER,
  SELECTED_VERSION_GETTER,
  SET_EDIT_MODE_ACTION,
  SET_SELECTED_VERSION_ACTION,
  UPDATE_INFOGRAPHICS_ACTION
} from "@/store/constants";
import InputModal from "@/components/common/InputModal.vue";
import TextField from "@/components/common/TextField.vue";
import { Form } from "vee-validate";
import { Options, Vue } from "vue-class-component";

export interface VersionDateData {
  date: number;
  namedVersions: Array<VersionData>;
  autoVersions: Array<VersionData>;
}

export interface VersionInterfaceData {
  year: number;
  month: number;
  dateVersions: Array<VersionDateData>;
}

@Options({
  components: {
    TextField,
    Form,
    InputModal
  }
})
export default class VersionHistorySidebar extends Vue {
  EditMode = EditMode;
  // Data
  icons: { [id: string]: string } = {
    chevron: STATIC_ASSET_ICONS_BASE_URL + "/caret-right.svg",
    threedots: STATIC_ASSET_ICONS_BASE_URL + "/three-dots-icon.svg"
  };
  singleDateVersionOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  };
  multiDateVersionOptions = {
    hour: "2-digit",
    minute: "2-digit"
  };
  versionsByMonthYear: VersionInterfaceData[] = [];
  hoverNameDate: VersionData | null = null;
  selectedAuto = "";
  showActions: VersionData | null = null;

  get selectedNameOrDate() {
    return this.$store.getters[SELECTED_VERSION_GETTER];
  }

  get currentVersion() {
    return this.$store.getters[CURRENT_VERSION_GETTER];
  }

  get projectVersions() {
    return this.$store.getters[PROJECT_VERSIONS_GETTER];
  }

  get modalElement() {
    return document.querySelector("#change-name-modal");
  }

  close() {
    this.$store.dispatch(SET_EDIT_MODE_ACTION, EditMode.None);
  }

  groupByMonthYear(array: VersionData[]) {
    const groups: VersionInterfaceData[] = [];
    array.map(arr => {
      const dateObject = new Date(arr.dateTime);
      const dateDate = dateObject.getDate();
      const dateMonth = dateObject.getMonth() + 1;
      const dateYear = dateObject.getFullYear();
      const findMonthYear = groups.findIndex(o => {
        return o.month === dateMonth && o.year === dateYear;
      });
      if (findMonthYear > -1) {
        const findDate = groups[findMonthYear].dateVersions.findIndex(o => {
          return o.date === dateDate;
        });
        if (findDate > -1) {
          if (arr.name) {
            groups[findMonthYear].dateVersions[findDate].namedVersions.push(
              arr
            );
          } else {
            groups[findMonthYear].dateVersions[findDate].autoVersions.push(arr);
          }
        } else {
          if (arr.name) {
            groups[findMonthYear].dateVersions.push({
              date: dateDate,
              namedVersions: [arr],
              autoVersions: []
            });
          } else {
            groups[findMonthYear].dateVersions.push({
              date: dateDate,
              namedVersions: [],
              autoVersions: [arr]
            });
          }
        }
      } else {
        if (arr.name) {
          groups.push({
            month: dateMonth,
            year: dateYear,
            dateVersions: [
              {
                date: dateDate,
                namedVersions: [arr],
                autoVersions: []
              }
            ]
          });
        } else {
          groups.push({
            month: dateMonth,
            year: dateYear,
            dateVersions: [
              {
                date: dateDate,
                namedVersions: [],
                autoVersions: [arr]
              }
            ]
          });
        }
      }
    });
    return groups;
  }

  selectVersion(nameDate: VersionData) {
    if (JSON.stringify(nameDate) !== JSON.stringify(this.showActions)) {
      this.showActions = null;
    }
    if (JSON.stringify(this.selectedNameOrDate) === JSON.stringify(nameDate)) {
      this.$store.dispatch(SET_SELECTED_VERSION_ACTION, null);
    } else {
      this.$store.dispatch(SET_SELECTED_VERSION_ACTION, nameDate);
    }
    if (this.selectedAuto) {
      if (this.selectedNameOrDate) {
        if (
          new Date(this.selectedNameOrDate.dateTime).toDateString() !==
          new Date(this.selectedAuto).toDateString()
        ) {
          this.selectedAuto = "";
        }
      }
    }
  }

  selectMultiCat(autoDate: string) {
    if (this.selectedNameOrDate) {
      this.$store.dispatch(SET_SELECTED_VERSION_ACTION, null);
    }
    if (this.selectedAuto === autoDate) {
      this.selectedAuto = "";
    } else {
      this.selectedAuto = autoDate;
    }
  }

  hoverVersion(nameDate: VersionData) {
    this.hoverNameDate = nameDate;
    if (this.showActions !== nameDate) {
      this.showActions = null;
    }
  }

  selectActionContainer(event: Event, nameDate: VersionData) {
    if (event.stopPropagation) {
      event.stopPropagation(); // W3C model
    } else {
      event.cancelBubble = true;
    }
    if (this.showActions === nameDate) {
      this.showActions = null;
    } else {
      this.showActions = nameDate;
    }
  }

  compareHoverOption(nameDate: VersionData | null) {
    if (nameDate == null) {
      return false;
    }
    return (
      JSON.stringify(this.hoverNameDate) === JSON.stringify(nameDate) ||
      JSON.stringify(this.selectedNameOrDate) === JSON.stringify(nameDate)
    );
  }

  compareVersion(nameDate: VersionData) {
    return JSON.stringify(this.selectedNameOrDate) === JSON.stringify(nameDate);
  }

  compareCurrent(nameDate: VersionData) {
    return JSON.stringify(this.currentVersion) === JSON.stringify(nameDate);
  }

  checkAuto(autoDate: string) {
    return this.selectedAuto === autoDate;
  }

  compareActions(nameDate: VersionData) {
    return JSON.stringify(this.showActions) === JSON.stringify(nameDate);
  }

  openNamingModal(event: Event) {
    if (event.stopPropagation) {
      event.stopPropagation(); // W3C model
    } else {
      event.cancelBubble = true;
    }
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }

  closeNamingModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).hide();
    }
  }

  async onVersionNameSubmit(values) {
    try {
      const { projectId } = this.$route.params;
      await this.$store.dispatch(UPDATE_INFOGRAPHICS_ACTION, {
        projectId,
        body: {
          name: values["Version Name"]
        }
      });
    } catch (err) {
      console.log(err);
    }
    this.closeNamingModal();
  }

  async restore(event: Event, version: VersionData) {
    if (event.stopPropagation) {
      event.stopPropagation(); // W3C model
    } else {
      event.cancelBubble = true;
    }
    await this.$store.dispatch(SET_SELECTED_VERSION_ACTION, version);
    this.$emit("restoreVersion");
  }

  mounted() {
    this.versionsByMonthYear = this.groupByMonthYear(this.projectVersions);
  }
}
