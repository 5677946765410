import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container__mobile__blocker"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileBlocker = _resolveComponent("MobileBlocker")
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_CreateInfographic = _resolveComponent("CreateInfographic")
  const _component_HelpFloatingButton = _resolveComponent("HelpFloatingButton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_MobileBlocker),
    (_ctx.status !== _ctx.CreationStatus.GeneratingInfographic)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          (_ctx.isLoggedIn && _ctx.status !== _ctx.CreationStatus.GeneratingInfographic)
            ? (_openBlock(), _createBlock(_component_NavBar, {
                key: 0,
                "logged-in": _ctx.isLoggedIn,
                stage: _ctx.ProjectStage.Create
              }, null, 8, ["logged-in", "stage"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_CreateInfographic, {
      class: "container__mobile__blocker",
      status: _ctx.status,
      onUpdateStatus: _ctx.updateStatus
    }, null, 8, ["status", "onUpdateStatus"]),
    (_ctx.loaded && _ctx.status !== _ctx.CreationStatus.GeneratingInfographic)
      ? (_openBlock(), _createBlock(_component_HelpFloatingButton, {
          key: 1,
          class: "container__mobile__blocker",
          "user-email": _ctx.user.email
        }, null, 8, ["user-email"]))
      : _createCommentVNode("", true)
  ], 64))
}