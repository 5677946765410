
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  inject: ["mq"],
  computed: {
    mobile() {
      return this.mq.current === "sm" || this.mq.current === "md"
        ? "uk-width-2-3"
        : "uk-width-1-3";
    }
  },
  components: { ThothButton }
})
export default class CallToAction extends Vue {
  image = STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-08.png";
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  goToDemo() {
    this.$router.push("/demo");
  }
  goToWaitlist() {
    this.$router.push("/waitlist");
  }
}
