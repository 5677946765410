import {
  USER_DETAILS_API,
  LOGOS_API,
  COLOUR_PALETTES_API,
  BRAND_LINKS_API,
  PROJECT_API
} from "../services";
import api from "../services/api";

const getDefaultState = () => {
  return {
    loaded: false,
    user: {},
    projects: [],
    payments: {}
  };
};

const state = getDefaultState();

const getters = {
  loaded(state) {
    return state.loaded;
  },
  user(state) {
    return state.user;
  },
  projects(state) {
    return state.projects;
  },
  profile(state) {
    return state.user.profile;
  },
  notifications(state) {
    return state.user.notifications;
  }
};

const actions = {
  async loadStore(context) {
    await context.dispatch("listUser");
    await context.dispatch("listProjects");
    context.commit("load");
  },
  async listUser(context) {
    let response;
    try {
      response = await api(USER_DETAILS_API);
      console.log(response.data);
      context.commit("setUser", response.data);
    } catch (err) {
      console.log(err);
    }
  },
  // Brand Kit-related actions
  async addLogo(context, form) {
    let response;
    try {
      response = await api.post(LOGOS_API, form);
    } catch (err) {
      console.log(`Error at action dashboard/addLogo: ${err}`);
      throw err.response.data;
    }
    context.commit("setLogo", response.data);
  },
  async deleteLogo(context, logoUUID) {
    try {
      await api.delete(`${LOGOS_API}/${logoUUID}`);
    } catch (err) {
      console.log(`Error at action dashboard/deleteLogo: ${err}`);
      throw err.response.data;
    }
    context.commit("deleteLogo", logoUUID);
  },
  async createColourPalette(context, payload) {
    let response;
    try {
      response = await api.post(
        `${COLOUR_PALETTES_API}/${encodeURIComponent(payload.paletteName)}`,
        payload.form ?? {}
      );
    } catch (err) {
      console.log(`Error at action dashboard/createColourPalette: ${err}`);
      throw err.response.data;
    }
    context.commit("setColourPalettes", response.data);
  },
  async deleteColourPalette(context, paletteName) {
    let response;
    try {
      response = await api.delete(
        `${COLOUR_PALETTES_API}/${encodeURIComponent(paletteName)}`
      );
    } catch (err) {
      console.log(`Error at action dashboard/deleteColourPalette: ${err}`);
      throw err.response.data;
    }
    context.commit("setColourPalettes", response.data);
  },
  async addColourToPalette(context, payload) {
    let response;
    const path =
      COLOUR_PALETTES_API +
      `/${encodeURIComponent(payload.paletteName)}` +
      `/${encodeURIComponent(payload.colour)}`;
    try {
      response = await api.post(path);
    } catch (err) {
      console.log(`Error at action dashboard/addColourToPalette: ${err}`);
      throw err.response.data;
    }
    context.commit("setColourPalettes", response.data);
  },
  async updateColourInPalette(context, payload) {
    let response;
    const path =
      COLOUR_PALETTES_API +
      `/${encodeURIComponent(payload.paletteName)}` +
      `/${encodeURIComponent(payload.colour)}`;
    try {
      response = await api.put(path, { newColour: payload.newColour });
    } catch (err) {
      console.log(`Error at action dashboard/updateColourInPalette: ${err}`);
      throw err.response.data;
    }
    context.commit("setColourPalettes", response.data);
  },
  async updatePaletteName(context, payload) {
    let response;
    try {
      response = await api.put(
        `${COLOUR_PALETTES_API}/${encodeURIComponent(payload.paletteName)}`,
        { newPaletteName: payload.newPaletteName }
      );
    } catch (err) {
      console.log(`Error at action dashboard/updatePaletteName: ${err}`);
      throw err.response.data;
    }
    context.commit("setColourPalettes", response.data);
  },
  async deleteColourInPalette(context, payload) {
    let response;
    const path =
      COLOUR_PALETTES_API +
      `/${encodeURIComponent(payload.paletteName)}` +
      `/${encodeURIComponent(payload.colour)}`;
    try {
      response = await api.delete(path);
    } catch (err) {
      console.log(`Error at action dashboard/deleteColourInPalette: ${err}`);
      throw err.response.data;
    }
    context.commit("setColourPalettes", response.data);
  },
  async updateBrandLinks(context, payload) {
    let response;
    try {
      response = await api.put(`${BRAND_LINKS_API}/${payload.social}`, {
        url: payload.url
      });
    } catch (err) {
      console.log(`Error at action dashboard/updateBrandLinks : ${err}`);
      throw err.response.data;
    }
    context.commit("updateBrandLinks", response.data);
  },
  revertBrandLink(context, payload) {
    context.commit("setBrandLink", {
      social: payload.social,
      url: payload.newUrl
    });
    setTimeout(() => {
      context.commit("setBrandLink", {
        social: payload.social,
        url: payload.oldUrl
      });
    }, 1);
  },
  // Project-related actions
  async listProjects(context) {
    let response;
    try {
      response = await api.get(PROJECT_API);
    } catch (err) {
      console.log(err);
    }
    context.commit("setAllProjects", response.data);
  },
  async listProject(context, projectUUID) {
    const projects = Object.values(context.getters["projects"]);
    const project = projects.find(p => p.uuid === projectUUID);
    context.commit("project/setProject", project, { root: true });
  },
  async trashProject(context, projectUUID) {
    try {
      await api.put(`${PROJECT_API}/${projectUUID}/trash`);
    } catch (err) {
      console.log(`Error at action dashboard/trashProject: ${err}`);
      throw err.response.data;
    }
    context.commit("trashProject", projectUUID);
  },
  async restoreProject(context, projectUUID) {
    try {
      await api.put(`${PROJECT_API}/${projectUUID}/restore`);
    } catch (err) {
      console.log(`Error at action dashboard/restoreProject: ${err}`);
      throw err.response.data;
    }
    context.commit("restoreProject", projectUUID);
  },
  async deleteProject(context, projectUUID) {
    try {
      await api.delete(`${PROJECT_API}/${projectUUID}/delete`);
    } catch (err) {
      console.log(`Error at action dashboard/deleteProject: ${err}`);
      throw err.response.data;
    }
    context.commit("deleteProject", projectUUID);
  },
  async generateInfographics(context, form) {
    let response;
    try {
      response = await api.post(PROJECT_API, form);
    } catch (err) {
      console.log(`Error at action dashboard/generateInfographics: ${err}`);
      throw err.response.data;
    }
    context.commit("setProject", response.data);
    context.commit("project/setProject", response.data, { root: true });
    return response.data.uuid;
  },
  // Actions called by Project module
  updateProject(context, project) {
    context.commit("setExistingProject", project);
    context.dispatch("listProject", project.uuid);
  },
  lockProject(context, { projectId, publishedInfographic }) {
    context.commit("setLockedProject", { projectId, publishedInfographic });
  },
  // Actions called by Settings
  updateProfile(context, payload) {
    try {
      const data = {
        firstName: payload.firstName,
        lastName: payload.lastName
      };
      context.commit("setProfile", data);
    } catch (err) {
      console.log(`Error at action dashboard/updateProfile: ${err}`);
      throw err.response.data;
    }
  },

  updateNotifications(context, payload) {
    try {
      const data = {
        officialNews: payload.officialNews,
        lastName: payload.productTips
      };
      context.commit("setNotifications", data);
    } catch (err) {
      console.log(`Error at action dashboard/updateNotifications: ${err}`);
      throw err.response.data;
    }
  }
};

const mutations = {
  load(state) {
    state.loaded = true;
  },
  setUser(state, user) {
    state.user = {
      email: user.email,
      credits: user.credits,
      logos: user.logos,
      colourPalettes: user.colour_palettes,
      brandLinks: user.brand_links,
      profile: user.profile
        ? user.profile
        : {
            firstName: "",
            lastName: ""
          },
      notifications: user.notifications
        ? user.notifications
        : {
            officialNews: false,
            productTips: false
          }
    };
  },
  setProfile(state, profile) {
    state.user = {
      ...state.user,
      profile: profile
    };
  },
  setNotifications(state, notifications) {
    state.user = {
      ...state.user,
      notifications: notifications
    };
  },
  setEmail(state, email) {
    state.user = {
      ...state.user,
      email: email
    };
  },
  setDarkMode(state, darkmode) {
    state.user.darkmode = darkmode;
  },
  setLogo(state, logo) {
    state.user.logos.push(logo);
  },
  deleteLogo(state, logoUUID) {
    const logoToDelete = state.user.logos.find(l => l.uuid === logoUUID);
    const logoIndex = state.user.logos.indexOf(logoToDelete);
    state.user.logos.splice(logoIndex, 1);
  },
  updateBrandLinks(state, brandLinks) {
    state.user.brandLinks = brandLinks;
  },
  setBrandLink(state, brandLink) {
    state.user.brandLinks[brandLink.social] = brandLink.url;
  },
  setColourPalettes(state, colourPalettes) {
    state.user = {
      ...state.user,
      colourPalettes
    };
  },
  setAllProjects(state, projects) {
    state.projects = projects;
  },
  setProject(state, project) {
    state.projects.push(project);
  },
  trashProject(state, projectUUID) {
    const projectToTrash = state.projects.find(p => p.uuid === projectUUID);
    projectToTrash.state = "TRASHED";
  },
  restoreProject(state, projectUUID) {
    const projectToRestore = state.projects.find(p => p.uuid === projectUUID);
    projectToRestore.state = "IN_USE";
  },
  deleteProject(state, projectUUID) {
    const projectToDelete = state.projects.find(p => p.uuid === projectUUID);
    projectToDelete.state = "DELETED";
    projectToDelete.infographics = [];
  },
  setExistingProject(state, project) {
    const projectToReplace = state.projects.find(p => p.uuid === project.uuid);
    Object.assign(projectToReplace, project);
  },
  setLockedProject(state, { projectId, publishedInfographic }) {
    const projectToLock = state.projects.find(p => p.uuid === projectId);
    projectToLock.status = "LOCKED";
    projectToLock.infographics = [publishedInfographic];
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
