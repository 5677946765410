
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy
  }
})
export default class TatlerGenTCaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-background.png",
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-1.webp",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-2.jpg",
    case3: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-3.jpeg",
    case4: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-4.png",
    case5: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-5.jpeg",
    case6: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-6.jpeg",
    case7: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-7.png",
    case8: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/TatlerGenT-2023-Example-8.jpeg",
  };
  links: { [id: string]: string } = {
    tatler: "https://www.tatlerasia.com/",
    tatlerGenTSummit: "https://media.tatlerasia.com/events/tatler-gent-summit",
    mPlus: "https://www.mplus.org.hk/en/",
    yh: "https://www.tatlerasia.com/gen-t/leadership/this-entrepreneur-is-making-annual-reports-less-boring",
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
  };
}
