
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import PublishInfographic from "@/components/product/PublishInfographic.vue";
import HelpFloatingButton from "@/components/HelpFloatingButton.vue";
import {
  authenticate,
  fetchProject,
  LOCAL_ACCESS_KEY,
  openTypeformModal,
  TYPEFORM_SEEN,
  TYPEFORM_SUBMITTED
} from "@/services";
import {
  USER_GETTER,
  LOAD_PROJECT_STORE_ACTION,
  LOAD_STORE_ACTION,
  LOADED_GETTER,
  PROJECT_LOADED_GETTER
} from "@/store/constants.js";
import { ProjectStage, TypeformOpenMode } from "@/commons";
import MobileBlocker from "@/components/common/MobileBlocker.vue";
@Options({
  components: {
    NavBar,
    PublishInfographic,
    HelpFloatingButton,
    MobileBlocker
  }
})
export default class Publish extends Vue {
  ProjectStage = ProjectStage;
  isLoggedIn = false;
  infographics: any[] = [];

  get dashboardLoaded() {
    return this.$store.getters[LOADED_GETTER];
  }

  get projectLoaded() {
    return this.$store.getters[PROJECT_LOADED_GETTER];
  }

  // get infographics() {
  //   return this.$store.getters[INFOGRAPHICS_GETTER];
  // }

  get user() {
    return this.$store.getters[USER_GETTER];
  }

  async mounted() {
    const { projectId } = this.$route.params;
    this.infographics = await fetchProject(projectId as string);

    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;

    if (this.isLoggedIn && !this.dashboardLoaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }

    if (this.isLoggedIn && !this.projectLoaded) {
      await this.$store.dispatch(LOAD_PROJECT_STORE_ACTION, projectId);
    }

    // If user is accessing Publish from any other route, do not show form
    const previousRoute = String(this.$router.options.history.state.back);
    if (previousRoute.slice(-4) !== "edit") {
      return;
    }

    // If Typeform has been submitted before, do not show again
    if (localStorage.getItem(TYPEFORM_SUBMITTED)) {
      return;
    }

    const publishedListString = localStorage.getItem(TYPEFORM_SEEN);
    const publishedList = publishedListString
      ? JSON.parse(publishedListString)
      : [];

    // Do not show if Typeform has been closed for this project before
    if (!publishedList.includes(projectId)) {
      openTypeformModal({
        openMode: TypeformOpenMode.LOAD,
        modalSize: 70,
        projectId: String(projectId),
        userEmail: this.user.email
      });
      publishedList.push(String(projectId));
      localStorage.setItem(TYPEFORM_SEEN, JSON.stringify(publishedList));
    }
  }
}
