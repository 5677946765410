
import api from "@/services/api";
import { Vue, Options } from "vue-class-component";
import UIkit from "uikit";
import {
  LOCAL_ACCESS_KEY,
  LOCAL_REFRESH_KEY,
  LOGIN_API,
  RESEND_VERIFICATION_API
} from "@/services";
import {
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL
} from "@/commons";
import Toast from "@/components/common/Toast.vue";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";

@Options({
  components: {
    ThothButton,
    Modal,
    Toast
  }
})
class Login extends Vue {
  //imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  username = "";
  password = "";

  icons: { [id: string]: string } = {
    logo: STATIC_ASSET_ICONS_BASE_URL + "/thoth-black-circle-icon.svg",
    eye: STATIC_ASSET_ICONS_BASE_URL + "/eye.svg",
    eyeSlash: STATIC_ASSET_ICONS_BASE_URL + "/eye-slash.svg",
    warningCircle: STATIC_ASSET_ICONS_BASE_URL + "/warning-circle.svg"
  };
  images: { [id: string]: string } = {
    verifyImage: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-02.png"
  };
  passwordHide = true;
  loginError = "";
  emailIsSent = false;
  toastProps = { message: "" };
  //To be used once switched to more specific error
  emailError = "";
  passwordError = "";

  get emailIsVerified() {
    return this.$route.query.verified === "true";
  }

  get emailIsChanged() {
    return this.$route.query.emailChanged === "true";
  }

  get passwordIsChanged() {
    return this.$route.query.passwordChanged === "true";
  }

  get userIsVerified() {
    return false;
  }
  get verifyModalTitle() {
    return this.emailIsVerified ? "Email verified" : "Something went wrong!";
  }

  get AccChangeModalTitle() {
    return "Something went wrong!";
  }

  get verifyModalMessage() {
    return this.emailIsVerified
      ? "Your email address has been verified."
      : "The link you&rsquo;re using has expired. Click on the button below to send a new verification link to your email.";
  }

  get sentEmailModalMessage() {
    return this.emailIsSent
      ? `We&rsquo;ve sent you an email with instructions to verify your email address.<br /><br />If you don&rsquo;t receive the email in the next 10 minutes, check your spam folder. We sent it from <span style="display:inline-block"><strong>no-reply@thoth.art</strong></span>.<br /><br />For support, contact us at <a href="mailto:hello@thoth.art" class="link--thoth body-2">hello@thoth.art</a>.`
      : `You need to verify your email to continue.<br /><br />If you have not received the verification email, please check your &quot;Spam&quot; or &quot;Bulk Email&quot; folder. You can also click on the button below to resend the verification link.`;
  }

  get AccChangeErrorModalMessage() {
    return "The link has already been used, or the request has been canceled. You can request another change through your profile settings.";
  }

  get disableButton() {
    if (this.loginError) {
      return true;
    }
    return !this.username.trim() || !this.password.trim();
  }
  passwordHideHandler() {
    this.passwordHide = !this.passwordHide;
  }
  loginErrorClear() {
    if (this.loginError) {
      this.loginError = "";
    }
  }

  // For use if emailError/passwordError is required
  // emailErrorClear() {
  //   if (this.emailError) {
  //     this.emailError = "";
  //   }
  // }
  // passwordErrorClear() {
  //   if (this.passwordError) {
  //     this.passwordError = "";
  //   }
  // }

  openModal(modalClass: string) {
    UIkit.modal(modalClass).show();
  }

  closeModal(modalClass: string) {
    console.log(modalClass);
    UIkit.modal(modalClass).hide();
  }

  closeLoginModalAndResetState() {
    this.closeModal("#login__modal");
    setTimeout(() => {
      this.emailIsSent = false;
    }, 500);
  }

  closeVerifyModalAndResetState() {
    this.closeModal("#verify__modal");
    setTimeout(() => {
      this.emailIsSent = false;
    }, 500);
  }

  toggleToast() {
    const element = document.querySelector("#toast") as HTMLElement;
    if (element) {
      UIkit.toggle(element).toggle();
      setTimeout(() => {
        UIkit.toggle(element).toggle();
      }, 2400);
    }
  }

  reregister() {
    api
      .post(RESEND_VERIFICATION_API, { email: this.username.trim() })
      .then(response => {
        if (response.status === 200) {
          this.closeModal("#login__modal");
          this.closeModal("#verify__modal");
          this.emailIsSent = true;
          this.openModal("#verify__modal");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  mounted() {
    // Hack 😅 to show dialog
    if (Object.keys(this.$route.query).length === 0) {
      return;
    }

    if (Object.prototype.hasOwnProperty.call(this.$route.query, "verified")) {
      this.openModal("#login__modal");
    } else if (
      Object.prototype.hasOwnProperty.call(this.$route.query, "emailChanged")
    ) {
      if (this.emailIsChanged) {
        this.toastProps.message = `Email address updated successfully.`;
        this.toggleToast();
      } else {
        this.openModal("#change__error__modal");
      }
    } else if (
      Object.prototype.hasOwnProperty.call(this.$route.query, "passwordChanged")
    ) {
      if (this.passwordIsChanged) {
        this.toastProps.message = `Password updated successfully.`;
        this.toggleToast();
      } else {
        this.openModal("#change__error__modal");
      }
    } else {
      console.error("Invalid query parameters");
      return;
    }
  }

  login() {
    const params = new URLSearchParams();
    params.append("username", this.username);
    params.append("password", this.password);
    console.log(api.defaults.headers);
    api
      .post(LOGIN_API, params)
      .then(response => {
        localStorage.setItem(LOCAL_ACCESS_KEY, response.data.access_token);
        localStorage.setItem(LOCAL_REFRESH_KEY, response.data.refresh_token);
        const route = this.$route.query.redirect || "/dashboard";
        this.$router.push(route as string);
      })
      .catch(error => {
        console.log(error);
        console.log(error.response.data.detail);
        if (error.response.data.detail == "LOGIN_USER_NOT_VERIFIED") {
          this.openModal("#verify__modal");
        }
        if (error.response.data.detail == "LOGIN_BAD_CREDENTIALS") {
          this.loginError = "Incorrect email or password.";
          this.password = "";
        }
      });
  }
}

export default Login;
