
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import { Vue } from "vue-class-component";

interface Statistic {
  count: number;
  entity: string;
}

export default class TrackRecord extends Vue {
  statistics: Statistic[] = [
    { count: 225, entity: "clients" },
    { count: 444, entity: "projects" },
    { count: 15, entity: "countries" },
    { count: 23, entity: "cities" }
  ];
  clients = [
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/UNDP.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/deloitte.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/google.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/linked-in.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/the-economist.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/citibank.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/prudential.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/huawei.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/sequoia.png",
    STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/fujitsu.png"
  ];
}
