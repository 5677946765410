
import { Vue, Options } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import ToggleSwitch from "@/components/common/ToggleSwitch.vue";
import {
  NOTIFICATIONS_GETTER,
  UPDATE_NOTIFICATIONS_ACTION
} from "@/store/constants";
import { NOTIFICATIONS_API } from "@/services";
import api from "@/services/api";

@Options({
  components: {
    ToggleSwitch
  },
  props: {
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
})
export default class AccountNotifications extends Vue {
  // Props
  loggedIn!: boolean;

  toggleTitle = {
    officialNews: "Official news, announcements & product updates",
    productTips: "Product tips, resources, and inspiration"
  };
  notifToggleData = {};
  get notifications() {
    return this.loggedIn ? this.$store.getters[NOTIFICATIONS_GETTER] : {};
  }
  mounted() {
    this.notifToggleData = this.notifications
      ? this.notifications
      : {
          officialNews: false,
          productTips: false
        };
  }
  updateNotifications(id, checked) {
    this.notifToggleData[id] = !checked;
    const data = { ...this.notifToggleData };
    api
      .post(NOTIFICATIONS_API, data)
      .then(response => {
        this.$store.dispatch(UPDATE_NOTIFICATIONS_ACTION, response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
}
