
import { PACKAGES, PackageTier, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { Options, Vue } from "vue-class-component";
import { User } from "@/commons";
import { PropType } from "@vue/runtime-core";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import api from "@/services/api";
import { PAYMENTS_API } from "@/services";
interface Payment {
  datetime: string;
  package: string;
  amountUsd: number;
  id: string;
}

@Options({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    }
  },
  components: {
    ThothButton
  }
})
export default class AccountPayments extends Vue {
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  dateOptions = {
    month: "short",
    day: "numeric",
    year: "numeric"
  };
  downloadIcon = STATIC_ASSET_ICONS_BASE_URL + "/download-icon.svg";
  headers: string[] = ["Date", "Package", "Amount", "Download"];
  payments = {};

  matchPackageName(tier: PackageTier) {
    const pkg = PACKAGES.map(e => e.tier).indexOf(tier);
    return `${PACKAGES[pkg].numInfographics} Infographics`;
  }

  gotoBuy() {
    this.$router.push("/pricing");
  }

  async mounted() {
    await api
      .get(PAYMENTS_API)
      .then(response => {
        this.payments = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

    this.payments = this.payments ? this.payments : {};
  }
}
