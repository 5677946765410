
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import {
  ProjectCount,
  ProjectType,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";

@Options({
  props: {
    activeFolder: {
      type: Number as PropType<ProjectType>,
      required: true
    },
    projectCount: {
      type: Object as PropType<ProjectCount>,
      required: true
    }
  },
  emits: {
    makeActiveFolder: {}
  }
})
export default class NavigationDrawerBody extends Vue {
  //Imports
  ProjectType = ProjectType;
  activeFolder!: ProjectType;
  projectCount!: ProjectCount;

  plusIcon = STATIC_ASSET_ICONS_BASE_URL + "/plus-dashboard.svg";
  get infographicFolders() {
    return [
      {
        icon:
          STATIC_ASSET_ICONS_BASE_URL + "/dashboard-navbar-document-empty.svg",
        name: "Drafts",
        type: ProjectType.Draft,
        numberOfFiles: this.projectCount.draft
      },
      {
        icon: STATIC_ASSET_ICONS_BASE_URL + "/dashboard-navbar-lock.svg",
        name: "Downloaded",
        type: ProjectType.Downloaded,
        numberOfFiles: this.projectCount.downloaded
      },
      {
        icon: STATIC_ASSET_ICONS_BASE_URL + "/dashboard-navbar-delete.svg",
        name: "Trash",
        type: ProjectType.Trash,
        numberOfFiles: this.projectCount.trash
      }
    ];
  }
  makeActiveFolder(folderType: ProjectType) {
    this.$emit("makeActiveFolder", folderType);
  }
  goToCreate() {
    this.$router.push({ name: "Create" });
  }
}
