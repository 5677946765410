
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton
  },
  props: {
    cta: {
      type: String as PropType<string>,
      required: false,
      default: "Interested to give Thoth.ai a try?"
    }
  }
})
export default class CallToActionCaseStudy extends Vue {
  cta?: string;
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  images: { [id: string]: string } = {
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-cta-background.png"
  };
  goToWaitlist() {
    this.$router.push("/waitlist");
  }
}
