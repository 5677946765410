<!-- ImageModal.vue -->
<template>
    <div v-if="isOpen" class="modal-overlay" @click="close">
        <div class="modal-content" @click.stop>
            <img :src="imageUrl" alt="Expanded View" class="modal-image" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imageUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        }
    }
};
</script>

<style>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
}

.modal-content {
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40px;
}

@keyframes rotateIn {
    from {
        transform: rotate(0deg) scale(0.5);
        opacity: 0;
    }

    to {
        transform: rotate(0deg) scale(1);
        opacity: 1;
    }
}

.modal-image {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    animation: rotateIn 0.3s ease-out forwards;
}
</style>
