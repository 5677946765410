
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy
  }
})
export default class KPMGCaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    finalImage:
      STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-2.png",
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-background.png",
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-KPMG-1.png",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Case-KPMG-2.png"
  };
  links: { [id: string]: string } = {
    article: "https://www.linkedin.com/pulse/dynamic-year-tiger-2022-governments-public-sector-asia-camerlengo/?trackingId=x4auRPqfSrK2dv4U8%2FvMRQ%3D%3D",
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
  };
  goToArticle() {
    window.location.href = this.links.article;
  }
}
