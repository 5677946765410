import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileBlocker = _resolveComponent("MobileBlocker")
  const _component_DashboardNavBar = _resolveComponent("DashboardNavBar")
  const _component_AccountSettings = _resolveComponent("AccountSettings")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_MobileBlocker),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_DashboardNavBar, {
          key: 0,
          class: "container__mobile__blocker",
          "logged-in": _ctx.isLoggedIn
        }, null, 8, ["logged-in"]))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_AccountSettings, {
          key: 1,
          class: "container__mobile__blocker",
          "logged-in": _ctx.isLoggedIn,
          user: _ctx.user,
          loaded: _ctx.loaded
        }, null, 8, ["logged-in", "user", "loaded"]))
      : _createCommentVNode("", true)
  ], 64))
}