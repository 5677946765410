
import { EditMode, ProjectStage } from "@/commons";
import NavBar from "@/components/NavBar.vue";
import MobileBlocker from "@/components/common/MobileBlocker.vue";
import EditInfographic from "@/components/product/EditInfographic.vue";
import {
  LOCAL_ACCESS_KEY,
  authenticate,
  authenticateAdmin,
  fetchProject
} from "@/services";
import {
  DELETE_DRAFT_INFOGRAPHICS_ACTION,
  EDIT_MODE_GETTER,
  LOADED_GETTER,
  LOAD_CURRENT_VERSION_ACTION,
  LOAD_PROJECT_STORE_ACTION,
  LOAD_PROJECT_VERSIONS_ACTION,
  LOAD_STORE_ACTION,
  PROJECT_LOADED_GETTER,
  PROJECT_TITLE_GETTER,
  PUBLISH_INFOGRAPHIC_ACTION,
  USER_GETTER
} from "@/store/constants.js";
import api from "@/services/api";
import UIkit from "uikit";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    NavBar,
    EditInfographic,
    MobileBlocker
  }
})
export default class Edit extends Vue {
  // Imported commons
  ProjectStage = ProjectStage;
  EditMode = EditMode;
  // States
  isLoggedIn = false;
  isAdmin = false;
  activeInfographic = 0;
  isLoading = false;
  infographics: any[] = [];

  // Computed Properties

  get projectTitle() {
    return this.$store.getters[PROJECT_TITLE_GETTER];
  }

  get projectLoaded() {
    return this.$store.getters[PROJECT_LOADED_GETTER];
  }

  get dashboardLoaded() {
    return this.$store.getters[LOADED_GETTER];
  }

  get user() {
    return this.$store.getters[USER_GETTER];
  }

  get editMode() {
    return this.$store.getters[EDIT_MODE_GETTER];
  }

  // Mounted
  async mounted() {
    const projectIdParams = this.$route.params.projectId;
    this.infographics = await fetchProject(projectIdParams as string);

    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    this.isAdmin = token ? await authenticateAdmin() : false;

    if (this.isLoggedIn && !this.dashboardLoaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }

    const { projectId } = this.$route.params;

    if (this.isLoggedIn && !this.projectLoaded) {
      this.$store.dispatch(LOAD_PROJECT_VERSIONS_ACTION, projectId);
      this.$store.dispatch(LOAD_CURRENT_VERSION_ACTION, projectId);
      await this.$store.dispatch(LOAD_PROJECT_STORE_ACTION, projectId);
    }
  }

  // Refresh alerts
  created() {
    window.addEventListener("beforeunload", e => this.preventRefresh(e));
  }
  destroyed() {
    window.removeEventListener("beforeunload", e => this.preventRefresh(e));
  }
  preventRefresh(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = "All progress made will be lost if you leave the page!";
  }

  // Methods
  changeActiveInfographic(activeInfographic: number) {
    this.activeInfographic = activeInfographic;
  }
  async publishInfographic(modalElement: Element) {
    this.isLoading = true;
    const infographicId = this.infographics[this.activeInfographic].id;
    const { projectId } = this.$route.params;
    this.activeInfographic = 0;
    try {
      await this.$store.dispatch(PUBLISH_INFOGRAPHIC_ACTION, {
        projectId,
        infographicId
      });
      this.$store.dispatch(DELETE_DRAFT_INFOGRAPHICS_ACTION, {
        projectId
      });
      if (modalElement) {
        UIkit.modal(modalElement).hide();
      }
      this.isLoading = false;
      try {
        this.$router.push({ path: `/project/${projectId}/publish` });
      } catch (err) {
        console.log("error pushing to new route", err);
      }
    } catch (err) {
      console.log("error after publishing", err);
      this.isLoading = false;
    }
  }
}
