<template>
  <div class="section traveltellers_template">
    <div class="container tags_heading  ">
      <div class="div-block-38">
        <h1>Visual Summaries</h1>
        <div class="text-block-16">
          For the full agenda, please visit the
          <a :href="eventConfig.eventWebsite" target="_blank" class="link-7">{{ eventConfig.eventTitle }} SITE</a>.
        </div>
      </div>
    </div>

    <!-- Agenda Tabs Section -->
    <div class="container blog_posts agendaextra ">

      <div class="w-tabs" data-duration-in="300" data-duration-out="100">
        <!-- <div class="tabs-menu-2 w-tab-menu" role="tablist">
            <template v-for="day in eventConfig.numberOfDays" :key="day">
              <a class="tab-link w-inline-block w-tab-link" :class="{ 'w--current': activeDay === day.toString() }"
                @click="loadDayData(day.toString())">
                <div class="text-block-39">DAY {{ day }}</div>
              </a>
            </template>
</div> -->
        <div :class="$style.tabsParent">
          <div :class="$style.tabsContainer">
            <div :class="$style.tabs">
              <!-- Tab for 'All' -->
              <div :class="activeDay === 'all' ? $style.tabNavigation : $style.tabNavigation1"
                @click="loadDayData('all')">
                <div :class="$style.navigation">All</div>
              </div>

              <!-- Dynamic Tabs for Each Day -->
              <div v-for="day in eventConfig.numberOfDays" :key="day">
                <div :class="activeDay === day.toString() ? $style.tabNavigation : $style.tabNavigation1"
                  @click="loadDayData(day.toString())">
                  <div :class="$style.navigation">
                    <span>Day {{ day }}</span>
                    <!-- put date -->
                    <!-- <span :class="$style.sep"> ({{ formatDate(eventConfig.eventDates[day]) }})</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="$style.tabs1">
            <div :class="[activeView === 'icon' ? $style.iconButton1 : $style.iconButton]">
              <img :class="$style.icon" alt="" src="../images/IconView.svg" @click="toggleView('icon')" />
            </div>
            <div :class="[activeView === 'list' ? $style.iconButton1 : $style.iconButton]">
              <img :class="$style.icon" alt="" src="../images/ListView.svg" @click="toggleView('list')" />
            </div>
          </div>
        </div>

        <!-- Search and Filter Section -->
        <div :class="$style.searchParent">
          <div :class="$style.search">
            <img :class="$style.icon" alt="" src="../images/Search.svg" />
            <input v-model="searchQuery" @input="applyFilters" placeholder="Search by session title, theme, or speaker"
              :class="$style.searchInput" />
          </div>
          <div :class="$style.mobileTabs">
            <div v-if="activeView === 'list'" :class="$style.iconButton">
              <img :class="$style.icon1" alt="" src="../images/IconView.svg" @click="toggleView('icon')" />
            </div>
            <div v-else :class="$style.iconButton">
              <img :class="$style.icon1" alt="" src="../images/ListView.svg" @click="toggleView('list')" />
            </div>
          </div>
          <!-- <div :class="$style.orBrowseByParent">
            <div :class="$style.orBrowseBy">Or browse by:</div>
            <div :class="$style.sessionTypeWrapper" @click="filterBy('sessionType')">
              <div :class="$style.sessionType">Session type</div>
            </div>
            <div :class="$style.sessionTypeWrapper" @click="filterBy('tags')">
              <div :class="$style.sessionType">Tags</div>
            </div>
            <div :class="$style.sessionTypeWrapper" @click="filterBy('category')">
              <div :class="$style.sessionType">Category</div>
            </div>
          </div> -->
        </div>


        <div v-if="activeView === 'list'" :class="$style.listsessions">
          <div :class="$style.dashboardlist">
            <img :class="$style.image1Icon" alt="" src="image 1.png" />
            <div :class="$style.details">
              <div :class="$style.time">Date/time ({{ eventConfig.timezone }})</div>
              <div :class="$style.sessionTitleWrapper">
                <div :class="$style.sessionTitle">Session(s)</div>
              </div>
              <div :class="$style.speakers">Speaker(s)</div>
            </div>
          </div>
          <!-- Loop over the session data to dynamically create dashboard lists -->

          <div v-if="filteredContents.length === 0">
            <p>No matching sessions found.</p>
          </div>

          <div v-for="(session, index) in filteredContents" :key="index" :class="$style.dashboardlist1"
            @click="onSessionClick(session)">
            <!-- <router-link :to="session.endpoint" class="link-block-11 w-inline-block"> -->
            <div :class="$style.details">
              <!-- Conditional Tag (e.g., UPCOMING) -->
              <div v-if="session.upcoming" :class="$style.tagParent">
                <div :class="$style.tag">
                  <b :class="$style.munthirAAlsheddie">UPCOMING</b>
                </div>
              </div>

              <!-- Time Information -->
              <div :class="session.upcoming ? $style.time9 : $style.timeParent">
                <div :class="$style.time1">{{ formatDate(session.date) }}, {{ session.time }}</div>
                <div :class="$style.time2">({{ session.duration }} mins)</div>
              </div>

              <!-- Session Details (Title, Description) -->
              <div :class="$style.keynoteTheValueTransfer2Parent">
                <div :class="$style.keynoteTheValue">{{ session.name }}</div>
                <div :class="$style.theFinancialWorld">{{ session.description }}</div>
              </div>

              <!-- Speaker Information -->
              <div :class="$style.speakers1">
                <div :class="$style.speakerNames">
                  <div v-for="(speaker, index) in session.speakers" :key="index">
                    <strong>{{ speaker.name }}</strong> | {{ speaker.designation }}{{ speaker.company ? ', ' +
                      speaker.company : '' }}<br />
                  </div>
                </div>
                <!-- Moderated by Section -->
                <div v-if="session.moderators.length > 0">
                  <div :class="$style.speakerNames">Moderated by:</div>
                  <div :class="$style.speakerNames">
                    <div v-for="(moderator, index) in session.moderators" :key="index">
                      <strong>{{ moderator.name }}</strong> | {{ moderator.designation }}{{ moderator.company ? ', ' +
                        moderator.company : '' }}<br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </router-link> -->
          </div>
        </div>
        <div v-else :class="$style.iconsessions">
          <div v-for="(session, index) in filteredContents" :key="index" :class="$style.dashboardgrid"
            @click="onSessionClick(session)">
            <div v-if="imageLoadStatus[session.name] === true">
              <img :class="$style.infographicIcon" alt="Session Image"
                :src="Array.isArray(session.imageUrl) ? session.imageUrl[0] : session.imageUrl" />
            </div>
            <div v-else>
              <img :class="$style.infographicIcon"
                :src="`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventName}/images/Holding+Slide.png`"
                loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image" />
            </div>
            <div :class="$style.griddetails">
              <div :class="$style.frameParent">
                <div :class="$style.timeWrapper">
                  <b :class="$style.icontime">{{ formatDate(session.date) }} {{ session.time }} {{ eventConfig.timezone
                    }} • {{
                      session.duration }} mins</b>
                </div>
                <div :class="$style.keynoteTheValueTransfer2Parent">
                  <div :class="$style.keynoteTheValue">{{ session.name }}</div>
                  <div :class="$style.theFinancialWorld">{{ session.description }}</div>
                </div>
              </div>
              <div :class="$style.rowtagParent">

                <div :class="$style.rowtag">
                  <div :class="$style.tag1">{{ session.category }}</div>
                </div>
              </div>
              <div :class="$style.iconSpeakers">
                <div v-for="(speaker, index) in session.speakers" :key="index" :class="$style.iconSpeakerNames">
                  <div v-if="speaker.imageurl" :class="$style.iconSpeakerNamesInner">
                    <img :class="$style.frameChild" alt="" :src="speaker.imageurl" />
                  </div>
                  <div :class="$style.speakerNamesChild">
                    <div :class="$style.iconMunthirAAlsheddieParent">
                      <div :class="$style.iconMunthirAAlsheddie">{{ speaker.name }}</div>
                      <div :class="$style.iconCountryGmTarabut">{{ speaker.designation }}{{ speaker.company ? ', ' +
                        speaker.company : '' }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="session.moderators.length > 0">
                <div :class="$style.munthirAAlsheddie">Moderated by:</div>
                <div :class="$style.iconSpeakers">
                  <div v-for="(moderator, index) in session.moderators" :key="index" :class="$style.iconSpeakerNames">
                    <div v-if="moderator.imageurl" :class="$style.iconSpeakerNamesInner">
                      <img :class="$style.frameChild" alt="" :src="moderator.imageurl" />
                    </div>
                    <div :class="$style.iconMunthirAAlsheddieParent">
                      <div :class="$style.iconMunthirAAlsheddie">{{ moderator.name }}</div>
                      <div :class="$style.iconCountryGmTarabut">{{ moderator.designation }}{{ moderator.company ? ', ' +
                        moderator.company : '' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="tabs-content-2 w-tab-content">
            <div v-if="contents.length > 0">
              <div class="agenda-pane w-tab-pane w--tab-active">
                <div class="w-layout-grid grid-10">
                  <h6 class="agenda-table-heading">DAY/time (SGT)</h6>
                  <h6 class="agenda-table-heading">Session</h6>
                </div>
                <div class="w-dyn-list">
                  <div role="list" class="w-dyn-items">
                    <div v-for="content in contents" :key="content.name" role="listitem" class="w-dyn-item">
                      <div class="w-layout-grid grid-5">
                        <div class="div-block-24">
                          <div class="text-block-14-day">
                            {{ formatDate(content.date) }}
                          </div>
                          <div class="text-block-14">{{ content.time }}</div>
                        </div>
                        <router-link :to="generateEndpoint(content.name)" class="link-block-11 w-inline-block">
                          <div class="text-block-15">{{ content.name }}</div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p>No sessions available for this day.</p>
            </div>
          </div> -->
      </div>

      <!-- <div class="sticky-nav-contain">
        <form :action="`/${eventName}/search`" class="search searchagenda w-form">
          <input class="search-input searchagendatext w-input" maxlength="256" name="query" placeholder="Search..."
            type="search" id="search" required />
          <input type="submit" class="search-button w-button" value="⌕" />
        </form>
        <div class="sticky">
          <h6 class="browse-headings">BROWSE BY session type</h6>
          <div class="line div stickynavline"></div>
          <div class="blog_categories_contain">
            <div class="w-dyn-list">
              <div role="list" class="category-tags-collection-list w-dyn-items">
                <div role="listitem" v-for="(location, index) in uniqueLocations" :key="index" class="w-dyn-item">
                  <router-link :to="`/${eventName}/stage/${location
                    .toLowerCase()
                    .replace(/ /g, '-')}`
                    " class="link-block-14 w-inline-block">
                    <div class="text-block-43">{{ location }}</div>
                  </router-link>
                </div>
              </div>
              <div class="w-dyn-empty" v-if="!uniqueLocations.length">
                <div>No items found.</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- Additional Content or Widgets Can Go Here -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AgendaSection",
  inject: ['eventConfig'],
  props: {
    eventName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeDay: "all",
      activeView: "icon",
      contents: [],
      data: null,
      imageLoadStatus: {},
      filteredContents: [], // Filtered sessions based on search and filters
      searchQuery: "",
      selectedFilter: null
    };
  },
  watch: {
    activeDay: {
      immediate: true,
      handler() {
        this.loadDayData(this.activeDay);
      }

    }
  },
  methods: {
    async fetchData() {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json`
        );
        this.data = response.data;
        this.loadDayData(this.activeDay);
        // this.loadUniqueLocations();

        this.checkImageStatus();
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },

    formatDate(date) {
      const options = { month: "short", day: "2-digit" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    toggleView(viewName) {
      this.activeView = viewName;
    },
    loadDayData(dayName) {
      if (!this.data) return;

      this.activeDay = dayName;

      let filteredData;
      if (dayName === "all") {
        filteredData = this.data;
      } else {
        filteredData = this.data.filter(
          item => item.day.toString() === dayName
        );
      }

      this.contents = filteredData.map(item => ({
        name: item.title,
        category: item.location,
        imageUrl: item.imageUrl,
        time: item.time,
        timeDetail: `Duration: ${item.duration} minutes`,
        heading: item.title,
        description: item.description,
        link: item.source,
        speakers: item.speakers,
        date: item.date,
        duration: item.duration,
        endpoint: item.talkEndpoint,
        moderators: item.moderators ?? []
      }));
      this.applyFilters();
    },
    applyFilters() {
      const query = this.searchQuery.toLowerCase();

      this.filteredContents = this.contents.filter(session => {
        const matchesSearch =
          session.name.toLowerCase().includes(query) ||
          session.description.toLowerCase().includes(query) ||
          session.speakers.some(speaker => speaker.name.toLowerCase().includes(query));

        // Additional logic to filter by session type, tags, or category
        const matchesFilter = !this.selectedFilter || session[this.selectedFilter];

        return matchesSearch && matchesFilter;
      });
    },
    filterBy(filterType) {
      this.selectedFilter = filterType;
      this.applyFilters();
    },
    onSessionClick(session) {
      this.$router.push(session.endpoint);
    },
    preloadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => reject(false);
      });
    },
    async checkImageStatus() {
      const statusPromises = this.contents.map(async (session) => {
        try {
          if (!Array.isArray(session.imageUrl)) {
            await this.preloadImage(session.imageUrl);
            this.imageLoadStatus[session.name] = true;
          } else {
            // Preload each image in the array
            const preloadPromises = session.imageUrl.map(url => this.preloadImage(url));
            await Promise.all(preloadPromises);
            this.imageLoadStatus[session.name] = true;
            this.isImageLoaded = true;
          }
        } catch (error) {
          this.imageLoadStatus[session.name] = false;
        }
      });
      await Promise.all(statusPromises);
    }
  },
  async mounted() {
    await this.fetchData();
  }
};
</script>

<style module>
.title {
  width: 761px;
  position: relative;
  font-size: 48px;
  line-height: 130%;
  font-family: 'Instrument Serif';
  color: #111;
  text-align: left;
  display: inline-block;
}

.navigation {
  position: relative;
  line-height: 130%;
}

.tabNavigation {
  border-radius: 16px;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: #fff;

}

.sep {
  font-size: 16px;
  font-family: 'DM Sans';
  color: #494949;
}

.tabNavigation1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
}

.sep1 {
  color: #494949;
  font-size: 16px;
  /* font-family: 'DM Sans'; */
}

.tabsContainer {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 5px;
}

.tabs {
  position: relative;
  border-radius: 16px;
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  box-sizing: border-box;
  text-align: left;
  font-size: 24px;
  color: #111;
  font-family: 'Instrument Serif';
  width: fit-content;
  flex-wrap: wrap;
  cursor: pointer;
}

.mobileTabs {
  position: relative;
  border-radius: 16px;
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  box-sizing: border-box;
  text-align: left;
  font-size: 24px;
  color: #111;
  font-family: 'Instrument Serif';
  width: fit-content;
  flex-wrap: wrap;
  cursor: pointer;
  display: none;
}


.tabsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tabs1 {
  border-radius: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  gap: 9px;
  cursor: pointer;
}

.icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.iconButton {
  width: 36px;
  border-radius: 12px;
  background-color: #f5f5f5;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  box-sizing: border-box;
}

.iconButton1 {
  width: 36px;
  border-radius: 12px;
  background-color: var(--tertiary-color);
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  box-sizing: border-box;
}

.image1Icon {
  width: 308px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 172px;
  object-fit: cover;
  display: none;
}

.time {
  width: 240px;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  color: #111;
  display: inline-block;
  flex-shrink: 0;
}

.icontime {
  flex: 1;
  width: 100%;
  position: relative;
  font-size: 14px;
  line-height: 130%;
  display: inline-block;
  font-family: 'DM Sans';
  color: #B89A6A;
  text-align: left;
}

.sessionTitle {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}

.sessionTitleWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.speakers {
  align-self: stretch;
  width: 33%;
  position: relative;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}

.details {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.dashboardlist {
  align-self: stretch;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 24px;
  gap: 40px;
  cursor: pointer;
  font-size: 20px;
}

.dashboardgrid {
  flex-basis: calc(33.33% - 16px);
  /* 3 columns */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

/* For medium screens (e.g., tablets), switch to 2 columns */
@media (max-width: 1024px) {
  .dashboardgrid {
    flex-basis: calc(50% - 16px);
    /* 2 columns */
  }
}

/* For small screens (e.g., mobile), switch to 1 column */
@media (max-width: 768px) {
  .dashboardgrid {
    flex-basis: 100%;
    /* 1 column */
  }
}

.griddetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.time1 {
  width: 240px;
  position: relative;
  line-height: 130%;
  display: inline-block;
  color: var(--primary-color);
}

.time2 {
  width: 240px;
  position: relative;
  font-size: 14px;
  line-height: 130%;
  color: #494949;
  display: inline-block;
}

.timeParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  color: #111;
}

.keynoteTheValue {
  align-self: stretch;
  position: relative;
  line-height: 130%;
}

.theFinancialWorld {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 130%;
  /* font-family: 'DM Sans'; */
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.keynoteTheValueTransfer2Parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 24px;
  font-family: 'Instrument Serif';
}

.munthirAAlsheddie {
  position: relative;
  line-height: 130%;
}

.countryGmTarabut {
  position: relative;
  line-height: 130%;
  white-space: pre-wrap;
}

.speakerNames {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 0px;
  font-size: 12px;
}

.munthirAAlsheddieParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  gap: 0px;
}

.speakerNames1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 0px 8px;
  font-size: 16px;
}

.speakers1 {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  font-size: 16px;
}

.dashboardlist1 {
  align-self: stretch;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 16px;
}

.tag {
  border-radius: 8px;
  border: 1px solid #007aff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
}

.time9 {
  width: 240px;
  position: relative;
  font-size: 16px;
  line-height: 130%;
  color: #111;
  display: inline-block;
}

.tagParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  color: #007aff;
}

.tag1 {
  position: relative;
  line-height: 130%;
}

.rowtag {
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px solid #727272;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 16px;
}



.rowtagParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 12px;
  color: #727272;
}

.speakers5 {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  font-size: 16px;
}

.dashboardlist5 {
  align-self: stretch;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 16px;
  font-size: 12px;
}

.listsessions {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px 0px;
  box-sizing: border-box;
  gap: 16px;
  text-align: left;
  font-size: 16px;
  color: #2c2c2c;
  /* font-family: 'DM Sans'; */
  margin-top: 1rem;
}

.iconsessions {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 24px 0px;
  box-sizing: border-box;
  gap: 40px 24px;
  text-align: left;
  font-size: 14px;
  color: #2c2c2c;
}

.frameChild {
  position: absolute;
  top: 5.09px;
  left: 5.09px;
  border-radius: 12.73px;
  width: 45.8px;
  height: 45.8px;
  overflow: hidden;
  object-fit: cover;
}

.iconSpeakerNamesInner {
  width: 56px;
  position: relative;
  border-radius: 15.27px;
  border: 1.3px solid #B89A6A;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}

.iconMunthirAAlsheddie {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}

.iconCountryGmTarabut {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 130%;
  color: #494949;
}

.iconMunthirAAlsheddieParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.speakerNamesChild {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.iconSpeakerNames {
  width: 198px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.iconSpeakers {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 14px;
  color: #B89A6A;
  font-family: 'DM Sans';
}

.searchParent {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.searchInput {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #727272;
  outline: none;
  background-color: transparent;
}

.search {
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.searchInput:focus {
  border-bottom: 2px solid #305535;
  /* Optional: Change the bottom border on focus */
}

.orBrowseByParent {
  display: flex;
  gap: 20px;
}

.sessionTypeWrapper {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #727272;
}



@media (max-width: 768px) {
  .tabs1 {
    padding: 4px;
    gap: 4px;
    display: None;
  }

  .mobileTabs {
    display: block;
  }

  .iconButton,
  .iconButton1 {
    width: 30px;
    height: 30px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .tabsParent {
    flex-direction: column;
    gap: 8px;
  }

  .tabs {
    font-size: 18px;
  }

  /* Hide speaker columns for mobile */
  .dashboardlist {
    display: none;
  }

  .timeParent {
    flex-direction: row;
  }

  /* Adjust time column size for mobile */
  .time,
  .time1,
  .time2 {
    font-size: 12px;
    width: auto;
    /* Let it auto-adjust */
    white-space: nowrap;
    /* Prevent the time from wrapping to the next line */
  }

  .theFinancialWorld {
    display: none;
  }

  /* Reduce padding and margin for mobile list view */
  .listsessions {
    padding: 8px 0;
    gap: 8px;
  }

  /* Adjust dashboard list padding */
  .dashboardlist {
    padding: 8px 0 16px;
  }

  .dashboardlist1 {
    padding: 8px 0 12px;
  }

  /* Adjust details in the list */
  .details {
    flex-direction: column;
    gap: 8px;
  }

  /* Adjust the session title font size */
  .sessionTitle {
    font-size: 16px;
  }

  .keynoteTheValueTransfer2Parent {
    font-size: 16px;
  }

  .searchParent {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .speakers1 {
    width: auto;

  }

}
</style>
