<template>
  <div class="congrats_container" @click="open">
    <div class="congrats_transit_anim">
      <lottie :options="defaultOptions" @animCreated="handleAnimation" />
    </div>
    <div class="congrats_title">
      Ta-Da! <br />Tap Here To Unlock <br />Your Masterpiece.
    </div>
  </div>
</template>
<script>
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import CongratsAnim from "@/assets/animate/present.json";
@Options({
  components: { lottie }
})
export default class Congrats extends Vue {
  defaultOptions = {
    animationData: CongratsAnim,
    autoplay: false,
    loop: false
  };

  open() {
    this.anim.play();
  }
  animationCompleted() {
    this.$emit("finished");
  }
  handleAnimation(anim) {
    this.anim = anim;
    anim.addEventListener("complete", () => this.animationCompleted());
  }
}
</script>
<style scoped>
.congrats_container {
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.congrats_transit_anim {
  position: absolute;
  height: 95vh;
  width: calc(320 / 568 * 95vh);
  z-index: 0;
}

.congrats_title {
  font: normal 400 32px/32px Brick;
  color: var(--ink);
  z-index: 1;
  text-align: center;
  margin-bottom: 25%;
}
@media screen and (max-width: 480px) {
  .congrats_transit_anim {
    height: calc(568 / 320 * 98vw);
    width: 98vw;
  }
  .congrats_title {
    font: normal 400 24px/28px Brick;
  }
}
</style>
