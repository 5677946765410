
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import { PropType } from "@vue/runtime-core";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import AlternateButton from "@/components/common/AlternateButton.vue";
@Options({
  props: {
    selectedKeypoints: {
      type: Array as PropType<number[]>,
      required: true,
      default: [4, 5, 6]
    },
    selectedColor: {
      type: String,
      required: true,
      default: "Peach"
    },
    selectedLayout: {
      type: String,
      required: true,
      default: "A"
    }
  },
  computed: {
    showModal() {
      return this.innerStep == 1;
    },
    imageSelect() {
      const finalPts = this.selectedKeypoints.join("");
      return (
        STATIC_ASSET_IMAGES_BASE_URL +
        `/demo/output/${finalPts}-${this.selectedColor}-${this.selectedLayout}.png`
      );
    }
  },
  components: { lottie, AlternateButton }
})
export default class FinalOutput extends Vue {
  //props
  selectedKeypoints!: number[];
  selectedColor!: string;
  selectedLayout!: string;
  //data
  innerStep = 0;
  closeButton = STATIC_ASSET_IMAGES_BASE_URL + "/demo/close-modal-button.png";
  modalMascot = STATIC_ASSET_IMAGES_BASE_URL + "/demo/waitlist-mascot.png";
  //functions
  nextStep() {
    this.innerStep++;
  }
  mounted() {
    setTimeout(() => {
      this.innerStep++;
    }, 5000);
  }
  returnHome() {
    this.$router.push("/");
  }
  goToWaitlist() {
    this.$router.push("/waitlist");
  }
}
