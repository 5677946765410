
import { Options, Vue } from "vue-class-component";
import EditBrandKitMain from "@/components/product/edit/EditBrandKitMain.vue";
import EditColour from "@/components/product/edit/EditColour.vue";
import EditLogo from "@/components/product/edit/EditLogo.vue";
import EditBrandLink from "@/components/product/edit/EditBrandLink.vue";
import { PropType } from "@vue/runtime-core";
import {
  ActiveColour,
  ActiveBrandLink,
  CanvasMode,
  EditMode,
  BrandKitMode,
  User
} from "@/commons";

@Options({
  components: {
    EditBrandKitMain,
    EditColour,
    EditLogo,
    EditBrandLink
  },
  emits: {
    setActiveColour: {}
  },
  props: {
    activeColour: {
      type: Object as PropType<ActiveColour>,
      required: true
    },
    activeLogo: {
      type: String as PropType<string>,
      required: false,
      default: null
    },
    activeBrandLink: {
      type: Object as PropType<ActiveBrandLink>,
      required: true
    },
    makeActiveColour: {
      type: Function as PropType<() => void>,
      required: true
    },
    mustSave: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    showToast: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    makeActiveLogo: {
      type: Function as PropType<() => void>,
      required: true
    },
    makeActiveBrandLink: {
      type: Function as PropType<() => void>,
      required: true
    },
    saveChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    cancelChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    },
    user: {
      type: Object as PropType<User>,
      required: true
    }
  }
})
export default class BrandKit extends Vue {
  // Props
  activeColour!: ActiveColour;
  activeLogo!: string;
  mustSave!: boolean;
  cancelChanges!: Function;
  // Imported commons
  EditMode = EditMode;
  BrandKitMode = BrandKitMode;
  brandKitMode = BrandKitMode.Main;

  selectBrandKitMode(mode: BrandKitMode) {
    if (!this.mustSave) {
      this.brandKitMode = mode;
    }
  }

  changeActiveColour(colour: string, id = "") {
    this.$emit("setActiveColour", colour, id);
  }

  cancelChangesHandler() {
    this.brandKitMode = BrandKitMode.Main;
    this.cancelChanges();
  }
}
