
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy
  }
})
export default class RevConCaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-background.png",
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/RevCon-Example-1.png",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/RevCon-Example-2.png",
    case3: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/RevCon-Example-3.png",
    case4: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/RevCon-Example-4.png",
    case5: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/RevCon-Example-5.png",
    case6: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/RevCon-Example-6.png",
  };
  links: { [id: string]: string } = {
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
  };
}
