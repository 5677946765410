
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy
  }
})
export default class SandPGlobalPlattsCaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-background.png",
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-1.jpg",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-2.jpg",
    case3: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-3.jpg",
    case4: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-4.jpg",
    case5: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-5.jpg",
    case6: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-6.jpg",
    case7: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-7.jpg",
    case8: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-8.jpg",
    case9: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-9.png",
    case10: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-10.jpeg",
    case11: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/Appec-2023-Example-11.png",
  };
  links: { [id: string]: string } = {
    sAndP: "https://www.spglobal.com/commodityinsights/en",
    appec: "https://www.spglobal.com/commodityinsights/en/market-insights/topics/appec",
    appecSpeakers: "https://commodityinsights.spglobal.com/appec2023.html#:~:text=to%202022%20here.-,Speakers,-Justin%20Murphy",
    cna: "https://www.channelnewsasia.com/business/appec-global-refining-output-seen-staying-near-full-capacity-high-margins-3747746",
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
  };
}
