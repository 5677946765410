<template>
  <NavBar :logged-in="isLoggedIn" />
  <ThothBanner />
  <ThothVideo />
  <Examples />
  <Steps />
  <ValueProposition />
  <PeopleTypes />
  <CallToAction />
  <Footer />
</template>
<script>
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import ThothBanner from "@/components/public/home/ThothBanner.vue";
import ThothVideo from "@/components/public/home/ThothVideo.vue";
import Examples from "@/components/public/home/Examples.vue";
import Steps from "@/components/public/home/Steps.vue";
import ValueProposition from "@/components/public/home/ValueProposition.vue";
import PeopleTypes from "@/components/public/home/PeopleTypes.vue";
import CallToAction from "@/components/public/home/CallToAction.vue";
import Footer from "@/components/Footer.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";
@Options({
  components: {
    NavBar,
    ThothBanner,
    ThothVideo,
    Examples,
    Steps,
    ValueProposition,
    PeopleTypes,
    CallToAction,
    Footer
  }
})
export default class Home extends Vue {
  isLoggedIn = false;
  loaded = false;

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate(token) : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
</script>
