
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";

@Options({
  components: {
    NavBar,
    Footer
  }
})
export default class License extends Vue {
  isLoggedIn = false;
  loaded = false;
  dateOfUpdate = "16 Nov 2023";

  content = [
    {
      title: "What's Allowed",
      texts: [
        `<ul><li>You can use Infographics for commercial and non-commercial purposes.</li></ul>`,
        `<ul><li>You can use an Infographic to create an End Product for yourself or for a client.</li></ul>`,
        `<ul><li>You can make any number of copies of the End Product created using an Infographic. You can distribute the End Product through multiple media (i.e. website, social media, eBooks).</li></ul>`,
        `<ul><li>You can modify or manipulate an Infographic, or combine the Infographic with other works, to suit your End Product.</li></ul>`
      ]
    },
    {
      title: "What's Not Allowed",
      texts: [
        `<ul><li>You can't re-sell or re-distribute Infographics.</li><ul>`,
        `<ul><li>You can't use Infographics as the basis for merchandising. For example, printing an Infographics from Thoth straight onto a T-shirt is not allowed.</li><ul>`,
        `<ul><li>You can't compile Infographics from Thoth to replicate a similar or competing service.</li><ul>`,
        `<ul><li>You can't claim ownership of an Infographic, even if modified.</li><ul>`
      ]
    },
    {
      title: "Long-Form",
      texts: [
        `You do not need to ask permission from or provide credit to Thoth, although it is appreciated when possible. Thoth grants you an irrevocable, nonexclusive, worldwide copyright license to download, copy, modify, and use Infographics made on Thoth for free, without permission from or attributing Thoth. This license does not include the right to compile illustrations from Thoth to replicate a similar or competing service.`
      ]
    }
  ];

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
