
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import { Chrome } from "@ckpack/vue-color";
import SaveAlertToast from "@/components/common/SaveAlertToast.vue";
import SaveChangesBar from "@/components/product/edit/SaveChangesBar.vue";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import {
  ActiveColour,
  ColourPalette,
  CanvasMode,
  BrandKitMode,
  presetDefaults,
  ChromePickerColour,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";

@Options({
  emits: {
    setActiveColour: {},
  },
  components: {
    Chrome,
    SaveChangesBar,
    SaveAlertToast,
    ThothButton
  },
  props: {
    colourPalettes: {
      type: Array as PropType<ColourPalette[]>,
      required: true
    },
    activeColour: {
      type: Object as PropType<ActiveColour>,
      required: true
    },
    makeActiveColour: {
      type: Function as PropType<() => void>,
      required: true
    },
    mustSave: {
      type: Boolean,
      required: true
    },
    showToast: {
      type: Boolean,
      required: true
    },
    saveChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    },
    selectBrandKitMode: {
      type: Function as PropType<() => void>,
      required: true
    }
  }
})
export default class EditColour extends Vue {
  //Commons
  CanvasMode = CanvasMode;
  BrandKitMode = BrandKitMode;
  presetDefaults = presetDefaults;
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  // Props
  activeColour!: ActiveColour;
  canvasMode!: CanvasMode;
  selectBrandKitMode!: Function;
  chevronLeft = STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-chevron-left.svg";
  chevronUp = STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-chevron-up.svg";
  chevronDown = STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-chevron-down.svg";

  chromePickerColour: ChromePickerColour = {
    a: 1,
    hex: "#000000",
    hex8: "#000000FF",
    hsl: {},
    hsv: {},
    oldHue: 0,
    rgba: { a: 1, b: 0, g: 0, r: 0 },
    source: "hsva"
  };
  customColours: string[] = [];
  chromeIsShown = false;
  palettesAreHidden = true;
  triggerInternalToast = false;
  internalToastSetTimeout = 0;

  addCustomColour() {
    const chromeDropElement = UIkit.drop("#chrome-drop");
    if (this.chromeIsShown && chromeDropElement) {
      chromeDropElement.hide();
      return;
    }

    const newColour = this.chromePickerColour.hex;
    let activeColourId;
    if (this.customColours.length > 3) {
      // Prevent user from over-creating custom colours
      this.customColours.shift();
      activeColourId = 3;
    } else {
      activeColourId = this.customColours.length;
    }
    this.customColours.push(newColour);
    this.$emit("setActiveColour", newColour, `custom-${activeColourId}`);
  }
  changeActiveColour() {
    this.customColours[
      this.activeColour.id.slice(-1)
    ] = this.chromePickerColour.hex;
    this.$emit("setActiveColour", this.chromePickerColour.hex);
  }
  goToBrandKit() {
    const brandKitRoute = this.$router.resolve({ name: "BrandKit" });
    window.open(brandKitRoute.href, "_blank");
  }

  cancelChanges() {
    this.$emit("cancelBrandKitChanges");
  }

  exitSegment() {
    if (this.triggerInternalToast) {
      window.clearTimeout(this.internalToastSetTimeout);
    }
    this.triggerInternalToast = true;
    this.selectBrandKitMode(BrandKitMode.Main);
    this.internalToastSetTimeout = setTimeout(() => {
      this.triggerInternalToast = false;
    }, 5000);
  }
  
}
