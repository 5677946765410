
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";

@Options({
  props: {
    modalId: {
      type: String as PropType<string>,
      required: true
    },
    modalTitle: {
      type: String as PropType<string>,
      required: true
    },
    modalContent: {
      type: String as PropType<string>,
      required: true
    },
    primaryButtonText: {
      type: String as PropType<string>,
      required: false,
      default: "done"
    },
    primaryButtonColour: {
      type: Number as PropType<ButtonColour>,
      required: false,
      default: ButtonColour.Brand
    },
    primaryButtonAction: {
      type: Function as PropType<() => void>,
      required: true
    },
    secondaryButtonText: {
      type: String as PropType<string>,
      required: false,
      default: "cancel"
    },
    secondaryButtonAction: {
      type: Function as PropType<() => void>,
      required: false
    },
    preventBackgroundClick: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    },
    image: {
      type: String as PropType<string>,
      required: false
    },
    middle: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    }
  },
  components: {
    ThothButton
  }
})
export default class Logos extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
}
