<template>
  <div class="template-hero-section " v-if="content">
    <img v-if="content.backgroundPattern" :class="$style.meshGradIcon" alt="" :src="content.backgroundPattern" />
    <h5 :class="content.styles.headingClass">{{ content.heading }}</h5>
    <div :class="content.styles.titleClass"
      :style="{ padding: '15px', position: 'relative', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }">
      <template v-for="(part, index) in content.titleParts" :key="index">
        <img v-if="part.type === 'image'" :src="part.src" :alt="part.alt || ''"
          :style="{ width: part.style.width, maxWidth: part.style.maxWidth, height: part.style.height, objectFit: part.style.objectFit }">
        <span v-else :style="part.style" :class="$style.responsiveTitle">
          {{ part.text }}
        </span>
      </template>
    </div>
    <p :class="content.styles.subtitleClass">{{ content.subtitle }}</p>
    <div :class="$style.buttonParent">
      <div :class="$style.button" @click="onBrowseClick">
        <b :class="$style.button1">View all</b>
      </div>
      <div :class="$style.buttonOutline" @click="onLearnMoreClick">
        <b :class="$style.button2">Learn more</b>
      </div>
    </div>
  </div>


  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
export default {
  name: "HeroSection",
  props: {
    eventName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    onBrowseClick() {
      this.$router.push(`/${this.eventName}/agenda`);
    },
    onLearnMoreClick() {
      this.$router.push(`/${this.eventName}/about`);
    }
  },
  async created() {
    try {
      const response = await fetch(`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/hero-section.json`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      this.content = await response.json();
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  },
};
</script>


<style module>
.button1 {
  position: relative;
  line-height: 130%;
}

.meshGradIcon {
  width: 1013px;
  position: absolute;
  margin: 0 !important;
  top: -266px;
  left: -166.5px;
  height: 807px;
  object-fit: cover;
  opacity: 0.2;
  z-index: 0;
}

.button {
  border-radius: 12px;
  background-color: var(--primary-color);
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.buttonOutline {
  border-radius: 12px;
  border: 1px solid #fff;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: left;
  font-size: 12px;
  color: #fff;
  font-family: 'DM Sans';
  margin-top: 1rem;
}

.buttonText {
  font-size: 16px;
  line-height: 130%;
}

.responsiveTitle {
  margin: 30px 0px 50px 0px;
  font-size: 80px;
  padding: 20px 5px 20px 5px;
}

@media (max-width: 768px) {
  .meshGradIcon {
    left: 0px
  }

  .responsiveTitle {
    margin: 5px 0px 10px 0px;
    padding: 10px 2px 10px 2px;
    font-size: 25px;
  }
}


@media (max-width: 620px) {
  .responsiveTitle {
    margin: 5px 0px 10px 0px;
    padding: 10px 2px 10px 2px;
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .responsiveTitle {
    margin: 5px 0px 10px 0px;
    padding: 10px 2px 10px 2px;
    font-size: 18px;
  }
}

@media (min-width: 601px) and (max-width: 1200px) {
  .responsiveTitle {
    font-size: 46px;
    padding: 10px 2px 10px 2px;
  }
}
</style>
