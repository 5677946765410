
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import PreviewModes from "@/components/public/pricing/PreviewModes.vue";
import UpgradeInfographic from "@/components/public/pricing/UpgradeInfographic.vue";
import FrequentlyAskedQuestions from "@/components/public/pricing/FrequentlyAskedQuestions.vue";
import Footer from "@/components/Footer.vue";
import { LOAD_STORE_ACTION } from "@/store/constants.js";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";

@Options({
  components: {
    NavBar,
    PreviewModes,
    UpgradeInfographic,
    FrequentlyAskedQuestions,
    Footer
  }
})
export default class Pricing extends Vue {
  isLoggedIn = false;
  loaded = false;

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
