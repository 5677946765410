
import {
  ActiveBrandLink,
  ActiveColour,
  CanvasMode,
  EditMode,
  Infographic,
  SocialTypes
} from "@/commons";
import HelpFloatingButton from "@/components/HelpFloatingButton.vue";
import Toast from "@/components/common/Toast.vue";
import BrandKit from "@/components/product/edit/BrandKit.vue";
import EditNavBar from "@/components/product/edit/EditNavBar.vue";
import InfographicDisplay from "@/components/product/edit/InfographicDisplay.vue";
import LayoutEdits from "@/components/product/edit/LayoutEdits.vue";
import TextEdits from "@/components/product/edit/TextEdits.vue";
import VersionHistorySidebar from "@/components/product/edit/VersionHistorySidebar.vue";
import VisualEdits from "@/components/product/edit/VisualEdits.vue";
import {
  EDIT_MODE_GETTER,
  LOAD_PROJECT_VERSIONS_ACTION,
  SELECTED_VERSION_GETTER,
  SET_CURRENT_VERSION_ACTION,
  SET_EDIT_MODE_ACTION,
  UPDATE_INFOGRAPHICS_ACTION,
  USER_GETTER,
  VERSION_CHANGE_ACTIVE_GETTER
} from "@/store/constants.js";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import { Options, Vue } from "vue-class-component";
import { screenview } from "vue-gtag";

@Options({
  emits: {
    changeActiveInfographic: {}
  },
  props: {
    infographics: {
      type: Array as PropType<Infographic[]>,
      required: true
    },
    loaded: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    isAdmin: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    activeInfographic: {
      type: Number as PropType<number>,
      required: true
    }
  },
  components: {
    EditNavBar,
    BrandKit,
    LayoutEdits,
    TextEdits,
    VisualEdits,
    InfographicDisplay,
    VersionHistorySidebar,
    HelpFloatingButton,
    Toast
  }
})
export default class EditInfographic extends Vue {
  // Props
  infographics!: Infographic[];
  isAdmin!: boolean;
  activeInfographic!: number;

  // Imported commons
  EditMode = EditMode;
  CanvasMode = CanvasMode;

  // States
  canvasMode: CanvasMode = CanvasMode.Editing;
  activeColour: ActiveColour = {
    id: "",
    hex: this.infographics[0].brandColour
  };
  infographicsDefault: Infographic[] = JSON.parse(
    JSON.stringify(this.infographics)
  );
  showToast = false;
  toastSetTimeout = 0;
  activeBrandLink: ActiveBrandLink = {
    type: this.infographics[0].socialType
      ? (this.infographics[0].socialType as SocialTypes)
      : null,
    text: this.infographics[0].socialText || null
  };
  activeLogo = this.infographics[0].brandLogoUrl || null;
  toastProps = { message: "" };

  // Methods
  trackScreen(mode: EditMode) {
    screenview({
      // eslint-disable-next-line prettier/prettier
      app_name: "Thoth",
      // eslint-disable-next-line prettier/prettier
      screen_name: String(EditMode[mode]),
    });
  }

  //Computed Properties

  get editMode() {
    return this.$store.getters[EDIT_MODE_GETTER];
  }

  get versionChangeActive() {
    return this.$store.getters[VERSION_CHANGE_ACTIVE_GETTER];
  }

  get selectedVersion() {
    return this.$store.getters[SELECTED_VERSION_GETTER];
  }

  selectEditMode(mode: EditMode) {
    if (!this.mustSave) {
      this.showToast = false;

      if (this.editMode === mode) {
        this.$store.dispatch(SET_EDIT_MODE_ACTION, EditMode.None);
        this.trackScreen(EditMode.None);
      } else {
        this.$store.dispatch(SET_EDIT_MODE_ACTION, mode);
        this.trackScreen(mode);
      }
    } else {
      this.showToast = true;
      if (this.showToast) {
        window.clearTimeout(this.toastSetTimeout);
      }
      this.toastSetTimeout = window.setTimeout(() => {
        this.showToast = false;
      }, 5000);
    }
  }

  makeActiveInfographic(infographicId: number) {
    this.$emit("changeActiveInfographic", infographicId);
  }

  makeActiveColour(colour: string, id = "") {
    if (id === "") {
      this.activeColour.hex = colour;
    } else {
      this.activeColour = { id: id, hex: colour };
    }
  }

  makeActiveLogo(url: string) {
    this.activeLogo = url;
  }

  get isAssetChanged() {
    for (const index in this.infographics[this.activeInfographic].assets) {
      if (
        this.infographics[this.activeInfographic].assets[index] !==
        this.infographicsDefault[this.activeInfographic].assets[index]
      ) {
        return true;
      }
    }
    return false;
  }

  get isVMChanged() {
    for (const index in this.infographics[this.activeInfographic]
      .visualMotifs) {
      if (
        this.infographics[this.activeInfographic].visualMotifs[index] !==
        this.infographicsDefault[this.activeInfographic].visualMotifs[index]
      ) {
        return true;
      }
    }
    return false;
  }

  get isBrandKitChanged() {
    if (this.infographicsDefault[0].brandColour !== this.activeColour.hex) {
      return true;
    } else if (this.infographicsDefault[0].brandLogoUrl !== this.activeLogo) {
      return true;
    } else if (
      this.infographicsDefault[0].socialType !== this.activeBrandLink.type ||
      this.infographicsDefault[0].socialText !== this.activeBrandLink.text
    ) {
      return true;
    }
    return false;
  }

  get mustSave() {
    switch (this.editMode) {
      case EditMode.Text: {
        if (
          this.infographics[this.activeInfographic].title !==
          this.infographicsDefault[this.activeInfographic].title
        ) {
          return true;
        }
        for (const index in this.infographics[this.activeInfographic].summary) {
          if (
            this.infographics[this.activeInfographic].summary[index] !==
            this.infographicsDefault[this.activeInfographic].summary[index]
          ) {
            return true;
          }
        }
        return false;
      }
      case EditMode.Visuals: {
        return this.isAssetChanged || this.isVMChanged;
      }
      case EditMode.BrandKit: {
        return this.isBrandKitChanged;
      }
      case EditMode.None:
        return undefined;
      default:
        return undefined;
    }
  }

  makeActiveBrandLink(type: string | null, text: string | null) {
    if (type) {
      this.activeBrandLink.type = type as SocialTypes;
    } else {
      this.activeBrandLink.type = null;
    }
    this.activeBrandLink.text = text;
  }

  get user() {
    return this.$store.getters[USER_GETTER];
  }

  get assetDictionary() {
    if (!this.infographics.every(i => i.id)) {
      return null;
    } else {
      const assetDictionary = this.infographics.map(i => ({
        [i.id || ""]: i.assets
      }));
      return Object.assign({}, ...assetDictionary);
    }
  }

  async saveChanges(editType: CanvasMode) {
    this.canvasMode = editType;
    const { projectId } = this.$route.params;
    const editRequest = this.infographics
      ? {
        title: this.infographics[this.activeInfographic].title,
        summary: this.infographics[this.activeInfographic].summary,
        brandColour: this.activeColour.hex,
        assets: this.assetDictionary,
        visualMotifs: this.infographics[this.activeInfographic].visualMotifs,
        brandLogoUrl: this.activeLogo,
        socialType: this.activeBrandLink.type,
        socialText: this.activeBrandLink.text
      }
      : {};
    this.infographics[
      this.activeInfographic
    ].brandColour = this.activeColour.hex;

    if (this.activeLogo) {
      this.infographics[this.activeInfographic].brandLogoUrl = this.activeLogo;
    }

    if (this.activeBrandLink.type !== null) {
      this.infographics[
        this.activeInfographic
      ].socialType = this.activeBrandLink.type;

      if (this.activeBrandLink.text) {
        this.infographics[
          this.activeInfographic
        ].socialText = this.activeBrandLink.text;
      }
    } else {
      this.infographics[this.activeInfographic].socialType = null;
      this.infographics[this.activeInfographic].socialText = null;
    }

    try {
      await this.$store.dispatch(UPDATE_INFOGRAPHICS_ACTION, {
        projectId,
        editRequest
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.canvasMode = CanvasMode.Editing;
      this.infographicsDefault = JSON.parse(JSON.stringify(this.infographics));

      // Refresh the version history after a change
      await this.$store.dispatch(LOAD_PROJECT_VERSIONS_ACTION, projectId);
    }
  }

  cancelTextChanges() {
    const textAreaElements = Array.from(
      document.getElementsByTagName("textarea")
    );
    textAreaElements.forEach(e => {
      const textAreaElement = e.id.split("-");
      switch (textAreaElement[1]) {
        case "title": {
          e.value = this.infographicsDefault[this.activeInfographic].title;
          break;
        }
        case "summary": {
          const index = parseInt(textAreaElement[2]);
          e.value = this.infographicsDefault[this.activeInfographic].summary[
            index
          ];
          break;
        }
        default:
          break;
      }
      e.dispatchEvent(new Event("input"));
    });
  }
  cancelVisualChanges() {
    this.$nextTick(() => {
      const assets = this.infographics[this.activeInfographic].assets;
      for (const a in assets) {
        assets[a] = this.infographicsDefault[this.activeInfographic].assets[a];
      }
      const visualMotifs = this.infographics[this.activeInfographic]
        .visualMotifs;
      for (const a in visualMotifs) {
        visualMotifs[a] = this.infographicsDefault[
          this.activeInfographic
        ].visualMotifs[a];
      }
    });
  }
  cancelBrandKitChanges() {
    this.$nextTick(() => {
      this.activeColour.hex = this.infographicsDefault[
        this.activeInfographic
      ].brandColour;
      this.activeLogo =
        this.infographicsDefault[this.activeInfographic].brandLogoUrl || null;
      this.activeBrandLink.type =
        (this.infographicsDefault[0].socialType as SocialTypes) || null;
      this.activeBrandLink.text =
        this.infographicsDefault[this.activeInfographic].socialText || null;
    });
  }
  toggleToast() {
    const element = document.querySelector("#toast") as HTMLElement;
    if (element) {
      UIkit.toggle(element).toggle();
      setTimeout(() => {
        UIkit.toggle(element).toggle();
      }, 2400);
    }
  }
  async restoreVersion() {
    await this.$store.dispatch(
      SET_CURRENT_VERSION_ACTION,
      this.selectedVersion
    );
    if (!this.versionChangeActive) {
      this.toastProps.message = `Version successfully restored`;
      this.toggleToast();
    }
  }
}
