
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import { Vue } from "vue-class-component";

interface Testimonial {
  image: string;
  words: string;
  person: string;
  position: string;
  organization: string;
}

export default class Testimonials extends Vue {
  testimonials: Testimonial[] = [
    {
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/lee-hsien-loong.png",
      words: `@_ideaink drew an on-the-spot graphic recording of what I shared at the conference.
They made me look decades younger by giving me a full head of black hair!
–
Once again, the creative hands at Idea Ink were there to bring my speech to life!`,
      person: "PM Lee Hsien Loong",
      position: "Prime Minister",
      organization: "Singapore"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/lee-kuo-chuen.png",
      words: `These talented youngsters are brilliant at translating complicated ideas in financial
technology into precise, engaging summaries. In one of the Chinese conferences that we had, the hidden talents
of the team surprised the audience and their bilingual work was an instant hit in China!

My global contacts are consistently impressed by Idea Ink's speed, talent, and skill. I highly recommend them, for anyone who wants intelligent, impactful images, done fast and well.`,
      person: "Prof David Lee Kuo Chuen",
      position: "Professor",
      organization: `Singapore University of Social Sciences (SUSS)
Fintech Industry Expert`
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/clients/about/lidija-castro.png",
      words: `Edgy. Creative. Smart. Witty. Eloquent. Idea Ink made us look so good every time we worked with them!

The team is incredibly talented and they manage to crystallize complex concepts into wonderfully clear illustrations and designs.
It's obvious that education, culture and books are part of their DNA, as they are very familiar with a wide range of trends that shape today's world. That knowledge paired with their talent and ease of doing business is an incredibly rare combination to find.`,
      person: "Lidija Castro",
      position: "Director of Strategic Marketing",
      organization: "Cushman & Wakefield, Asia Pacific"
    }
  ];
}
