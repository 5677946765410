import { PROJECT_API, DARK_MODE_API } from "../services";
import { LIST_PROJECT_ACTION, LIST_USER_ACTION } from "@/store/constants.js";
import { EditMode } from "@/commons";
import api from "../services/api";

const getDefaultState = () => {
  return {
    loaded: false,
    uuid: "",
    title: "",
    infographics: [],
    editMode: EditMode.None,
    selectedVersion: null,
    currentVersion: null,
    projectVersions: [],
    alternateSummaries: []
  };
};

const state = getDefaultState();

const getters = {
  loaded(state) {
    return state.loaded;
  },
  infographics(state) {
    return state.infographics;
  },
  title(state) {
    return state.title;
  },
  editMode(state) {
    return state.editMode;
  },
  versionChangeActive(state) {
    if (state.currentVersion && state.selectedVersion) {
      return (
        JSON.stringify(state.selectedVersion) !==
        JSON.stringify(state.currentVersion)
      );
    }
    return false;
  },
  selectedVersion(state) {
    return state.selectedVersion;
  },
  currentVersion(state) {
    return state.currentVersion;
  },
  projectVersions(state) {
    return state.projectVersions;
  },
  alternateSummaries(state) {
    return state.alternateSummaries;
  }
};

const actions = {
  async loadStore(context, projectUUID) {
    await context.dispatch(LIST_PROJECT_ACTION, projectUUID, { root: true });
    context.commit("load");
  },
  resetStore(context) {
    context.commit("resetState");
  },
  async upgradeProject(context, projectId) {
    let response;
    try {
      response = await api.put(`${PROJECT_API}/${projectId}/upgrade`);
    } catch (err) {
      console.log(`Error at action project/upgradeProject: ${err}`);
      throw err.response.data;
    }

    const project = response.data;
    context.dispatch("dashboard/updateProject", project, { root: true });
    await context.dispatch(LIST_USER_ACTION, "", { root: true });
  },
  async updateInfographics(context, { projectId, editRequest }) {
    let response;
    try {
      response = await api.put(`${PROJECT_API}/${projectId}`, editRequest);
    } catch (err) {
      console.log(`Error at action project/updateInfographics: ${err}`);
      throw err.response.data;
    }

    const project = response.data;
    project.infographics.forEach(el => {
      el.url = el.url + "?version=" + Date.now();
    });
    context.dispatch("dashboard/updateProject", project, { root: true });
  },
  async publishInfographic(context, { projectId, infographicId }) {
    let response;
    try {
      const url = `${PROJECT_API}/${projectId}/publish/${infographicId}`;
      response = await api.post(url);
    } catch (err) {
      console.log(`Error at action project/publishInfographics: ${err}`);
      throw err.response.data;
    }

    const publishedInfographic = response.data;
    context.commit("setInfographics", [publishedInfographic]);
    context.dispatch(
      "dashboard/lockProject",
      { projectId, publishedInfographic },
      { root: true }
    );
  },
  async deleteDraftInfographics(context, { projectId }) {
    try {
      await api.delete(`${PROJECT_API}/${projectId}/delete-drafts`);
    } catch (err) {
      console.log(`Error at action project/deleteDraftInfographics: ${err}`);
      throw err.response.data;
    }
  },
  setEditModeState(context, modalState) {
    context.commit("setEditMode", modalState);
  },
  async loadCurrentVersion(context, projectUUID) {
    //TODO curVersion data should be referenced in dashboard for preview
    let response;
    try {
      const url = `${PROJECT_API}/${projectUUID}/version/current`;
      response = await api.get(url);
    } catch (err) {
      console.log(`Error at action project/version/current: ${err}`);
      throw err.response.data;
    }
    context.commit("setCurrentVersion", response.data);
  },
  async loadVersions(context, projectUUID) {
    // get BE data of project versions from getter
    let response;
    try {
      const url = `${PROJECT_API}/${projectUUID}/versions`;
      response = await api.get(url);
    } catch (err) {
      console.log(`Error at action project/versions: ${err}`);
      throw err.response.data;
    }
    context.commit("setProjectVersions", response.data);
  },
  setSelectedVersionState(context, version) {
    context.commit("setSelectedVersion", version);
  },
  async setCurrentVersionState(context, version) {
    let response;
    try {
      const projectUUID = version.projectId;
      const versionId = version.id;
      const url = `${PROJECT_API}/${projectUUID}/version/${versionId}`;
      response = await api.patch(url, {
        isCurrent: true,
        infographic: version.data
      });
    } catch (err) {
      console.log(`Error at action project/version/current: ${err}`);
      throw err.response.data;
    }
    context.commit("setCurrentVersion", version);
    context.commit("setEditMode", EditMode.None);
  },
  async toggleDarkMode(context, { projectId, mode }) {
    let response;
    try {
      const url = `${PROJECT_API}/${projectId}/dark-mode`;
      response = await api.put(url, mode);
    } catch (err) {
      console.log(`Error at action project/toggleDarkMode: ${err}`);
      throw err.response.data;
    }
    context.commit("setDarkMode", response.data);
  },
  async loadAlternateSummaries(context, { projectId, point }) {
    let response;
    try {
      const url = `${PROJECT_API}/${projectId}/suggestions/${point}`;
      response = await api.get(url);
    } catch (err) {
      console.log(`Error at action project/summaries: ${err}`);
      throw err.response.data;
    }
    context.commit("setAlternateSummaries", response.data);
  }
};

const mutations = {
  load(state) {
    state.loaded = true;
  },
  setProject(state, project) {
    state.uuid = project.uuid;
    state.infographics = project.infographics;
    state.title = project.infographics[0].title;
  },
  setInfographics(state, infographics) {
    state.infographics = infographics;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setEditMode(state, editMode) {
    state.editMode = editMode;
  },
  addProjectVersion(state, version) {
    state.projectVersions.push(version);
  },
  setProjectVersions(state, versions) {
    state.projectVersions = versions;
  },
  setSelectedVersion(state, version) {
    state.selectedVersion = version;
  },
  setCurrentVersion(state, version) {
    state.currentVersion = version;
  },
  setAlternateSummaries(state, alternateSummaries) {
    state.alternateSummaries = alternateSummaries;
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
