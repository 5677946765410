
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import Intro from "@/components/public/about/Intro.vue";
import Testimonials from "@/components/public/about/Testimonials.vue";
import TrackRecord from "@/components/public/about/TrackRecord.vue";
import CallToAction from "@/components/public/home/CallToAction.vue";
import Footer from "@/components/Footer.vue";
import BetaBanner from "@/components/common/BetaBanner.vue";
import BetaIntro from "@/components/public/about/BetaIntro.vue";
import BetaDescription from "@/components/public/about/BetaDescription.vue";
import BetaFormats from "@/components/public/about/BetaFormats.vue";
import BetaCallToAction from "@/components/public/about/BetaCallToAction.vue";
import BetaMission from "@/components/public/about/BetaMission.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";
import ThothBanner from "@/components/public/home/ThothBanner.vue";

@Options({
  components: {
    Intro,
    Testimonials,
    TrackRecord,
    CallToAction,
    NavBar,
    Footer,
    BetaBanner,
    BetaIntro,
    BetaDescription,
    BetaFormats,
    BetaCallToAction,
    ThothBanner,
    BetaMission
  }
})
export default class About extends Vue {
  isLoggedIn = false;
  loaded = false;

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
