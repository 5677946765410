
import { defineRule, Form } from "vee-validate";
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_ICONS_BASE_URL, User } from "@/commons";
import UIkit from "uikit";
import { PropType } from "@vue/runtime-core";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import InputModal from "@/components/common/InputModal.vue";
import Modal from "@/components/common/Modal.vue";
import PasswordField from "@/components/common/PasswordField.vue";
import TextField from "@/components/common/TextField.vue";
import ErrorPasswordField from "@/components/common/ErrorPasswordField.vue";
import {
  CHANGE_EMAIL_API,
  CHANGE_PASSWORD_API,
  DELETE_ACCOUNT_API,
  RESEND_CHANGE_EMAIL_API,
  CANCEL_CHANGE_EMAIL_API,
  LOCAL_ACCESS_KEY,
  LOCAL_REFRESH_KEY
} from "@/services";
import api from "@/services/api";
import { RESET_STORE_ACTION } from "@/store/constants";

defineRule("email", email => {
  if (!email || !email.length) {
    return true;
  }
  if (!/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/.test(email)) {
    return "This field must be a valid email";
  }
  return true;
});

@Options({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    }
  },
  components: {
    Form,
    TextField,
    ErrorPasswordField,
    PasswordField,
    ThothButton,
    InputModal,
    Modal
  },
  computed: {
    verifyModalMessage() {
      return `Follow the link in the email to confirm the email change. Your current email will be valid until then.`;
    }
  }
})
export default class AccountDetails extends Vue {
  user!: User;
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  icons: { [id: string]: string } = {
    crossCircle: STATIC_ASSET_ICONS_BASE_URL + "/x-circle.svg"
  };

  emailRules = "email";
  pwRules = "";
  //data for emailState
  emailData = {
    currentEmail: this.user.email,
    newEmail: ""
  };

  //data for errors
  emailError = "";
  passError = "";
  conPassError = "";

  field = { userInput: "" };
  currentPassword = { userInput: "" };
  confirmedPassword = { userInput: "" };

  get modalElement() {
    return document.querySelector("#change-email-modal");
  }
  get modalVerifyElement() {
    return document.querySelector("#verify-modal");
  }
  get modalPwElement() {
    return document.querySelector("#change-pw-modal");
  }
  get modalDeleteElement() {
    return document.querySelector("#delete-account-modal");
  }
  get modalDeleteConfirmElement() {
    return document.querySelector("#delete-confirm-account-modal");
  }

  get emailModalText() {
    return `Enter the email you want to link to this account. We&rsquo;ll send an email to this email address for verification.`;
  }

  get passwordModalText() {
    return `Enter the email you want to link to this account. We&rsquo;ll send an email to this email address for verification.`;
  }

  get deleteModalText() {
    return `Are you sure you want to delete your account? Account deletion is final, and will do the following:`;
  }

  get deleteConfirmModalText() {
    return `Enter your password to proceed with account deletion:`;
  }

  clearFields(modalId, fields) {
    for (const field of fields) {
      try {
        const input = document.querySelector(
          `${modalId} input[name="${field}"]`
        ) as HTMLInputElement;
        input.value = "";
      } catch (err) {
        console.log(err);
      }
    }
  }

  openEmailModal() {
    if (this.modalElement) {
      UIkit.modal("#change-email-modal").show();
    }
  }

  closeEmailModal() {
    if (this.modalElement) {
      UIkit.modal("#change-email-modal").hide();
      this.emailError = "";
      this.passError = "";
      this.clearFields("#change-email-modal", [
        "Current Password",
        "New Email Address"
      ]);
    }
  }

  openVerifyModal() {
    if (this.modalVerifyElement) {
      UIkit.modal("#verify-modal").show();
    }
  }

  closeVerifyModal() {
    if (this.modalVerifyElement) {
      UIkit.modal("#verify-modal").hide();
    }
  }

  openPwModal() {
    if (this.modalPwElement) {
      UIkit.modal("#change-pw-modal").show();
      this.field.userInput = "";
      this.currentPassword.userInput = "";
      this.confirmedPassword.userInput = "";
    }
  }

  closePwModal() {
    if (this.modalPwElement) {
      UIkit.modal("#change-pw-modal").hide();
      this.emailError = "";
      this.passError = "";
      this.conPassError = "";
      this.clearFields("#change-pw-modal", [
        "Current Password",
        "New Password",
        "Confirm New Password"
      ]);
    }
  }

  openDeleteModal() {
    if (this.modalDeleteElement) {
      UIkit.modal("#delete-account-modal").show();
    }
  }

  closeDeleteModal() {
    if (this.modalDeleteElement) {
      UIkit.modal("#delete-account-modal").hide();
    }
  }

  openDeleteConfirmModal() {
    if (this.modalDeleteElement) {
      UIkit.modal("#delete-confirm-account-modal").show();
    }
  }

  closeDeleteConfirmModal() {
    if (this.modalDeleteElement) {
      UIkit.modal("#delete-confirm-account-modal").hide();
    }
    this.passError = "";
  }

  deleteConfirm() {
    this.closeDeleteModal();
    this.openDeleteConfirmModal();
  }

  resetEmailError() {
    if (this.emailError !== "") {
      this.emailError = "";
    }
  }
  resetPasswordError() {
    if (this.passError !== "") {
      this.passError = "";
    }
  }
  resetConPasswordError() {
    if (this.conPassError !== "") {
      this.conPassError = "";
    }
  }

  onEmailSubmit(values) {
    this.emailData = {
      currentEmail: this.user.email,
      newEmail: values["New Email Address"]
    };

    const data = {
      newEmail: values["New Email Address"],
      password: values["Current Password"]
    };

    api
      .post(CHANGE_EMAIL_API, data)
      .then(() => {
        this.closeEmailModal();
        this.openVerifyModal();
      })
      .catch(error => {
        const data = error.response?.data || {};
        switch (data.message) {
          case "EMAIL_IN_USE":
            this.emailError =
              "There is already an account associated with this email.";
            break;
          case "EMAIL_INVALID":
            this.emailError = "Please enter a valid email address";
            break;
          case "INCORRECT_PASSWORD":
            this.passError = "Incorrect password entered.";
            break;
          default:
            break;
        }
        console.log(error);
      });
  }

  async onPwSubmit(values) {
    const data = {
      password: values["Current Password"],
      newPassword: values["New Password"]
    };

    if (data.newPassword !== values["Confirm New Password"]) {
      this.conPassError = "Passwords do not match";
    } else {
      api
        .post(CHANGE_PASSWORD_API, data)
        .then(() => {
          this.closePwModal();
          //get if success push
          if (localStorage.getItem(LOCAL_ACCESS_KEY)) {
            localStorage.removeItem(LOCAL_ACCESS_KEY);
          }
          if (localStorage.getItem(LOCAL_REFRESH_KEY)) {
            localStorage.removeItem(LOCAL_REFRESH_KEY);
          }
          this.$store.dispatch(RESET_STORE_ACTION);
          this.$router.push({
            name: "Login",
            params: { passwordChanged: "true" }
          });
        })
        .catch(error => {
          const data = error.response?.data || {};
          switch (data.message) {
            case "E_BAD_PASSWORD":
              this.passError = "Incorrect password entered.";
              break;

            default:
              break;
          }
          console.log(error);
        });
    }
  }

  async onDeleteSubmit(values) {
    if (this.modalDeleteElement) {
      UIkit.modal("#delete-confirm-account-modal").hide();
    }
    const data = {
      password: values["Confirm Password"]
    };

    api
      .post(DELETE_ACCOUNT_API, data)
      .then(() => {
        //get if success push
        if (localStorage.getItem(LOCAL_ACCESS_KEY)) {
          localStorage.removeItem(LOCAL_ACCESS_KEY);
        }
        this.$router.push({ name: "Home" });
      })
      .catch(error => {
        switch (error.message) {
          case "INCORRECT_PASSWORD":
            this.passError = "Incorrect password entered.";
            break;
          default:
            break;
        }
        console.log(error);
      });
  }

  resendEmail() {
    const data = { email: this.emailData.newEmail };

    api
      .post(RESEND_CHANGE_EMAIL_API, data)
      .then(() => {
        console.log("Resent email");
      })
      .catch(error => {
        console.log(error);
      });
  }

  cancelChange() {
    api
      .post(CANCEL_CHANGE_EMAIL_API, {})
      .then(response => {
        this.emailData = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }

  mounted() {
    api
      .get(CHANGE_EMAIL_API)
      .then(response => {
        this.emailData = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
