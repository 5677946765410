
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
interface Example {
  image: string;
  title: string;
  subtitle: string;
  slug?: string;
}
@Options({
  inject: ["mq"],
  computed: {
    mobile() {
      return this.mq.current === "sm" || this.mq.current === "md"
        ? "uk-width-2-3"
        : "uk-width-1-3";
    }
  }
})
export default class Examples extends Vue {
  BASE_CASE_STUDIES = "/case-studies";
  examples: Example[] = [
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/AI-Engineer-World-Fair-2024.webp",
      title: "AI Engineer, World's Fair",
      subtitle:
        "Showcasing our AI-powered web-app at the biggest technical AI conference in San Francisco.",
      slug: this.BASE_CASE_STUDIES + "/ai-engineer-world-fair"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Campaign360-by-Haymarket-Media.webp",
      title: "Campaign360 by Haymarket Media",
      subtitle:
        "Powering through disruption with an AI portrait booth and graphic recording at Asia's leading marketing conference.",
      slug: this.BASE_CASE_STUDIES + "/camapaign-360-haymarket-media"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/RevCon-by-Rev-Genius.webp",
      title: "RevCon by Rev Genius",
      subtitle:
        "A combination of AI-powered visual summaries, and manually-tweaked portraits for a 70,000+ strong digital community.",
      slug: this.BASE_CASE_STUDIES + "/revcon"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Investment-Management-Association-of-Singapore.webp",
      title: "Investment Management Association of Singapore",
      subtitle:
        "Live AI-portraits interaction for delegates of a finance masterclass and conference.",
      slug: this.BASE_CASE_STUDIES + "/investment-management-association-of-singapore"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Singapore-Tourism-Board-Tourism-Data-Leadership-Conference.webp",
      title: "Singapore Tourism Board, Tourism Data Leadership Conference",
      subtitle:
        "AI-generated infographics & portraits for STB's flagship data conference.",
      slug: this.BASE_CASE_STUDIES + "/stb-tourism-data-leadership-conference"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Tatler-Gen-T-Summit.webp",
      title: "Tatler Gen.T Summit",
      subtitle:
        "AI powered infographics & portraits at the inaugural Tatler Gen.T Summit in M+ Museum, West Kowloon.",
      slug: this.BASE_CASE_STUDIES + "/tatler-gen-t-summit-2023"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/S-and-P-Global-Platts-APPEC-2023.webp",
      title: "S&P Global Platts APPEC 2023",
      subtitle:
        "AI-powered visual summaries on a 20-metre LED wall display.",
      slug: this.BASE_CASE_STUDIES + "/s-and-p-global-platts-appec-2023"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Singapore-FinTech-Festival-2022.webp",
      title: "Singapore FinTech Festival 2022",
      subtitle:
        "71 visual summaries, plus a 13m immersive infographic wall.",
      slug: this.BASE_CASE_STUDIES + "/singapore-fintech-festival-2022"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-1.png",
      title: "HUSTLE FUND",
      subtitle:
        "Visualizing Hustle Fund's 1st AMA about the Hustleverse community and Web3.",
      slug: this.BASE_CASE_STUDIES + "/hustle-fund"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-2.png",
      title: "KPMG",
      subtitle: "Visualizing a LinkedIn article on ASPAC's public sector.",
      slug: this.BASE_CASE_STUDIES + "/kpmg"
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-3.png",
      title: "SWITCH 2021",
      subtitle:
        "A contactless interactive wall and AI-generated visual summaries for an innovative tech festival.",
      slug: this.BASE_CASE_STUDIES + "/switch-2021"
    },
    // {
    //   image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-4.png",
    //   title: "SINGAPORE FINTECH FESTIVAL 2021",
    //   subtitle:
    //     "Creating visual summaries at speed & scale for fireside chats, presentations, and panel discussions.",
    //   slug: this.BASE_CASE_STUDIES + "/sff-2021"
    // },
    // {
    //   image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-5.png",
    //   title: "SEQUOIA",
    //   subtitle: "Illustrated insights summarizing a 25-min podcast."
    // },
    // {
    //   image: STATIC_ASSET_IMAGES_BASE_URL + "/examples/Homepage-Example-6.png",
    //   title: "SEQUOIA (SURGE)",
    //   subtitle: "Visualizing a quick video to building startup culture."
    // }
  ];
}
