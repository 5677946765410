
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { Vue } from "vue-class-component";
export default class Tooltip extends Vue {
  showStatus = false;
  clickHandler() {
    this.showStatus = true;
  }
  closeHandler() {
    this.showStatus = false;
  }
  crossIcon = STATIC_ASSET_ICONS_BASE_URL + "/cross-white.svg";
}
