
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";

export enum ButtonStyle {
  Inverse,
  Black,
  Grey,
  Red,
  White,
  Yellow
}

@Options({
  props: {
    text: {
      type: String,
      required: true
    },
    fontSize: {
      type: String,
      required: true
    },
    lineHeight: {
      type: String,
      required: true
    },
    padding: {
      type: String,
      required: false
    },
    style: {
      type: Number as PropType<ButtonStyle>,
      required: false
    },
    uppercase: {
      type: Boolean,
      required: false
    },
    actionOnClick: {
      type: Function as PropType<() => void>,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
})
export default class AlternateButton extends Vue {
  // Props
  fontSize!: string;
  lineHeight!: string;
  padding?: string;
  style?: ButtonStyle;
  uppercase?: boolean;
  text!: string;
  disabled?: boolean;

  // Data
  buttonFontDefaults = {
    fontFamily: "Baton Turbo",
    fontStyle: "normal",
    fontWeight: 500
  };
  paddingDefault = "12px 24px";

  colourMapping = {
    [ButtonStyle.Inverse]: { backgroundColor: "#fff", color: "#e5a016" },
    [ButtonStyle.Black]: { backgroundColor: "#111111", color: "#fff" },
    [ButtonStyle.Grey]: { backgroundColor: "#e8e8e8", color: "#111111" },
    [ButtonStyle.Red]: { backgroundColor: "#ed2c55", color: "#fff" },
    [ButtonStyle.White]: { backgroundColor: "#eeeeee", color: "#000000" },
    [ButtonStyle.Yellow]: { backgroundColor: "#f7ce46", color: "#000000" }
  };

  get colourScheme() {
    let backgroundColor: string;
    let color: string;
    if (this.disabled) {
      backgroundColor = "#e5e5e5";
      color = "#a8a8a8";
    } else {
      if (this.style) {
        return this.colourMapping[this.style];
      } else {
        backgroundColor = "#f5b940";
        color = "#292929";
      }
    }
    return { backgroundColor: backgroundColor, color: color };
  }

  get buttonStyle() {
    return {
      ...this.colourScheme,
      fontSize: this.fontSize,
      lineHeight: this.lineHeight,
      ...this.buttonFontDefaults,
      padding: this.padding || this.paddingDefault
    };
  }
}
