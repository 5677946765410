import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileBlocker = _resolveComponent("MobileBlocker")
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_PreviewInfographic = _resolveComponent("PreviewInfographic")
  const _component_HelpFloatingButton = _resolveComponent("HelpFloatingButton")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_MobileBlocker),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_NavBar, {
          key: 0,
          class: "container__mobile__blocker",
          "logged-in": _ctx.isLoggedIn,
          stage: _ctx.ProjectStage.Preview,
          "project-title": _ctx.infographics[0].title
        }, null, 8, ["logged-in", "stage", "project-title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PreviewInfographic, {
      class: "container__mobile__blocker",
      "logged-in": _ctx.isLoggedIn,
      loaded: _ctx.projectLoaded,
      infographics: _ctx.infographics
    }, null, 8, ["logged-in", "loaded", "infographics"]),
    (_ctx.dashboardLoaded)
      ? (_openBlock(), _createBlock(_component_HelpFloatingButton, {
          key: 1,
          class: "container__mobile__blocker",
          "user-email": _ctx.user.email
        }, null, 8, ["user-email"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, {
      class: "container__mobile__blocker",
      "modal-id": "congrats-first",
      image: _ctx.congratsImage,
      "modal-title": "Congratulations on your first infographic!",
      "modal-content": _ctx.congratsModalText,
      "primary-button-text": "Back to Infographic",
      "primary-button-action": _ctx.closeModal,
      preventBackgroundClick: "",
      middle: ""
    }, null, 8, ["image", "modal-content", "primary-button-action"])
  ], 64))
}