<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <!-- Replace the static contents with dynamic bindings as needed -->
      <div :class="[$style.details, { [$style.expanded]: isExpanded }]">
        <div :class="$style.expandButton" @click="toggleExpand">
          <img v-if="!isExpanded" :class="$style.icon" alt="Expand" src="../images/arrow-up.svg" />
          <img v-if="isExpanded" :class="$style.icon" alt="Collapse" src="../images/arrow-down.svg" />
        </div>
        <!-- <img :class="$style.meshGradIcon" alt="" src="Mesh-Grad.png" /> -->
        <div :class="$style.button" @click="goBack">
          <img :class="$style.icon" alt="" src="../images/Back.svg" />
          <div :class="$style.thisVisualSummary">Back</div>
        </div>

        <!-- Details Section -->
        <div :class="$style.details1">
          <div :class="$style.session">
            <div :class="$style.descriptionParent">
              <div :class="$style.description">About this session</div>
              <b :class="$style.time">{{ formatDate(content.date) }} {{ content.time }} {{ eventConfig.timezone }} • {{
                content.time }} mins</b>
              <div :class="$style.title"> {{ content.heading }}
              </div>
              <!-- Tags Section -->
              <div :class="$style.tagParent">
                <div :class="$style.tag">
                  <div :class="$style.thisVisualSummary">{{ content.category }}</div>
                </div>
              </div>
            </div>

            <!-- Session Description -->
            <div :class="$style.session">
              <div :class="$style.munthirAAlsheddie">{{ content.description }}</div>
            </div>

            <div v-if="content.websites && content.websites.length > 0">
              <div v-for="website in content.websites" :key="website.name" :class="$style.websiteButton"
                @click="goToWebsite(website.link)">
                {{ website.name }}
                <img :class="$style.icon" alt="" src="../images/goto.svg" />
              </div>
            </div>
            <!-- Speaker Names -->
            <div :class="$style.descriptionContainer">
              <div :class="$style.munthirAAlsheddie">Speakers:</div>
              <div :class="$style.speakerNames">
                <div v-for="speaker in content.speakers" :key="speaker.name" :class="$style.speakerNames1">
                  <div v-if="speaker.imageUrl" :class="$style.speakerNamesInner">
                    <img :class="$style.frameChild" alt="" :src="speaker.imageUrl" />
                  </div>
                  <div :class="$style.frameParent">
                    <div :class="$style.munthirAAlsheddieParent">
                      <div :class="$style.munthirAAlsheddie">{{ speaker.name }}</div>
                      <div :class="$style.countryGmTarabut">{{ speaker.designation }}{{ speaker.company ? ', ' +
                        speaker.company : '' }}</div>
                    </div>
                    <div :class="$style.ellipseParent">
                      <a v-if="speaker.x" :href="speaker.x" target="_blank" rel="noopener noreferrer">
                        <img :class="$style.frameItem" src="../images/X_white.svg" alt="X Icon" />
                      </a>
                      <a v-if="speaker.linkedin" :href="speaker.linkedin" target="_blank" rel="noopener noreferrer">
                        <img :class="$style.frameItem" src="../images/LinkedIN_white.svg" alt="LinkedIn Icon" />
                      </a>
                      <a v-if="speaker.instagram" :href="speaker.instagram" target="_blank" rel="noopener noreferrer">
                        <img :class="$style.frameItem" src="../images/Instagram_white.svg" alt="Instagram Icon" />
                      </a>
                      <a v-if="speaker.fb" :href="speaker.fb" target="_blank" rel="noopener noreferrer">
                        <img :class="$style.frameItem" src="../images/Facebook_white.svg" alt="Facebook Icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Moderator Names -->
            <div v-if="content.moderators.length > 0">
              <div :class="$style.descriptionContainer">
                <div :class="$style.munthirAAlsheddie">Moderated by:</div>
                <div :class="$style.speakerNames">
                  <div v-for="moderator in content.moderators" :key="moderator.name" :class="$style.speakerNames1">
                    <div v-if="moderator.imageUrl" :class="$style.speakerNamesInner">
                      <img :class="$style.frameChild" alt="" :src="moderator.imageUrl" />
                    </div>
                    <div :class="$style.frameParent">
                      <div :class="$style.munthirAAlsheddieParent">
                        <div :class="$style.munthirAAlsheddie">{{ moderator.name }}</div>
                        <div :class="$style.countryGmTarabut">{{ moderator.designation }}{{ moderator.company ? ', ' +
                          moderator.company : '' }}</div>
                      </div>
                      <div :class="$style.ellipseParent">
                        <a v-if="moderator.x" :href="moderator.x" target="_blank" rel="noopener noreferrer">
                          <img :class="$style.frameItem" src="../images/X_white.svg" alt="X Icon" />
                        </a>
                        <a v-if="moderator.linkedin" :href="moderator.linkedin" target="_blank"
                          rel="noopener noreferrer">
                          <img :class="$style.frameItem" src="../images/LinkedIN_white.svg" alt="LinkedIn Icon" />
                        </a>
                        <a v-if="moderator.instagram" :href="moderator.instagram" target="_blank"
                          rel="noopener noreferrer">
                          <img :class="$style.frameItem" src="../images/Instagram_white.svg" alt="Instagram Icon" />
                        </a>
                        <a v-if="moderator.fb" :href="moderator.fb" target="_blank" rel="noopener noreferrer">
                          <img :class="$style.frameItem" src="../images/Facebook_white.svg" alt="Facebook Icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.body">
        <div :class="$style.infographic" ref="swipeArea">

          <div :class="$style.previousnext">
            <div :class="$style.button" @click="goToPrevious">
              <img :class="$style.icon" alt="" src="../images/Back_black.svg" />
              <div :class="$style.thisVisualSummary">Previous session</div>
            </div>
            <div :class="$style.button" @click="goToNext">
              <div :class="$style.thisVisualSummary">Next session</div>
              <img :class="$style.icon" alt="" src="../images/Next_black.svg" />
            </div>
          </div>
          <div :class="$style.descriptionParent1">
            <b :class="$style.time">{{ formatDate(content.date) }} {{ content.time }} {{ eventConfig.timezone }} • {{
              content.time }} mins</b>
            <div :class="$style.title"> {{ content.heading }}
            </div>
            <!-- Tags Section -->
            <div :class="$style.like">
              <div :class="$style.button6" @click="toggleExpand">
                <div :class="$style.thisVisualSummary">Read more</div>
              </div>
            </div>
          </div>
          <div :class="$style.infographicParent">
            <div v-if="imageLoadStatus[content.talkEndpoint] === true">
              <a href="javascript:void(0)" class="lightbox-link w-inline-block w-lightbox" aria-label="open lightbox"
                aria-haspopup="dialog" @click="openModal(content.imageUrl)">
                <img :src="Array.isArray(content.imageUrl) ? content.imageUrl[currentIndex] : content.imageUrl"
                  loading="lazy" alt="Stage Image" :class="$style.infographicIcon" />
              </a>
            </div>
            <div v-else>
              <img
                :src="`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventConfig.eventName}/images/Holding+Slide.png`"
                loading="lazy" alt="Placeholder Image" :class="$style.infographicIcon" />
            </div>

            <div v-if="imageLoadStatus[content.talkEndpoint] === true" :class="$style.infographicCarousel">
              <div v-if="Array.isArray(content.imageUrl) && content.imageUrl.length !== 0"
                :class="$style.frameParentInfographic">
                <div v-for="(url, index) in content.imageUrl" :key="index" @click="currentIndex = index"
                  :class="[currentIndex === index ? $style.rectangleWrapper : $style.rectangleContainer]">
                  <img :class="$style.frameChildInfographic" alt="" :src="url" />
                </div>
              </div>
            </div>

            <div :class="$style.likeshare">
              <div :class="$style.likeParent">

                <div :class="$style.like">
                  <div :class="$style.button6" @click=goToWebsite(content.source)>
                    <div :class="$style.thisVisualSummary">Learn More</div>
                    <img :class="$style.icon" alt="" src="../images/goto_black.svg" />
                  </div>
                </div>

              </div>
              <div :class="$style.likeParent">
                <div :class="$style.like">
                  <div :class="$style.button6" @click="toggleLike">
                    <img v-if="liked" :class="$style.icon" alt="" src="../images/Like.svg" />
                    <img v-else :class="$style.icon" alt="" src="../images/Like-hollow.svg" />
                    <div :class="$style.thisVisualSummary">{{ content.likes ?? 0 }} Likes</div>
                  </div>
                </div>
                <div :class="$style.like">
                  <div :class="$style.button6" @click="openTooltip">
                    <img :class="$style.icon" alt="" src="../images/Share_black.svg" />
                    <div :class="$style.thisVisualSummary">Share</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div :class="$style.credits">
          <div :class="$style.thisVisualSummaryIsPoweredParent">
            <div :class="$style.thisVisualSummary">This visual summary is powered by </div>
            <img :class="$style.image2Icon" alt="" src="../images/thothai_blue-p-500.png" />
          </div>
          <div :class="$style.joinOurWaitlistContainer">
            <b>Join our waitlist</b>
            <span> to create your own visual summary</span>
          </div>
        </div>
      </div>
    </div>

  </div>
  <PortalPopup v-if="isTooltipOpen" overlayColor="rgba(113, 113, 113, 0.3)" placement="Centered"
    :onOutsideClick="closeTooltip">
    <Tooltip :talkEndpoint="content.talkEndpoint" :name="content.name" :onClose="closeTooltip" />
  </PortalPopup>
  <image-modal v-if="imageUrl"
    :imageUrl="Array.isArray(content.imageUrl) ? content.imageUrl[currentIndex] : content.imageUrl" ref="imageModal">
  </image-modal>
</template>

<script>
import axios from "axios";
import ImageModal from "./ImageModal.vue";
import Tooltip from "../components/Tooltip.vue";
import PortalPopup from "../components/PortalPopup.vue";
import X_onLight_icon from "../images/X_onLight.svg";
import Hammer from 'hammerjs';
import Cookies from 'js-cookie';


export default {
  name: "Talk",
  inject: ["eventConfig"],
  props: {
    talkName: {
      type: String,
      required: true,
    },
    eventName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: {
        moderators: []
      },
      previousTalk: "",
      nextTalk: "",
      imageUrl: null,
      data: null,
      isImageLoaded: false,
      imageLoadError: false,
      imageLoadStatus: {},
      X_onLight_icon,
      isTooltipOpen: false,
      isExpanded: false,
      liked: false,
      currentIndex: 0
    };
  },
  components: { Tooltip, PortalPopup, ImageModal },
  methods: {
    async fetchData() {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
        this.loadTalkData(this.$route.params.name);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    toggleLike() {
      this.liked = !this.liked;
      const talkEndpoint = this.content.talkEndpoint;
      const likedSessions = Cookies.get('liked_sessions');
      let likedSessionsList = likedSessions ? JSON.parse(likedSessions) : [];

      if (likedSessionsList.includes(talkEndpoint)) {
        // Unlike the session
        this.content.likes = (this.content.likes || 0) - 1;
        likedSessionsList = likedSessionsList.filter((session) => session !== talkEndpoint);
        const updatedData = this.updateLikes(this.data, this.content.talkEndpoint)
        this.uploadJsonToS3(`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json`, updatedData)
      } else {
        // Like the session
        this.content.likes = (this.content.likes || 0) + 1;
        likedSessionsList.push(talkEndpoint);
        const updatedData = this.updateLikes(this.data, this.content.talkEndpoint)
        this.uploadJsonToS3(`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json`, updatedData)
      }
      Cookies.set('liked_sessions', JSON.stringify(likedSessionsList));
    },
    openModal(url) {
      if (url) {
        this.imageUrl = url;
        this.$refs.imageModal.open();
      }
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    openTooltip() {
      this.isTooltipOpen = true;
    },
    closeTooltip() {
      this.isTooltipOpen = false;
    },
    goToWebsite(link) {
      window.open(link, "_blank");
    },
    goBack() {
      this.$router.go(-1);
    },
    formatDate(date) {
      const options = { month: "short", day: "2-digit" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    async loadTalkData(talkName) {
      if (!this.data) return;
      const matchedTalk = this.data.find(
        (item) => item.talkEndpoint === `/${this.eventName}/talk/${talkName}`
      );
      if (!matchedTalk) {
        this.$router.push("/404");
        return;
      }
      if (matchedTalk.imageUrl) {
        try {
          await this.preloadImage(matchedTalk.imageUrl);
          this.isImageLoaded = true;
        } catch (error) {
          console.error(error);
          this.imageLoadError = true;
        }
      }

      this.content = {
        eventName: this.eventName,
        name: matchedTalk.title,
        category: matchedTalk.location,
        date: matchedTalk.date,
        time: matchedTalk.time,
        heading: matchedTalk.title,
        speakers: matchedTalk.speakers,
        moderators: matchedTalk.moderators ?? [],
        day: matchedTalk.day,
        imageUrl: matchedTalk.imageUrl,
        talkEndpoint: matchedTalk.talkEndpoint,
        description: matchedTalk.description,
        websites: matchedTalk.websites,
        source: matchedTalk.source,
        // websites: [{ name: "hi", link: "https://www.google.com" }]
        likes: matchedTalk.likes
      };

      const sortedTalks = this.data.sort((a, b) => {
        const dateA = new Date(`${a.date} ${a.time}`);
        const dateB = new Date(`${b.date} ${b.time}`);
        return dateA - dateB;
      });

      // Find the index of the current talk
      const currentIndex = sortedTalks.findIndex(
        (talk) => talk.talkEndpoint === matchedTalk.talkEndpoint
      );

      // Find the previous and next talks
      const previousTalk = currentIndex > 0 ? sortedTalks[currentIndex - 1] : sortedTalks[currentIndex];
      const nextTalk = currentIndex < sortedTalks.length - 1 ? sortedTalks[currentIndex + 1] : sortedTalks[currentIndex];

      // Store previous and next talks in the data
      this.previousTalk = previousTalk;
      this.nextTalk = nextTalk;
      this.checkImageStatus();
      this.liked = JSON.parse(Cookies.get('liked_sessions').includes(this.content.talkEndpoint))
    },
    async checkImageStatus() {
      // Check if the main image is valid
      try {
        if (!Array.isArray(this.content.imageUrl)) {
          await this.preloadImage(this.content.imageUrl);
          this.imageLoadStatus[this.content.talkEndpoint] = true;
          this.isImageLoaded = true;
        } else {
          // Preload each image in the array
          const preloadPromises = this.content.imageUrl.map(url => this.preloadImage(url));
          await Promise.all(preloadPromises);
          this.imageLoadStatus[this.content.talkEndpoint] = true;
          this.isImageLoaded = true;
        }
      } catch (error) {
        this.imageLoadStatus[this.content.talkEndpoint] = false;
        this.imageLoadError = true;
      }
    },
    async preloadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
      });
    },
    goToNext() {
      this.$router.push(this.nextTalk.talkEndpoint);
    },
    goToPrevious() {
      this.$router.push(this.previousTalk.talkEndpoint);
    },
    updateLikes(data, sessionTalkEndpoint) {
      const session = data.find(item => item.talkEndpoint === sessionTalkEndpoint);
      if (session) {
        session.likes = this.content.likes;
        session.updatedAt = new Date().toISOString();
      }
      return data;
    },
    async uploadJsonToS3(url, updatedData) {
      try {
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updatedData)
        });
        if (!response.ok) {
          throw new Error("Failed to upload the file to S3");
        }
      } catch (error) {
        console.error("Error uploading the file:", error);
      }
    }
  },

  async mounted() {
    await this.fetchData();
    const hammer = new Hammer(this.$refs.swipeArea);

    hammer.on('swipeleft', this.goToNext);
    hammer.on('swiperight', this.goToPrevious);
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      handler(newName) {
        if (this.$route.path.includes('/talk/')) {
          this.loadTalkData(newName);
        }
      }
    }
  }
};
</script>

<style module>
.container {
  display: flex;
  flex-direction: row;
}

.meshGradIcon {
  width: 1013px;
  position: absolute;
  margin: 0 !important;
  top: -266px;
  left: -166.5px;
  height: 807px;
  object-fit: cover;
  opacity: 0.2;
  z-index: 0;
}

.icon {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.websiteButton {
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  border: 1px solid #fff;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  gap: 4px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: 'DM Sans';
}

.button {
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 1;
  cursor: pointer;
}

.description {
  width: 340px;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--highlight-color);
  display: inline-block;
}

.time {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  color: #d9d9d9;
}

.descriptionParent1 .time {
  color: #B89A6A
}

.title {
  align-self: stretch;
  position: relative;
  font-size: 32px;
  line-height: 130%;
  font-family: 'Instrument Serif';
}

.tag {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  box-sizing: border-box;
}

.tagParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
}

.descriptionParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 14px;
}

.descriptionParent1 {
  display: none;
}

.button2 {
  border-radius: 12px;
  border: 1px solid #fff;
  box-sizing: border-box;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  gap: 4px;
}

.buttonWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
}

.frameChild {
  position: absolute;
  top: 5.09px;
  left: 5.09px;
  border-radius: 12.73px;
  width: 45.8px;
  height: 45.8px;
  overflow: hidden;
  object-fit: cover;
}

.frameChildInfographic {
  position: relative;
  border-radius: 12.73px;
  overflow: hidden;
  object-fit: cover;
}

.speakerNamesInner {
  width: 56px;
  position: relative;
  border-radius: 15.27px;
  border: 1.3px solid #fff;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}

.munthirAAlsheddie {
  align-self: stretch;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}

.countryGmTarabut {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 130%;
  color: #fff;
}

.munthirAAlsheddieParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameItem {
  width: 20px;
  position: relative;
  border-radius: 50%;
  background-color: var(--highlight-color);
  height: 20px;
}

.ellipseParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.frameParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.speakerNames1 {
  width: 280px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.moderatedBy {
  align-self: stretch;
  position: relative;
  line-height: 130%;
}

.munthirAAlsheddie4 {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  color: var(--highlight-color);
}

.speakerNames5 {
  width: 280px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 12px;
  color: #fff;
}

.speakerNames {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: var(--highlight-color);
}

.descriptionContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.session {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.description3 {
  width: 308px;
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
}

.description4 {
  width: 308px;
  position: relative;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  display: inline-block;
}

.details2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.event {
  align-self: stretch;
  border-radius: 16px;
  background-color: #B89A6A;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 16px;
  gap: 24px;
  font-size: 14px;
}

.padding {
  width: 340px;
  position: relative;
  background-color: rgba(217, 217, 217, 0.01);
  height: 24px;
  flex-shrink: 0;
}

.details1 {
  max-height: 100vh;
  align-self: stretch;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  z-index: 2;
  margin-bottom: 3rem;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.container {
  -ms-overflow-style: none;
  /* Disable scrollbar */
}

.button6 {
  cursor: pointer;
  border-radius: 12px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 8px;
}

.likeshare {
  width: 100%;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  justify-content: space-between;
  padding: 8px 0px;
  gap: 16px;
  z-index: 3;
}

.button10 {
  border-radius: 12px;
  border: 1px solid #fff;
  box-sizing: border-box;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 8px;
}

.previousnext {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  width: 100%;
}

.details {
  align-self: stretch;
  width: 420px;
  border-radius: 0px 24px 24px 0px;
  background-color: #111;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 40px 0px;
  box-sizing: border-box;
  position: relative;
  gap: 24px;
  margin-top: 100px;
  transition: max-height 0.4s ease-in-out;
}

.expandButton {
  position: absolute;
  top: 15px;
  right: 24px;
  cursor: pointer;
}

.expanded .details {
  transform: translateY(calc(100vh - 30px - 100%));
}

.button14 {
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}


.infographicIcon {
  width: 940px;
  position: relative;
  height: 514px;
  object-fit: contain;
}

.rectangleIcon {
  align-self: stretch;
  position: relative;
  border-radius: 12px;
  max-width: 100%;
  overflow: hidden;
  height: 64px;
  flex-shrink: 0;
  object-fit: cover;
}

.rectangleWrapper {
  width: 108.6px;
  border-radius: 12px;
  border: 1px solid #B89A6A;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px;
}

.rectangleContainer {
  width: 108.6px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px;
  box-sizing: border-box;
  opacity: 0.5;
}

.frameParentInfographic {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.infographicCarousel {
  max-width: 100%;
  flex-shrink: 0;
}

.infographicParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}


.button18 {
  border-radius: 12px;
  background-color: #f5f5f5;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 8px;
}

.like {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 12px;
  background-color: #f5f5f5;
  padding: 6px 16px;
}

.button20 {
  border-radius: 12px;
  background-color: #f5f5f5;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 8px;
  cursor: pointer;
}

.likeParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  justify-content: space-between;
  gap: 16px;
}

.likeshare1 {
  align-self: stretch;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 0px;
  color: #2c2c2c;
}

.infographic {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 80px 0px;
  box-sizing: border-box;
  gap: 24px;
  max-width: 1080px;
  z-index: 0;
  min-width: 0;
  overflow-x: auto;
}

.thisVisualSummary {
  position: relative;
  line-height: 130%;
}

.image2Icon {
  width: 80px;
  position: relative;
  height: 16px;
  object-fit: cover;
}

.thisVisualSummaryIsPoweredParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.joinOurWaitlistContainer {
  position: relative;
  font-size: 12px;
  line-height: 130%;
}

.credits {
  margin: 0 !important;
  position: absolute;
  bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  z-index: 1;
  font-size: 14px;
}

.body {
  align-self: stretch;
  flex: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 40px;
  position: relative;
  color: #494949;
  margin-top: 106px;
  position: relative;
  min-width: 0;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
}

.expandButton {
  display: none;
}

/* For smaller screens */
@media (max-width: 1024px) {
  .previousnext {
    display: none;
  }

  .descriptionParent1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 13px;
    display: display;
  }

  .details {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 24px 24px 0 0;
    padding: 20px;
    z-index: 10;
    transform: translateY(101%);
    transition: transform 0.5s ease-in-out;
  }

  .expandButton {
    display: block;
  }

  .details.expanded {
    max-height: 80vh;
  }

  .expanded {
    /* max-height: 100vh; */
    transform: translateY(5%);

  }

  .infographicIcon {
    height: 35vh;
  }

  .infographic {
    padding: 30px 0px 80px 0px;
  }

  .body {
    margin-bottom: 3rem;
    padding: 15px;
  }

  .title {
    font-size: x-large;
  }

  .thisVisualSummary {
    font-size: small;
  }
}
</style>
