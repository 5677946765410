
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { Infographic } from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import {
  STATIC_ASSET_IMAGES_BASE_URL,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";

@Options({
  props: {
    publishedInfographic: {
      type: Object as PropType<Infographic>,
      required: true
    },
    loaded: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  components: {
    ThothButton
  }
})
export default class PublishInfographic extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  images: { [id: string]: string } = {
    backgroundImage: STATIC_ASSET_IMAGES_BASE_URL + "/Preview-Background.svg",
    linkedInIcon: STATIC_ASSET_ICONS_BASE_URL + "/linkedin.svg",
    twitterIcon: STATIC_ASSET_ICONS_BASE_URL + "/twitter.svg",
    whatsappIcon: STATIC_ASSET_ICONS_BASE_URL + "/whatsapp.svg",
    facebookIcon: STATIC_ASSET_ICONS_BASE_URL + "/facebook.svg"
  };

  title = "";

  projectId;

  shareUrl = "";

  copyButton = "Copy";

  copiedLink = false;

  created() {
    const { projectId } = this.$route.params;
    this.projectId = projectId;
    this.shareUrl = window.location.origin + "/infographic/" + projectId;
  }

  downloadInfographic() {
    const img = this.getBase64Image(
      document.getElementById("published-infographic") as HTMLImageElement
    );
    const link = document.createElement("a");
    const hiddenTitleElement = document.querySelector(
      "#hidden-infographic-title"
    ) as HTMLInputElement;
    const title = hiddenTitleElement.value;
    const formattedTitle = title.replace(/[^A-Z0-9]/gi, "_");
    if (img) {
      link.href = "data:image/png;base64," + img;
      link.download = formattedTitle + ".png";
      link.click();
    } else {
      console.log("Error downloading selected image");
    }
  }
  getBase64Image(img: HTMLImageElement): string {
    const canvas = document.createElement("canvas");
    canvas.width = 3840;
    canvas.height = 2160;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(img as CanvasImageSource, 0, 0);
      const dataURL = canvas.toDataURL("image/jpg");
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    } else {
      console.log("Error getting image");
      return "";
    }
  }
  returnToHome() {
    this.$router.push({ name: "Home" });
  }
  copyUrl = () => {
    const urlField = document.querySelector(
      "#infographic-url"
    ) as HTMLInputElement;
    urlField.select();
    if (document.execCommand("copy")) {
      this.copiedLink = true;
      this.copyButton = "Copied";
    }
  };
  buildSharer = socialMedia => {
    const sharers = {
      facebook: {
        shareUrl: "https://www.facebook.com/sharer/sharer.php",
        params: {
          u: this.shareUrl
        }
      },
      linkedin: {
        shareUrl: "https://www.linkedin.com/shareArticle",
        params: {
          url: this.shareUrl,
          mini: true
        }
      },
      twitter: {
        shareUrl: "https://X.com/intent/tweet/",
        params: {
          text: this.getInfographicTitle(),
          url: this.shareUrl
        }
      },
      whatsapp: {
        shareUrl: "https://wa.me/",
        params: {
          text: this.shareUrl
        }
      }
    };
    const { shareUrl, params } = sharers[socialMedia];
    const formattedParams = Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    console.log(formattedParams);
    return shareUrl + "?" + formattedParams;
  };
  shareOnSocialMedia = e => {
    const socialMedia = e.target.id;
    const url = this.buildSharer(socialMedia);
    window.open(
      url,
      "popUpWindow",
      "height=800,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
    );
  };
  getInfographicTitle = () => {
    const hiddenTitleElement = document.querySelector(
      "#hidden-infographic-title"
    ) as HTMLInputElement;
    if (hiddenTitleElement != null) {
      this.title = hiddenTitleElement.value;
    }
    return this.title;
  };
}
