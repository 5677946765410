
import {
  Project,
  ProjectCount,
  ProjectType,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";
import ContentPlaceholder from "@/components/product/dashboard/content/ContentPlaceholder.vue";
import DashboardToast from "@/components/product/dashboard/content/DashboardToast.vue";
import ProjectDisplay from "@/components/product/dashboard/content/ProjectDisplay.vue";
import { PROJECT_API } from "@/services";
import {
  RESTORE_PROJECT_ACTION,
  TRASH_PROJECT_ACTION
} from "@/store/constants.js";
import { PropType } from "@vue/runtime-core";
import api from "@/services/api";
import UIkit from "uikit";
import { Options, Vue } from "vue-class-component";

export enum DraftFilter {
  "All",
  "Arts mode",
  "Preview"
}

@Options({
  emits: {
    viewDrafts: {},
    updateCurrentPage: {},
    projectStateUpdated: {}
  },
  props: {
    activeFolder: {
      type: Number as PropType<ProjectType>,
      required: true
    },
    projects: {
      type: Array as PropType<Project[]>,
      required: true
    },
    projectCount: {
      type: Object as PropType<ProjectCount[]>,
      required: true
    },
    currentPage: {
      type: Number as PropType<number>,
      require: true
    }
  },
  components: {
    ContentPlaceholder,
    ProjectDisplay,
    DashboardToast
  },
  watch: {
    projects: {
      handler() {
        this.loadedProjects = []; // Clear loadedProjects when projects prop changes
      },
      immediate: true
    }
  }
})
export default class DashboardContent extends Vue {
  ProjectType = ProjectType;
  activeFolder!: ProjectType;
  projects!: Project[];
  projectCount!: ProjectCount;

  chevronLeft = STATIC_ASSET_ICONS_BASE_URL + "/dashboard-chevron-left.svg";
  chevronDown = STATIC_ASSET_ICONS_BASE_URL + "/dashboard-chevron-down.svg";
  filters = ["All", "Editing", "Preview"];
  selectedFilter = "All";
  toastProps = { message: "" };
  currentPage!: number;
  isLoadingMore = false;
  loadedProjects: Project[] = [];
  boundHandleScroll;

  get dashboardHeader() {
    if (this.activeFolder === ProjectType.Draft) {
      if (this.selectedFilter === "All") {
        return { title: "Drafts", count: this.projectCount.draft };
      } else if (this.selectedFilter === "Editing") {
        return { title: "Editing", count: this.projectsToDisplay.length };
      } else {
        return { title: "Preview", count: this.projectsToDisplay.length };
      }
    } else if (this.activeFolder === ProjectType.Downloaded) {
      return { title: "Downloaded", count: this.projectCount.downloaded };
    } else {
      return { title: "Trash", count: this.projectCount.trash };
    }
  }

  async loadMoreProjects() {
    const nextPage = this.currentPage + 1;
    const status = this.activeFolder === ProjectType.Draft ? "READY" : "LOCKED";
    const state =
      this.activeFolder === ProjectType.Trash ? "TRASHED" : "IN_USE";

    try {
      const response = await api.get(`${PROJECT_API}/dashboard`, {
        params: {
          page: nextPage,
          perPage: 10,
          status: status,
          state: state
        }
      });

      const projects = response.data.projects;
      const currentPage = parseInt(response.data.currentPage);

      this.loadedProjects = [...this.loadedProjects, ...projects];
      this.$emit("updateCurrentPage", currentPage);
      this.isLoadingMore = false;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  get projectsToDisplay() {
    const allProjects = this.projects
      ? [...this.projects, ...this.loadedProjects]
      : [];

    if (this.activeFolder === ProjectType.Draft) {
      const projectsInUse = allProjects.filter(p => p.state === "IN_USE");
      if (this.activeFolder === ProjectType.Draft) {
        const draftProjects = projectsInUse.filter(p => p.status === "READY");
        if (this.selectedFilter === "All") {
          return draftProjects;
        } else if (this.selectedFilter === "Editing") {
          return draftProjects.filter(p => p.mode === "ARTS");
        } else {
          return draftProjects.filter(p => p.mode === "PREVIEW");
        }
      }
      return projectsInUse;
    }
    return allProjects;
  }

  viewDrafts() {
    this.$emit("viewDrafts");
  }

  filterDrafts(filter: string) {
    UIkit.dropdown("#dashboard__dropdown").hide();
    this.selectedFilter = filter;
  }

  toggleToast() {
    const element = document.querySelector("#dashboard-toast") as HTMLElement;
    if (element) {
      UIkit.toggle(element).toggle();
      setTimeout(() => {
        UIkit.toggle(element).toggle();
      }, 2400);
    }
  }

  async moveToTrash(projectUUID: string) {
    await this.$store.dispatch(TRASH_PROJECT_ACTION, projectUUID);
    const infographicDropdown = document.querySelector(
      ".infographic__dropdown"
    );
    if (infographicDropdown) {
      UIkit.dropdown(infographicDropdown).hide();
    }
    this.toastProps.message = "Infographic moved to Trash";
    this.toggleToast();
    this.$emit("projectStateUpdated");
  }

  async restoreProject(projectUUID: string) {
    await this.$store.dispatch(RESTORE_PROJECT_ACTION, projectUUID);
    const infographicDropdown = document.querySelector(
      ".infographic__dropdown"
    );
    if (infographicDropdown) {
      UIkit.dropdown(infographicDropdown).hide();
    }
    const project = this.projects.find(p => p.uuid === projectUUID);
    if (project) {
      const folder = project.status === "READY" ? "Drafts" : "Downloaded";
      this.toastProps.message = `Infographic moved to ${folder}`;
      this.toggleToast();
      this.$emit("projectStateUpdated");
    }
  }

  updateProjectsState() {
    this.$emit("projectStateUpdated");
  }

  created(): void {
    this.boundHandleScroll = this.handleScroll.bind(this);
  }

  mounted(): void {
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeDestroy(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (!this.$refs.projectContainer) {
      return;
    }

    const projectContainer = this.$refs.projectContainer as HTMLElement;

    const scrollPosition = window.innerHeight + window.pageYOffset;
    const containerHeight = projectContainer.offsetHeight;

    const threshold = 500; // Adjust this value to control the scroll threshold

    if (scrollPosition >= containerHeight - threshold && !this.isLoadingMore) {
      this.isLoadingMore = true;
      this.loadMoreProjects();
    }
  }
}
