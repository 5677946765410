<template>
  <div class="layout_selection_container">
    <div class="layout_transit_anim">
      <lottie :options="defaultOptions" @animCreated="handleAnimation" />
    </div>
    <div class="layout_choices">
      <img
        class="layout_image uk-width-1-3"
        :class="selectedLayout0"
        @click="select(layouts[0].layoutTitle)"
        :src="layouts[0].image"
        alt="layout_choice"
      />
      <img
        class="layout_image uk-width-1-3"
        :class="selectedLayout1"
        @click="select(layouts[1].layoutTitle)"
        :src="layouts[1].image"
        alt="layout_choice"
      />
      <img
        class="layout_image uk-width-1-3"
        :class="selectedLayout2"
        @click="select(layouts[2].layoutTitle)"
        :src="layouts[2].image"
        alt="layout_choice"
      />
    </div>
    <DemoModal
      v-for="(innerStep, index) in innerSteps"
      :key="index"
      :delayed="innerStep.delayed"
      :modal="checkInnerStep(innerStep.stepNum)"
      :title="innerStep.title"
      :subtexts="innerStep.subtexts"
      :moveonEnd="innerStep.moveonEnd"
      @modal-closed="close"
    />
  </div>
</template>
<script>
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import DemoModal from "@/components/common/DemoModal.vue";
import layoutPressAnim from "@/assets/animate/press.json";
@Options({
  computed: {
    imageSelect() {
      return this.choice >= 0 ? this.layouts[this.choice].lottieimage : null;
    },
    selectedLayout0() {
      return this.choice === "A" ? "layout_selected_image" : "";
    },
    selectedLayout1() {
      return this.choice === "B" ? "layout_selected_image" : "";
    },
    selectedLayout2() {
      return this.choice === "C" ? "layout_selected_image" : "";
    }
  },
  components: { lottie, DemoModal }
})
export default class LayoutSelection extends Vue {
  //data
  choice = "";
  innerStep = 0;
  chosen = false;
  defaultOptions = {
    animationData: layoutPressAnim,
    loop: false,
    autoplay: false
  };
  innerSteps = [
    {
      stepNum: 0,
      moveonEnd: false,
      delayed: true,
      title: "Step 4/4: Select Layout",
      subtexts: [
        "Almost ready! Finally, choose a layout that makes sense to you."
      ]
    }
  ];
  layouts = [
    {
      layoutTitle: "A",
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Layout-1-Wireframe.svg"
    },
    {
      layoutTitle: "B",
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Layout-2-Wireframe.svg"
    },
    {
      layoutTitle: "C",
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Layout-3-Wireframe.svg"
    }
  ];
  //functions
  checkInnerStep(number) {
    switch (number) {
      case -1:
        return this.innerSteps.length == this.innerStep;
      case -2:
        return this.innerStep < this.innerSteps.length;
      default:
        return number == this.innerStep;
    }
  }
  close() {
    this.innerStep++;
  }
  select(layout) {
    if (!this.chosen) {
      this.chosen = true;
      this.choice = layout;
      this.$emit("selectLayout", layout);
      this.anim.play();
      setTimeout(() => {
        this.$emit("finished");
      }, 2000);
    }
  }
  animationCompleted() {
    this.anim.stop();
  }
  handleAnimation(anim) {
    this.anim = anim;
    this.anim.addEventListener("loopComplete", () => this.animationCompleted());
  }
}
</script>
<style scoped>
.layout_selection_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.layout_transit_anim {
  position: absolute;
  height: 95vh;
  width: calc(320 / 568 * 95vh);
  z-index: 0;
}
.layout_choices {
  background: var(--ink);
  width: 85%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  padding: 15px;
  border-radius: 10px 10px 0 0;
}
.layout_image {
  padding: 5px;
}

.layout_selected_image {
  border: 2px solid var(--peach);
}

@media screen and (max-width: 480px) {
  .layout_transit_anim {
    height: calc(568 / 320 * 98vw);
    width: 98vw;
  }
}
</style>
