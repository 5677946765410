
import { Form } from "vee-validate";
import UIkit from "uikit";
import { Options, Vue } from "vue-class-component";
import { User } from "@/commons";
import { PropType } from "@vue/runtime-core";
import Toast from "@/components/common/Toast.vue";
import TextField from "@/components/common/TextField.vue";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { PROFILE_GETTER, UPDATE_PROFILE_ACTION } from "@/store/constants";
import { PROFILE_API } from "@/services";
import api from "@/services/api";

@Options({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  components: {
    Form,
    TextField,
    ThothButton,
    Toast
  }
})
export default class AccountProfile extends Vue {
  // Props
  loggedIn!: boolean;
  user!: User;

  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  formValues = {};
  nameRules = "";
  bgError = "";
  toastProps = { message: "" };

  get profile() {
    return this.loggedIn ? this.$store.getters[PROFILE_GETTER] : {};
  }

  saveChanges(form) {
    const data = {
      firstName: form["First Name"],
      lastName: form["Last Name"]
    };

    api
      .post(PROFILE_API, data)
      .then(response => {
        this.$store.dispatch(UPDATE_PROFILE_ACTION, response.data);
        this.toastProps.message = `Name updated successfully.`;
        this.toggleToast();
      })
      .catch(error => {
        console.log(error);
      });
  }

  toggleToast() {
    const element = document.querySelector("#toast") as HTMLElement;
    if (element) {
      UIkit.toggle(element).toggle();
      setTimeout(() => {
        UIkit.toggle(element).toggle();
      }, 2400);
    }
  }
  mounted() {
    this.formValues = {
      "First Name": this.profile ? this.profile.firstName : "",
      "Last Name": this.profile ? this.profile.lastName : ""
    };
  }
}
