
import { Vue, Options } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
@Options({
  props: {
    toggleId: {
      type: String as PropType<string>,
      required: true
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
})
export default class ToggleSwitch extends Vue {}
