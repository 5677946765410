<template>
  <div :class="$style.footer">
    <div :class="$style.leftHandPanelParent">
      <div :class="$style.leftHandPanel">
        <img :class="$style.logoIcon" alt="Logo" :src="eventConfig.partnerLockup" />
        <div :class="$style.buttonParent">
          <div :class="$style.button" @click="visitEventWebsite">
            <div :class="$style.button1">Visit {{ eventConfig.eventTitle }}</div>
            <img :class="$style.icon" alt="Icon" src="../images/goto.svg" />
          </div>
          <div :class="$style.button" @click="visitThoth">
            <div :class="$style.button1">Visit Thoth.ai</div>
            <img :class="$style.icon" alt="Icon" src="../images/goto.svg" />
          </div>
        </div>
      </div>
      <div :class="$style.manageCookiesParent">
        <div :class="$style.manageCookies" @click="openCookieSettings">Manage cookies</div>
        <div :class="$style.manageCookies">|</div>
        <div :class="$style.manageCookies" @click="goToPrivacyPolicy">Privacy policy</div>
        <div :class="$style.manageCookies">|</div>
        <div :class="$style.manageCookies" @click="goToTermsOfUse">Terms of use</div>
      </div>
    </div>
    <div :class="$style.copyrightContainer">
      <div :class="$style.copyright">
        <span :class="$style.span">© Copyright 2024 </span>
        <router-link :to="{ path: '/privacy' }" :class="$style.thoth">
          <span :class="$style.span">
            <span :class="$style.thoth2">Thoth</span>
          </span>
        </router-link>
        <span :class="$style.span">.</span>
      </div>
      <div :class="$style.disclaimer">
        All images and work product are the proprietary products of Thoth and
        fully protected by the Copyright Act (Cap. 63 of 2006) of Singapore and
        other applicable laws. The revocable licence to use Thoth shall not
        result in the transfer of any intellectual property rights to any user.
        By using Thoth, you agree that in consideration of the grant of the
        licence, all rights in any user input shall be irrevocably signed to Thoth.
      </div>
    </div>
    <div :class="$style.manageCookiesParentMobile">
      <div :class="$style.manageCookies" @click="openCookieSettings">Manage cookies</div>
      <div :class="$style.manageCookies" @click="goToPrivacyPolicy">Privacy policy</div>
      <div :class="$style.manageCookies" @click="goToTermsOfUse">Terms of use</div>
    </div>
  </div>
</template>

<script>
import EventBus from './eventBus';

export default {
  name: "Footer",
  inject: ['eventConfig'],
  methods: {
    visitEventWebsite() {
      const website = this.eventConfig.eventWebsite;
      window.open(website, '_blank');
    },
    visitThoth() {
      window.open('https://thoth.art', '_blank');
    },
    openCookieSettings() {
      EventBus.emit('openCookieSettings');
    },
    goToPrivacyPolicy() {
      this.$router.push('/privacy')
    },
    goToTermsOfUse() {
      this.$router.push('/tos')
    }
  }
};
</script>

<style module>
.logoIcon {
  width: 203.4px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  line-height: 130%;
  color: #fff;
}

.icon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.button {
  border-radius: 12px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  box-sizing: border-box;
  gap: 8px;
  cursor: pointer
}

.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.leftHandPanel {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}

.thoth2 {
  text-decoration: underline;
}

.thoth {
  color: inherit;
}

.span {
  font-weight: 600;
}

.copyright {
  width: 100%;
  line-height: 130%;
  display: inline-block;
}

.disclaimer {
  width: 100%;
  font-size: 12px;
  line-height: 130%;
  display: inline-block;
}

.copyrightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #d9d9d9;
}

.footer {
  width: 100%;
  border-radius: 40px 40px 0px 0px;
  background-color: #111;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 40px;
  gap: 24px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: 'DM Sans';
}

.manageCookies {
  position: relative;
  line-height: 130%;
  cursor: pointer;
}

.manageCookiesParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #d9d9d9;
}

.manageCookiesParentMobile {
  display: none;
}

.leftHandPanelParent {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: 'DM Sans';
}

@media (max-width: 1080px) {
  .manageCookiesParent {
    display: none;
  }

  .manageCookiesParentMobile {
    display: block;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 14px;
    color: #d9d9d9;
    font-family: 'DM Sans';

  }
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 30px;
    gap: 16px;
  }

  .leftHandPanel {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .buttonParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .button {
    border-radius: 12px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    box-sizing: border-box;
    gap: 8px;
  }

  .button1 {
    position: relative;
    line-height: 130%;
  }

  .icon {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .copyrightContainer {
    font-size: 12px;
  }

  .logoIcon {
    height: 28px;
  }

}
</style>
