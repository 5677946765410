
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import SaveChangesBar from "@/components/product/edit/SaveChangesBar.vue";
import SaveAlertToast from "@/components/common/SaveAlertToast.vue";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import {
  ActiveBrandLink,
  CanvasMode,
  BrandKitMode,
  BrandLinks,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";

@Options({
  components: {
    SaveAlertToast,
    SaveChangesBar,
    ThothButton
  },
  props: {
    brandLinks: {
      type: Object as PropType<BrandLinks>,
      required: true
    },
    activeBrandLink: {
      type: Object as PropType<ActiveBrandLink>,
      required: true
    },
    makeActiveBrandLink: {
      type: Function as PropType<() => void>,
      required: true
    },
    mustSave: {
      type: Boolean,
      required: true
    },
    showToast: {
      type: Boolean,
      required: true
    },
    saveChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    },
    selectBrandKitMode: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
})
export default class EditBrandLink extends Vue {
  //Commons
  CanvasMode = CanvasMode;
  BrandKitMode = BrandKitMode;
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  socials = {
    website: { title: "Website URL", prefix: "https://" },
    linkedin: { title: "LinkedIn", prefix: "https://linkedin.com/" },
    twitter: { title: "Twitter", prefix: "@" },
    instagram: { title: "Instagram", prefix: "@" },
    facebook: { title: "Facebook", prefix: "https://facebook.com/" }
  };
  icons = {
    facebook: STATIC_ASSET_ICONS_BASE_URL + "/facebook.svg",
    linkedin: STATIC_ASSET_ICONS_BASE_URL + "/linkedin.svg",
    website: STATIC_ASSET_ICONS_BASE_URL + "/website.svg",
    twitter: STATIC_ASSET_ICONS_BASE_URL + "/twitter.svg",
    instagram: STATIC_ASSET_ICONS_BASE_URL + "/instagram.svg"
  };

  // Props
  selectBrandKitMode!: Function;
  chevronLeft = STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-chevron-left.svg";
  noDisplay = STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-no-display.svg";
  triggerInternalToast = false;
  internalToastSetTimeout = 0;

  goToBrandKit() {
    const brandKitRoute = this.$router.resolve({ name: "BrandKit" });
    window.open(brandKitRoute.href, "_blank");
  }

  cancelChanges() {
    this.$emit("cancelBrandKitChanges");
  }

  exitSegment() {
    if (this.triggerInternalToast) {
      window.clearTimeout(this.internalToastSetTimeout);
    }
    this.triggerInternalToast = true;
    this.selectBrandKitMode(BrandKitMode.Main);
    this.internalToastSetTimeout = setTimeout(() => {
      this.triggerInternalToast = false;
    }, 5000);
  }
}
