<template>
  <div id="app">
    <NavBar />
    <HeroSection :eventName="$route.params.eventName" />
    <SessionHighlights :eventName="$route.params.eventName" />
    <Footer :eventName="$route.params.eventName" />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import HeroSection from "./components/HeroSection.vue";
import SessionHighlights from "./components/SessionHighlights.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    HeroSection,
    SessionHighlights,
    Footer
  }
};
</script>

<style scoped>
@import "./components/webflow.css";
@import "./components/normalize.css";
@import "./components/sffnotes.webflow.css";
</style>
