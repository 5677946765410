
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
@Options({
  props: {
    text: {
      type: String as PropType<string>,
      required: true
    },
    quoter: {
      type: String as PropType<string>,
      required: true
    }
  }
})
export default class Quote extends Vue {}
