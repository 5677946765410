
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import { Vue } from "vue-class-component";

export default class BetaFormats extends Vue {
  images: { [id: string]: string } = {
    formatOne: STATIC_ASSET_IMAGES_BASE_URL + "/format-1.png",
    formatTwo: STATIC_ASSET_IMAGES_BASE_URL + "/format-2.png",
    formatThree: STATIC_ASSET_IMAGES_BASE_URL + "/format-3.png",
    formatFour: STATIC_ASSET_IMAGES_BASE_URL + "/format-4.png",
    formatFive: STATIC_ASSET_IMAGES_BASE_URL + "/format-5.png",
    formatSix: STATIC_ASSET_IMAGES_BASE_URL + "/format-6.png"
  };

  infographics = [
    {
      id: 1,
      title: "Event Conferences",
      description:
        "Our AI cuts through complexity for impact. Build consensus with meaningful takeaways.",
      image: this.images.formatOne
    },
    {
      id: 2,
      title: "Online Webinars",
      description:
        "Transform live discussions into real-time social media content. Great for sharing on-the-go.",
      image: this.images.formatTwo
    },
    {
      id: 3,
      title: "Company Reports",
      description:
        "Create shared libraries of team discussions, to align on decisions and strategize for action.",
      image: this.images.formatThree
    },
    {
      id: 4,
      title: "Media Articles",
      description:
        "We live in a world of noise. Help your audience navigate your nuances and pick out key insights quickly.",
      image: this.images.formatFour
    },
    {
      id: 5,
      title: "Research Articles",
      description:
        "Important ideas are hard to digest. Our visual 1-pagers break down big ideas into shareable quick bites.",
      image: this.images.formatFive
    },
    {
      id: 6,
      title: "Edtech Videos",
      description:
        "Summarize talks and podcasts into visuals with a punch. An image literally speaks a thousand words.",
      image: this.images.formatSix
    }
  ];
}
