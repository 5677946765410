
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import { Logo, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { ADD_LOGO_ACTION, DELETE_LOGO_ACTION } from "@/store/constants";
import { ButtonColour } from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";

@Options({
  props: {
    logos: {
      type: Array as PropType<Logo[]>,
      required: true
    }
  },
  components: {
    Modal
  }
})
export default class Logos extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;

  // States

  logoWarning =
    "Recommended logo size is 360px by 540px or higher, for optimal scaling in infographic.";
  icons = {
    trash: STATIC_ASSET_ICONS_BASE_URL + "/trash-logo.svg"
  };
  logoToDelete: Logo = { uuid: "", filename: "", s3Url: "" };

  get modalText() {
    return `You're about to delete <b>${this.logoToDelete.filename}</b>. You will not be able to recover it if you change your mind.`;
  }
  get modalElement() {
    return document.querySelector("#logos__modal");
  }

  // Methods
  async uploadLogo() {
    const inputEl = this.$refs.logo as HTMLInputElement;
    const uploadedFile = inputEl.files?.item(0) || undefined;
    if (!uploadedFile) {
      console.error("Error uploading file.");
    } else {
      try {
        const form = new FormData();
        form.append("image", uploadedFile);
        await this.$store.dispatch(ADD_LOGO_ACTION, form);
      } catch (err) {
        console.log(err);
      }
    }
  }
  openModal(logo: Logo) {
    if (this.modalElement) {
      this.logoToDelete = logo;
      UIkit.modal(this.modalElement).show();
    }
  }
  closeModal() {
    if (this.modalElement) {
      this.logoToDelete = { uuid: "", filename: "", s3Url: "" };
      UIkit.modal(this.modalElement).hide();
    }
  }
  async deleteLogo() {
    if (this.logoToDelete.uuid.length > 0) {
      try {
        await this.$store.dispatch(DELETE_LOGO_ACTION, this.logoToDelete.uuid);
        this.closeModal();
      } catch (err) {
        throw err.response.data;
      }
    }
  }
}
