
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";

@Options({
  components: {
    ThothButton
  }
})
export default class BetaBanner extends Vue {
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  ButtonColour = ButtonColour;

  goToWaitlist() {
    this.$router.push("/waitlist");
  }
}
