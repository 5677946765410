
import { Vue } from "vue-class-component";

export default class BrandKitNavBar extends Vue {
  // Imported commons

  // States
  brandKitComponents = [
    { name: "Logos", link: "#logos__header__title" },
    { name: "Colour Palettes", link: "#colour-palettes__header__title" },
    { name: "Brand Links", link: "#brand-links__header__title" }
  ];

  // Methods
}
