
import { Vue, Options } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { ADD_COLOUR_ACTION } from "@/store/constants";

@Options({
  props: {
    paletteName: {
      type: String,
      required: true
    },
    existingColours: {
      type: Array as PropType<string[]>,
      required: true
    }
  }
})
export default class AddColour extends Vue {
  // Props
  paletteName!: string;
  existingColours!: string[];

  async addColour() {
    const payload = {
      paletteName: this.paletteName,
      colour: this.generateRandomColourNotInExisting()
    };
    try {
      await this.$store.dispatch(ADD_COLOUR_ACTION, payload);
    } catch (err) {
      throw err.response.data;
    }
  }

  generateRandomHexColour() {
    const letters = "0123456789ABCDEF";
    let colour = "#";
    for (let i = 0; i < 6; i++) {
      colour += letters[Math.floor(Math.random() * 16)];
    }
    return colour;
  }

  generateRandomColourNotInExisting() {
    let colour = this.generateRandomHexColour();
    while (this.existingColours.includes(colour)) {
      colour = this.generateRandomHexColour();
    }
    return colour;
  }
}
