
import { Options, Vue } from "vue-class-component";
import { FORGOT_PASSWORD_API, RESET_PASSWORD_API } from "@/services";
import { Form } from "vee-validate";
import api from "@/services/api";
import UIkit from "uikit";
import PasswordField from "@/components/common/PasswordField.vue";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";

type Mode = "SendResetLink" | "ResetPassword";

@Options({
  components: {
    ThothButton,
    PasswordField,
    Form,
    Modal
  }
})
export default class PasswordReset extends Vue {
  username = "";
  password = "";
  dialogBox = {
    title: "",
    message: ""
  };
  passwordReenter = "";
  mode: Mode = "SendResetLink";
  resetToken?: string;
  icons: { [id: string]: string } = {
    logo: STATIC_ASSET_ICONS_BASE_URL + "/thoth-black-circle-icon.svg",
    warningCircle: STATIC_ASSET_ICONS_BASE_URL + "/warning-circle.svg"
  };
  emailError = "";
  resetFail = false;
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  get disableButton() {
    if (this.emailError) {
      return true;
    }
    return !this.username.trim();
  }

  resetModalText = `We've sent you instructions to reset your password, if an account exists with the email you entered. <br /><br />If you don&rsquo;t receive the email in the next 10 minutes, check your spam folder. We sent it from <span style="display:inline-block"><strong>no-reply@thoth.art</strong></span>.<br /><br />For support, contact us at <a href="mailto:hello@thoth.art" class="link--thoth">hello@thoth.art</a>.`;

  async mounted() {
    this.resetToken = this.$route.query.token as string;
    this.mode = this.resetToken ? "ResetPassword" : "SendResetLink";

    if (Object.keys(this.$route.query).length === 0) {
      return;
    }
    if (!this.resetToken) {
      // Illegal state
      console.log("No reset token found");
      return;
    }
    const data = {
      token: this.resetToken,
      password: "",
      linkOnly: true
    };
    api
      .post(RESET_PASSWORD_API, data)
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          this.resetFail = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.dialogBox = {
          title: "Password reset failed",
          message:
            "Your request to reset your password has expired, or the link has already been used. Please reissue the request again."
        };
        this.resetFail = true;
        this.openPasswordModal();
      });
  }
  sendResetLink() {
    if (
      !this.username
        .trim()
        .match(
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
        )
    ) {
      this.emailError = "Please enter a valid email address.";
      return;
    }

    const data = {
      email: this.username
    };

    api
      .post(FORGOT_PASSWORD_API, data)
      .then(response => {
        console.log(response);
        if (response.status == 202) {
          this.openResetModal();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  resetPassword(form) {
    if (!this.resetToken) {
      // Illegal state
      console.log("No reset token found");
      return;
    }
    const data = {
      token: this.resetToken,
      password: form.Password,
      linkOnly: false
    };
    api
      .post(RESET_PASSWORD_API, data)
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          this.dialogBox = {
            title: "Password updated!",
            message: "Your password has been successfully updated."
          };
          this.openPasswordModal();
        }
      })
      .catch(error => {
        console.log(error);
        this.dialogBox = {
          title: "Password reset failed",
          message:
            "Your request to reset your password has expired, or the link has already been used. Please reissue the request again."
        };
        this.openPasswordModal();
      });
  }
  emailErrorClear() {
    if (this.emailError) {
      this.emailError = "";
    }
  }

  openResetModal() {
    UIkit.modal("#modal--reset").show();
  }

  openPasswordModal() {
    UIkit.modal("#modal--password").show();
  }

  closePasswordModal() {
    UIkit.modal("#modal--password").hide();
  }

  returnToLogin() {
    window.location.href = "/login";
  }

  returnToReset() {
    window.location.href = "/password-reset";
  }

  closePasswordModalAndResetState() {
    this.closePasswordModal();
    setTimeout(() => {
      this.returnToLogin();
    }, 500);
  }
}
