<template>
	<NavBar />
	<div :class="$style.content">
		<div :class="$style.body">
			<div :class="$style.bodyInner">
				<div :class="$style.titleWrapper">
					<div :class="$style.title">Liked infographics</div>
				</div>
			</div>

			<!-- Render when functionalityCookiesEnabled is true -->
			<div v-if="functionalityCookiesEnabled" :class="$style.bodyChild">
				<div v-if="likedContents.length === 0" :class="$style.image4Parent">
					<img :class="$style.image4Icon" alt="" src="../images/no_liked_infographics.png" />
					<div :class="$style.noLikedInfographicsParent">
						<div :class="$style.noLikedInfographics">No liked infographics :-(</div>
						<div :class="$style.clickOnThe">Click on the ‘Like’ button on each visual summary to save it to
							this list</div>
					</div>
					<div :class="$style.button" @click="goToAgenda">
						<div :class="$style.manageCookies">Browse all visual summaries</div>
					</div>
				</div>

				<div v-else :class="$style.iconsessions">
					<div v-for="(session, index) in likedContents" :key="index" :class="$style.dashboardgrid"
						@click="onSessionClick(session)">
						<div v-if="imageLoadStatus[session.title] === true">
							<img :class="$style.infographicIcon" alt="Session Image"
								:src="Array.isArray(session.imageUrl) ? session.imageUrl[0] : session.imageUrl" />
						</div>
						<div v-else>
							<img :class="$style.infographicIcon"
								:src="`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${$route.params.eventName}/images/Holding+Slide.png`"
								loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image" />
						</div>
						<div :class="$style.griddetails">
							<div :class="$style.frameParent">
								<div :class="$style.timeWrapper">
									<b :class="$style.icontime">{{ formatDate(session.date) }} {{ session.time }} {{
										eventConfig.timezone }} • {{ session.duration }} mins</b>
								</div>
								<div :class="$style.keynoteTheValueTransfer2Parent">
									<div :class="$style.keynoteTheValue">{{ session.name }}</div>
									<div :class="$style.theFinancialWorld">{{ session.description }}</div>
								</div>
							</div>
							<div :class="$style.rowtagParent">
								<div :class="$style.rowtag">
									<div :class="$style.tag1">{{ session.location }}</div>
								</div>
							</div>
							<div :class="$style.iconSpeakers">
								<div v-for="(speaker, index) in session.speakers" :key="index"
									:class="$style.iconSpeakerNames">
									<div v-if="speaker.imageurl" :class="$style.iconSpeakerNamesInner">
										<img :class="$style.frameChild" alt="" :src="speaker.imageurl" />
									</div>
									<div :class="$style.speakerNamesChild">
										<div :class="$style.iconMunthirAAlsheddieParent">
											<div :class="$style.iconMunthirAAlsheddie">{{ speaker.name }}</div>
											<div :class="$style.iconCountryGmTarabut">{{ speaker.designation }}, {{
												speaker.company }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Render when functionalityCookiesEnabled is false -->
			<div v-else :class="[$style.bodyChild, $style.image4Parent]">
				<img :class="$style.image4Icon" alt="" src="../images/no_liked_infographics.png" />
				<div :class="$style.noLikedInfographicsParent">
					<div :class="$style.noLikedInfographics">Functionality Cookies disabled</div>
					<div :class="$style.clickOnThe">Click on the ‘Manage Cookies’ button to enable it</div>
				</div>
				<div :class="$style.button" @click="openCookieSettings">
					<div :class="$style.manageCookies">Manage Cookies</div>
				</div>
			</div>
		</div>
	</div>
	<Footer />
</template>


<script>
import NavBar from "./NavBar.vue";
import Footer from "./Footer.vue";
import Cookies from 'js-cookie';
import axios from "axios";
import EventBus from './eventBus';

export default {
	name: "LikeInfographics",
	inject: ['eventConfig'],
	components: {
		NavBar,
		Footer,
	},
	data() {
		return {
			data: null,
			imageLoadStatus: {},
			likedContents: [],
			functionalityCookiesEnabled: Cookies.get('functionalityCookies') === 'true'  // Ensure it's a boolean
		}
	},
	methods: {
		goToAgenda() {
			this.$router.push(`/${this.$route.params.eventName}/agenda`);
		},
		openCookieSettings() {
			EventBus.emit('openCookieSettings');
		},
		async fetchData() {
			try {
				const timestamp = new Date().getTime();
				const response = await axios.get(
					`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.$route.params.eventName}/talk_descriptions.json?v=${timestamp}`
				);
				this.data = response.data;
				this.loadLikedData();
				this.checkImageStatus();
			} catch (error) {
				console.error("Error fetching image data:", error);
			}
		},
		onSessionClick(session) {
			this.$router.push(session.talkEndpoint);
		},
		formatDate(date) {
			const options = { month: "short", day: "2-digit" };
			return new Date(date).toLocaleDateString("en-US", options);
		},
		preloadImage(url) {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.src = url;
				img.onload = () => resolve(true);
				img.onerror = () => reject(false);
			});
		},
		async checkImageStatus() {
			const statusPromises = this.likedContents.map(async (session) => {
				try {
					if (!Array.isArray(session.imageUrl)) {
						await this.preloadImage(session.imageUrl);
						this.imageLoadStatus[session.title] = true;
					} else {
						// Preload each image in the array
						const preloadPromises = session.imageUrl.map(url => this.preloadImage(url));
						await Promise.all(preloadPromises);
						this.imageLoadStatus[session.title] = true;
						this.isImageLoaded = true;
					}
				} catch (error) {
					this.imageLoadStatus[session.name] = false;
				}
			});
			await Promise.all(statusPromises);
		},
		loadLikedData() {
			if (!this.data) return;
			const likedSessions = Cookies.get('liked_sessions');
			const likedContents = [];
			const parsedLikedSessions = likedSessions ? JSON.parse(likedSessions) : [];

			this.data.map(item => {
				if (parsedLikedSessions.includes(item.talkEndpoint)) {
					likedContents.push(item);
				}
			});
			this.likedContents = likedContents;
		},
		updateFunctionalityCookie() {
			this.functionalityCookiesEnabled = Cookies.get('functionalityCookies') === 'true';
		},
	},
	async mounted() {
		await this.fetchData();
		EventBus.on('cookiesUpdated', this.updateFunctionalityCookie);
	},
}
</script>

<style module>
.title {
	width: 761px;
	position: relative;
	line-height: 130%;
	display: inline-block;
}

.titleWrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.bodyInner {
	align-self: stretch;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
}

.image4Icon {
	width: 224px;
	position: relative;
	height: 150px;
	object-fit: cover;
}

.noLikedInfographics {
	align-self: stretch;
	position: relative;
	line-height: 130%;
	font-weight: 600;
}

.clickOnThe {
	align-self: stretch;
	position: relative;
	font-size: 14px;
	line-height: 130%;
}

.noLikedInfographicsParent {
	max-width: 450;
	width: 80vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4px;
}

.button {
	border-radius: 12px;
	background-color: #8c7340;
	height: 36px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 6px 16px;
	box-sizing: border-box;
	text-align: left;
	color: #fff;
	cursor: pointer;
}

.image4Parent {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;
}

.bodyChild {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 40px 0px;
	text-align: center;
	font-size: 16px;
	color: #000;
	font-family: 'DM Sans';
}

.body {
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 64px 80px;
	box-sizing: border-box;
	gap: 24px;
}

.logoIcon {
	width: 203.4px;
	position: relative;
	height: 40px;
	overflow: hidden;
	flex-shrink: 0;
}

.icon {
	width: 16px;
	position: relative;
	height: 16px;
	overflow: hidden;
	flex-shrink: 0;
}

.button2 {
	border-radius: 12px;
	height: 36px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 6px 0px;
	box-sizing: border-box;
	gap: 8px;
}

.buttonParent {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;
}

.leftHandPanel {
	flex: 1;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 40px;
}

.manageCookies {
	position: relative;
	line-height: 130%;
}

.manageCookiesParent {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;
	font-size: 14px;
	color: #d9d9d9;
}

.leftHandPanelParent {
	align-self: stretch;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
}

.thoth2 {
	text-decoration: underline;
}

.thoth {
	color: inherit;
}

.span {
	font-weight: 600;
}

.copyright2024Thoth {
	align-self: stretch;
	position: relative;
	line-height: 130%;
}

.allImagesAnd {
	align-self: stretch;
	position: relative;
	font-size: 12px;
	line-height: 130%;
}

.copyright2024ThothParent {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;
	font-size: 14px;
	color: #d9d9d9;
}

.content {
	min-height: 100vh;
	top: 80px;
	height: 100%;
	position: relative;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: left;
	font-size: 48px;
	color: #111;
	font-family: 'Instrument Serif';
}

.dashboardgrid {
	flex-basis: calc(33.33% - 16px);
	/* 3 columns */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 10px;
	cursor: pointer;
}

.griddetails {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 16px;
}

.icontime {
	flex: 1;
	width: 100%;
	position: relative;
	font-size: 14px;
	line-height: 130%;
	display: inline-block;
	font-family: 'DM Sans';
	color: #B89A6A;
	text-align: left;
}

.keynoteTheValueTransfer2Parent {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;
	font-size: 24px;
	font-family: 'Instrument Serif';
}

.keynoteTheValue {
	align-self: stretch;
	position: relative;
	line-height: 130%;
}

.theFinancialWorld {
	align-self: stretch;
	position: relative;
	font-size: 14px;
	line-height: 130%;
	/* font-family: 'DM Sans'; */
	display: -webkit-inline-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-align: left;
}

.rowtag {
	border-radius: 8px;
	background-color: #f5f5f5;
	border: 1px solid #727272;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 2px 16px;
}

.rowtagParent {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;
	font-size: 12px;
	color: #727272;
}

.tag1 {
	position: relative;
	line-height: 130%;
}

.iconSpeakers {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-content: flex-start;
	gap: 12px;
	text-align: left;
	font-size: 14px;
	color: #B89A6A;
	font-family: 'DM Sans';
}

.iconSpeakerNamesInner {
	width: 56px;
	position: relative;
	border-radius: 15.27px;
	border: 1.3px solid #B89A6A;
	box-sizing: border-box;
	height: 56px;
	overflow: hidden;
	flex-shrink: 0;
}

.iconSpeakerNamesInner {
	width: 56px;
	position: relative;
	border-radius: 15.27px;
	border: 1.3px solid #B89A6A;
	box-sizing: border-box;
	height: 56px;
	overflow: hidden;
	flex-shrink: 0;
}

.frameChild {
	position: absolute;
	top: 5.09px;
	left: 5.09px;
	border-radius: 12.73px;
	width: 45.8px;
	height: 45.8px;
	overflow: hidden;
	object-fit: cover;
}

.iconMunthirAAlsheddieParent {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.speakerNamesChild {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.iconMunthirAAlsheddie {
	align-self: stretch;
	position: relative;
	line-height: 130%;
	font-weight: 600;
}

.iconCountryGmTarabut {
	align-self: stretch;
	position: relative;
	font-size: 12px;
	line-height: 130%;
	color: #494949;
}

.iconsessions {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-content: flex-start;
	padding: 24px 0px;
	box-sizing: border-box;
	gap: 40px 24px;
	text-align: left;
	font-size: 14px;
	color: #2c2c2c;

}

/* For medium screens (e.g., tablets), switch to 2 columns */
@media (max-width: 1024px) {
	.dashboardgrid {
		flex-basis: calc(50% - 16px);
	}

	.body {
		padding: 64px 32px;
	}

}

/* For small screens (e.g., mobile), switch to 1 column */
@media (max-width: 768px) {
	.dashboardgrid {
		flex-basis: 100%;
		/* 1 column */
	}

	.body {
		padding: 64px 16px;
	}

	.title {
		width: 100vw;
		font-size: 50px;
	}
}
</style>
