
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import {
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL
} from "@/commons";
import { INVITE_API } from "@/services";
import { defineRule, Field, ErrorMessage, Form } from "vee-validate";
import api from "@/services/api";
defineRule("email", email => {
  if (!email || !email.length) {
    return true;
  }
  if (!/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/.test(email)) {
    return "Please enter a valid email";
  }
  return true;
});

@Options({
  props: {
    modalId: {
      type: String as PropType<string>,
      required: true
    },
    referLink: {
      type: String as PropType<string>,
      required: true
    },
    preventBackgroundClick: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    copyText() {
      return this.textCopied ? "COPIED" : "COPY";
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    ThothButton
  }
})
export default class ReferModal extends Vue {
  referLink!: string;
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  images: { [id: string]: string } = {
    alpha: STATIC_ASSET_IMAGES_BASE_URL + "/Illo__AlphaProgram.png"
  };
  icons: { [id: string]: string } = {
    error: STATIC_ASSET_ICONS_BASE_URL + "/form-error.svg",
    check: STATIC_ASSET_ICONS_BASE_URL + "/check-icon.svg"
  };
  //data
  emailInput = "";
  backendError = "";
  textCopied = false;
  inviteSent = false;

  fallbackCopyTextToClipboard(text: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
  async copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text);
  }
  inviteFriend() {
    const data = {
      email: this.emailInput
    };
    api
      .post(INVITE_API, data)
      .then(() => {
        this.inviteSent = true;
        setTimeout(() => {
          this.inviteSent = false;
        }, 5000);
      })
      .catch(error => {
        const data = error.response?.data || {};
        switch (data.message) {
          case "USER_ALREADY_EXISTS":
            this.backendError =
              "There is already an account associated with this email.";
            break;
          case "EMAIL_INVALID":
            this.backendError = "Please enter a valid email address";
            break;
        }
      });
  }
  copyLink() {
    console.log("invite");
    this.copyTextToClipboard(this.referLink).then(
      () => {
        console.log("Copying to clipboard was successful!");
        this.textCopied = true;
        setTimeout(() => {
          this.textCopied = false;
        }, 3000);
      },
      err => {
        console.error("Could not copy text: ", err);
      }
    );
  }
}
