
import { Options, Vue } from "vue-class-component";
import TextToggleSwitch from "@/components/common/TextToggleSwitch.vue";
import Tooltip from "@/components/common/Tooltip.vue";
import { PropType } from "@vue/runtime-core";
import { TOGGLE_DARK_MODE } from "@/store/constants";
import {
  ActiveColour,
  ActiveBrandLink,
  BrandKitMode,
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL
} from "@/commons";

@Options({
  computed: {
    haveLogo() {
      return this.activeLogo;
    }
  },
  props: {
    accentColour: {
      type: Object as PropType<ActiveColour>,
      required: true
    },
    selectBrandKitMode: {
      type: Function as PropType<() => void>,
      required: true
    },
    activeLogo: {
      type: String as PropType<string>,
      required: false,
      default: null
    },
    activeBrandLink: {
      type: Object as PropType<ActiveBrandLink>,
      required: true
    }
  },
  components: {
    TextToggleSwitch,
    Tooltip
  }
})
export default class EditBrandKitMain extends Vue {
  BrandKitMode = BrandKitMode;
  activeBrandLink!: ActiveBrandLink;
  flipStatus = "light";
  //TODO init flipStatus by replacing with either GET or project info
  icons: { [id: string]: string } = {
    chevronRight:
      STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-chevron-right.svg",
    info: STATIC_ASSET_ICONS_BASE_URL + "/info.svg",
    facebook: STATIC_ASSET_ICONS_BASE_URL + "/facebook.svg",
    linkedin: STATIC_ASSET_ICONS_BASE_URL + "/linkedin.svg",
    website: STATIC_ASSET_ICONS_BASE_URL + "/website.svg",
    twitter: STATIC_ASSET_ICONS_BASE_URL + "/twitter.svg",
    instagram: STATIC_ASSET_ICONS_BASE_URL + "/instagram.svg"
  };
  maxLinkChars = 20;

  appearanceData = [
    {
      mode: "Light mode example",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Light-Example.png"
    },
    {
      mode: "Dark mode example",
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Dark-Example.png"
    }
  ];

  truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  get truncatedBrandLink() {
    return this.truncateString(this.activeBrandLink.text, this.maxLinkChars);
  }

  async flipStatusHandler(str) {
    const { projectId } = this.$route.params;
    const data = {
      projectId,
      mode: str
    };
    await this.$store.dispatch(TOGGLE_DARK_MODE, data);
    this.flipStatus = str;
  }
}
