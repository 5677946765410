
import { Options, Vue } from "vue-class-component";
import { Chrome } from "@ckpack/vue-color";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";

@Options({
  props: {
    hex: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  components: { Chrome },
  emits: {
    toggleActive: {},
    setInactive: {},
    updateColour: {},
    deleteColour: {}
  }
})
export default class Colour extends Vue {
  // Props
  hex!: string;

  // Locals
  chosenColour = {
    hex: this.hex
  };
  icons: { [id: string]: string } = {
    deleteColour:
      STATIC_ASSET_ICONS_BASE_URL + "/brandkit-colour-palette-delete-colour.png"
  };

  // Methods
  beforeChromeHide() {
    this.$emit("updateColour", {
      colour: this.hex,
      newColour: this.chosenColour.hex
    });
    this.$emit("setInactive");
  }

  toggleActive() {
    this.$emit("toggleActive", this.hex);
  }

  async deleteColour() {
    this.$emit("deleteColour", this.hex);
  }
}
