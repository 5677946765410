
import { Vue, Options } from "vue-class-component";
import AccountSettings from "@/components/product/AccountSettings.vue";
import DashboardNavBar from "@/components/navbar/DashboardNavBar.vue";
import { USER_GETTER, LOAD_STORE_ACTION } from "@/store/constants.js";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import MobileBlocker from "@/components/common/MobileBlocker.vue";
@Options({
  components: {
    DashboardNavBar,
    AccountSettings,
    MobileBlocker
  }
})
export default class Settings extends Vue {
  // States
  isLoggedIn = false;
  loaded = false;
  get user() {
    return this.$store.getters[USER_GETTER];
  }

  // Mounted
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (!this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
