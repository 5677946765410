
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import NavBar from "@/components/NavBar.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";

@Options({
  components: { NavBar, ThothButton }
})
export default class ResourceNotFound extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  isLoggedIn = false;
  loaded = false;

  errorImage = STATIC_ASSET_IMAGES_BASE_URL + "/404.svg";
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
