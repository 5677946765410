import { createStore } from "vuex";
import dashboard from "./dashboard";
import project from "./project";

export const store = createStore({
  actions: {
    async resetStore({ commit }) {
      commit("dashboard/resetState");
      commit("project/resetState");
    }
  },
  modules: {
    dashboard,
    project
  }
});
