
import { Options, Vue } from "vue-class-component";
import BrandKitContent from "@/components/product/brandkit/BrandKitContent.vue";
import BrandKitNavBar from "@/components/product/brandkit/BrandKitNavBar.vue";
import BrandKitModal from "@/components/product/brandkit/BrandKitModal.vue";
import { LOADED_GETTER, USER_GETTER } from "@/store/constants";

@Options({
  components: {
    BrandKitContent,
    BrandKitNavBar,
    BrandKitModal
  }
})
export default class EditBrandKit extends Vue {
  // Imported commons

  // States
  get user() {
    return this.$store.getters[USER_GETTER];
  }
  get loaded() {
    return this.$store.getters[LOADED_GETTER];
  }
  get visited() {
    const logosExist = this.user.logos.length > 0;
    const colourPalettesExist = this.user.colourPalettes.length > 0;
    const brandLinks: string[] = Object.values(this.user.brandLinks);
    const brandLinksExist = brandLinks.some(l => l !== null);
    return logosExist || colourPalettesExist || brandLinksExist ? true : false;
  }

  // Methods
}
