import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileBlocker = _resolveComponent("MobileBlocker")
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_PublishInfographic = _resolveComponent("PublishInfographic")
  const _component_HelpFloatingButton = _resolveComponent("HelpFloatingButton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_MobileBlocker),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_NavBar, {
          key: 0,
          class: "container__mobile__blocker",
          "logged-in": _ctx.isLoggedIn,
          stage: _ctx.ProjectStage.Publish,
          "project-title": _ctx.infographics[0].title
        }, null, 8, ["logged-in", "stage", "project-title"]))
      : _createCommentVNode("", true),
    (_ctx.projectLoaded && _ctx.infographics.length > 0)
      ? (_openBlock(), _createBlock(_component_PublishInfographic, {
          key: 1,
          class: "container__mobile__blocker",
          loaded: _ctx.projectLoaded,
          publishedInfographic: _ctx.infographics[0]
        }, null, 8, ["loaded", "publishedInfographic"]))
      : _createCommentVNode("", true),
    (_ctx.dashboardLoaded)
      ? (_openBlock(), _createBlock(_component_HelpFloatingButton, {
          key: 2,
          class: "container__mobile__blocker",
          "user-email": _ctx.user.email
        }, null, 8, ["user-email"]))
      : _createCommentVNode("", true)
  ], 64))
}