
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import introAnim from "@/assets/animate/unicycle.json";
@Options({
  components: { lottie }
})
export default class TransIntro extends Vue {
  count = 0;
  playing = true;
  defaultOptions = {
    animationData: introAnim,
    loop: true
  };
  mounted() {
    setTimeout(() => {
      this.$emit("finished");
    }, 4000);
  }
}
