<template>
  <div class="upload_background">
    <div v-if="checkInnerStep(-2)" class="upload_article .uk-overflow-auto">
      <img :src="TextImage" alt="sampleArticle" />
      <div class="continue_button">
        <AlternateButton
          class="uk-width-5-6"
          text="Continue"
          fontSize="16px"
          lineHeight="24px"
          :style="0"
          v-if="checkInnerStep(-2)"
          :actionOnClick="nextStep"
        />
      </div>
    </div>
    <DemoModal
      v-for="(innerStep, index) in innerSteps"
      :key="index"
      :delayed="innerStep.delayed"
      :modal="checkInnerStep(innerStep.stepNum)"
      :title="innerStep.title"
      :subtexts="innerStep.subtexts"
      :moveonEnd="innerStep.moveonEnd"
      @modal-closed="close"
    />
    <lottie
      v-if="checkInnerStep(-1)"
      :options="defaultOptions"
      @animCreated="handleAnimation"
    />
  </div>
</template>
<script>
import { Options, Vue } from "vue-class-component";
import DemoModal from "@/components/common/DemoModal.vue";
import AlternateButton from "@/components/common/AlternateButton.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import highlightAnim from "@/assets/animate/text_highlight.json";
import lottie from "vue-lottie";

@Options({
  components: { AlternateButton, DemoModal, lottie }
})
export default class Upload extends Vue {
  //data
  TextImage = STATIC_ASSET_IMAGES_BASE_URL + "/demo/SampleText.svg";
  blockEnd = false;
  innerStep = 0;
  innerSteps = [
    {
      stepNum: 0,
      moveonEnd: false,
      delayed: true,
      title: "Step 1/4: Upload",
      subtexts: [
        "Hi there! We’ve prepared a short article for you. Fancy giving it a read?"
      ]
    },
    {
      stepNum: 1,
      moveonEnd: true,
      delayed: false,
      subtexts: [
        "Let’s start by selecting key points for your infographic.",
        "Here’s a surprise -- our A.I. will automate this for you! Sit back and enjoy."
      ]
    }
  ];
  defaultOptions = {
    animationData: highlightAnim,
    loop: false,
    autoplay: false
  };
  //functions
  finish() {
    this.$emit("finished");
  }
  nextStep() {
    this.innerStep++;
  }
  checkInnerStep(number) {
    switch (number) {
      case -1:
        return this.innerSteps.length == this.innerStep;
      case -2:
        return this.innerStep < this.innerSteps.length;
      default:
        return number == this.innerStep;
    }
  }
  close() {
    if (this.innerStep == this.innerSteps.length - 1) {
      setTimeout(() => {
        this.anim.play();
      }, 1000);
    }
    this.innerStep++;
  }
  animationCompleted() {
    this.finish();
  }
  handleAnimation(anim) {
    this.anim = anim;
    this.anim.setSpeed(0.6);
    this.anim.addEventListener("complete", () => this.animationCompleted());
  }
}
</script>
<style scoped>
.evade {
  position: absolute;
}
.continue_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.upload_background {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload_article {
  width: 90%;
  height: 85%;
  overflow: auto;
  margin-bottom: 15px;
}
.upload_article--title {
  font: normal 400 48px/48px Brick;
  margin-bottom: 10px;
}
.upload_article--source {
  font: normal 400 14px/14px "Baton Turbo";
  margin-bottom: 10px;
}
.upload_article--header {
  font: normal 400 24px/24px "Baton Turbo";
  margin-bottom: 10px;
}
.upload_article img {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .upload_article--title {
    font: normal 400 32px/32px Brick;
    margin-bottom: 10px;
  }
}
</style>
