<template>
  <div :class="$style.body">
    <div :class="$style.gridContainer">
      <!-- Left column -->
      <div v-for="(session, index) in leftColumnSessions" :key="index" :class="$style.gridItem">
        <div :class="$style.dashboardgrid" @click="onSessionClick(session)">
          <div v-if="imageLoadStatus[session.title] === true">
            <img :class="$style.infographicIcon" alt="Session Image"
              :src="Array.isArray(session.imageUrl) ? session.imageUrl[0] : session.imageUrl" />
          </div>
          <div v-else>
            <img :class="$style.infographicIcon"
              :src="`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventName}/images/Holding+Slide.png`"
              loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image" />
          </div>
          <div :class="$style.details">
            <div :class="$style.timeParent">
              <b :class="$style.time">{{ formatDate(session.date) }} {{ session.time }} {{ eventConfig.timezone }}• {{
                session.duration }}
                mins</b>
              <div :class="$style.sessionTitle">{{ session.title }}</div>
              <div :class="$style.sessionDescription">{{ session.description }}</div>
            </div>
            <div :class="$style.detailsChild"></div>
            <div :class="$style.speakers">
              <div :class="$style.speakerNames">
                <div v-for="(speaker, index) in session.speakers" :key="index">
                  <strong>{{ speaker.name }}</strong> | {{ speaker.designation }}{{ speaker.company ? ', ' +
                    speaker.company : '' }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right column -->
      <div v-for="(session, index) in rightColumnSessions" :key="index" :class="$style.gridItem">
        <div :class="$style.dashboardgrid" @click="onSessionClick(session)">
          <div v-if="imageLoadStatus[session.title] === true">
            <img :class="$style.infographicIcon" alt="Session Image"
              :src="Array.isArray(session.imageUrl) ? session.imageUrl[0] : session.imageUrl" />
          </div>
          <div v-else>
            <img :class="$style.infographicIcon"
              :src="`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventName}/images/Holding+Slide.png`"
              loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image" />
          </div>
          <div :class="$style.details">
            <div :class="$style.timeParent">
              <b :class="$style.time">{{ formatDate(session.date) }} {{ session.time }} • {{ session.duration }}
                mins</b>
              <div :class="$style.sessionTitle">{{ session.title }}</div>
              <div :class="$style.sessionDescription">{{ session.description }}</div>
            </div>
            <div :class="$style.detailsChild"></div>
            <div :class="$style.speakers">
              <div :class="$style.speakerNames">
                <div v-for="(speaker, index) in session.speakers" :key="index">
                  <strong>{{ speaker.name }}</strong> | {{ speaker.designation }}{{ speaker.company ? ', ' +
                    speaker.company : '' }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "SessionHighlights",
  inject: ["eventConfig"],
  props: {
    eventName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      lightboxData: {
        items: [],
        group: ""
      },
      data: null, // Initialize data as null
      imageLoadStatus: {}
    };
  },
  computed: {
    // Split the sessions into two columns
    leftColumnSessions() {
      return this.lightboxData.items.filter((_, index) => index % 2 === 0);
    },
    rightColumnSessions() {
      return this.lightboxData.items.filter((_, index) => index % 2 === 1);
    }
  },
  methods: {
    async fetchData() {
      try {
        const timestamp = new Date().getTime(); // Get the current time in milliseconds
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
        this.loadData();
        this.checkImageStatus();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    loadData() {
      if (!this.data) return;
      this.lightboxData.items = this.data.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date
      this.lightboxData.items = this.lightboxData.items.slice(0, 10); // Select the first 10 items
    },
    formatDate(date) {
      const options = { day: "2-digit", month: "short" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    onSessionClick(session) {
      this.$router.push(session.talkEndpoint);
    },
    preloadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => reject(false);
      });
    },
    async checkImageStatus() {
      const statusPromises = this.lightboxData.items.map(async (session) => {
        try {
          if (!Array.isArray(session.imageUrl)) {
            await this.preloadImage(session.imageUrl);
            this.imageLoadStatus[session.title] = true;
          } else {
            // Preload each image in the array
            const preloadPromises = session.imageUrl.map(url => this.preloadImage(url));
            await Promise.all(preloadPromises);
            this.imageLoadStatus[session.title] = true;
            this.isImageLoaded = true;
          }
        } catch (error) {
          this.imageLoadStatus[session.title] = false;
        }
      });
      await Promise.all(statusPromises);
    }
  },
  async mounted() {
    await this.fetchData();
  }
};
</script>
<style module>
.body {
  padding: 3rem;
  padding-bottom: 1rem;
}

/* Container to handle the two-column layout */
.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two columns of equal width */
  gap: 3rem;
  width: 100%;
  position: relative;
}

/* Offset the right column slightly lower than the left */
.gridItem:nth-child(2n) {
  margin-top: 40px;
  /* Push the second (right) column down */
}

/* Each grid item (session card) */
.dashboardgrid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  width: 100%;
}

.infographicIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Details section */
.details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.timeParent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.time {
  font-weight: bold;
  color: #B89A6A;
}

.sessionTitle {
  font-size: 1.5rem;
  align-self: stretch;
  position: relative;
  line-height: 130%;
  font-family: 'Instrument Serif';
  width: 100%;
}

.sessionDescription {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 130%;
}

.detailsChild {
  height: 1px;
  background-color: #d9d9d9;
}

.speakers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.speakerNames {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Media Queries for Mobile Friendliness */
@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr;
    /* Switch to a single column on mobile */
  }

  .gridItem:nth-child(2n) {
    margin-top: 0;
    /* Remove the offset for the right column on mobile */
  }

  .sessionTitle {
    font-size: 1.2rem;
    /* Adjust text size for mobile */
  }

  .time {
    font-size: 1rem;
    /* Adjust time text size for mobile */
  }
}

@media (max-width: 480px) {


  .sessionTitle {
    font-size: 1rem;
    /* Further reduce text size */
  }

  .time {
    font-size: 0.9rem;
    /* Further reduce time text size */
  }

  .body {
    padding: 1rem;
    padding-bottom: 3rem;
    /* Reduce padding on small mobile screens */
  }
}
</style>
