
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import ImageSlideshow from "@/components/common/ImageSlideshow.vue";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy,
    ImageSlideshow
  }
})
export default class AIEngineerWorldFairCaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-background.png",
    case0: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-0.png",
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-1.png",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-2.png",
    case3: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-3.png",
    case4: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-4.png",
    case5: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-5.png",
    case6: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-6.jpeg",
    case7: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-7.png",
    case8: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/AIEngineer-Example-8.png"
  };
  imageSlideCard1: string[] = [
    this.images.case2,
    this.images.case3,
    this.images.case4,
    this.images.case5
  ]
  links: { [id: string]: string } = {
    latentSpace: "https://www.latent.space/",
    aiEngineerWeb: "https://www.ai.engineer/worldsfair",
    waitlist: "https://thoth.art/waitlist",
    dropbox: "https://www.dropbox.com/scl/fo/jisfrqdm0i5aw59nigpmg/AKoUTvlGoixRLLtgtTP756E?rlkey=pq5r3zrrqllwqk75jj3zarty2&st=5gropmqh&dl=0",
    email: "mailto:hello@thoth.art",
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
  };
}
