
import { Project, ProjectCount, ProjectStage, ProjectType } from "@/commons";
import HelpFloatingButton from "@/components/HelpFloatingButton.vue";
import MobileBlocker from "@/components/common/MobileBlocker.vue";
import DashboardNavBar from "@/components/navbar/DashboardNavBar.vue";
import DashboardContent from "@/components/product/dashboard/DashboardContent.vue";
import DashboardNavigationDrawer from "@/components/product/dashboard/DashboardNavigationDrawer.vue";
import { authenticate, PROJECT_API, LOCAL_ACCESS_KEY } from "@/services";
import {
  LOADED_GETTER,
  LOAD_STORE_ACTION,
  USER_GETTER
} from "@/store/constants.js";
import api from "@/services/api";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MobileBlocker,
    DashboardNavBar,
    DashboardContent,
    DashboardNavigationDrawer,
    HelpFloatingButton
  }
})
export default class Dashboard extends Vue {
  // Imported commons
  ProjectStage = ProjectStage;
  ProjectType = ProjectType;

  // States
  isLoggedIn = false;
  activeFolder = ProjectType.Draft;
  trashedProjects: Project[] = []; // New variable for storing trashed projects
  selectedProjectsType: Project[] = [];
  currentPage = 1; // Set the initial value of currentPage to 1
  projectCount = {} as ProjectCount;

  async makeActiveFolder(folderType: ProjectType) {
    this.activeFolder = folderType;
    this.currentPage = 1; // Reset currentPage to 1 when switching folders

    if (folderType === ProjectType.Draft) {
      await this.getProjectsByState("READY", "IN_USE");
    } else if (folderType === ProjectType.Downloaded) {
      await this.getProjectsByState("LOCKED", "IN_USE");
    } else if (folderType === ProjectType.Trash) {
      await this.getProjectsByState(null, "TRASHED");
    }
  }

  async getProjectsByState(
    status: string | null,
    state: string
  ): Promise<void> {
    try {
      const response = await api.get(`${PROJECT_API}/dashboard`, {
        params: {
          page: this.currentPage,
          perPage: 10,
          status: status,
          state: state
        }
      });
      this.selectedProjectsType = response.data.projects;
      this.currentPage = parseInt(response.data.currentPage);
      if (state === "TRASHED") {
        this.projectCount.trash = response.data.total;
      }
      if (status === "READY") {
        this.projectCount.draft = response.data.total;
      }
      if (status === "LOCKED") {
        this.projectCount.downloaded = response.data.total;
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  updateCurrentPage(newPage: number) {
    this.currentPage = newPage;
  }

  viewDrafts() {
    this.makeActiveFolder(ProjectType.Draft);
  }

  get loaded() {
    return this.$store.getters[LOADED_GETTER];
  }
  get projects() {
    if (Array.isArray(this.selectedProjectsType)) {
      return this.selectedProjectsType;
    }
    return [];
    // return this.$store.getters[PROJECTS_GETTER];
  }
  get user() {
    return this.$store.getters[USER_GETTER];
  }

  async getProjectsByCurrentState() {
    const folderType = this.activeFolder;
    if (folderType === ProjectType.Draft) {
      await this.getProjectsByState("READY", "IN_USE");
    } else if (folderType === ProjectType.Downloaded) {
      await this.getProjectsByState("LOCKED", "IN_USE");
    } else if (folderType === ProjectType.Trash) {
      await this.getProjectsByState(null, "TRASHED");
    }
  }

  // Mounted
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (!this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }
    // Set activeFolder to "draft" so that drafts load immediately
    this.activeFolder = ProjectType.Draft;
    await this.getProjectsByState("READY", "IN_USE");
  }
}
