
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import Intro from "@/components/public/demo/Intro.vue";
import TransIntro from "@/components/public/demo/TransIntro.vue";
import Upload from "@/components/public/demo/Upload.vue";
import TransSlide from "@/components/public/demo/TransSlide.vue";
import KeyPointSelection from "@/components/public/demo/KeyPointSelection.vue";
import ColorSelection from "@/components/public/demo/ColorSelection.vue";
import LayoutSelection from "@/components/public/demo/LayoutSelection.vue";
import Congrats from "@/components/public/demo/Congrats.vue";
import FinalOutput from "@/components/public/demo/FinalOutput.vue";
@Options({
  components: {
    Intro,
    TransIntro,
    Upload,
    TransSlide,
    KeyPointSelection,
    ColorSelection,
    LayoutSelection,
    Congrats,
    FinalOutput
  }
})
export default class Demo extends Vue {
  //data
  selectedColor = "Peach";
  selectedKeypoints: number[] = [];
  selectedLayout = "A";
  count = 1;

  icons: { [id: string]: string } = {
    thoth: STATIC_ASSET_ICONS_BASE_URL + "/alternate-thoth-logo-white.svg"
  };
  //functions
  addKeyPT(kp: number) {
    this.selectedKeypoints.push(kp);
    this.selectedKeypoints.sort((n1, n2) => n1 - n2);
  }
  selectColor(color: string) {
    this.selectedColor = color;
  }
  selectLayout(layout: string) {
    this.selectedLayout = layout;
  }
  swapComponent() {
    this.count++;
  }
  checkCount(number: number) {
    return number == this.count;
  }
}
