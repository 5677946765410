import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditBrandKitMain = _resolveComponent("EditBrandKitMain")
  const _component_EditColour = _resolveComponent("EditColour")
  const _component_EditLogo = _resolveComponent("EditLogo")
  const _component_EditBrandLink = _resolveComponent("EditBrandLink")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.brandKitMode === _ctx.BrandKitMode.Main)
      ? (_openBlock(), _createBlock(_component_EditBrandKitMain, {
          key: 0,
          selectBrandKitMode: _ctx.selectBrandKitMode,
          accentColour: _ctx.activeColour,
          activeLogo: _ctx.activeLogo,
          activeBrandLink: _ctx.activeBrandLink
        }, null, 8, ["selectBrandKitMode", "accentColour", "activeLogo", "activeBrandLink"]))
      : _createCommentVNode("", true),
    (_ctx.brandKitMode === _ctx.BrandKitMode.Colour)
      ? (_openBlock(), _createBlock(_component_EditColour, {
          key: 1,
          colourPalettes: _ctx.user.colourPalettes,
          activeColour: _ctx.activeColour,
          makeActiveColour: _ctx.makeActiveColour,
          mustSave: _ctx.mustSave,
          showToast: _ctx.showToast,
          saveChanges: _ctx.saveChanges,
          canvasMode: _ctx.canvasMode,
          selectBrandKitMode: _ctx.selectBrandKitMode,
          onCancelBrandKitChanges: _ctx.cancelChangesHandler,
          onSetActiveColour: _ctx.changeActiveColour
        }, null, 8, ["colourPalettes", "activeColour", "makeActiveColour", "mustSave", "showToast", "saveChanges", "canvasMode", "selectBrandKitMode", "onCancelBrandKitChanges", "onSetActiveColour"]))
      : _createCommentVNode("", true),
    (_ctx.brandKitMode === _ctx.BrandKitMode.Logos)
      ? (_openBlock(), _createBlock(_component_EditLogo, {
          key: 2,
          logos: _ctx.user.logos,
          activeLogo: _ctx.activeLogo,
          makeActiveLogo: _ctx.makeActiveLogo,
          mustSave: _ctx.mustSave,
          showToast: _ctx.showToast,
          saveChanges: _ctx.saveChanges,
          canvasMode: _ctx.canvasMode,
          selectBrandKitMode: _ctx.selectBrandKitMode,
          onCancelBrandKitChanges: _ctx.cancelChangesHandler
        }, null, 8, ["logos", "activeLogo", "makeActiveLogo", "mustSave", "showToast", "saveChanges", "canvasMode", "selectBrandKitMode", "onCancelBrandKitChanges"]))
      : _createCommentVNode("", true),
    (_ctx.brandKitMode === _ctx.BrandKitMode.BrandLinks)
      ? (_openBlock(), _createBlock(_component_EditBrandLink, {
          key: 3,
          brandLinks: _ctx.user.brandLinks,
          activeBrandLink: _ctx.activeBrandLink,
          makeActiveBrandLink: _ctx.makeActiveBrandLink,
          mustSave: _ctx.mustSave,
          showToast: _ctx.showToast,
          saveChanges: _ctx.saveChanges,
          canvasMode: _ctx.canvasMode,
          selectBrandKitMode: _ctx.selectBrandKitMode,
          onCancelBrandKitChanges: _ctx.cancelChangesHandler
        }, null, 8, ["brandLinks", "activeBrandLink", "makeActiveBrandLink", "mustSave", "showToast", "saveChanges", "canvasMode", "selectBrandKitMode", "onCancelBrandKitChanges"]))
      : _createCommentVNode("", true)
  ], 64))
}