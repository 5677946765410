
import { CreationStatus, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { EXTRACT_TEXT_API } from "@/services";
import EditorJS from "@editorjs/editorjs";
import List from "@editorjs/list";
import { PropType } from "@vue/runtime-core";
import api from "@/services/api";
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import Toast from "@/components/common/Toast.vue";

@Options({
  props: {
    status: {
      type: Number as PropType<CreationStatus>,
      required: true
    },
    fileExtractionTitle: {
      type: String
    },
    fileExtractionBody: {
      type: String
    },
    keyWordsData: {
      type: Array
    },
    method: { type: Function }
  },
  emits: {
    updateFileStatus: {},
    generateInfographic: {},
    clearTranscript: {},
    toggleToast: {}
  },
  components: {
    ThothButton,
    lottie,
    Toast
  }
})
export default class TextExtraction extends Vue {
  ButtonColour = ButtonColour;
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  CreationStatus = CreationStatus;
  status!: CreationStatus;
  fileExtractionTitle?: string;
  fileExtractionBody?: string;
  keyWordsData?: Array<string>;

  images: { [id: string]: string } = {
    leftArrow: STATIC_ASSET_ICONS_BASE_URL + "/arrow-left.svg",
    textExtractionLoader:
      STATIC_ASSET_ICONS_BASE_URL + "/text-extraction-loader.svg",
    whiteCross: STATIC_ASSET_ICONS_BASE_URL + "/cross-white.svg",
    infoIcon: STATIC_ASSET_ICONS_BASE_URL + "/info.svg"
  };

  editor;

  urlList: Array<string> = [];

  cancelledUrlList: Array<string> = [];

  infographicTitle = "";

  showPreview = false;

  previewTitle = "";

  previewBody = "";

  previewUrl = "";

  extractionBody = "";

  wordCount = 0;

  uploadPercentage = 0;

  extractingText = false;

  keyWordsList: Array<string> = [];

  keyWord = "";

  showPopup = false;

  mounted() {
    const editorContainer = document.querySelector("#editor-container");

    this.editor = new EditorJS({
      holder: "editor-container",
      minHeight: 0,
      placeholder: "Type/paste your text here, or enter a URL",
      tools: {
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered"
          }
        }
      },
      onReady: () => {
        // if text comes from file
        if (this.keyWordsData) {
          this.keyWordsList = this.keyWordsData;
        }
        console.log(this.keyWordsList);
        if (this.fileExtractionBody) {
          this.extractionBody = this.fileExtractionBody;
          let currentBlockIndex = this.editor.blocks.getCurrentBlockIndex();
          this.extractionBody.split("\n\n").forEach(paragraph => {
            if (paragraph && paragraph.trim().length) {
              this.editor.blocks.insert(
                "paragraph",
                {
                  text: paragraph
                },
                "number",
                currentBlockIndex
              );
              currentBlockIndex++;
            }
          });
          if (this.fileExtractionTitle) {
            this.infographicTitle = this.fileExtractionTitle;
          } else {
            this.infographicTitle = this.extractionBody.split("\n\n")[0];
          }
        }
        this.editor.listeners.on(editorContainer, "keyup", () => {
          const blockIndex = this.editor.blocks.getCurrentBlockIndex();
          const block = this.editor.blocks.getBlockByIndex(blockIndex).holder;
          const bodyText = block.textContent;
          this.urlChecker(bodyText);
        });
      },
      onChange: () => {
        const numOfBlocks = this.editor.blocks.getBlocksCount();
        let blockText = "";
        const blockArray: string[] = [];
        for (let i = 0; i < numOfBlocks; i++) {
          blockText = this.editor.blocks.getBlockByIndex(i).holder.textContent;
          blockArray.push(blockText);
        }
        let joinedText = blockArray.join(" ");
        joinedText = joinedText.replace(/ – |\s/g, " ");
        this.wordCount = this.getWordCount(joinedText.trim());
      }
    });
  }

  // onSubmit method which calls parents generateInfographic function
  onSubmit() {
    this.editor.saver.save().then(response => {
      const blocks = response.blocks;
      const blockArray: string[] = [];

      // Prefix the text with the title
      if (this.infographicTitle && this.infographicTitle.length) {
        blockArray.push(this.infographicTitle + "\n");
      }
      // Collect the remaining text
      blocks.forEach(block => {
        blockArray.push(block.data.text);
      });
      this.editor.blocks.delete();
      this.$emit(
        "generateInfographic",
        this.infographicTitle,
        blockArray.join(" \n"),
        this.keyWordsList
      );
      this.extractionBody = "";
      this.infographicTitle = "";
    });
  }

  get disableButton() {
    if (this.wordCount < 200) {
      return true;
    }
    return false;
  }

  urlChecker = textToCheck => {
    interface Text {
      text: string;
      type: string;
    }
    /* eslint-disable-next-line */
    const expression = /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g;
    const regex = new RegExp(expression);
    let match;
    const splitText: Text[] = [];
    let startIndex = 0;
    while ((match = regex.exec(textToCheck)) != null) {
      const tempText: Text = {
        text: textToCheck.substr(startIndex, match.index - startIndex),
        type: "text"
      };
      splitText.push(tempText);

      const cleanedLink: Text = {
        text: textToCheck.substr(match.index, match[0].length),
        type: "link"
      };
      splitText.push(cleanedLink);

      startIndex = match.index + match[0].length;
    }
    if (startIndex < textToCheck.length)
      splitText.push({ text: textToCheck.substr(startIndex), type: "text" });

    // add all unique urls to urlList
    splitText.filter((obj: Text) => {
      if (
        obj.type === "link" &&
        !this.urlList.includes(obj.text) &&
        !this.cancelledUrlList.includes(obj.text)
      ) {
        this.urlList.push(obj.text);
        const formData = new FormData();
        formData.append("text", this.urlList[0]);
        this.extractingText = true;
        api
          .post(EXTRACT_TEXT_API, formData, {
            timeout: 15000
          })
          .then(response => {
            this.$emit("updateFileStatus", CreationStatus.AfterUpload);
            this.showPreview = true;
            console.log(response);
            const {
              title,
              description,
              canonicalLink,
              text,
              keywords
            } = response.data.data[0];
            this.previewTitle = title;
            this.extractionBody = this.trimExtraWhiteSpace(
              this.extractionBody + text
            );
            this.keyWordsList = keywords;
            this.previewBody = this.truncateText(description);
            this.previewUrl = canonicalLink;
            this.extractingText = false;
            this.$emit(
              "toggleToast",
              "Text successfully extracted! Click Extract Text to start editing!"
            );
          })
          .catch(error => {
            this.$emit("updateFileStatus", CreationStatus.BeforeUpload);
            this.$emit(
              "toggleToast",
              this.trimExtraWhiteSpace(
                error.message + `. Try again or contact the administrator.`
              )
            );
          });
      }
    });
  };

  truncateText = text => {
    text = text.trim();
    const words = text.split(" ");
    return words.length > 200 ? words.slice(0, 10).join(" ") + "..." : text;
  };

  extractTextFromLink() {
    this.editor.blocks.delete();
    this.extractionBody.split("\n\n").forEach(paragraph => {
      this.editor.blocks.insert("paragraph", { text: paragraph });
    });
    if (this.infographicTitle === "") {
      this.infographicTitle = this.previewTitle;
    }
    this.showPreview = false;
    this.previewTitle = "";
    this.previewBody = "";
    this.previewUrl = "";
    this.cancelledUrlList.push(this.urlList[this.urlList.length - 1]);
    this.urlList.pop();
    this.extractionBody = "";
  }

  cancelExtraction = () => {
    this.showPreview = false;
    this.previewTitle = "";
    this.previewBody = "";
    this.previewUrl = "";
    this.urlList.pop();
    this.extractionBody = "";
  };

  getWordCount(str) {
    return str.split(" ").length;
  }

  trimExtraWhiteSpace = body => {
    body = body.replace(/ +(?= )/g, "");
    return body;
  };

  clearExtractionData() {
    this.extractionBody = "";
    this.infographicTitle = "";
    this.editor.blocks.clear();
    this.$emit("updateFileStatus", CreationStatus.BeforeUpload);
    this.urlList = [];
    this.cancelledUrlList = [];
    this.$emit("clearTranscript");
  }

  addKeyWord(event) {
    event?.preventDefault();
    this.keyWordsList.push(this.keyWord);
    this.keyWord = "";
  }

  deleteKeyWord(index) {
    this.keyWordsList.splice(index, 1);
  }

  toggleKeywordSelectionPopup = () => {
    this.showPopup = !this.showPopup;
  };
}
