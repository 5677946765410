<template>
  <div class="section search-results">
    <div class="container search-title">
      <h1 class="max_500 search">Search results</h1>
      <!-- Search Form -->
      <form @submit.prevent="performSearch" class="search searchresultspg w-form">
        <input class="search-input w-input" maxlength="256" v-model="searchQuery"
          placeholder="Search speaker, talk name, theme, etc." type="search" id="search" required />
        <input type="submit" class="search-button w-button" value="⌕" />
      </form>

      <!-- Display Search Results -->
      <div v-if="searchResults.length > 0" class="search-result-list search-result-items">
        <div v-for="result in searchResults" :key="result.talkEndpoint" class="search-result-item">
          <router-link :to="result.talkEndpoint" class="link-block-3 w-inline-block">
            <div v-if="result.imageUrl" :style="{ backgroundImage: `url(${result.imageUrl})` }"
              class="search_results_image"></div>
            <div v-else :style="{
              backgroundImage: `url(https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventName}/images/Holding+Slide.png)`
            }" class="search_results_image"></div>
            <div class="div-block-22">
              <div class="text-block-12">{{ result.title }}</div>
              <p class="paragraph-3">
                {{ formatDate(result.date) }} | {{ result.time }}
              </p>
              <div v-if="result.speakers && result.speakers.length" class="rich-text-block-4">
                <div v-for="(speaker, index) in result.speakers" :key="index">
                  <strong>{{ speaker.name }}</strong> |
                  {{ speaker.designation }} , {{ speaker.company }}<br />
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-else>
        <p>No results found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SearchResults",
  props: {
    eventName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchQuery: this.$route.query.query || "",
      searchResults: [],
      data: null // Initialize data as null
    };
  },
  computed: {
    query() {
      return this.$route.query.query;
    }
  },
  watch: {
    query: "performSearch"
  },
  async mounted() {
    await this.fetchData(); // Fetch data when the component is mounted
    this.performSearch(); // Perform search once data is fetched
  },
  methods: {
    async fetchData() {
      try {
        const timestamp = new Date().getTime(); // Get the current time in milliseconds
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    performSearch() {
      if (!this.data) return; // Ensure data is available before proceeding
      this.$router.push({
        path: this.$route.path,
        query: { query: this.searchQuery }
      });
      const query = this.searchQuery.toLowerCase().trim();

      this.searchResults = this.data.filter(item => {
        const speakerMatch = item.speakers.some(speaker =>
          speaker.name.toLowerCase().includes(query)
        );
        const dateMatch = item.date.toLowerCase().includes(query);
        const locationMatch = item.location.toLowerCase().includes(query);
        const titleMatch = item.title.toLowerCase().includes(query);

        return speakerMatch || dateMatch || locationMatch || titleMatch;
      });
    },
    formatDate(date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(date).toLocaleDateString("en-US", options);
    }
  }
};
</script>
