
import { Vue } from "vue-class-component";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
export default class ValueProposition extends Vue {
  valueProps = [
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-01.png",
      subtitle:
        "Our A.I. recommends all content design, so you can have a strong base to quickly build on. "
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-02.png",
      subtitle:
        "Everything is laid out neatly, for you to make fuss-free tweaks with precision."
    },
    {
      image: STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-03.png",
      subtitle:
        "Quickly share our bite-sized visuals, and make a lasting mark on your audience."
    }
  ];
}
