
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import {
  Infographic,
  CanvasMode,
  EditMode,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";
import { VERSION_CHANGE_ACTIVE_GETTER } from "@/store/constants.js";
@Options({
  props: {
    infographics: {
      type: Array as PropType<Infographic[]>,
      required: true
    },
    activeInfographic: {
      type: Number,
      required: true
    },
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    },
    editMode: {
      type: Number as PropType<EditMode>,
      required: true
    }
  }
})
export default class InfographicDisplay extends Vue {
  CanvasMode = CanvasMode;
  EditMode = EditMode;
  editMode!: EditMode;

  // Data
  icons: { [id: string]: string } = {
    arrowRight: STATIC_ASSET_ICONS_BASE_URL + "/arrow-right.svg"
  };

  get versionChangeActive() {
    return this.$store.getters[VERSION_CHANGE_ACTIVE_GETTER];
  }

  getLoadingText(canvasMode: CanvasMode) {
    let text: string;
    switch (canvasMode) {
      case CanvasMode.LoadingText:
        text = "work on your text edits";
        break;
      case CanvasMode.LoadingColour:
        text = "update your accent colour";
        break;
      case CanvasMode.LoadingElements:
        text = "change your assets";
        break;
      case CanvasMode.LoadingLogo:
        text = "update your logo";
        break;
      case CanvasMode.LoadingBrandLink:
        text = "update your brand link";
        break;
      default:
        text = "";
    }
    return "Getting our AI illustrators to " + text + "...";
  }
}
