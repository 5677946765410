
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { configure, defineRule, Field, ErrorMessage } from "vee-validate";
import { PropType } from "@vue/runtime-core";

defineRule("hasMinimumLength", password => {
  if (password.length < 8 && password.length > 0) {
    return "length";
  } else {
    return true;
  }
});
defineRule("hasNumerals", password => {
  const regex = /.*[0-9].*/;
  if (!regex.test(password) && password.length > 0) {
    return "number";
  } else {
    return true;
  }
});
defineRule("hasUpperAndLowerCase", password => {
  const regex = /([A-Z].*[a-z]|[a-z].*[A-Z])/;
  if (!regex.test(password) && password.length > 0) {
    return "case";
  } else {
    return true;
  }
});
configure({ bails: false, validateOnInput: true });

@Options({
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
      default: "Password"
    },
    field: {
      type: Object as PropType<{ userInput: string }>,
      required: false,
      default: () => ({ userInput: "" })
    }
  },
  components: {
    Field,
    ErrorMessage
  }
})
export default class PasswordField extends Vue {
  icons: { [id: string]: string } = {
    eye: STATIC_ASSET_ICONS_BASE_URL + "/eye.svg",
    eyeSlash: STATIC_ASSET_ICONS_BASE_URL + "/eye-slash.svg",
    error: STATIC_ASSET_ICONS_BASE_URL + "/form-error.svg",
    checkCircle: STATIC_ASSET_ICONS_BASE_URL + "/check-circle-grey.svg",
    checkCircleBlue:
      STATIC_ASSET_ICONS_BASE_URL + "/check-circle-egyptianblue.svg"
  };
  inputType = "password";
  passwordRules = [
    { name: "length", text: "At least 8 characters in length" },
    { name: "case", text: "At least 1 lowercase or uppercase letter" },
    { name: "number", text: "At least 1 number" }
  ];
  placeholder = "";

  // Methods
  togglePasswordMode() {
    this.inputType = this.inputType === "password" ? "text" : "password";
  }
  hasMinimumLength(password: string) {
    if (password.length < 8) {
      return false;
    } else {
      return true;
    }
  }
  hasNumerals(password: string) {
    const regex = /.*[0-9].*/;
    if (regex.test(password)) {
      return true;
    } else {
      return false;
    }
  }
}
