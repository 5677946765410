
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
interface ToastProps {
  message: string;
}
@Options({
  props: {
    toastProps: {
      type: Object as PropType<ToastProps>,
      required: true
    }
  }
})
export default class Toast extends Vue {
  toastProps!: ToastProps;
  icons: { [id: string]: string } = {
    check: STATIC_ASSET_ICONS_BASE_URL + "/dashboard-toast-check-circle.svg"
  };
}
