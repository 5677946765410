
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";

@Options({
  props: {
    collapsed: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
})
export default class NavigationDrawerHeader extends Vue {
  icons: { [id: string]: string } = {
    whiteThoth: STATIC_ASSET_ICONS_BASE_URL + "/thoth-dashboard-white.svg",
    blackThoth: STATIC_ASSET_ICONS_BASE_URL + "/thoth-dashboard.svg",
    whiteCross: STATIC_ASSET_ICONS_BASE_URL + "/cross-white.svg"
  };
}
