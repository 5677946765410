
import { Project, ProjectType, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import InfographicDropdown from "@/components/product/dashboard/content/InfographicDropdown.vue";
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  emits: {
    trash: {},
    restore: {},
    updateProjectsState: {}
  },
  props: {
    activeFolder: {
      type: Number as PropType<ProjectType>,
      required: true
    },
    projectsToDisplay: {
      type: Array as PropType<Project[]> | Promise<Project[]>,
      required: true
    }
  },
  components: {
    InfographicDropdown
  }
})
export default class ProjectDisplay extends Vue {
  ProjectType = ProjectType;
  activeFolder!: ProjectType;

  icons: { [id: string]: string } = {
    optionsVertical: STATIC_ASSET_ICONS_BASE_URL + "/options-vertical.svg",
    star: STATIC_ASSET_ICONS_BASE_URL + "/star.svg"
  };

  projects: Project[] = [];
  projectsToDisplay!: Project[] | Promise<Project[]>;
  trashedProjects: Project[] = [];

  getDateForComparison(p: Project) {
    // Priority in choosing date for comparison: Deleted > Updated > Created
    return p.deletedAt ? p.deletedAt : p.updatedAt ? p.updatedAt : p.createdAt;
  }

  sortByMostRecent(a: Project, b: Project) {
    const dateA = new Date(this.getDateForComparison(a)).getTime();
    const dateB = new Date(this.getDateForComparison(b)).getTime();
    return dateB - dateA;
  }

  getProjectCSSClass(project: Project) {
    const CSSClass = project.state !== "IN_USE" ? "project--trash" : "project";
    return `${CSSClass} uk-width-1-2`;
  }

  selectInfographicForDisplay(project: Project) {
    if (this.activeFolder === ProjectType.Draft) {
      return project.infographics[0];
    } else if (this.activeFolder === ProjectType.Downloaded) {
      return project.infographics[0];
    } else {
      return project.infographics[0];
    }
  }
  getInfographicModeStyle(projectMode: string, projectStatus: string) {
    const isPreview = projectMode === "PREVIEW";
    const isLocked = projectStatus === "LOCKED";
    const backgroundColor = isPreview
      ? "#ffffff"
      : isLocked
      ? "#deb3e7"
      : "#f7ce46";
    const color = isPreview ? "#585858" : "#414141";
    return { backgroundColor: backgroundColor, color: color };
  }
  getInfographicMode(projectMode: string, projectStatus: string) {
    if (projectMode === "PREVIEW") {
      return "PREVIEW";
    } else {
      if (projectStatus === "LOCKED") {
        return "LOCKED";
      } else {
        return "EDITING";
      }
    }
  }

  sanitizeTime(time: number) {
    if (time <= 1) {
      return "just now";
    } else if (time < 60) {
      return `${time} minute${time === 1 ? "" : "s"} ago`;
    } else if (time < 1440) {
      const hours = Math.floor(time / 60);
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (time < 14400) {
      const days = Math.floor(time / 1440);
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else {
      return "";
    }
  }
  sanitizeDate(dateTime: Date) {
    // Select Day, Month, Year from UTC format
    const date = new Date(dateTime).toUTCString().slice(4, -13);
    return `on ${date}`;
  }
  getTimeElapsed(dateTime: Date) {
    const now = new Date().getTime();
    const date = new Date(dateTime).getTime();
    const timeElapsedInMinutes =
      Math.floor((now - date) / 60000) + new Date().getTimezoneOffset();
    return timeElapsedInMinutes >= 14400
      ? this.sanitizeDate(dateTime)
      : this.sanitizeTime(timeElapsedInMinutes);
  }
  getLastUpdated(project: Project) {
    if (project.mode === "PREVIEW") {
      return `Created ${this.getTimeElapsed(new Date(project.createdAt))}`;
    } else if (project.status === "LOCKED") {
      if (project.updatedAt) {
        return `Downloaded ${this.getTimeElapsed(new Date(project.updatedAt))}`;
      }
    } else if (project.mode !== "PREVIEW") {
      if (project.updatedAt) {
        return `Last edited ${this.getTimeElapsed(
          new Date(project.updatedAt)
        )}`;
      } else {
        return `Created ${this.getTimeElapsed(new Date(project.createdAt))}`;
      }
    } else {
      return "";
    }
  }
  moveToTrash(projectUUID: string) {
    this.$emit("trash", projectUUID);
  }
  restoreProject(projectUUID: string) {
    this.$emit("restore", projectUUID);
  }
  goToProductRoute(project: Project) {
    if (project.state !== "IN_USE") {
      return;
    } else {
      let path;
      if (project.status === "LOCKED") {
        path = `/project/${project.uuid}/publish`;
      } else {
        if (project.mode === "PREVIEW") {
          path = `/project/${project.uuid}/preview`;
        } else {
          path = `/project/${project.uuid}/edit`;
        }
      }
      this.$router.push({ path: path });
    }
  }
  updateProjectsState() {
    this.$emit("updateProjectsState");
  }
}
