import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "uk-flex container__mobile__blocker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileBlocker = _resolveComponent("MobileBlocker")
  const _component_DashboardNavBar = _resolveComponent("DashboardNavBar")
  const _component_DashboardNavigationDrawer = _resolveComponent("DashboardNavigationDrawer")
  const _component_DashboardContent = _resolveComponent("DashboardContent")
  const _component_HelpFloatingButton = _resolveComponent("HelpFloatingButton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_MobileBlocker),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_DashboardNavBar, {
          key: 0,
          class: "container__mobile__blocker",
          "logged-in": _ctx.isLoggedIn
        }, null, 8, ["logged-in"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DashboardNavigationDrawer, {
        class: "container__mobile__blocker",
        "project-count": _ctx.projectCount,
        "active-folder": _ctx.activeFolder,
        onMakeActiveFolder: _ctx.makeActiveFolder
      }, null, 8, ["project-count", "active-folder", "onMakeActiveFolder"]),
      _createVNode(_component_DashboardContent, {
        class: "container__mobile__blocker",
        projects: _ctx.selectedProjectsType,
        currentPage: _ctx.currentPage,
        "project-count": _ctx.projectCount,
        "active-folder": _ctx.activeFolder,
        onViewDrafts: _ctx.viewDrafts,
        onUpdateCurrentPage: _ctx.updateCurrentPage,
        onProjectStateUpdated: _ctx.getProjectsByCurrentState
      }, null, 8, ["projects", "currentPage", "project-count", "active-folder", "onViewDrafts", "onUpdateCurrentPage", "onProjectStateUpdated"])
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_HelpFloatingButton, {
          key: 1,
          class: "container__mobile__blocker",
          "user-email": _ctx.user.email
        }, null, 8, ["user-email"]))
      : _createCommentVNode("", true)
  ], 64))
}