<template>
  <div class="keypoint_selection_container">
    <div class="selectedImage">
      <transition name="fastfade" appear>
        <img v-if="chosen" class="actualImage" :src="imageSelect" alt="" />
      </transition>
    </div>
    <div class="keypoint_transit_anim">
      <lottie :options="defaultOptions" @animCreated="handleAnimation" />
    </div>

    <div class="keypoints_show_selector">
      <img :src="checkKeySelected1" alt="" />
      <img :src="checkKeySelected2" alt="" />
      <img :src="checkKeySelected3" alt="" />
    </div>

    <div
      class="keypoints uk-position-relative"
      tabindex="-1"
      uk-slider="finite: true;"
    >
      <ul class="uk-slider-items .uk-panel-box-hover">
        <li
          v-for="(keypoint, index) in keypoints"
          :key="index"
          class="kp_container uk-width-3-4"
        >
          <img
            class="kp_image"
            :class="{ kp_image_clicked: count.includes(index) }"
            :src="keypoint.words"
            alt="keypt+index"
            @click="select(index)"
          />
        </li>
      </ul>
    </div>
    <DemoModal
      v-for="(innerStep, index) in innerSteps"
      :key="index"
      :delayed="innerStep.delayed"
      :modal="checkInnerStep(innerStep.stepNum)"
      :title="innerStep.title"
      :subtexts="innerStep.subtexts"
      :moveonEnd="innerStep.moveonEnd"
      @modal-closed="close"
    />
  </div>
</template>
<script>
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import DemoModal from "@/components/common/DemoModal.vue";
import ScanAnim from "@/assets/animate/scan.json";
@Options({
  computed: {
    imageSelect() {
      return this.choice >= 0 ? this.keypoints[this.choice].image : null;
    },
    checkKeySelected1() {
      if (this.count.length >= 1) {
        return STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/KP_Selected.svg";
      }
      return STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/KP_NotSelected.svg";
    },
    checkKeySelected2() {
      if (this.count.length >= 2) {
        return STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/KP_Selected.svg";
      }
      return STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/KP_NotSelected.svg";
    },
    checkKeySelected3() {
      if (this.count.length >= 3) {
        return STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/KP_Selected.svg";
      }
      return STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/KP_NotSelected.svg";
    }
  },
  components: { lottie, DemoModal }
})
export default class KeyPointSelection extends Vue {
  //data
  choice = -1;
  innerStep = 0;
  count = [];
  chosen = false;
  playing = false;
  defaultOptions = {
    animationData: ScanAnim,
    loop: true,
    autoplay: false
  };
  innerSteps = [
    {
      stepNum: 0,
      moveonEnd: false,
      delayed: true,
      title: "Step 2/4: SELECT KEY POINTS",
      subtexts: ["Select your favourites from our A.I. key points."]
    }
  ];
  keypoints = [
    {
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Point1-Grayscale.png",
      words: STATIC_ASSET_IMAGES_BASE_URL + "/demo/KP-01.svg"
    },
    {
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Point2-Grayscale.png",
      words: STATIC_ASSET_IMAGES_BASE_URL + "/demo/KP-02.svg"
    },
    {
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Point3-Grayscale.png",
      words: STATIC_ASSET_IMAGES_BASE_URL + "/demo/KP-03.svg"
    },
    {
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Point4-Grayscale.png",
      words: STATIC_ASSET_IMAGES_BASE_URL + "/demo/KP-04.svg"
    },
    {
      image:
        STATIC_ASSET_IMAGES_BASE_URL + "/demo/choices/Point5-Grayscale.png",
      words: STATIC_ASSET_IMAGES_BASE_URL + "/demo/KP-05.svg"
    }
  ];
  //functions
  checkInnerStep(number) {
    switch (number) {
      case -1:
        return this.innerSteps.length == this.innerStep;
      case -2:
        return this.innerStep < this.innerSteps.length;
      default:
        return number == this.innerStep;
    }
  }
  close() {
    this.innerStep++;
  }
  select(i) {
    if (!this.playing && this.count.length < 3 && !this.count.includes(i)) {
      this.playing = true;
      this.choice = i;
      this.chosen = false;
      this.$emit("addKP", i + 1);
      this.count.push(i);
      setTimeout(() => {
        this.chosen = true;
      }, 1100);
      this.anim.play();
    }
  }
  animationCompleted() {
    this.anim.stop();
    this.playing = false;
    if (this.count.length == 3) {
      setTimeout(() => {
        this.$emit("finished");
      }, 1000);
    }
  }
  handleAnimation(anim) {
    this.anim = anim;
    this.anim.addEventListener("loopComplete", () => this.animationCompleted());
  }
}
</script>
<style scoped>
.keypoint_selection_container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.actualImage {
  height: 75%;
}
.selectedImage {
  position: absolute;
  width: 100%;
  top: 0;
  height: calc((173 / 246) * 320 / 568 * 95vh);
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/demo/ipadScreen.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.keypoints_show_selector {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}
.keypoint_transit_anim {
  position: absolute;
  height: 95vh;
  width: calc(320 / 568 * 95vh);
  z-index: 0;
}
.keypoints {
  z-index: 2;
}
.kp_container {
  padding: 15px;
}
.kp_image {
  width: 100%;
  box-shadow: 0px 10px 8px #888888;
}
.kp_image_clicked {
  width: 100%;
  border: 3px solid var(--ink);
  transform: translateY(10px);
  box-shadow: 0px 0px 8px #888888;
}

@media screen and (max-width: 480px) {
  .keypoint_transit_anim {
    height: calc(568 / 320 * 98vw);
    width: 98vw;
  }
}
</style>
