
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import NavigationDrawerHeader from "@/components/product/dashboard/nav-drawer/NavigationDrawerHeader.vue";
import NavigationDrawerBody from "@/components/product/dashboard/nav-drawer/NavigationDrawerBody.vue";
import { ProjectCount, ProjectType } from "@/commons";

@Options({
  components: {
    NavigationDrawerHeader,
    NavigationDrawerBody
  },
  emits: {
    makeActiveFolder: {}
  },
  props: {
    activeFolder: {
      type: Number as PropType<ProjectType>,
      required: true
    },
    projectCount: {
      type: Object as PropType<ProjectCount>,
      required: true
    }
  }
})
export default class DashboardNavigationDrawer extends Vue {
  ProjectType = ProjectType;
  activeFolder!: ProjectType;

  get navDrawerElement() {
    return document.querySelector("#nav-drawer--fixed");
  }

  makeActiveFolder(folderType: ProjectType) {
    this.$emit("makeActiveFolder", folderType);
  }

  // Mounted
  async mounted() {
    if (this.navDrawerElement) {
      UIkit.offcanvas(this.navDrawerElement).show();
    }
  }
}
