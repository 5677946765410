
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
@Options({
  props: {
    showToast: {
      type: Boolean,
      required: true
    }
  }
})
export default class SaveAlertToast extends Vue {
  icons: { [id: string]: string } = {
    warningCircle: STATIC_ASSET_ICONS_BASE_URL + "/warning-circle.svg"
  };
}
