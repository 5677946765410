
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import Logos from "@/components/product/brandkit/Logos.vue";
import ColourPalettes from "@/components/product/brandkit/ColourPalettes.vue";
import BrandLinksSection from "@/components/product/brandkit/BrandLinksSection.vue";
import { User } from "@/commons";

@Options({
  components: {
    Logos,
    ColourPalettes,
    BrandLinksSection
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    loaded: {
      type: Boolean,
      required: true
    }
  }
})
export default class BrandKitContent extends Vue {}
