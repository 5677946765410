<template>
    <div v-if="showCookieConsent" :class="$style.cookiesContainer">
        <div :class="$style.cookies">
            <div :class="$style.cookieConsentParent">
                <div :class="$style.cookieConsent">Cookie consent</div>
                <div :class="$style.weUseCookies">We use cookies to enhance your browsing experience, serve
                    personalized ads or content, and analyze our traffic. By clicking "Accept all", you consent to
                    our use of cookies.</div>
            </div>
            <div :class="$style.cookiesInner">
                <div :class="$style.buttonParent">
                    <div :class="$style.button" @click="acceptAllCookies">
                        <div :class="$style.button1">Accept all</div>
                    </div>
                    <div :class="$style.button2" @click="acceptNeccessaryCookies">
                        <div :class="$style.button1">Necessary cookies only</div>
                    </div>
                    <div :class="$style.button2" @click="openCookiesSettings">
                        <div :class="$style.button1">Manage cookies</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Manage Cookie Preferences -->
    <div v-if="showCookieSettings">
        <div :class="$style.rectangleDiv" />
        <div :class="$style.cookies1">
            <div :class="$style.manageCookiesGroup">

                <div :class="$style.manageCookies">Manage cookies</div>

                <div :class="$style.weUseCookiesContainer">
                    <span>We use cookies to enhance your browsing experience, serve personalized ads or content, and
                        analyze
                        our traffic. To learn more, please read our </span>
                    <router-link :to="{ path: '/privacy', hash: '#cookies' }" target="_blank">
                        <span :class="$style.cookiesPolicy">Cookies policy</span>
                    </router-link>
                    <span>.</span>

                </div>
            </div>
            <div :class="$style.frameParent">
                <div :class="$style.frameGroup">
                    <div :class="$style.strictlyNecessaryCookiesParent">
                        <div :class="$style.strictlyNecessaryCookies">Strictly necessary cookies</div>
                        <b :class="$style.alwaysOn">Always on</b>
                    </div>
                    <div :class="$style.theseCookiesAre">These cookies are essential for the website to function
                        properly.
                        They cannot be disabled.</div>
                </div>
                <div :class="$style.frameChild" />
                <div :class="$style.frameGroup">
                    <div :class="$style.strictlyNecessaryCookiesParent">
                        <div :class="$style.strictlyNecessaryCookies">Functionality cookies</div>
                        <div :class="getToggleClass('functionality')" @click="toggleCookie('functionality')">
                            <div :class="getKnobClass('functionality')" />
                        </div>
                    </div>
                    <div :class="$style.theseCookiesAre">These cookies enable personalized features and remember your
                        preferences.</div>
                </div>
                <div :class="$style.frameChild" />
                <div :class="$style.frameGroup">
                    <div :class="$style.strictlyNecessaryCookiesParent">
                        <div :class="$style.strictlyNecessaryCookies">Analytics cookies</div>
                        <div :class="getToggleClass('analytics')" @click="toggleCookie('analytics')">
                            <div :class="getKnobClass('analytics')" />
                        </div>
                    </div>
                    <div :class="$style.theseCookiesAre">These cookies help us understand how visitors interact with the
                        website, helping us improve our services.</div>
                </div>
                <div :class="$style.frameChild" />
                <div :class="$style.frameGroup">
                    <div :class="$style.strictlyNecessaryCookiesParent">
                        <div :class="$style.strictlyNecessaryCookies">Advertising cookies</div>
                        <div :class="getToggleClass('advertising')" @click="toggleCookie('advertising')">
                            <div :class="getKnobClass('advertising')" />
                        </div>
                    </div>
                    <div :class="$style.theseCookiesAre">These cookies are used to serve you with personalized ads and
                        marketing campaigns.</div>
                </div>
            </div>
            <div :class="$style.cookiesInner">
                <div :class="$style.buttonWrapper">
                    <div :class="$style.button" @click="savePreferences">
                        <div :class="$style.munthirAAlsheddieContainer">Save changes</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Cookies from 'js-cookie';
import EventBus from './eventBus';

export default {
    data() {
        return {
            showCookieConsent: false,
            showCookieSettings: false,
            cookiePreferences: {
                functionality: false,
                analytics: false,
                advertising: false,
            },
        };
    },
    mounted() {
        this.checkCookieConsent();
        EventBus.on('openCookieSettings', () => {
            this.openCookiesSettings();
        });
    },
    methods: {
        checkCookieConsent() {
            const cookieConsent = Cookies.get('cookieConsent');
            if (!cookieConsent) {
                this.showCookieConsent = true;
            }
        },
        openCookiesSettings() {
            this.showCookieSettings = true;
            this.showCookieConsent = false;
        },
        acceptAllCookies() {
            this.setCookies(true, true, true);
            this.showCookieConsent = false;
            EventBus.emit('cookiesUpdated');
        },
        acceptNeccessaryCookies() {
            this.setCookies(false, false, false);
            this.showCookieConsent = false;
            EventBus.emit('cookiesUpdated');
        },
        setCookies(functionality, analytics, advertising) {
            Cookies.set('cookieConsent', 'accepted', { expires: 365 });
            Cookies.set('functionalityCookies', functionality, { expires: 365 });
            Cookies.set('analyticsCookies', analytics, { expires: 365 });
            Cookies.set('advertisingCookies', advertising, { expires: 365 });
            EventBus.emit('cookiesUpdated');
        },
        toggleCookie(type) {
            this.cookiePreferences[type] = !this.cookiePreferences[type];
        },
        getToggleClass(type) {
            return this.cookiePreferences[type] ? this.$style.toggle1 : this.$style.toggle;
        },
        getKnobClass(type) {
            return this.cookiePreferences[type] ? this.$style.knob1 : this.$style.knob;
        },
        savePreferences() {
            this.setCookies(
                this.cookiePreferences.functionality,
                this.cookiePreferences.analytics,
                this.cookiePreferences.advertising
            );
            this.showCookieSettings = false;
        },
    },
};
</script>

<style module>
.cookiesContainer {
    z-index: 200000;
    padding: 2rem;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
}

.cookieConsent {
    position: relative;
    line-height: 130%;
    font-weight: 600;
}

.weUseCookies {
    position: relative;
    font-size: 14px;
    line-height: 130%;
}

.cookieConsentParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.button1 {
    position: relative;
    line-height: 130%;
    justify-content: center
}

.button {
    border-radius: 12px;
    background-color: #8c7340;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    box-sizing: border-box;
    cursor: pointer;
    color: #fff;
    font-weight: 300;
}

.button2 {
    border-radius: 12px;
    border: 1px solid #111;
    box-sizing: border-box;
    height: 36px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    cursor: pointer;

}

.buttonParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

}

.cookiesInner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: #111;
}

.cookies {
    position: relative;
    border-radius: 16px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    box-sizing: border-box;
    gap: 16px;
    text-align: left;
    font-size: 16px;
    color: #000;
    font-family: 'DM Sans';
    z-index: 200000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.cookies1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background-color: #f5f5f5;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 24px;
    box-sizing: border-box;
    gap: 24px;
    font-size: 16px;
    color: #000;
    z-index: 200000;
}

@media (max-width: 768px) {
    .cookies1 {
        width: 95%;
        padding: 16px;
        font-size: 14px;
    }

    .cookiesContainer {
        padding: 1rem;
    }

    .button,
    .button2 {
        height: 32px;
        font-size: 14px;
    }
}

.manageCookies {
    position: relative;
    font-size: 24px;
    line-height: 130%;
    font-family: 'Instrument Serif';
    color: #000;
    text-align: left;
}

.cookiesPolicy {
    text-decoration: underline;
    font-weight: 600;
    color: #8c7340;
}

.munthirAAlsheddieContainer {
    position: relative;
    line-height: 130%;
}

.weUseCookiesContainer {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 130%;
    font-family: 'DM Sans';
}

.manageCookiesParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    font-size: 24px;
    font-family: 'Instrument Serif';

}

.strictlyNecessaryCookies {
    flex: 1;
    position: relative;
    line-height: 130%;
    font-weight: 600;
}

.alwaysOn {
    position: relative;
    font-size: 14px;
    line-height: 130%;
    color: #8c7340;
}

.strictlyNecessaryCookiesParent {
    width: 552px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 63px;
}

.theseCookiesAre {
    width: 552px;
    position: relative;
    font-size: 14px;
    line-height: 130%;
    color: #3b3b3b;
    display: inline-block;
}

.frameGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.frameChild {
    width: 551px;
    position: relative;
    border-top: 1px solid #d9d9d9;
    box-sizing: border-box;
    height: 1px;
    gap: 8px;
}

.knob {
    position: absolute;
    height: 87.08%;
    top: 6.45%;
    bottom: 6.47%;
    left: 1.55px;
    box-shadow: 0px 0px 0px 0.77px rgba(0, 0, 0, 0.04), 0px 2.3225808143615723px 6.19px rgba(0, 0, 0, 0.15), 0px 2.3225808143615723px 0.77px rgba(0, 0, 0, 0.06);
    border-radius: 77.42px;
    background-color: #fff;
    width: 20.9px;
    transition: transform 0.3s ease;
}

.toggle {
    width: 39.5px;
    position: relative;
    border-radius: 77.42px;
    background-color: #d9d9d9;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.knob1 {
    position: absolute;
    top: calc(50% - 10.84px);
    box-shadow: 0px 0px 0px 0.77px rgba(0, 0, 0, 0.04), 0px 2.3225808143615723px 6.19px rgba(0, 0, 0, 0.15), 0px 2.3225808143615723px 0.77px rgba(0, 0, 0, 0.06);
    border-radius: 77.42px;
    background-color: #fff;
    width: 20.9px;
    height: 20.9px;
    transition: transform 0.3s ease;
    transform: translateX(18px);
}

.toggle1 {
    width: 39.5px;
    position: relative;
    border-radius: 77.42px;
    background-color: #8c7340;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.frameParent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    text-align: left;
    font-size: 16px;
    color: #000;
    font-family: 'DM Sans';
    overflow: auto;
}

.rectangleDiv {
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: 1024px;
    z-index: 999;
}

.button1 {
    font-size: smaller;
    text-align: center;
}
</style>
