
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    ThothButton
  }
})
export default class BetaDescription extends Vue {
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  ButtonColour = ButtonColour;

  images: { [id: string]: string } = {
    descriptionIllo: STATIC_ASSET_IMAGES_BASE_URL + "/about-illo-1.png"
  };

  goToWaitlist() {
    this.$router.push("/waitlist");
  }
}
