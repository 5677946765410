
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { ProjectType, STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import { DraftFilter } from "@/components/product/dashboard/DashboardContent.vue";

@Options({
  emits: {
    viewDrafts: {}
  },
  props: {
    activeFolder: {
      type: Number as PropType<ProjectType>,
      required: true
    },
    selectedFilter: {
      type: String as PropType<string>,
      required: true
    }
  }
})
export default class ContentPlaceholder extends Vue {
  ProjectType = ProjectType;
  activeFolder!: ProjectType;
  selectedFilter!: DraftFilter;

  emptyFolderImage =
    STATIC_ASSET_IMAGES_BASE_URL + "/dashboard-placeholder.svg";
  displayText = {
    [ProjectType.Draft]: {
      header: "Start building your next infographic!",
      caption: "Click on the 'Create New' button to get started"
    },
    [ProjectType.Downloaded]: {
      header: "You don't have any published infographics yet!",
      caption: "Infographics that are upgraded and locked will be shown here."
    },
    [ProjectType.Trash]: {
      header: "There's nothing here!",
      caption:
        "Deleted infographics will end up here. You have 30 days to restore them before they are deleted permanently."
    }
  };
  viewDrafts() {
    this.$emit("viewDrafts");
  }
}
