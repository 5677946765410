<template>
  <div class="section traveltellers_template">
    <div class="container tags_heading">
      <div class="text-block-38">
        <h2 class="heading-9">STAGE</h2>
        <h2 class="heading-27">{{ stageTitle }}</h2>
      </div>
    </div>
    <div class="container blog_posts">
      <div class="blog-collection-list-wrapper on-category-template">
        <div role="list" class="blog-collection-list">
          <div role="listitem" v-for="content in contents" :key="content.name">
            <div class="story_image on_blog_page full-width">
              <div class="div-block-56">
                <div class="top_content ">
                  <div class="category-6 theme_background" :style="{
                    backgroundColor: getCategoryColor(content.category)
                  }">
                    {{ content.category }}
                  </div>
                </div>
                <div v-if="imageLoadStatus[content.name] === true">
                  <img :src="content.imageUrl" sizes="(max-width: 479px) 100vw, 100vw" loading="lazy" alt="Stage Image"
                    class="image-24 responsive-image" />
                </div>
                <div v-else>
                  <img
                    :src="`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventName}/images/Holding+Slide.png`"
                    loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image"
                    class="image-39 responsive-image" />
                </div>
              </div>
              <div class="story_content">
                <div class="div-block-32">
                  <div class="text_block talk-time left-talk-time">
                    {{ formatDate(content.date) }}
                  </div>
                  <div class="text_block talk-time left-talk-time left-talk-time-time">
                    {{ content.time }}
                  </div>
                </div>
                <h4 class="heading-26">{{ content.heading }}</h4>
                <div v-if="content.speakers && content.speakers.length">
                  <div class="rich-text-block-4 w-richtext" v-for="(speaker, index) in content.speakers" :key="index">
                    <strong>{{ speaker.name }}</strong> |
                    {{ speaker.designation }} , {{ speaker.company }}<br />
                  </div>
                </div>
              </div>
              <!-- <a :href="content.link" class="link-block-13 w-inline-block"></a> -->
              <router-link :to="generateEndpoint(content.name)" class="link-block-13 w-inline-block"></router-link>
            </div>
          </div>
        </div>
        <div class="empty-state-3 w-dyn-empty" v-if="!hasContent">
          <div>No items found.</div>
        </div>
      </div>

      <div class="sticky-nav-contain">
        <form :action="`/${eventName}/search`" class="search searchagenda w-form">
          <input class="search-input searchagendatext w-input" maxlength="256" name="query" placeholder="Search..."
            type="search" id="search" required />
          <input type="submit" class="search-button w-button" value="⌕" />
        </form>
        <div class="sticky">
          <h6 class="browse-headings">BROWSE BY session type</h6>
          <div class="line div stickynavline"></div>
          <div class="blog_categories_contain">
            <div class="w-dyn-list">
              <div role="list" class="category-tags-collection-list w-dyn-items">
                <div role="listitem" v-for="(location, index) in uniqueLocations" :key="index" class="w-dyn-item">
                  <router-link :to="`/${eventName}/stage/${location
                    .toLowerCase()
                    .replace(/ /g, '-')}`
                    " class="link-block-14 w-inline-block">
                    <div class="text-block-43">{{ location }}</div>
                  </router-link>
                </div>
              </div>
              <div class="w-dyn-empty" v-if="!uniqueLocations.length">
                <div>No items found.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Stage",
  inject: ["eventConfig"],
  props: {
    eventName: {
      type: String,
      required: true
    },
    stageName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      stageTitle: "", // Will be dynamically updated based on the route param
      contents: [], // Array to hold the list of session contents
      uniqueLocations: [], // Initialize uniqueLocations as an empty array
      hasContent: true, // Controls the visibility of the empty state
      data: null, // Initialize data as null
      imageLoadStatus: {}
    };
  },
  watch: {
    stageName: {
      immediate: true, // Run this on initial load
      async handler(newName) {
        this.loadStageData(newName); // Load the appropriate data based on the new name
        await this.checkImageStatus();
      }
    }
  },
  methods: {
    getCategoryColor(category) {
      const colors = this.eventConfig?.locationColors || {};
      return colors[category] || "#000000"; // Default color if category not found or if locationColors is undefined
    },
    async fetchData() {
      try {
        const timestamp = new Date().getTime(); // Get the current time in milliseconds
        const response = await axios.get(
          `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json?v=${timestamp}`
        );
        this.data = response.data;
        this.loadUniqueLocations(); // Load unique session types once data is fetched
        this.loadStageData(this.stageName); // Load stage data once the fetch is complete
        this.checkImageStatus();
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    formatDate(date) {
      // Format the date as "dd MMM"
      const options = { day: "2-digit", month: "short" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    generateEndpoint(title) {
      return `/${this.eventName}/talk/${title
        .toLowerCase() // Convert to lowercase

        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .trim()}`; // Trim any extra whitespace
    },
    loadStageData(stageName) {
      if (!this.data) return; // Ensure data is available before proceeding


      const capitalizeFirstLetter = string => {
        return string.replace(/\b\w/g, char => char.toUpperCase());
      };

      const formattedStageName = capitalizeFirstLetter(
        stageName.replace(/-/g, " ")
      ); // Convert 'stage-name' to 'STAGE NAME'
      this.stageTitle = `${formattedStageName} Highlights`;

      // Filter the data based on the stageName
      const filteredData = this.data.filter(
        item => item.location === formattedStageName.toUpperCase()
      );

      // Map the data to the contents structure
      this.contents = filteredData.map(item => ({
        name: item.title, // Assuming title can serve as a unique name
        category: item.location,
        imageUrl: item.imageUrl,
        time: item.time,
        timeDetail: `Duration: ${item.duration} minutes`, // Format duration
        heading: item.title,
        description: item.description,
        link: item.source,
        speakers: item.speakers,
        date: item.date
      }));

      this.hasContent = this.contents.length > 0;
      if (!this.hasContent) {
        this.$router.push('/404');
        return;
      }
    },
    loadUniqueLocations() {
      if (!this.data) return; // Ensure data is available before proceeding

      // Extract unique locations from the data
      const seen = new Set();
      this.uniqueLocations = this.data
        .filter(item => {
          const duplicate = seen.has(item.location);
          seen.add(item.location);
          return !duplicate;
        })
        .map(item => item.location); // Ensure you extract only locations
    },
    preloadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => reject(false);
      });
    },
    async checkImageStatus() {
      const statusPromises = this.contents.map(async (content) => {
        try {
          await this.preloadImage(content.imageUrl);
          this.imageLoadStatus[content.name] = true;
        } catch (error) {
          this.imageLoadStatus[content.name] = false;
        }
      });
      await Promise.all(statusPromises);
    }
  },
  async mounted() {
    await this.fetchData(); // Fetch data when the component is mounted
  }
};
</script>

<style scoped>
.responsive-image {
  width: 100%;
  /* Fill the width of the container */
  height: 200px;
  /* Set a specific height */
  object-fit: cover;
}
</style>
