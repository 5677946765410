
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import {
  LOAD_STORE_ACTION,
  LOADED_GETTER,
  USER_GETTER
} from "@/store/constants.js";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";

@Options({
  props: {
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  components: { ThothButton }
})
export default class DashboardNavBar extends Vue {
  // Props
  loggedIn!: boolean;

  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  // Data
  icons: { [id: string]: string } = {
    logo: STATIC_ASSET_ICONS_BASE_URL + "/navbar-thoth-logo.svg",
    logoWhite: STATIC_ASSET_ICONS_BASE_URL + "/navbar-thoth-logo-white.svg",
    navbarDrop: STATIC_ASSET_ICONS_BASE_URL + "/navbar-public-dropdown.svg",
    cross: STATIC_ASSET_ICONS_BASE_URL + "/navbar-public-cross.svg",
    arrow: STATIC_ASSET_ICONS_BASE_URL + "/navbar-dropdown-arrow-up-right.svg"
  };
  links = [
    { text: "Pricing", link: "/pricing" },
    { text: "About", link: "/about" }
  ];
  allLinks = [
    { text: "Pricing", link: "/pricing" },
    { text: "About", link: "/about" },
    { text: "Sign In", link: "/login" },
    { text: "Join Waitlist", link: "/waitlist" }
  ];
  navIsOpen = false;

  // Methods
  async mounted() {
    if (this.loggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }
  }
  get loaded() {
    return this.$store.getters[LOADED_GETTER];
  }
  get user() {
    return this.loggedIn ? this.$store.getters[USER_GETTER] : {};
  }
  goToLogin() {
    this.$router.push({ name: "Login" });
  }
  goToWaitlist() {
    this.$router.push({ name: "Waitlist" });
  }
  goToDashboard() {
    this.$router.push({ name: "Dashboard" });
  }
}
