
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import SaveChangesBar from "@/components/product/edit/SaveChangesBar.vue";
import SaveAlertToast from "@/components/common/SaveAlertToast.vue";
import {
  ASSETS_BASE_URL,
  VISUAL_MOTIFS_BASE_URL,
  CanvasMode,
  Infographic,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";

@Options({
  components: {
    SaveChangesBar,
    SaveAlertToast
  },
  props: {
    infographics: {
      type: Array as PropType<Infographic[]>,
      required: true
    },
    activeInfographic: {
      type: Number,
      required: true
    },
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    },
    saveChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    cancelChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    mustSave: {
      type: Boolean,
      required: true
    },
    showToast: {
      type: Boolean,
      required: true
    }
  }
})
export default class VisualEdits extends Vue {
  CanvasMode = CanvasMode;

  infographics!: Infographic[];
  activeInfographic!: number;
  canvasMode!: CanvasMode;
  chevronLeft = STATIC_ASSET_ICONS_BASE_URL + "/chevron-left.svg";
  displaySuggestions = false;
  displayVMSuggestions = false;
  activeAssetId = 0;
  activeVMId = 0;
  makeActiveElement(id: number) {
    this.displaySuggestions = true;
    this.activeAssetId = id;
  }
  makeActiveVM(id: number) {
    this.displayVMSuggestions = true;
    this.activeVMId = id;
  }
  selectAsset(id: number) {
    this.displaySuggestions = false;
    this.$nextTick(() => {
      const assets = this.infographics[this.activeInfographic].assets;
      assets[this.activeAssetId] = this.similarAssets[id];
    });
  }
  selectVM(id: number) {
    this.displayVMSuggestions = false;
    setTimeout(() => {
      const vm = this.infographics[this.activeInfographic].visualMotifs;
      vm[this.activeVMId] = this.similarVisualMotifs[id];
    }, 300);
  }
  getAssetURL(assetId: string) {
    return `${ASSETS_BASE_URL}/${assetId}.png`;
  }
  getVisualMotifURL(vmId: string) {
    return `${VISUAL_MOTIFS_BASE_URL}/${vmId}.png`;
  }
  get displayAssets() {
    return this.infographics[this.activeInfographic].assets;
  }
  get displayVM() {
    return this.displayVisualMotif !== null;
  }
  get displayVisualMotif() {
    return this.infographics[this.activeInfographic].visualMotifs;
  }
  get allSimilarAssets() {
    return this.infographics[this.activeInfographic].similarAssets || [];
  }
  get allSimilarVMs() {
    return this.infographics[this.activeInfographic].similarVisualMotifs || [];
  }
  get similarAssets() {
    return this.allSimilarAssets[this.activeAssetId];
  }
  get similarVisualMotifs() {
    return this.allSimilarVMs[this.activeVMId];
  }
}
