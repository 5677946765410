
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import Colour from "@/components/product/brandkit/Colour.vue";
import AddColour from "@/components/product/brandkit/AddColour.vue";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { DELETE_COLOUR_ACTION, UPDATE_COLOUR_ACTION } from "@/store/constants";

@Options({
  props: {
    paletteName: {
      type: String,
      required: true
    },
    colours: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  components: {
    Colour,
    AddColour
  },
  emits: {
    updatePaletteName: {},
    deletePalette: {}
  }
})
export default class ColourPaletteDialog extends Vue {
  // props
  colours!: string[];
  paletteName!: string;

  // locals
  subtitle = this.paletteName;
  activeColour: string | null = null;
  icons: { [id: string]: string } = {
    deletePalette:
      STATIC_ASSET_ICONS_BASE_URL +
      "/brandkit-colour-palette-delete-palette.svg"
  };

  // methods
  updatePaletteName() {
    const payload = {
      paletteName: this.paletteName,
      newPaletteName: this.subtitle
    };
    this.$emit("updatePaletteName", payload);
  }

  isValidPaletteName(paletteName: string): boolean {
    // check if alphanumeric, hyphens, underscores, parentheses, and periods
    const regex = /^[a-zA-Z0-9-_.,() ]+$/;
    return regex.test(paletteName);
  }

  async deleteColour(colour: string) {
    const payload = {
      paletteName: this.paletteName,
      colour
    };
    try {
      await this.$store.dispatch(DELETE_COLOUR_ACTION, payload);
    } catch (err) {
      throw err.response.data;
    }
  }

  toggleActive(colour: string) {
    if (this.activeColour === colour) {
      this.activeColour = null;
    } else {
      this.activeColour = colour;
    }
  }

  setInactive() {
    this.activeColour = null;
  }

  async updateColour({
    colour,
    newColour
  }: {
    colour: string;
    newColour: string;
  }) {
    // check colour is unique
    if (!this.isUnique(newColour)) {
      return;
    }

    const payload = {
      paletteName: this.paletteName,
      colour,
      newColour
    };
    try {
      await this.$store.dispatch(UPDATE_COLOUR_ACTION, payload);
    } catch (err) {
      throw err.response.data;
    }
  }

  isUnique(colour: string) {
    return this.colours.indexOf(colour) === -1;
  }

  deletePalette() {
    this.$emit("deletePalette", this.paletteName);
  }
}
