<template>
    <div class="section traveltellers_template">
        <div class="container tags_heading w-container">
            <div class="text-block-38">
                <h2 class="heading-9">DAY {{ dayName }}</h2>
                <h2 class="heading-27">{{ dayTitle }}</h2>
            </div>
        </div>
        <div class="container blog_posts w-container">
            <div class="blog-collection-list-wrapper w-dyn-list ">
                <div role="list" class="blog-collection-list">
                    <div role="listitem" v-for="content in contents" :key="content.name">
                        <div class="story_image on_blog_page full-width">
                            <div class="div-block-56">
                                <div class="top_content ">
                                    <div class="category-6 theme_background" :style="{
                                        backgroundColor: getCategoryColor(content.category)
                                    }">
                                        {{ content.category }}
                                    </div>
                                </div>
                                <div v-if="imageLoadStatus[content.name] === true">
                                    <img :src="content.imageUrl" sizes=" (max-width: 479px) 100vw, 100vw" loading="lazy"
                                        alt="Stage Image" class="image-24 responsive-image" />
                                </div>
                                <div v-else>
                                    <img :src="`https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventName}/images/Holding+Slide.png`"
                                        loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="Placeholder Image"
                                        class="image-39 responsive-image" />
                                </div>
                            </div>
                            <div class="story_content">
                                <div class="div-block-32">
                                    <div class="text_block talk-time left-talk-time">
                                        {{ formatDate(content.date) }}
                                    </div>
                                    <div class="text_block talk-time left-talk-time left-talk-time-time">
                                        {{ content.time }}
                                    </div>
                                </div>
                                <h4 class="heading-26">{{ content.heading }}</h4>
                                <div v-if="content.speakers && content.speakers.length"
                                    class="rich-text-block-4 w-richtext">
                                    <div v-for="(speaker, index) in content.speakers" :key="index">
                                        <strong>{{ speaker.name }}</strong> |
                                        {{ speaker.designation }} , {{ speaker.company }}<br />
                                    </div>
                                </div>
                            </div>
                            <router-link :to="generateEndpoint(content.name)"
                                class="link-block-13 w-inline-block"></router-link>
                        </div>
                    </div>
                </div>
                <div class="empty-state-3 w-dyn-empty" v-if="!hasContent">
                    <div>No items found.</div>
                </div>
            </div>
            <div class="sticky-nav-contain">
                <form :action="`/${eventName}/search`" class="search searchagenda w-form">
                    <input class="search-input searchagendatext w-input" maxlength="256" name="query"
                        placeholder="Search..." type="search" id="search" required />
                    <input type="submit" class="search-button w-button" value="⌕" />
                </form>
                <div class="sticky">
                    <h6 class="browse-headings">BROWSE BY session type</h6>
                    <div class="line div stickynavline"></div>
                    <div class="blog_categories_contain">
                        <div class="w-dyn-list">
                            <div role="list" class="category-tags-collection-list w-dyn-items">
                                <div role="listitem" v-for="(location, index) in uniqueLocations" :key="index"
                                    class="w-dyn-item">
                                    <router-link :to="`/${eventName}/stage/${location
                                        .toLowerCase()
                                        .replace(/ /g, '-')}`
                                        " class="link-block-14 w-inline-block">
                                        <div class="text-block-43">{{ location }}</div>
                                    </router-link>
                                </div>
                            </div>
                            <div class="w-dyn-empty" v-if="!uniqueLocations.length">
                                <div>No items found.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Day",
    inject: ["eventConfig"],
    props: {
        dayName: {
            type: String,
            required: true
        },
        eventName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dayTitle: "",
            contents: [],
            uniqueLocations: [],
            hasContent: true,
            data: null,
            imageLoadStatus: {}
        };
    },
    watch: {
        dayName: {
            immediate: true,
            async handler(newDay) {
                this.loadDayData(newDay);
                await this.checkImageStatus()
            }
        }
    },
    methods: {
        getCategoryColor(category) {
            const colors = this.eventConfig.locationColors
            return colors[category] || "#000000";
        },
        formatDate(date) {
            // Format the date as "dd MMM"
            const options = { day: "2-digit", month: "short" };
            return new Date(date).toLocaleDateString("en-US", options);
        },
        generateEndpoint(title) {
            return `/${this.eventName}/talk/${title
                .toLowerCase()
                .replace(/[^a-zA-Z0-9\s]/g, "")
                .replace(/\s+/g, "-")
                .trim()}`;
        },
        loadDayData(dayName) {
            if (!this.data) return;

            this.dayTitle = `Highlights for Day ${dayName}`;

            // Filter the data based on the dayName
            const filteredData = this.data.filter(
                item => item.day.toString() === dayName
            );


            this.contents = filteredData.map(item => ({
                name: item.title,
                category: item.location,
                imageUrl: item.imageUrl,
                time: item.time,
                timeDetail: `Duration: ${item.duration} minutes`,
                heading: item.title,
                description: item.description,
                link: item.source,
                speakers: item.speakers,
                date: item.date
            }));
            this.hasContent = this.contents.length > 0;
            if (!this.hasContent) {
                this.$router.push('/404');
                return;

            }
        },
        loadUniqueLocations() {
            if (!this.data) return;


            const seen = new Set();
            this.uniqueLocations = this.data
                .filter(item => {
                    const duplicate = seen.has(item.location);
                    seen.add(item.location);
                    return !duplicate;
                })
                .map(item => item.location);
        },
        preloadImage(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(true);
                img.onerror = () => reject(false);
            });
        },
        async checkImageStatus() {
            const statusPromises = this.contents.map(async (content) => {
                try {
                    await this.preloadImage(content.imageUrl);
                    this.imageLoadStatus[content.name] = true;
                } catch (error) {
                    console.error('e')
                    this.imageLoadStatus[content.name] = false;
                }
            });
            await Promise.all(statusPromises);
        },
        async fetchData() {
            try {
                const timestamp = new Date().getTime(); // Get the current time in milliseconds
                const response = await axios.get(
                    `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${this.eventName}/talk_descriptions.json?v=${timestamp}`
                );
                this.data = response.data;
                this.loadDayData(this.dayName);
                this.loadUniqueLocations();
            } catch (error) {
                console.error("Error fetching image data:", error);
            }
        }
    },
    async mounted() {
        await this.fetchData();
        await this.checkImageStatus()
    }
};
</script>

<style scoped>
.responsive-image {
    width: 100%;

    height: 200px;

    object-fit: cover;
}
</style>
