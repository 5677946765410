
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { LOCAL_ACCESS_KEY, LOCAL_REFRESH_KEY } from "@/services";
import {
  LOADED_GETTER,
  USER_GETTER,
  LOAD_STORE_ACTION
} from "@/store/constants.js";
import { ProjectStage } from "@/commons";
import PublicNavBar from "@/components/navbar/PublicNavBar.vue";
import ProductNavBar from "@/components/navbar/ProductNavBar.vue";
import BetaBanner from "./common/BetaBanner.vue";
import { RESET_STORE_ACTION } from "@/store/constants";
@Options({
  emits: {
    publish: {}
  },
  props: {
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    stage: {
      type: String as PropType<ProjectStage>,
      required: false
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      required: false
    },
    projectTitle: {
      type: String as PropType<string>,
      required: false
    }
  },
  components: {
    ProductNavBar,
    PublicNavBar,
    BetaBanner
  }
})
export default class NavBar extends Vue {
  // Props
  loggedIn!: boolean;
  stage?: ProjectStage;
  isLoading?: boolean;
  projectTitle?: string;

  // Imported commons
  ProjectStage = ProjectStage;

  // Mounted
  async mounted() {
    if (this.loggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }
  }

  // Computed Properties
  get loaded() {
    return this.$store.getters[LOADED_GETTER];
  }
  get user() {
    return this.loggedIn ? this.$store.getters[USER_GETTER] : {};
  }

  // Methods
  login() {
    this.$router.push({ name: "Login" });
  }
  logout() {
    if (localStorage.getItem(LOCAL_ACCESS_KEY)) {
      localStorage.removeItem(LOCAL_ACCESS_KEY);
    }
    if (localStorage.getItem(LOCAL_REFRESH_KEY)) {
      localStorage.removeItem(LOCAL_REFRESH_KEY);
    }
    this.$store.dispatch(RESET_STORE_ACTION);
    if (this.$route.name === "Home") {
      location.reload();
    } else {
      this.$router.push({ name: "Home" });
    }
  }
  backToHome() {
    this.$router.push({ name: "Home" });
  }
  goToPricing() {
    this.$router.push({ name: "Pricing" });
  }
  publish(modalElement: Element) {
    this.$emit("publish", modalElement);
  }
}
