
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import { Project, ProjectType, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { ButtonColour } from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";
import { DELETE_PROJECT_ACTION } from "@/store/constants.js";

@Options({
  emits: {
    trash: {},
    restore: {},
    updateProjectsState: {}
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    },
    activeFolder: {
      type: Number as PropType<ProjectType>,
      required: true
    }
  },
  components: {
    Modal
  }
})
export default class InfographicDropdown extends Vue {
  ProjectType = ProjectType;
  ButtonColour = ButtonColour;
  project!: Project;
  activeFolder!: ProjectType;

  icons: { [id: string]: string } = {
    edit: STATIC_ASSET_ICONS_BASE_URL + "/infographic-dropdown-edit.svg",
    externalLink:
      STATIC_ASSET_ICONS_BASE_URL + "/infographic-dropdown-external-link.svg",
    star: STATIC_ASSET_ICONS_BASE_URL + "/infographic-dropdown-star.svg",
    trash: STATIC_ASSET_ICONS_BASE_URL + "/infographic-dropdown-delete.svg",
    restore: STATIC_ASSET_ICONS_BASE_URL + "/infographic-dropdown-restore.svg"
  };

  get modalElement() {
    return document.querySelector("#delete-project-modal");
  }

  get dropdownElement() {
    return document.querySelector(".infographic__dropdown");
  }

  goToProductRoute(productStage: string) {
    const path = `/project/${this.project.uuid}/${productStage}`;
    this.$router.push({ path: path });
  }
  moveToTrash() {
    this.$emit("trash", this.project.uuid);
  }
  restoreProject() {
    this.$emit("restore", this.project.uuid);
  }
  openModal() {
    if (this.dropdownElement) {
      UIkit.dropdown(this.dropdownElement).hide();
    }
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }
  closeModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).hide();
    }
  }
  async deletePermanently() {
    try {
      await this.$store.dispatch(DELETE_PROJECT_ACTION, this.project.uuid);
      this.closeModal();
      this.$emit("updateProjectsState");
    } catch (err) {
      throw err.response.data;
    }
  }
}
