import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_BetaIntro = _resolveComponent("BetaIntro")
  const _component_BetaDescription = _resolveComponent("BetaDescription")
  const _component_BetaFormats = _resolveComponent("BetaFormats")
  const _component_BetaCallToAction = _resolveComponent("BetaCallToAction")
  const _component_BetaMission = _resolveComponent("BetaMission")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_NavBar, { "logged-in": _ctx.isLoggedIn }, null, 8, ["logged-in"]),
    _createVNode(_component_BetaIntro),
    _createVNode(_component_BetaDescription),
    _createVNode(_component_BetaFormats),
    _createVNode(_component_BetaCallToAction),
    _createVNode(_component_BetaMission),
    _createVNode(_component_Footer)
  ], 64))
}