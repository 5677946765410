
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import { loadStripe } from "@stripe/stripe-js";
import { CHECKOUT_SESSION, STRIPE_PUBLIC_KEY } from "@/services";
import { UPGRADE_PROJECT_ACTION, USER_GETTER } from "@/store/constants.js";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import {
  Infographic,
  ARTS_COST,
  PACKAGES,
  PackageTier,
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL
} from "@/commons";
import api from "@/services/api";

@Options({
  props: {
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    loaded: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    infographics: {
      type: Array as PropType<Infographic[]>,
      required: true
    }
  },
  components: {
    ThothButton
  }
})
export default class PreviewInfographic extends Vue {
  // Props
  loggedIn!: boolean;

  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  packages = PACKAGES;
  artsCost = ARTS_COST;

  // Data
  session = "";
  isLoading = false;
  images: { [id: string]: string } = {
    upgradeInfoCheck: STATIC_ASSET_ICONS_BASE_URL + "/Check.svg",
    backgroundImage: STATIC_ASSET_IMAGES_BASE_URL + "/Preview-Background.svg"
  };
  features: string[] = [
    "Access to >500 hand-drawn illustrations",
    "Unlimited once-off text edits",
    "Automated infographic layouts",
    "Auto-applied brand kits"
  ];

  // Computed Properties
  get user() {
    return this.loggedIn ? this.$store.getters[USER_GETTER] : {};
  }

  get sufficientCredits() {
    return this.$store.getters[USER_GETTER].credits >= ARTS_COST;
  }

  // Mounted
  mounted() {
    const stripe = document.createElement("script");
    stripe.setAttribute("src", "https://js.stripe.com/v3/");
    document.head.appendChild(stripe);
  }

  // Methods
  async checkOut(tier: PackageTier) {
    if (!this.loggedIn) {
      this.$router.push({ name: "Login" });
    }
    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
    const stripe = await stripePromise;

    try {
      const response = await api.post(
        CHECKOUT_SESSION + "/" + tier,
        STRIPE_PUBLIC_KEY
      );
      this.session = response.data;
      if (stripe == null) {
        console.error("No stripe object awaited!");
        return;
      }
      return stripe.redirectToCheckout({
        sessionId: this.session
      });
    } catch (err) {
      console.log(err);
    }
  }

  optionText(price) {
    return "USD $" + price.toLocaleString("en-SG");
  }
  get modalElement() {
    return document.querySelector("#upgrade-modal");
  }
  openModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }
  async upgradeProject() {
    this.isLoading = true;
    const { projectId } = this.$route.params;
    try {
      await this.$store.dispatch(UPGRADE_PROJECT_ACTION, projectId);
      if (this.modalElement) {
        UIkit.modal(this.modalElement).hide();
      }
      this.isLoading = false;
      this.$router.push({ path: `/project/${projectId}/edit` });
    } catch (err) {
      console.log(err);
      this.isLoading = false;
    }
  }
}
