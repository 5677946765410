
import { Vue } from "vue-class-component";
import {
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL
} from "@/commons";
export default class PreviewModes extends Vue {
  images: { [id: string]: string } = {
    upgradeInfoMain:
      STATIC_ASSET_IMAGES_BASE_URL + "/upgrade-Infographic-main.png",
    upgradeInfoCheck: STATIC_ASSET_ICONS_BASE_URL + "/Check.svg"
  };
  features: string[] = [
    "Access to >500 hand-drawn illustrations",
    "Unlimited once-off text edits",
    "Automated infographic layouts",
    "Auto-applied brand kits"
  ];
}
