
import { Vue, Options } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
@Options({
  props: {
    flip: {
      type: String as PropType<string>,
      required: true
    },
    option1: {
      type: String as PropType<string>,
      required: true
    },
    option2: {
      type: String as PropType<string>,
      required: true
    },
    ignoreRatio: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    }
  }
})
export default class TextToggleSwitch extends Vue {
  option1!: string;
  option2!: string;
  ignoreRatio!: boolean;
  ratio =
    (this.ignoreRatio ? 1 : this.option1.length / this.option2.length) * 50;
  styles = {
    width: `calc( ((100% - 72px) * ${this.ratio * 0.01}) + 36px)`
  };
  clicked = false;

  flipHandler(status: string) {
    this.clicked = true;
    if (status === this.option2) {
      const tempRatio = 100 - this.ratio;
      this.styles = {
        width: `calc( ((100% - 72px) * ${tempRatio} * 0.01) + 36px)`
      };
    } else {
      this.styles = {
        width: `calc( ((100% - 72px) * ${this.ratio} * 0.01) + 36px)`
      };
    }

    this.$emit("toggle", status);
  }
  testRatioGap = this.ratio > 50 ? this.ratio : 100 - this.ratio;
}
