
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_ICONS_BASE_URL, SocialType } from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";
import { REVERT_BRANDLINK_ACTION } from "@/store/constants";
import UIkit from "uikit";

@Options({
  props: {
    url: {
      type: String,
      required: false
    },
    social: {
      type: String,
      required: true
    }
  },
  components: {
    ThothButton,
    Modal
  },
  emits: {
    updateBrandLink: {},
    deleteBrandLink: {}
  }
})
export default class BrandLink extends Vue {
  // commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  social!: SocialType;
  url!: string;

  // states
  icons: { [id: string]: string } = {
    facebook: STATIC_ASSET_ICONS_BASE_URL + "/facebook.svg",
    linkedin: STATIC_ASSET_ICONS_BASE_URL + "/linkedin.svg",
    website: STATIC_ASSET_ICONS_BASE_URL + "/website.svg",
    twitter: STATIC_ASSET_ICONS_BASE_URL + "/twitter.svg",
    instagram: STATIC_ASSET_ICONS_BASE_URL + "/instagram.svg",
    trash:
      STATIC_ASSET_ICONS_BASE_URL +
      "/brandkit-colour-palette-delete-palette.svg"
  };
  placeholderText = {
    facebook: "company-name",
    linkedin: "company/company-name",
    twitter: "username",
    instagram: "username",
    website: "yourwebsiteurl.com"
  };
  prefix = {
    facebook: "https://facebook.com/",
    linkedin: "https://linkedin.com/",
    twitter: "@",
    instagram: "@",
    website: "https://"
  };
  inputIsOnFocus = false;
  brandLinkToUpdate = { social: this.social, url: this.url };

  mounted() {
    // automatically focus if user is entering fresh information
    if (this.url === "") {
      this.focusInput();
    }
  }

  // Computed Properties
  get deleteModalText() {
    return `You're about to delete <b>${this.prefix[this.social]}${
      this.url
    }</b>. You will not be able to recover it if you change your mind.`;
  }

  get deleteModalElement() {
    return document.querySelector(`#${this.social}__modal--delete`);
  }

  get warningModalElement() {
    return document.querySelector(`#${this.social}__modal--warning`);
  }

  get inputElement() {
    return this.$refs[this.social] as HTMLInputElement;
  }

  get addButtonIsDisabled() {
    return this.brandLinkToUpdate.url === "" && this.url === "" ? true : false;
  }

  get cancel() {
    return this.url === ""
      ? this.closeDeleteModalAndFocus
      : this.closeDeleteModal;
  }

  // Methods
  focusInput() {
    this.inputElement.focus();
  }
  closeEditMode() {
    this.inputIsOnFocus = false;
  }
  openDeleteModal() {
    this.inputIsOnFocus = false;
    if (this.deleteModalElement) {
      UIkit.modal(this.deleteModalElement).show();
    }
  }
  openWarningModal() {
    if (this.warningModalElement) {
      UIkit.modal(this.warningModalElement).show();
    }
  }
  closeWarningModal() {
    if (this.warningModalElement) {
      UIkit.modal(this.warningModalElement).hide();
    }
  }
  deleteBrandLink() {
    this.$emit("deleteBrandLink", {
      social: this.brandLinkToUpdate.social,
      url: null
    });
    this.closeDeleteModal();
  }
  // Delete Modal
  closeDeleteModal() {
    if (this.deleteModalElement) {
      UIkit.modal(this.deleteModalElement).hide();
    }
  }
  closeDeleteModalAndFocus() {
    this.closeDeleteModal();
    this.focusInput();
  }
  // Warning Modal
  closeWarningModalAndFocusInput() {
    this.closeWarningModal();
    this.focusInput();
  }
  closeWarningModalAndRevertBrandLink() {
    this.closeWarningModal();
    this.revertBrandLinkAndClose();
  }
  // Add/Update/Cancel Buttons
  updateBrandLink() {
    this.closeEditMode();
    this.$emit("updateBrandLink", this.brandLinkToUpdate);
  }
  revertBrandLinkAndClose() {
    if (this.url === "") {
      this.inputIsOnFocus = false;
      this.deleteBrandLink();
      return;
    }
    const payload = {
      social: this.social,
      oldUrl: this.url,
      newUrl: this.inputElement.value
    };
    this.$store.dispatch(REVERT_BRANDLINK_ACTION, payload);
    this.closeEditMode();
  }
  // On Blur
  clickOutsideInput() {
    setTimeout(() => {
      // Do not execute function on blur if a button was clicked
      if (this.inputIsOnFocus) {
        if (this.inputElement.value === this.url) {
          if (this.url === "") {
            this.deleteBrandLink();
          }
          this.closeEditMode();
        } else {
          this.openWarningModal();
        }
      }
    }, 100);
  }
}
