
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import UIkit from "uikit";
import { LOCAL_ACCESS_KEY, LOCAL_REFRESH_KEY } from "@/services";
import { PROFILE_GETTER } from "@/store/constants.js";
import {
  EditMode,
  ProjectStage,
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL,
  User
} from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";
import {
  SET_EDIT_MODE_ACTION,
  UNDO_VERSION_ACTION,
  REDO_VERSION_ACTION
} from "@/store/constants";
import { RESET_STORE_ACTION } from "@/store/constants";
@Options({
  emits: {
    publish: {}
  },
  props: {
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    loaded: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      required: false
    },
    user: {
      type: Object as PropType<User>,
      required: true
    },
    stage: {
      type: String as PropType<ProjectStage>,
      required: true
    },
    projectTitle: {
      type: String as PropType<string>,
      required: false
    }
  },
  components: {
    ThothButton,
    Modal
  }
})
export default class ProductNavBar extends Vue {
  // Props
  loggedIn!: boolean;
  loaded!: boolean;
  user!: User;

  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  EditMode = EditMode;
  // Data
  icons: { [id: string]: string } = {
    chevronDownWhite:
      STATIC_ASSET_ICONS_BASE_URL + "/navbar-chevron-down-white.svg",
    plus: STATIC_ASSET_ICONS_BASE_URL + "/dashboard-plus.svg",
    logout: STATIC_ASSET_ICONS_BASE_URL + "/navbar-logout.svg",
    credit: STATIC_ASSET_ICONS_BASE_URL + "/navbar-coin-vertical.svg",
    settings: STATIC_ASSET_ICONS_BASE_URL + "/navbar-user-circle.svg",
    undo: STATIC_ASSET_ICONS_BASE_URL + "/undo-white-icon.svg",
    redo: STATIC_ASSET_ICONS_BASE_URL + "/redo-white-icon.svg"
  };
  thothLogo = STATIC_ASSET_IMAGES_BASE_URL + "/navbar-th-logo-white.svg";

  modalText =
    "Your project will be <b>LOCKED</b>, and you won't be able to make any further edits. Are you sure you wish to proceed?";
  modalImage = STATIC_ASSET_IMAGES_BASE_URL + "/publish-modal-image.png";

  get modalElement() {
    return document.querySelector("#publish__modal");
  }

  get profile() {
    return this.loggedIn ? this.$store.getters[PROFILE_GETTER] : {};
  }

  truncate(profile) {
    const profileTokenLimit = 25;
    const profileString = profile.firstName.concat(" ", profile.lastName);
    return profileString.length > profileTokenLimit
      ? profileString.slice(0, profileTokenLimit - 4) + "..."
      : profileString;
  }

  // Methods
  logout() {
    if (localStorage.getItem(LOCAL_ACCESS_KEY)) {
      localStorage.removeItem(LOCAL_ACCESS_KEY);
    }
    if (localStorage.getItem(LOCAL_REFRESH_KEY)) {
      localStorage.removeItem(LOCAL_REFRESH_KEY);
    }
    this.$store.dispatch(RESET_STORE_ACTION);
    if (this.$route.name === "Home") {
      location.reload();
    } else {
      this.$router.push({ name: "Home" });
    }
  }
  openPublishModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }
  closePublishModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).hide();
    }
  }
  publishInfographic() {
    this.$emit("publish", this.modalElement);
    this.$store.dispatch(SET_EDIT_MODE_ACTION, EditMode.None);
  }
  openCloseVersionHistory() {
    this.$store.dispatch(SET_EDIT_MODE_ACTION, EditMode.Version);
  }
  undoVersionHandler() {
    this.$store.dispatch(UNDO_VERSION_ACTION);
  }
  redoVersionHandler() {
    this.$store.dispatch(REDO_VERSION_ACTION);
  }
}
