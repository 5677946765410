
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { EditMode, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";

interface EditOption {
  mode: EditMode;
  icon: string;
  iconSelected: string;
  text: string;
}

@Options({
  props: {
    editMode: {
      type: Number as PropType<EditMode>,
      required: true
    },
    selectEditMode: {
      type: Function as PropType<() => void>,
      required: true
    },
    isAdmin: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
})
export default class EditNavBar extends Vue {
  EditMode = EditMode;
  editMode!: EditMode;
  isAdmin!: boolean;

  icons: { [id: string]: string } = {
    layout: STATIC_ASSET_ICONS_BASE_URL + "/layout-edit-icon-grey.svg",
    layoutWhite: STATIC_ASSET_ICONS_BASE_URL + "/layout-edit-icon-white.svg",
    text: STATIC_ASSET_ICONS_BASE_URL + "/text-edit-icon-grey.svg",
    textWhite: STATIC_ASSET_ICONS_BASE_URL + "/text-edit-icon-white.svg",
    brandKit: STATIC_ASSET_ICONS_BASE_URL + "/brand-kit-edit-icon-grey.svg",
    brandKitWhite:
      STATIC_ASSET_ICONS_BASE_URL + "/brand-kit-edit-icon-white.svg",
    visuals: STATIC_ASSET_ICONS_BASE_URL + "/visuals-edit-icon-grey.svg",
    visualsWhite: STATIC_ASSET_ICONS_BASE_URL + "/visuals-edit-icon-white.svg"
  };
  get editOptions(): EditOption[] {
    const publicOptions: EditOption[] = [
      {
        mode: EditMode.Text,
        icon: this.icons.text,
        iconSelected: this.icons.textWhite,
        text: "Text"
      },
      {
        mode: EditMode.Layout,
        icon: this.icons.layout,
        iconSelected: this.icons.layoutWhite,
        text: "Layout"
      },
      {
        mode: EditMode.Visuals,
        icon: this.icons.visuals,
        iconSelected: this.icons.visualsWhite,
        text: "Visuals"
      },
      {
        mode: EditMode.BrandKit,
        icon: this.icons.brandKit,
        iconSelected: this.icons.brandKitWhite,
        text: "Brand Kit"
      }
    ];
    return publicOptions;
  }
}
