import { ObjectHTMLAttributes } from "vue";
import { VIEW_INFOGRAPHIC_API } from "@/services";

export interface ChromePickerColour {
  a: number;
  hex: string;
  hex8: string;
  hsl: object;
  hsv: object;
  oldHue: number;
  rgba: object;
  source: string;
}

export interface Logo {
  uuid: string;
  filename: string;
  s3Url: string;
}

export interface ColourPalette {
  paletteName: string;
  colours: string[];
}
export interface BrandLinks {
  website: string | null;
  linkedin: string | null;
  twitter: string | null;
  instagram: string | null;
  facebook: string | null;
}

export interface User {
  email: string;
  credits: number;
  logos: Logo[];
  colourPalettes: ColourPalette[];
  brandLinks: BrandLinks;
  profile: UserProfile;
}

export interface UserProfile {
  firstName: string;
  lastName: string;
}

export interface Project {
  uuid: string;
  mode: string;
  status: string;
  state: string;
  infographics: Infographic[];
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface ProjectCount {
  draft: number;
  downloaded: number;
  trash: number;
}

export enum SocialTypes {
  WEBSITE = "website",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  LINKEDIN = "linkedin",
}

export interface Infographic {
  id?: string;
  title: string;
  summary: string[];
  brandColour: string;
  brandLogoUrl?: string;
  density: string;
  assets: string[];
  visualMotifs: string[];
  similarAssets?: string[][];
  similarVisualMotifs?: string[][];
  socialType?: SocialTypes | null;
  socialText?: string | null;
  layout: string;
  url: string;
  maxCharactersTitle: number;
  maxCharactersSummary: number[];
}

export enum ProjectType {
  Draft,
  Downloaded,
  Trash,
}

export enum CanvasMode {
  Editing,
  LoadingColour,
  LoadingText,
  LoadingElements,
  LoadingLogo,
  LoadingBrandLink,
}

export enum EditMode {
  None,
  Layout,
  Text,
  BrandKit,
  Elements,
  Visuals,
  Version,
}

export enum BrandKitMode {
  Main,
  Colour,
  Logos,
  BrandLinks,
}

export interface ActiveColour {
  id: string;
  hex: string;
}

export interface ActiveBrandLink {
  type: SocialTypes | null;
  text: string | null;
}

export const presetDefaults: string[] = [
  "#ec2022",
  "#ea6b22",
  "#057c48",
  "#2a53c6",
  "#027de7",
  "#fe646a",
  "#efa702",
  "#08c472",
  "#4083ff",
  "#01b2e1",
];

export enum ProjectStage {
  Dashboard = "DASHBOARD",
  Create = "CREATE",
  Preview = "PREVIEW",
  Edit = "EDIT",
  Publish = "PUBLISH",
}

export enum CreationStatus {
  BeforeUpload,
  Uploading,
  AfterUpload,
  GeneratingInfographic,
}

export enum PackageTier {
  ONE_INFOGRAPHIC = "ONE",
  FIVE_INFOGRAPHICS = "FIVE",
  ELEVEN_INFOGRAPHICS = "ELEVEN",
}

export enum SocialType {
  WEBSITE = "website",
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  LINKEDIN = "linkedin",
  INSTAGRAM = "instagram",
}

export interface Package {
  name: string;
  numInfographics: number;
  price: string;
  bonus?: string;
  tier: PackageTier;
  comment?: string;
  image: string;
}

export const ARTS_COST = 1;

export enum Links {
  INSTAGRAM = "http://instagram.com/th0th.ai",
  LINKEDIN = "https://www.linkedin.com/company/thoth-ai",
  TWITTER = "https://X.com/_thothAI",
  FACEBOOK = "https://www.facebook.com/IdeaInkIllustrations/",
  IDEAINK = "https://www.ideaink.co",
}

export const ASSETS_BASE_URL =
  "https://thoth-assets.s3.ap-southeast-1.amazonaws.com";

export const VISUAL_MOTIFS_BASE_URL =
  "https://thoth-visual-motifs.s3.ap-southeast-1.amazonaws.com";

export const STATIC_ASSET_IMAGES_BASE_URL =
  "https://thoth-static-assets.s3.ap-southeast-1.amazonaws.com/assets/images";

export const STATIC_ASSET_ANIMATE_BASE_URL =
  "https://thoth-static-assets.s3.ap-southeast-1.amazonaws.com/assets/animate";

export const STATIC_ASSET_VIDEO_BASE_URL =
  "https://thoth-static-assets.s3.ap-southeast-1.amazonaws.com/assets/video";

export const STATIC_ASSET_ICONS_BASE_URL =
  "https://thoth-static-assets.s3.ap-southeast-1.amazonaws.com/assets/images/icons";

export const USER_INFOGRAPHICS_BASE_URL = VIEW_INFOGRAPHIC_API;

export const PACKAGES: Package[] = [
  {
    name: "package-1",
    numInfographics: 1,
    price: "100",
    tier: PackageTier.ONE_INFOGRAPHIC,
    image: STATIC_ASSET_IMAGES_BASE_URL + "/infographic-1.png",
  },
  {
    name: "package-2",
    numInfographics: 5,
    price: "500",
    tier: PackageTier.FIVE_INFOGRAPHICS,
    image: STATIC_ASSET_IMAGES_BASE_URL + "/infographic-5.png",
  },
  {
    name: "package-3",
    numInfographics: 11,
    price: "1,000",
    bonus: "10%",
    tier: PackageTier.ELEVEN_INFOGRAPHICS,
    image: STATIC_ASSET_IMAGES_BASE_URL + "/infographic-11.png",
  },
];

export enum TypeformOpenMode {
  TIME = "time",
  LOAD = "load",
}

export interface TypeformPopupConfig {
  openMode: TypeformOpenMode;
  openDelay?: number;
  modalSize: number;
  projectId: string;
  userEmail: string;
}

export interface VersionData {
  dateTime: string;
  name: string;
  infographicId: string;
  previewImage: string;
}
