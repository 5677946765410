import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bk-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logos = _resolveComponent("Logos")
  const _component_ColourPalettes = _resolveComponent("ColourPalettes")
  const _component_BrandLinksSection = _resolveComponent("BrandLinksSection")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Logos, {
      logos: _ctx.user.logos
    }, null, 8, ["logos"]),
    _createVNode(_component_ColourPalettes, {
      colourPalettes: _ctx.user.colourPalettes
    }, null, 8, ["colourPalettes"]),
    _createVNode(_component_BrandLinksSection, {
      brandLinks: _ctx.user.brandLinks
    }, null, 8, ["brandLinks"])
  ]))
}