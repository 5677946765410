
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { configure, Field, ErrorMessage } from "vee-validate";

configure({ bails: false });

@Options({
  props: {
    title: {
      type: String,
      required: true
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    helper: {
      type: String,
      required: false
    },
    iconHelper: {
      type: String,
      required: false
    },
    rules: {
      type: [Function, String] as PropType<() => void | string>,
      required: true
    },
    backendError: {
      type: String,
      required: false
    },
    autocompleteType: {
      type: String,
      required: false,
      default: "off"
    }
  },
  components: {
    Field,
    ErrorMessage
  }
})
export default class TextField extends Vue {
  icons: { [id: string]: string } = {
    error: STATIC_ASSET_ICONS_BASE_URL + "/form-error.svg"
  };
  field = { userInput: "" };
}
