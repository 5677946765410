import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileBlocker = _resolveComponent("MobileBlocker")
  const _component_DashboardNavBar = _resolveComponent("DashboardNavBar")
  const _component_EditBrandKit = _resolveComponent("EditBrandKit")
  const _component_HelpFloatingButton = _resolveComponent("HelpFloatingButton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_MobileBlocker),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_DashboardNavBar, {
          key: 0,
          class: "container__mobile__blocker",
          "logged-in": _ctx.isLoggedIn
        }, null, 8, ["logged-in"]))
      : _createCommentVNode("", true),
    _createVNode(_component_EditBrandKit, { class: "container__mobile__blocker" }),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_HelpFloatingButton, {
          key: 1,
          class: "container__mobile__blocker",
          "user-email": _ctx.user.email
        }, null, 8, ["user-email"]))
      : _createCommentVNode("", true)
  ], 64))
}