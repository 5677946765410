import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_AIEngineerWorldFairCaseStudy = _resolveComponent("AIEngineerWorldFairCaseStudy")
  const _component_HaymarketMediaCaseStudy = _resolveComponent("HaymarketMediaCaseStudy")
  const _component_RevConCaseStudy = _resolveComponent("RevConCaseStudy")
  const _component_IMASCaseStudy = _resolveComponent("IMASCaseStudy")
  const _component_STBCaseStudy = _resolveComponent("STBCaseStudy")
  const _component_TatlerGenTCaseStudy = _resolveComponent("TatlerGenTCaseStudy")
  const _component_SandPGlobalPlattsCaseStudy = _resolveComponent("SandPGlobalPlattsCaseStudy")
  const _component_SFF2022CaseStudy = _resolveComponent("SFF2022CaseStudy")
  const _component_HustleFundCaseStudy = _resolveComponent("HustleFundCaseStudy")
  const _component_KPMGCaseStudy = _resolveComponent("KPMGCaseStudy")
  const _component_SWITCH2021CaseStudy = _resolveComponent("SWITCH2021CaseStudy")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_NavBar, { "logged-in": _ctx.isLoggedIn }, null, 8, ["logged-in"]),
    (this.$route.params.caseStudy == 'ai-engineer-world-fair')
      ? (_openBlock(), _createBlock(_component_AIEngineerWorldFairCaseStudy, { key: 0 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'camapaign-360-haymarket-media')
      ? (_openBlock(), _createBlock(_component_HaymarketMediaCaseStudy, { key: 1 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'revcon')
      ? (_openBlock(), _createBlock(_component_RevConCaseStudy, { key: 2 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'investment-management-association-of-singapore')
      ? (_openBlock(), _createBlock(_component_IMASCaseStudy, { key: 3 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'stb-tourism-data-leadership-conference')
      ? (_openBlock(), _createBlock(_component_STBCaseStudy, { key: 4 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'tatler-gen-t-summit-2023')
      ? (_openBlock(), _createBlock(_component_TatlerGenTCaseStudy, { key: 5 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 's-and-p-global-platts-appec-2023')
      ? (_openBlock(), _createBlock(_component_SandPGlobalPlattsCaseStudy, { key: 6 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'singapore-fintech-festival-2022')
      ? (_openBlock(), _createBlock(_component_SFF2022CaseStudy, { key: 7 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'hustle-fund')
      ? (_openBlock(), _createBlock(_component_HustleFundCaseStudy, { key: 8 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'kpmg')
      ? (_openBlock(), _createBlock(_component_KPMGCaseStudy, { key: 9 }))
      : _createCommentVNode("", true),
    (this.$route.params.caseStudy == 'switch-2021')
      ? (_openBlock(), _createBlock(_component_SWITCH2021CaseStudy, { key: 10 }))
      : _createCommentVNode("", true)
  ], 64))
}