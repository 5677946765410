
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import CreateInfographic from "@/components/product/CreateInfographic.vue";
import HelpFloatingButton from "@/components/HelpFloatingButton.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { USER_GETTER, LOAD_STORE_ACTION } from "@/store/constants.js";
import { ProjectStage, CreationStatus } from "@/commons";
import MobileBlocker from "@/components/common/MobileBlocker.vue";
@Options({
  components: {
    NavBar,
    CreateInfographic,
    HelpFloatingButton,
    MobileBlocker
  }
})
export default class Create extends Vue {
  // Imported commons
  ProjectStage = ProjectStage;
  CreationStatus = CreationStatus;

  // States
  isLoggedIn = false;
  loaded = false;
  status = CreationStatus.BeforeUpload;

  get user() {
    return this.$store.getters[USER_GETTER];
  }

  // Mounted
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (!this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }

  // Methods
  updateStatus(newStatus: CreationStatus) {
    this.status = newStatus;
  }
}
