
import { Options, Vue } from "vue-class-component";
import UIkit from "uikit";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";

@Options({
  components: {
    ThothButton
  },
  props: {
    disable: {
      type: Boolean,
      required: true
    }
  }
})
export default class BrandKitModal extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  disable!: boolean;

  // States

  // Methods
  mounted() {
    if (!this.disable) {
      this.openModal();
    }
  }
  get modalElement() {
    return document.querySelector("#bk__modal");
  }
  openModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }
  closeModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).hide();
    }
  }
}
