
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import {
  STATIC_ASSET_ICONS_BASE_URL,
  STATIC_ASSET_IMAGES_BASE_URL,
  PACKAGES,
  PackageTier
} from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import api from "@/services/api";
import { authenticate, LOCAL_ACCESS_KEY, STRIPE_PUBLIC_KEY } from "@/services";
import { CHECKOUT_SESSION } from "@/services";
import { loadStripe } from "@stripe/stripe-js";

@Options({
  components: {
    ThothButton
  },
  props: {
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
})
export default class PreviewModes extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  packages = PACKAGES;
  // locals

  images: { [id: string]: string } = {
    infoIcon: STATIC_ASSET_ICONS_BASE_URL + "/info.svg",
    thothCredit: STATIC_ASSET_ICONS_BASE_URL + "/thoth-credit.svg",
    backgroundLeft:
      STATIC_ASSET_IMAGES_BASE_URL + "/Pricing-Background_Left.png",
    backgroundRight:
      STATIC_ASSET_IMAGES_BASE_URL + "/Pricing-Background_Right.png"
  };

  isLoggedIn = false;
  session = "";

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
  }
  async checkOut(tier: PackageTier) {
    if (!this.isLoggedIn) {
      this.$router.push({ name: "Login", query: { redirect: "/pricing" } });
    }
    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
    const stripe = await stripePromise;

    api
      .post(CHECKOUT_SESSION + "/" + tier, STRIPE_PUBLIC_KEY)
      .then(response => {
        this.session = response.data;
        if (stripe == null) {
          console.error("No stripe object awaited!");
          return;
        }
        return stripe.redirectToCheckout({
          sessionId: this.session
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
}
