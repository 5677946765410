
import loadingAnimation from "@/assets/animate/lie_conveyor_horizontal.json";
import { EXTRACT_TEXT_API } from "@/services";
import {
  CreationStatus,
  STATIC_ASSET_IMAGES_BASE_URL,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { GENERATE_INFOGRAPHICS_ACTION } from "@/store/constants.js";
import { PropType } from "@vue/runtime-core";
import { Dropzone } from "dropzone";
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import TextExtraction from "./TextExtraction.vue";
import Toast from "@/components/common/Toast.vue";
import UIkit from "uikit";
import api from "@/services/api";
@Options({
  props: {
    status: {
      type: Number as PropType<CreationStatus>,
      required: true
    },
    method: { type: Function }
  },
  emits: {
    updateStatus: {}
  },
  components: {
    ThothButton,
    lottie,
    TextExtraction,
    Toast
  }
})
export default class CreateInfographic extends Vue {
  ButtonColour = ButtonColour;
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  CreationStatus = CreationStatus;
  status!: CreationStatus;

  // Data
  images: { [id: string]: string } = {
    cardImage:
      STATIC_ASSET_IMAGES_BASE_URL + "/create-infographic-card-image.svg",
    pdfImage: STATIC_ASSET_ICONS_BASE_URL + "/pdf.svg",
    docImage: STATIC_ASSET_ICONS_BASE_URL + "/doc.svg",
    txtImage: STATIC_ASSET_ICONS_BASE_URL + "/txt.svg",
    mp3Image: STATIC_ASSET_ICONS_BASE_URL + "/mp3.svg",
    mp4Image: STATIC_ASSET_ICONS_BASE_URL + "/mp4.svg",
    leftBg: STATIC_ASSET_IMAGES_BASE_URL + "/left-bg.png",
    leftArrow: STATIC_ASSET_ICONS_BASE_URL + "/arrow-left.svg",
    textExtractionLoader:
      STATIC_ASSET_ICONS_BASE_URL + "/text-extraction-loader.svg"
  };
  uploadedFile?: File;
  hints = [
    "Full paragraphs are better than bullet points",
    "Tables, charts, and graphs will be excluded",
    "Text errors will be inherited in infographic"
  ];

  animationOptions = {
    animationData: loadingAnimation,
    loop: true
  };

  fileExtractionTitle = "";
  fileExtractionBody = "";

  uploadPercentage = 0;

  config = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.uploadPercentage = percentCompleted;
      return percentCompleted;
    }
  };

  formData = new FormData();

  toastProps = { message: "" };

  keyWordsData: Array<string> = [];

  mounted() {
    const dropRef = document.querySelector("#dropzone");

    if (dropRef !== null) {
      this.dropzone = new Dropzone(dropRef, {
        autoQueue: true,
        url: "/",
        clickable: false,
        disablePreviews: true,
        acceptedFiles:
          "application/pdf, audio/mpeg, video/mp4, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        accept: file => {
          this.uploadedFile = file || undefined;
          if (!this.uploadedFile) {
            console.error("An error occurred.");
          } else {
            this.$emit("updateStatus", CreationStatus.Uploading);
            this.formData.append("file", this.uploadedFile);
            api
              .post(EXTRACT_TEXT_API, this.formData, this.config)
              .then(response => {
                if (response) {
                  const { title, text, keywords } = response.data.data[0];
                  this.fileExtractionBody = this.trimExtraWhiteSpace(text);
                  if (text.startsWith("ERROR")) {
                    this.uploadedFile = undefined;
                    this.toastProps.message =
                      "Something went wrong. Try again or contract the administrator.";
                    this.toggleToast();
                    this.$emit("updateStatus", CreationStatus.BeforeUpload);
                  } else {
                    this.$emit("updateStatus", CreationStatus.AfterUpload);
                    this.toastProps.message =
                      "Successfully extracted text! Edit your document or generate your infographic!";
                    this.toggleToast();
                    this.fileExtractionTitle = title;
                    this.fileExtractionBody = this.trimExtraWhiteSpace(text);
                    this.uploadedFile = undefined;
                    this.keyWordsData = keywords;
                  }
                }
              })
              .catch(error => {
                this.$emit("updateStatus", CreationStatus.BeforeUpload);
                this.toastProps.message =
                  this.trimExtraWhiteSpace(error.message) +
                  `. Try again or contact the administrator.`;
                this.toggleToast();
              });
          }
        }
      });
    }
  }

  // Methods
  formatFileSize(numBytes: number) {
    const unit: string[] = ["B", "KB", "MB", "GB"];
    let i = 0;
    while (numBytes > 1024) {
      numBytes = parseFloat((numBytes / 1024).toFixed(1));
      i++;
    }
    return `${numBytes}${unit[i]}`;
  }

  handleFileUpload() {
    const inputEl = this.$refs.transcript as HTMLInputElement;
    this.uploadedFile = inputEl.files?.item(0) || undefined;
    if (!this.uploadedFile) {
      console.error("An error occurred.");
    } else {
      this.formData.append("file", this.uploadedFile);
      api
        .post(EXTRACT_TEXT_API, this.formData, this.config)
        .then(response => {
          if (response) {
            const { title, text, keywords } = response.data.data[0];
            if (text.startsWith("ERROR")) {
              this.toastProps.message =
                "Something went wrong. Try again or contract the administrator.";
              this.toggleToast();
              this.$emit("updateStatus", CreationStatus.BeforeUpload);
            } else {
              this.$emit("updateStatus", CreationStatus.AfterUpload);
              this.toastProps.message =
                "Successfully extracted text! Edit your document or generate your infographic!";
              this.toggleToast();
              this.fileExtractionTitle = title;
              this.fileExtractionBody = this.trimExtraWhiteSpace(text);
              this.keyWordsData = keywords;
            }
          }
        })
        .catch(error => {
          this.$emit("updateStatus", CreationStatus.BeforeUpload);
          this.toastProps.message =
            this.trimExtraWhiteSpace(error.message) +
            `. Try again or contact the administrator.`;
          this.toggleToast();
        });
      this.$emit("updateStatus", CreationStatus.AfterUpload);
    }
  }

  clearTranscript() {
    this.uploadedFile = undefined;
    this.fileExtractionBody = "";
    this.$emit("updateStatus", CreationStatus.BeforeUpload);
    this.formData.delete("file");
  }
  async generateInfographic(infographicTitle, infographicText, keyWordsList) {
    if (!infographicText) {
      alert("No file/text uploaded!");
      return;
    }
    console.log(keyWordsList);
    this.$emit("updateStatus", CreationStatus.GeneratingInfographic);

    const form = new FormData();
    // form.append("file", this.uploadedFile);
    form.append("title", infographicTitle);
    form.append("text", infographicText);
    form.append("keywords", keyWordsList);
    form.append("brand_colour", "#292929");
    form.append("density", "5");
    form.append("mode", "trial");

    try {
      const projectId = await this.$store.dispatch(
        GENERATE_INFOGRAPHICS_ACTION,
        form
      );
      this.$router.push({ path: `/project/${projectId}/preview` });
    } catch (err) {
      this.toastProps.message =
        this.trimExtraWhiteSpace(err.message) +
        `. Try again or contact the administrator.`;
      this.toggleToast();
      this.$emit("updateStatus", CreationStatus.BeforeUpload);
    }
  }
  updateFileStatus = (newStatus: CreationStatus) => {
    this.$emit("updateStatus", newStatus);
  };
  selectedFile = file => {
    this.uploadedFile = file;
  };
  dropzone = () => {
    return new Dropzone();
  };
  goToTextEditor = () => {
    this.$emit("updateStatus", CreationStatus.AfterUpload);
  };

  trimExtraWhiteSpace = body => {
    body = body.replace(/ +(?= )/g, "");
    return body;
  };

  toggleToast(message?: string) {
    const element = document.querySelector("#toast") as HTMLElement;
    if (message) {
      this.toastProps.message = message;
    }
    if (element) {
      UIkit.toggle(element).toggle();
      setTimeout(() => {
        UIkit.toggle(element).toggle();
      }, 2400);
    }
  }
}
