
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import SaveAlertToast from "@/components/common/SaveAlertToast.vue";
import SaveChangesBar from "@/components/product/edit/SaveChangesBar.vue";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import {
  CanvasMode,
  BrandKitMode,
  Logo,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";

@Options({
  components: {
    SaveAlertToast,
    SaveChangesBar,
    ThothButton
  },
  props: {
    logos: {
      type: Array as PropType<Logo[]>,
      required: true
    },
    activeLogo: {
      type: String as PropType<string>,
      required: false,
      default: null
    },
    makeActiveLogo: {
      type: Function as PropType<() => void>,
      required: true
    },
    mustSave: {
      type: Boolean,
      required: true
    },
    showToast: {
      type: Boolean,
      required: true
    },
    saveChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    },
    selectBrandKitMode: {
      type: Function as PropType<() => void>,
      required: true
    }
  }
})
export default class EditLogos extends Vue {
  //Commons
  CanvasMode = CanvasMode;
  BrandKitMode = BrandKitMode;
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  // Props
  selectBrandKitMode!: Function;
  chevronLeft = STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-chevron-left.svg";
  noDisplay = STATIC_ASSET_ICONS_BASE_URL + "/edit-brandkit-no-display.svg";
  triggerInternalToast = false;
  internalToastSetTimeout = 0;

  goToBrandKit() {
    const brandKitRoute = this.$router.resolve({ name: "BrandKit" });
    window.open(brandKitRoute.href, "_blank");
  }

  cancelChanges() {
    this.$emit("cancelBrandKitChanges");
  }

  exitSegment() {
    if (this.triggerInternalToast) {
      window.clearTimeout(this.internalToastSetTimeout);
    }
    this.triggerInternalToast = true;
    this.selectBrandKitMode(BrandKitMode.Main);
    this.internalToastSetTimeout = setTimeout(() => {
      this.triggerInternalToast = false;
    }, 5000);
  }
}
