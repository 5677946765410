
import { Options, Vue } from "vue-class-component";
import AlternateButton, {
  ButtonStyle
} from "@/components/common/AlternateButton.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({ components: { AlternateButton } })
export default class ThothBanner extends Vue {
  KeyVisual = STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-00.png";
  ButtonStyle = ButtonStyle;
  goToDemo() {
    this.$router.push("/demo");
  }
  goToWaitlist() {
    this.$router.push("/waitlist");
  }
}
