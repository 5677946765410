<template>
  <div id="app">
    <NavBar />
    <Agenda :eventName="$route.params.eventName" />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import Agenda from "./components/Agenda.vue";

export default {
  name: "AgendaPage",
  components: {
    NavBar,
    Footer,
    Agenda
  }
};
</script>

<style scoped>
@import "./components/webflow.css";
@import "./components/normalize.css";
@import "./components/sffnotes.webflow.css";
</style>
