
import { Options, Vue } from "vue-class-component";
import UIkit from "uikit";
import NavBar from "@/components/NavBar.vue";
import Modal from "@/components/common/Modal.vue";
import PreviewInfographic from "@/components/product/PreviewInfographic.vue";
import HelpFloatingButton from "@/components/HelpFloatingButton.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import {
  INFOGRAPHICS_GETTER,
  LOAD_PROJECT_STORE_ACTION,
  LOAD_STORE_ACTION,
  LOADED_GETTER,
  PROJECT_LOADED_GETTER,
  USER_GETTER
} from "@/store/constants.js";
import { ProjectStage, STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import MobileBlocker from "@/components/common/MobileBlocker.vue";
@Options({
  components: {
    NavBar,
    PreviewInfographic,
    HelpFloatingButton,
    Modal,
    MobileBlocker
  }
})
export default class Create extends Vue {
  ProjectStage = ProjectStage;
  isLoggedIn = false;
  congratsModalText = `We've just added <strong>5 free credits</strong> to your account! <br/>Your friend (bob@thoth.art) will receive 5 credits too.`;
  congratsImage = STATIC_ASSET_IMAGES_BASE_URL + "/Homepage-spot-03.png";
  get dashboardLoaded() {
    return this.$store.getters[LOADED_GETTER];
  }

  get projectLoaded() {
    return this.$store.getters[PROJECT_LOADED_GETTER];
  }

  get infographics() {
    return this.$store.getters[INFOGRAPHICS_GETTER];
  }

  get user() {
    return this.$store.getters[USER_GETTER];
  }

  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    //this.openModal();
    if (this.isLoggedIn && !this.dashboardLoaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }

    if (this.isLoggedIn && !this.projectLoaded) {
      const { projectId } = this.$route.params;
      await this.$store.dispatch(LOAD_PROJECT_STORE_ACTION, projectId);
    }
  }

  get modalElement() {
    return document.querySelector("#congrats-first");
  }

  openModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }

  closeModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).hide();
    }
  }
}
