
import { Options, Vue } from "vue-class-component";
import DashboardNavBar from "@/components/navbar/DashboardNavBar.vue";
import EditBrandKit from "@/components/product/EditBrandKit.vue";
import HelpFloatingButton from "@/components/HelpFloatingButton.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { USER_GETTER, LOAD_STORE_ACTION } from "@/store/constants.js";
import { ProjectStage } from "@/commons";
import MobileBlocker from "@/components/common/MobileBlocker.vue";

@Options({
  components: {
    DashboardNavBar,
    EditBrandKit,
    HelpFloatingButton,
    MobileBlocker
  }
})
export default class BrandKit extends Vue {
  // Imported commons
  ProjectStage = ProjectStage;

  // States
  isLoggedIn = false;
  loaded = false;

  get user() {
    return this.$store.getters[USER_GETTER];
  }

  // Mounted
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (!this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }

  // Methods
}
