
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import {
  authenticate,
  CREATE_INVITE_CODE_API,
  INVITE_CODES_API,
  LOCAL_ACCESS_KEY,
  CREATE_PROMO_CODE_API,
  FREE_CREDITS_API
} from "@/services";
import api from "@/services/api";
import { PackageTier } from "@/commons";

interface InviteCode {
  code: string;
  expiry: string;
  createdAt: string;
}

interface PromoCode {
  product: PackageTier;
  code: string;
}

@Options({
  components: {
    NavBar,
    Footer
  }
})
export default class Admin extends Vue {
  origin = "";
  isLoggedIn = false;
  freeCreditEmailAddress = "";
  freeCreditsNum = 1;
  successMessage = "";
  newInviteCode = "";
  productSelected = "";
  newPromoCode = "";
  inviteCodes: InviteCode[] = [{ code: "", expiry: "", createdAt: "" }];
  dateOptions = {
    day: "numeric",
    month: "long",
    year: "numeric"
  };

  async mounted() {
    this.origin = window.location.origin;
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;

    api
      .get(INVITE_CODES_API)
      .then(response => {
        this.inviteCodes = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }

  clearPromoCode() {
    this.newPromoCode = "";
  }

  createInviteCode() {
    const data = {
      emailAddress: "" //previous requestedEmailAddress
    };

    api
      .post(CREATE_INVITE_CODE_API, data)
      .then(response => {
        console.log(response.data);
        this.newInviteCode = (response.data as InviteCode).code;
      })
      .catch(error => {
        console.log(error);
      });
  }

  createPromoCode() {
    const data = {
      product: this.productSelected
    };

    api
      .post(CREATE_PROMO_CODE_API, null, { params: data })
      .then(response => {
        this.newPromoCode = (response.data as PromoCode).code;
      })
      .catch(error => {
        console.log(error);
      });
  }

  giveCredits() {
    const data: {
      emailAddress: string;
      credits: number;
    } = {
      emailAddress: this.freeCreditEmailAddress,
      credits: this.freeCreditsNum
    };

    api
      .post(FREE_CREDITS_API, data)
      .then(response => {
        this.successMessage = `${data.credits} Credits given successfully to ${data.emailAddress}!`;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
