
import { Vue, Options } from "vue-class-component";
import AlternateButton from "@/components/common/AlternateButton.vue";
@Options({ components: { AlternateButton } })
export default class Intro extends Vue {
  //functions
  finish() {
    this.$emit("finished");
  }
}
