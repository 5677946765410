
import { Vue, Options } from "vue-class-component";
import { STATIC_ASSET_ICONS_BASE_URL, User } from "@/commons";
import { PropType } from "@vue/runtime-core";
interface SettingOption {
  name: string;
  icon: string;
  slug: string;
  comp: string;
}
@Options({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    loaded: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
})
export default class AccountSettings extends Vue {
  // Props
  loggedIn!: boolean;

  settingsCategories: SettingOption[] = [
    {
      name: "Profile",
      slug: "/settings/profile",
      icon: STATIC_ASSET_ICONS_BASE_URL + "/account-profile-icon.svg",
      comp: "AccountProfile"
    },
    {
      name: "Account & Security",
      slug: "/settings/account",
      icon: STATIC_ASSET_ICONS_BASE_URL + "/account-details-icon.svg",
      comp: "AccountDetails"
    },
    {
      name: "Notifications",
      slug: "/settings/notifications",
      icon: STATIC_ASSET_ICONS_BASE_URL + "/account-notif-icon.svg",
      comp: "AccountNotifications"
    },
    {
      name: "Payments",
      slug: "/settings/payments",
      icon: STATIC_ASSET_ICONS_BASE_URL + "/account-pay-icon.svg",
      comp: "AccountPayments"
    }
  ];

  selectedComponent = this.settingsCategories[0];

  matchSelectedComp(str: string) {
    const pos = this.settingsCategories
      .map(e => {
        return e.slug;
      })
      .indexOf(str);
    return pos;
  }

  changeSelectedComp(comp: SettingOption) {
    this.selectedComponent = comp;
  }
  mounted() {
    const idx = this.matchSelectedComp(this.$route.path);
    if (idx > -1) {
      this.selectedComponent = this.settingsCategories[idx];
    } else {
      this.selectedComponent = this.settingsCategories[0];
    }
  }
}
