
import { Options, Vue } from "vue-class-component";
import api from "@/services/api";
import {
  USER_GETTER,
  LOADED_GETTER,
  LOAD_STORE_ACTION
} from "@/store/constants.js";
import {
  authenticate,
  LOCAL_ACCESS_KEY,
  RETRIEVE_CHECKOUT_API
} from "@/services";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
interface CheckoutSession {
  orderId: string;
  amountTotal: number;
  currency: string;
  paymentMethod: string;
  paymentDate: Date;
  email: string;
  credits: number;
}

@Options({
  components: {
    ThothButton
  },
  computed: {
    orderIDTruncated: function() {
      const truncate = this.lastCheckoutSession.orderId.substring(
        0,
        Math.min(12, this.lastCheckoutSession.orderId.length)
      );
      return `${truncate}...`;
    }
  }
})
export default class Success extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  //locals
  isLoggedIn = false;
  lastCheckoutSession: CheckoutSession = {
    orderId: "",
    amountTotal: 0.0,
    currency: "",
    paymentMethod: "",
    paymentDate: new Date(),
    email: "",
    credits: 0
  };
  dateOptions = {
    day: "numeric",
    month: "long",
    year: "numeric"
  };
  logo = STATIC_ASSET_ICONS_BASE_URL + "/thoth-black-circle-icon.svg";

  async mounted() {
    const checkoutSessionId = this.$route.query.session_id;
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;

    if (!this.isLoggedIn || typeof checkoutSessionId === "undefined") {
      this.$router.push("/login");
    } else if (!this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }

    api
      .post(RETRIEVE_CHECKOUT_API + "/" + checkoutSessionId, null)
      .then(response => {
        this.lastCheckoutSession = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
  get loaded() {
    return this.$store.getters[LOADED_GETTER];
  }
  get user() {
    return this.$store.getters[USER_GETTER];
  }
  goToDashboard() {
    this.$router.push({ name: "Dashboard" });
  }
}
