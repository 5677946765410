
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import SaveChangesBar from "@/components/product/edit/SaveChangesBar.vue";
import SaveAlertToast from "@/components/common/SaveAlertToast.vue";
import {
  CanvasMode,
  Infographic,
  STATIC_ASSET_ICONS_BASE_URL
} from "@/commons";
import draggable from "vuedraggable";
import {
  ALTERNATE_SUMMARIES_GETTER,
  GET_PROJECT_SUGGESTIONS
} from "@/store/constants";
import Spinner from "@/components/common/Spinner.vue";

@Options({
  components: {
    Spinner,
    SaveChangesBar,
    draggable,
    SaveAlertToast
  },
  props: {
    infographics: {
      type: Array as PropType<Infographic[]>,
      required: true
    },
    activeInfographic: {
      type: Number,
      required: true
    },
    saveChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    cancelChanges: {
      type: Function as PropType<() => void>,
      required: true
    },
    mustSave: {
      type: Boolean,
      required: true
    },
    showToast: {
      type: Boolean,
      required: true
    },
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    }
  }
})
export default class LayoutEdits extends Vue {
  // Commons
  CanvasMode = CanvasMode;

  // Props
  infographics!: Infographic[];
  activeInfographic!: number;
  canvasMode!: CanvasMode;

  dragging = false;
  icons: { [id: string]: string } = {
    handle: STATIC_ASSET_ICONS_BASE_URL + "/summary-reorder-handle-icon.svg",
    handleHover:
      STATIC_ASSET_ICONS_BASE_URL + "/summary-reorder-handle-icon-hover.svg",
    chevronRight: STATIC_ASSET_ICONS_BASE_URL + "/caret-right-black.svg"
  };
  dragOptions = {
    animation: 300,
    ghostClass: "ghost",
    handle: ".handle"
  };
  altSummariesPanel = false;
  selectedTextArea = -1;
  altSummaries = [[], [], [], [], []];
  mounted() {
    // Set text areas to the appropriate height at mounting
    const textAreas = document.querySelectorAll("textarea");
    textAreas.forEach(area => {
      area.style.height = "auto";
      area.style.height = `${area.scrollHeight}px`;
    });

    // // Preload the alternative suggestions
    // const { projectId } = this.$route.params;
    // if (this.inf && this.inf.summary) {
    //   for (let index = 0; index < this.inf.summary.length; index++) {
    //     this.$store.dispatch(GET_PROJECT_SUGGESTIONS, {projectId, point: index}).then(() => {
    //       this.altSummaries[index] = this.$store.getters[ALTERNATE_SUMMARIES_GETTER];
    //     });
    //   }
    // }
  }
  get inf() {
    return this.infographics[this.activeInfographic];
  }
  numStyle(numChar: number, maxChar: number) {
    const fontWeight = numChar < maxChar ? "400" : "500";
    const color = numChar < maxChar ? "#9f9f9f" : "#202226";
    return { fontWeight: fontWeight, color: color };
  }
  autoResizeTextArea(textAreaElement: HTMLTextAreaElement) {
    textAreaElement.style.height = "auto";
    textAreaElement.style.height = `${textAreaElement.scrollHeight}px`;
  }
  autoResizeOnInput(event: InputEvent) {
    const textAreaElement = event.target as HTMLTextAreaElement;
    this.autoResizeTextArea(textAreaElement);
  }
  autoResizeOnSorting() {
    const textAreaElements = Array.from(
      document.getElementsByTagName("textarea")
    );
    textAreaElements.forEach(e => {
      this.autoResizeTextArea(e);
    });
  }
  selectTextArea(index: number) {
    if (this.selectedTextArea !== index) {
      this.altSummariesPanel = false;
      this.selectedTextArea = index;
    }

    // Preload the alternative suggestions only when they click into a text area
    if (this.altSummaries[index].length === 0) {
      const { projectId } = this.$route.params;
      this.$store
        .dispatch(GET_PROJECT_SUGGESTIONS, { projectId, point: index })
        .then(() => {
          this.altSummaries[index] = this.$store.getters[
            ALTERNATE_SUMMARIES_GETTER
          ];
        });
    }
  }
  toggleAltSummariesPanel() {
    this.altSummariesPanel = !this.altSummariesPanel;
  }
  selectSummary(index: number, text: string) {
    this.inf.summary[index] = text;
    this.altSummariesPanel = false;
  }
}
