
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import { CanvasMode } from "@/commons";

@Options({
  components: {
    ThothButton
  },
  emits: {
    saveChanges: {},
    cancelChanges: {}
  },
  props: {
    canvasMode: {
      type: Number as PropType<CanvasMode>,
      required: true
    },
    initialState: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    }
  }
})
export default class SaveChangesBar extends Vue {
  CanvasMode = CanvasMode;
  canvasMode!: CanvasMode;
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  initialState?: boolean;

  get disabled() {
    if (this.initialState) {
      return true;
    } else {
      if (this.canvasMode !== CanvasMode.Editing) {
        return true;
      } else {
        return false;
      }
    }
  }

  saveChanges() {
    this.$emit("saveChanges");
  }
  cancelChanges() {
    this.$emit("cancelChanges");
  }
}
