
import { Vue } from "vue-class-component";

interface Question {
  text: string;
  answer: string;
}

export default class FrequentlyAskedQuestions extends Vue {
  questions: Question[] = [
    {
      text: "What are my payment options?",
      answer:
        "All major credit cards, Apple Pay, and (in select countries) direct debit are accepted. We also support transactions in a range of currencies."
    },
    {
      text: "Why am I redirected to Stripe during payment?",
      answer:
        "We use Stripe to process your payment in a secure and fast manner. No worries — you will be redirected back to Thoth after successful payment! <br/> To read up more on Stripe, please visit: <a href='https://stripe.com/us/privacy' class='link--thoth'>https://stripe.com/us/privacy</a> "
    },
    {
      text: "How do I see how many infographics I have available?",
      answer:
        "From the dashboard, you can view your infographic balance on the top navigation bar underneath your email address. This number represents the number of infographics you can upgrade and edit."
    },
    {
      text: "Can I get a refund if I change my mind?",
      answer:
        "All purchases made are final and non-refundable. Please <a href='mailto:hello@thoth.art' class='link--thoth'>contact us</a> if you face any issues."
    },
    {
      text:
        "Do you offer discounts for non-profit organisations or educational institutions?",
      answer:
        "We&rsquo;re currently in closed Beta, and invite you to join  <a href='/waitlist' class='link--thoth'>our waitlist</a>. Once you&rsquo;re in Beta, you&rsquo;ll get to experience Thoth with some free infographics. In the future, we&rsquo;re planning to roll out an ambassador program, where non-profit organisations and educational institutions can enjoy huge discounts on our packages."
    },
    {
      text: "Can I try Thoth for free?",
      answer:
        "We&rsquo;re currently in closed Beta, and invite you to join <a href='/waitlist' class='link--thoth'>our waitlist</a>. Once you&rsquo;re in Beta, you&rsquo;ll get to experience Thoth with some free infographics."
    },
    {
      text: "Can I purchase infographics in bulk?",
      answer:
        "For enterprise pricing, please contact us at <a href='mailto:hello@thoth.art' class='link--thoth'>hello@thoth.art</a>."
    }
  ];
}
