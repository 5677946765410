<template>
  <div />
</template>
<script>
export default {
  created() {
    window.location.href = "https://form.typeform.com/to/Tbyclmz4";
  }
};
</script>
