
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import { PropType } from "@vue/runtime-core";
import slideLieAnim from "@/assets/animate/lie_conveyor.json";
@Options({
  props: {
    alternateStyle: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    }
  },
  components: { lottie }
})
export default class TransSlide extends Vue {
  alternateStyle?: boolean;
  defaultOptions = {
    animationData: slideLieAnim,
    loop: false
  };

  finish() {
    this.$emit("finished");
  }
  animationCompleted() {
    this.finish();
  }
  handleAnimation(anim) {
    anim.setSpeed(0.6);
    anim.addEventListener("complete", () => this.animationCompleted());
  }
}
