
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
// New
import AIEngineerWorldFairCaseStudy from "@/components/public/case-studies/AIEngineerWorldFairCaseStudy.vue";
import HaymarketMediaCaseStudy from "@/components/public/case-studies/HaymarketMediaCaseStudy.vue";
import IMASCaseStudy from "@/components/public/case-studies/IMASCaseStudy.vue";
import RevConCaseStudy from "@/components/public/case-studies/RevConCaseStudy.vue";
import SandPGlobalPlattsCaseStudy from "@/components/public/case-studies/SandPGlobalPlattsCaseStudy.vue";
import SFF2022CaseStudy from "@/components/public/case-studies/SFF2022CaseStudy.vue";
import STBCaseStudy from "@/components/public/case-studies/STBCaseStudy.vue";
import TatlerGenTCaseStudy from "@/components/public/case-studies/TatlerGenTCaseStudy.vue";
// Old
import HustleFundCaseStudy from "@/components/public/case-studies/HustleFundCaseStudy.vue";
import KPMGCaseStudy from "@/components/public/case-studies/KPMGCaseStudy.vue";
import SWITCH2021CaseStudy from "@/components/public/case-studies/SWITCH2021CaseStudy.vue";
// import SFF2021CaseStudy from "@/components/public/case-studies/SFF2021CaseStudy.vue";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";
@Options({
  components: {
    NavBar,
    AIEngineerWorldFairCaseStudy,
    HaymarketMediaCaseStudy,
    IMASCaseStudy,
    RevConCaseStudy,
    SandPGlobalPlattsCaseStudy,
    SFF2022CaseStudy,
    STBCaseStudy,
    TatlerGenTCaseStudy,
    HustleFundCaseStudy,
    KPMGCaseStudy,
    SWITCH2021CaseStudy,
    // SFF2021CaseStudy
  }
})
export default class CaseStudyBase extends Vue {
  isLoggedIn = false;
  loaded = false;
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
