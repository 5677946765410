
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { configure, Field, ErrorMessage } from "vee-validate";

configure({ bails: false });

@Options({
  props: {
    title: {
      type: String,
      required: true
    },
    field: {
      type: Object as PropType<{ userInput: string }>,
      required: false,
      default: () => ({ userInput: "" })
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    helper: {
      type: String,
      required: false
    },
    iconHelper: {
      type: String,
      required: false
    },
    rules: {
      type: [Function, String] as PropType<() => void | string>,
      required: true
    },
    backendError: {
      type: String,
      required: false
    },
    autocompleteType: {
      type: String,
      required: false,
      default: "off"
    },
    currentPassword: {
      type: String,
      required: false}
  },
  components: {
    Field,
    ErrorMessage
  }
})
export default class ErrorPasswordField extends Vue {
  icons: { [id: string]: string } = {
    eye: STATIC_ASSET_ICONS_BASE_URL + "/eye.svg",
    eyeSlash: STATIC_ASSET_ICONS_BASE_URL + "/eye-slash.svg",
    error: STATIC_ASSET_ICONS_BASE_URL + "/form-error.svg"
  };
  inputType = "password";

  // Methods
  togglePasswordMode() {
    this.inputType = this.inputType === "password" ? "text" : "password";
  }
}
