const DASHBOARD = "dashboard";
const PROJECT = "project";
const AUTH = "auth";

//root
export const RESET_STORE_ACTION = "resetStore";

// Dashboard
export const LOADED_GETTER = DASHBOARD + "/loaded";
export const USER_GETTER = DASHBOARD + "/user";
export const PROJECTS_GETTER = DASHBOARD + "/projects";
export const LOAD_STORE_ACTION = DASHBOARD + "/loadStore";
export const LIST_USER_ACTION = DASHBOARD + "/listUser";
export const LIST_PROJECTS_ACTION = DASHBOARD + "/listProjects";
export const LIST_PROJECT_ACTION = DASHBOARD + "/listProject";
export const GENERATE_INFOGRAPHICS_ACTION = DASHBOARD + "/generateInfographics";
export const TRASH_PROJECT_ACTION = DASHBOARD + "/trashProject";
export const RESTORE_PROJECT_ACTION = DASHBOARD + "/restoreProject";
export const DELETE_PROJECT_ACTION = DASHBOARD + "/deleteProject";
// Brand Kit
export const ADD_LOGO_ACTION = DASHBOARD + "/addLogo";
export const DELETE_LOGO_ACTION = DASHBOARD + "/deleteLogo";
export const UPDATE_BRANDLINK_ACTION = DASHBOARD + "/updateBrandLinks";
export const REVERT_BRANDLINK_ACTION = DASHBOARD + "/revertBrandLink";
export const ADD_PALETTE_ACTION = DASHBOARD + "/createColourPalette";
export const DELETE_PALETTE_ACTION = DASHBOARD + "/deleteColourPalette";
export const ADD_COLOUR_ACTION = DASHBOARD + "/addColourToPalette";
export const DELETE_COLOUR_ACTION = DASHBOARD + "/deleteColourInPalette";
export const UPDATE_COLOUR_ACTION = DASHBOARD + "/updateColourInPalette";
export const EDIT_PALETTE_NAME_ACTION = DASHBOARD + "/updatePaletteName";

// Project
export const PROJECT_LOADED_GETTER = PROJECT + "/loaded";
export const INFOGRAPHICS_GETTER = PROJECT + "/infographics";
export const PROJECT_TITLE_GETTER = PROJECT + "/title";
export const EDIT_MODE_GETTER = PROJECT + "/editMode";
export const LOAD_PROJECT_STORE_ACTION = PROJECT + "/loadStore";
export const RESET_PROJECT_STORE_ACTION = PROJECT + "/resetStore";
export const UPGRADE_PROJECT_ACTION = PROJECT + "/upgradeProject";
export const UPDATE_INFOGRAPHICS_ACTION = PROJECT + "/updateInfographics";
export const PUBLISH_INFOGRAPHIC_ACTION = PROJECT + "/publishInfographic";
export const DELETE_DRAFT_INFOGRAPHICS_ACTION =
  PROJECT + "/deleteDraftInfographics";

//Settings
export const PROFILE_GETTER = DASHBOARD + "/profile";
export const UPDATE_PROFILE_ACTION = DASHBOARD + "/updateProfile";
export const NOTIFICATIONS_GETTER = DASHBOARD + "/notifications";
export const UPDATE_NOTIFICATIONS_ACTION = DASHBOARD + "/updateNotifications";
export const SET_EDIT_MODE_ACTION = PROJECT + "/setEditModeState";

// Project-Versioning
export const UNDO_VERSION_ACTION = PROJECT + "/undoVersion";
export const REDO_VERSION_ACTION = PROJECT + "/redoVersion";
export const VERSION_CHANGE_ACTIVE_GETTER = PROJECT + "/versionChangeActive";
export const CURRENT_VERSION_GETTER = PROJECT + "/currentVersion";
export const SELECTED_VERSION_GETTER = PROJECT + "/selectedVersion";
export const UPDATE_VERSION_ACTION = PROJECT + "/updateVersion";
export const PROJECT_VERSIONS_GETTER = PROJECT + "/projectVersions";
export const LOAD_PROJECT_VERSIONS_ACTION = PROJECT + "/loadVersions";
export const LOAD_CURRENT_VERSION_ACTION = PROJECT + "/loadCurrentVersion";
export const SET_CURRENT_VERSION_ACTION = PROJECT + "/setCurrentVersionState";
export const SET_SELECTED_VERSION_ACTION = PROJECT + "/setSelectedVersionState";
export const TOGGLE_DARK_MODE = PROJECT + "/toggleDarkMode";
export const GET_PROJECT_SUGGESTIONS = PROJECT + "/loadAlternateSummaries";
export const ALTERNATE_SUMMARIES_GETTER = PROJECT + "/alternateSummaries";
