import { createApp } from "vue";
import VueGtag from "vue-gtag";
import VueLazyLoad from "vue3-lazyload";
import App from "./App.vue";
import router from "./router";
import { store } from "./store/index.js";
import { GA_TRACKING_ID } from "@/services";
import { Vue3Mq } from "vue3-mq";
import setupInterceptors from "./services/setupInterceptors";

setupInterceptors(store);

function initializeApp(app) {
  app
    .use(router)
    .use(store)
    .use(Vue3Mq, {
      breakpoints: {
        xs: 0,
        sm: 320,
        md: 480,
        lg: 768,
        xl: 960,
        xxl: 1200,
      },
    })
    .use(
      VueGtag,
      {
        config: {
          id: GA_TRACKING_ID,
          appName: "Thoth",
        },
      },
      router
    )
    .use(VueLazyLoad, { log: false })
    .mount("#app");
}
async function bootstrap() {
  const app = createApp(App);

  router.beforeEach(async (to, from, next) => {
    document.title = (to.meta.title as string) || "Thoth";

    const { eventName } = to.params;

    if (eventName) {
      try {
        const configUrl = `https://thoth-weblibrary.s3.ap-southeast-1.amazonaws.com/${eventName}/main.json`;
        const response = await fetch(configUrl);

        if (!response.ok) {
          console.error("Failed to fetch event configuration");
          next("/404");
        } else {
          const eventConfig = await response.json();

          const root = document.documentElement;
          if (eventConfig.cssVariables) {
            for (const [key, value] of Object.entries(
              eventConfig.cssVariables
            )) {
              root.style.setProperty(key, value as string);
            }
          }

          if (eventConfig.fonts) {
            const { primaryFont, secondaryFont } = eventConfig.fonts;
            root.style.setProperty("--primary-font", primaryFont);
            root.style.setProperty("--secondary-font", secondaryFont);

            if (primaryFont && secondaryFont) {
              const fontLink = document.createElement("link");
              fontLink.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(
                primaryFont
              )}`;
              fontLink.rel = "stylesheet";
              document.head.appendChild(fontLink);
            }
          }

          app.provide("eventConfig", eventConfig);

          next();
        }
      } catch (error) {
        console.error("Error fetching event configuration:", error);
        next("/404");
      }
    } else {
      next();
    }
  });

  initializeApp(app);
}

bootstrap();
