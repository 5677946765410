<template>
  <div class="thoth_video">
    <div class="thoth_video_container">
      <video controls="true">
        <source :src="mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>
<script>
import { Vue } from "vue-class-component";
import { STATIC_ASSET_VIDEO_BASE_URL } from "@/commons";
export default class ThothVideo extends Vue {
  mp4 = STATIC_ASSET_VIDEO_BASE_URL + "/Demo+Video_Thoth.mp4";
}
</script>
<style scoped>
.thoth_video {
  background-color: var(--black);
  height: fit-content;
  color: var(--white);
  padding: 80px 60px;
}
.thoth_video_container {
  max-width: 60%;
  height: auto;
  margin: 0 auto;
}
@media screen and (max-width: 480px) {
  .thoth_video_container {
    max-width: 90%;
  }
}
</style>
