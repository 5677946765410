<template>
    <div :class="$style.tooltip">
        <div :class="$style.buttonWrapper">
            <div :class="$style.button" @click="copyLink">
                <img :class="$style.socialIcons" alt="" src="../images/Copy.svg" />
                <div :class="$style.button1">Copy Link</div>
            </div>
        </div>
        <div :class="$style.tooltipChild" />
        <div :class="$style.buttonParent">
            <div :class="$style.button" @click="share('facebook')">
                <img :class="$style.socialIcons" alt="Facebook" src="../images/Facebook_onLight.svg" />
                <div :class="$style.button1">Share on Facebook</div>
            </div>
            <div :class="$style.button" @click="share('linkedin')">
                <img :class="$style.socialIcons" alt="LinkedIn" src="../images/LinkedIN_onLight.svg" />
                <div :class="$style.button1">Share on LinkedIn</div>
            </div>
            <div :class="$style.button" @click="share('x')">
                <img :class="$style.socialIcons" alt="X" src="../images/X_onLight.svg" />
                <div :class="$style.button1">Share on X</div>
            </div>
        </div>

        <div :class="$style.tooltipItem" />

        <div :class="$style.buttonGroup">
            <div :class="$style.button" @click="share('whatsapp')">
                <img :class="$style.socialIcons" alt="WhatsApp" src="../images/WhatsApp_onLight.svg" />
                <div :class="$style.button1">Send via WhatsApp</div>
            </div>
            <div :class="$style.button" @click="share('telegram')">
                <img :class="$style.socialIcons" alt="Telegram" src="../images/Telegram_onLight.svg" />
                <div :class="$style.button1">Send via Telegram</div>
            </div>
        </div>
        <div :class="$style.tailPositionUpDownRoun">
            <img :class="$style.tailAnchorIcon" alt="" src="Tail Anchor.svg" />
        </div>
        <div :class="$style.pleaseReferToTermsOfUseFWrapper">
            <div :class="$style.pleaseReferToContainer">
                <span>Please refer to </span>
                <router-link to="/license" :class="$style.termsOfUse">Terms of use</router-link>
                <span> for guidelines on sharing</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tooltip",
    props: {
        talkEndpoint: { type: String, required: true },
        name: { type: String, required: true }
    },
    methods: {
        copyLink() {
            // Copy the URL to the clipboard
            navigator.clipboard.writeText(`www.thoth.art${this.talkEndpoint}`).then(() => {
                alert('Link copied to clipboard');
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        },
        share(platform) {
            const url = `https://thoth.art${this.talkEndpoint}`;
            const title = this.name;
            let shareUrl = '';

            switch (platform) {
                case 'facebook':
                    shareUrl = `https://www.addtoany.com/add_to/facebook?linkurl=${url}&linkname=${title}`;
                    break;
                case 'linkedin':
                    shareUrl = `https://www.addtoany.com/add_to/linkedin?linkurl=${url}&linkname=${title}`;
                    break;
                case 'x':
                    shareUrl = `https://www.addtoany.com/add_to/x?linkurl=${url}&linkname=${title}`;
                    break;
                case 'whatsapp':
                    shareUrl = `https://www.addtoany.com/add_to/whatsapp?linkurl=${url}&linkname=${title}`;
                    break;
                case 'telegram':
                    shareUrl = `https://www.addtoany.com/add_to/telegram?linkurl=${url}&linkname=${title}`;
                    break;
                default:
                    break;
            }

            if (shareUrl) {
                window.open(shareUrl, '_blank');
            }
        }
    },
};
</script>

<style module>
.socialIcons {
    width: 16px;
    position: relative;
    height: 16px;
}

.button1 {
    position: relative;
    line-height: 130%;
}

.button {
    align-self: stretch;
    border-radius: 12px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 16px;
    box-sizing: border-box;
    gap: 8px;
    cursor: pointer;
}

.buttonWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 0;
}

.tooltipChild {
    align-self: stretch;
    position: relative;
    border-top: 1px solid #d9d9d9;
    box-sizing: border-box;
    height: 1px;
    z-index: 1;
}

.buttonParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
}

.tooltipItem {
    align-self: stretch;
    position: relative;
    border-top: 1px solid #d9d9d9;
    box-sizing: border-box;
    height: 1px;
    z-index: 3;
}

.buttonGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 4;
}

.tailAnchorIcon {
    width: 12px;
    position: relative;
    height: 0px;
}

.tailPositionUpDownRoun {
    width: 188px;
    margin: 0 !important;
    position: absolute;
    top: 252px;
    left: 31px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 5;
}

.termsOfUse {
    text-decoration: underline;
    font-weight: 600;
    color: #B89A6A;
}

.pleaseReferToContainer {
    flex: 1;
    position: relative;
    line-height: 130%;
}

.pleaseReferToTermsOfUseFWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 24px 8px;
    z-index: 6;
    font-size: 14px;
}

.tooltip {
    width: 260px;
    position: relative;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 0px;
    gap: 8px;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    font-size: 16px;
    color: #494949;
    font-family: 'DM Sans';
}
</style>
