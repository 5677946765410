
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import { authenticate, LOCAL_ACCESS_KEY } from "@/services";
import { LOAD_STORE_ACTION } from "@/store/constants.js";
import NavBar from "@/components/NavBar.vue";

@Options({
  components: { NavBar }
})
export default class InternalServerError extends Vue {
  // Imported commons

  isLoggedIn = false;
  loaded = false;

  errorImage = STATIC_ASSET_IMAGES_BASE_URL + "/500.svg";
  async mounted() {
    const token = localStorage.getItem(LOCAL_ACCESS_KEY);
    this.isLoggedIn = token ? await authenticate() : false;
    if (this.isLoggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
      this.loaded = true;
    }
  }
}
