
import { Options, Vue } from "vue-class-component";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import Quote from "@/components/common/Quote.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
@Options({
  components: {
    ThothButton,
    Quote,
    CallToActionCaseStudy
  }
})
export default class SFF2022CaseStudy extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;
  // locals
  images: { [id: string]: string } = {
    case1: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-1.png",
    case2: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-2.jpeg",
    case3: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-3.jpeg",
    case4: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-4.png",
    case5: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-5.png",
    case6: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-6.gif",
    case7: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-7.gif",
    case8: STATIC_ASSET_IMAGES_BASE_URL + "/case-studies/SFF2022-Example-8.gif",
  };
  links: { [id: string]: string } = {
    webLibrary: "http://sff.thoth.art",
    ideaInk: "http://www.ideaink.co",
    sff: "https://www.fintechfestival.sg",
    mas: "https://www.mas.gov.sg",
    talentPavilion: "https://talentpavilion.sg/home",
    onlineAvatars: "https://form.talentpavilion.sg/",
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
  };
  goToArticle() {
    window.location.href = this.links.webLibrary;
  }
}
