import { TypeformPopupConfig } from "@/commons";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import api from "./services/api";

// Simple switch for local development and other environments
const BACKEND =
  process.env.NODE_ENV == "dev" ? "http://localhost:8001" : "/api";

// Google Analytics
// export const GA_TRACKING_ID = "UA-91532633-2";
export const GA_TRACKING_ID = "G-9N1SEQYS0H";

// Typeform
export const TYPEFORM_SUBMITTED = "typeformSubmitted";
export const TYPEFORM_SEEN = "typeformSeen";
export const TYPEFORM_ID = "tBru7mCe";
export const TYPEFORM_FEEDBACK_FORM = "https://form.typeform.com/to/WucIWg5z";

// Auth
export const LOGIN_API = BACKEND + "/auth/login";
export const SIGNUP_API = BACKEND + "/auth/register";
export const REFRESH_TOKEN_API = BACKEND + "/auth/refresh";
export const REREGISTER_API = BACKEND + "/auth/reregister";
export const FORGOT_PASSWORD_API = BACKEND + "/auth/forgot-password";
export const RESET_PASSWORD_API = BACKEND + "/auth/reset-password";
export const RESEND_VERIFICATION_API = BACKEND + "/auth/resend-verification";
export const USER_TOKEN_API = BACKEND + "/users/me";
export const DEACTIVATE_INVITE_CODE_API = BACKEND + "/deactivate-invite-code";

// Admin
export const ADMIN_API = BACKEND + "/admin/me";
export const INVITE_CODES_API = BACKEND + "/admin/inviteCodes";
export const CREATE_INVITE_CODE_API = BACKEND + "/admin/inviteCodes";
export const CREATE_PROMO_CODE_API = BACKEND + "/admin/promoCode";
export const FREE_CREDITS_API = BACKEND + "/admin/credits";
// Contact
export const WAITLIST_API = BACKEND + "/contact/waitlist";
export const GET_IN_TOUCH_API = BACKEND + "/contact/get-in-touch";

// User Information
export const USER_DETAILS_API = BACKEND + "/users/me";
export const CHECKOUT_SESSION = BACKEND + "/payments/create-checkout-session";
export const RETRIEVE_CHECKOUT_API =
  BACKEND + "/payments/retrieve-checkout-session";
export const PROJECT_API = BACKEND + "/projects";
export const DARK_MODE_API = PROJECT_API + "/dark-mode";
export const BRANDKIT_API = BACKEND + "/brandkit";
export const LOGOS_API = BRANDKIT_API + "/logo";
export const COLOUR_PALETTES_API = BRANDKIT_API + "/colour-palette";
export const BRAND_LINKS_API = BRANDKIT_API + "/brand-links";

// Referral
export const REFERRAL_LINK_API = BACKEND + "/users/referral";
export const INVITE_API = BACKEND + "/users/invite";

// Settings
export const PROFILE_API = BACKEND + "/users/profile";
export const NOTIFICATIONS_API = BACKEND + "/users/notifications";
export const CHANGE_EMAIL_API = BACKEND + "/users/change-email";
export const RESEND_CHANGE_EMAIL_API = BACKEND + "/users/resend-change-email";
export const CANCEL_CHANGE_EMAIL_API = BACKEND + "/users/cancel-change-email";
export const CHANGE_PASSWORD_API = BACKEND + "/users/change-password";
export const DELETE_ACCOUNT_API = BACKEND + "/users/delete";
export const PAYMENTS_API = BACKEND + "/users/payments";
// Keys
export const LOCAL_ACCESS_KEY = "accessToken";
export const LOCAL_REFRESH_KEY = "refreshToken";
export const STRIPE_PUBLIC_KEY =
  "pk_test_51IdZOCJGtdoKVn9gGvWRdoL9zgtnQ2WzoNtw5vEflRzg2OyMHm1VY3YE3u1rWVdLHm3dlZkthWCJl9PK0OYyKz2J00VKuUfiu4";

// Monday.com form submission
export const MONDAY_API_KEY = process.env.VUE_APP_MONDAY_API_KEY;
export const MONDAY_BOARD_ID = process.env.VUE_APP_MONDAY_BOARD_ID;

// Infographics
export const EXTRACT_TEXT_API = BACKEND + "/import";
export const VIEW_INFOGRAPHIC_API = BACKEND + "/infographic";

export const authenticate = async () => {
  return api
    .get(USER_TOKEN_API)
    .then((response) => response.status === 200)
    .catch(() => false);
};

export const authenticateAdmin = async () => {
  return api
    .get(ADMIN_API)
    .then((response) => response.status === 200)
    .catch(() => false);
};

export const validatePassword = (password: string): string => {
  if (password.length < 6) {
    return "Password should be at least 6 characters.";
  }

  if (password.replaceAll(password[0], "") === "") {
    return "Password should have more than 1 unique character.";
  }

  return "";
};

export function openTypeformModal({
  openMode,
  openDelay,
  modalSize,
  projectId,
  userEmail,
}: TypeformPopupConfig) {
  const typeformConfig = {
    hidden: {
      projectid: projectId,
      email: userEmail,
    },
    open: openMode,
    openValue: openDelay,
    hideHeaders: true,
    hideFooter: true,
    size: modalSize,
    onSubmit: () => {
      localStorage.setItem(TYPEFORM_SUBMITTED, "true");
    },
  };
  createPopup(TYPEFORM_ID, typeformConfig);
}

export const fetchProject = async (projectId: string) => {
  try {
    const project = await api.get(`${PROJECT_API}/${projectId}`);
    return project.data[0].infographics;
  } catch (error) {
    console.log("error fetching infographics", error);
    throw error;
  }
};

export function getLocalRefreshToken() {
  const refreshToken = localStorage.getItem(LOCAL_REFRESH_KEY);
  return refreshToken ? refreshToken : "";
}

export function getLocalAccessToken() {
  const accessToken = localStorage.getItem(LOCAL_ACCESS_KEY);
  return accessToken ? accessToken : "";
}

export function updateLocalAccessToken(token: string) {
  localStorage.setItem(LOCAL_ACCESS_KEY, token);
}

export function updateLocalRefreshToken(token: string) {
  localStorage.setItem(LOCAL_REFRESH_KEY, token);
}
