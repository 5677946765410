
import { Vue, Options } from "vue-class-component";
import { PropType } from "@vue/runtime-core";

@Options({
  props: {
    moveonEnd: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    },
    modal: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    },
    delayed: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    },
    hasStep: {
      type: Boolean as PropType<boolean>,
      required: false
    },
    title: {
      type: String as PropType<string>,
      required: false
    },
    subtexts: {
      type: Array as PropType<string[]>,
      required: true
    }
  }
})
export default class DemoModal extends Vue {
  moveonEnd?: boolean;
  hasStep?: boolean;
  title?: string;
  subtexts!: string[];
  modal?: boolean;
  delayed?: boolean;
  miniStep = 0;
  fakeModal = false;
  modalBlockEnd = false;
  mounted() {
    if (!this.modal && this.delayed) {
      setTimeout(() => {
        this.fakeModal = true;
      }, 400);
    }
  }
  showSubtext(number) {
    return number <= this.miniStep;
  }
  checkEnd() {
    if (this.modal || this.fakeModal) {
      if (this.miniStep >= this.subtexts.length) {
        if (this.moveonEnd && !this.modalBlockEnd) {
          this.modalBlockEnd = true;
          setTimeout(() => {
            this.$emit("modal-closed");
          }, 100);
        }
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
  stepLoader() {
    this.miniStep++;
  }
}
