
import { Vue } from "vue-class-component";
import {
  STATIC_ASSET_IMAGES_BASE_URL,
  USER_INFOGRAPHICS_BASE_URL
} from "@/commons";

export default class ViewInfographic extends Vue {
  infographicUrl = "";
  thothLogo = STATIC_ASSET_IMAGES_BASE_URL + "/white-thoth-logo.svg";

  created() {
    const { projectId } = this.$route.params;
    this.infographicUrl = USER_INFOGRAPHICS_BASE_URL + "/" + projectId + ".png";
  }
}
