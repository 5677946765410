import {
  REFRESH_TOKEN_API,
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
  updateLocalRefreshToken
} from "@/services";
import axiosInstance from "./api";
import axios from "axios";

const setupInterceptors = store => {
  axiosInstance.interceptors.request.use(
    config => {
      const token = getLocalAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    res => {
      return res;
    },
    async err => {
      const originalConfig = err.config;
      if (
        originalConfig.url !== "/auth/login" &&
        originalConfig.url !== "/auth/refresh" &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await axios.post(
              REFRESH_TOKEN_API,
              {},
              {
                headers: {
                  Authorization: `Bearer ${getLocalRefreshToken()}`
                }
              }
            );
            const { accessToken, refreshToken } = rs.data;
            //store.dispatch(REFRESH_TOKEN_ACTION, accessToken);
            updateLocalAccessToken(accessToken);
            updateLocalRefreshToken(refreshToken);
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setupInterceptors;
