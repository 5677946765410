<template>
  <div id="app">
    <NavBar />
    <SearchResults :eventName="$route.params.eventName" />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import SearchResults from "./components/SearchResults.vue";

export default {
  name: "SearchResultsPage",
  components: {
    NavBar,
    Footer,
    SearchResults
  }
};
</script>
