
import { Options, Vue } from "vue-class-component";
import lottie from "vue-lottie";
import { PropType } from "@vue/runtime-core";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import DemoModal from "@/components/common/DemoModal.vue";
import PeachSplashAnim from "@/assets/animate/select_color_peach.json";
import BlueSplashAnim from "@/assets/animate/select_color_blue.json";
@Options({
  props: {
    selectedKeypoints: {
      type: Array as PropType<number[]>,
      required: false,
      default: [4, 5, 6]
    }
  },
  computed: {
    imageSelect1() {
      return (
        STATIC_ASSET_IMAGES_BASE_URL +
        `/demo/choices/Point${this.selectedKeypoints[0]}-${this.currentColor}.png`
      );
    },
    imageSelect2() {
      return (
        STATIC_ASSET_IMAGES_BASE_URL +
        `/demo/choices/Point${this.selectedKeypoints[1]}-${this.currentColor}.png`
      );
    },
    imageSelect3() {
      return (
        STATIC_ASSET_IMAGES_BASE_URL +
        `/demo/choices/Point${this.selectedKeypoints[2]}-${this.currentColor}.png`
      );
    }
  },
  components: { lottie, DemoModal }
})
export default class ColorSelection extends Vue {
  //props
  selectedKeypoints?: number[];
  //data
  base = STATIC_ASSET_IMAGES_BASE_URL + "/demo/color_select_base.svg";
  choice = -1;
  animChoice = -1;
  currentColor = "Grayscale";
  playing = false;
  innerStep = 0;
  chosen = true;
  innerSteps = [
    {
      stepNum: 0,
      moveonEnd: false,
      delayed: true,
      title: "Step 3/4: SELECT COLOR",
      subtexts: [
        "Cool! Next let’s decide on a colour, and watch your visuals come alive."
      ]
    }
  ];
  colors = [
    {
      color: "Peach",
      colorImage: STATIC_ASSET_IMAGES_BASE_URL + "/demo/image-01.png",
      lottieImage: {
        animationData: PeachSplashAnim,
        loop: false,
        autoplay: true
      }
    },
    {
      color: "Blue",
      colorImage: STATIC_ASSET_IMAGES_BASE_URL + "/demo/image-01.png",
      lottieImage: {
        animationData: BlueSplashAnim,
        loop: false,
        autoplay: true
      }
    }
  ];
  //functions
  checkInnerStep(number) {
    switch (number) {
      case -1:
        return this.innerSteps.length == this.innerStep;
      case -2:
        return this.innerStep < this.innerSteps.length;
      default:
        return number == this.innerStep;
    }
  }
  close() {
    this.innerStep++;
  }
  select(i) {
    if (!this.playing) {
      this.playing = true;
      this.animChoice = i;
      this.choice = i;
      this.$emit("selectColor", this.colors[i].color);
      setTimeout(() => {
        this.currentColor = this.colors[i].color;
      }, 1060);
      setTimeout(() => {
        this.$emit("finished");
      }, 3000);
    }
  }
  handleAnimation(anim) {
    anim.setSpeed(0.8);
  }
}
