
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { LOCAL_ACCESS_KEY, LOCAL_REFRESH_KEY } from "@/services";
import { STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import {
  LOAD_STORE_ACTION,
  LOADED_GETTER,
  USER_GETTER,
  PROFILE_GETTER
} from "@/store/constants.js";
import ThothButton, {
  ButtonColour,
  ButtonEmphasis,
  ButtonSize
} from "@/components/common/ThothButton.vue";
import ReferModal from "@/components/common/ReferModal.vue";
import UIkit from "uikit";
import { REFERRAL_LINK_API } from "@/services";
import api from "@/services/api";
import { RESET_STORE_ACTION } from "@/store/constants";

@Options({
  emits: {
    publish: {}
  },
  props: {
    loggedIn: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  components: { ThothButton, ReferModal }
})
export default class DashboardNavBar extends Vue {
  // Props
  loggedIn!: boolean;

  // Imported commons
  ButtonColour = ButtonColour;
  ButtonEmphasis = ButtonEmphasis;
  ButtonSize = ButtonSize;

  // Data
  icons: { [id: string]: string } = {
    logo: STATIC_ASSET_ICONS_BASE_URL + "/navbar-thoth-logo.svg",
    chevronDown: STATIC_ASSET_ICONS_BASE_URL + "/dashboard-chevron-down.svg",
    plus: STATIC_ASSET_ICONS_BASE_URL + "/dashboard-plus.svg",
    logout: STATIC_ASSET_ICONS_BASE_URL + "/navbar-logout.svg",
    credit: STATIC_ASSET_ICONS_BASE_URL + "/navbar-coin-vertical.svg",
    settings: STATIC_ASSET_ICONS_BASE_URL + "/navbar-user-circle.svg",
    refer: STATIC_ASSET_ICONS_BASE_URL + "/user-plus.svg"
  };
  links = [
    { text: "Home", link: "/dashboard" },
    { text: "Brand Kit", link: "/brandkit" }
  ];
  referLink = "";

  // Methods
  async mounted() {
    if (this.loggedIn && !this.loaded) {
      await this.$store.dispatch(LOAD_STORE_ACTION);
    }
  }

  get loaded() {
    return this.$store.getters[LOADED_GETTER];
  }
  get profile() {
    return this.loggedIn ? this.$store.getters[PROFILE_GETTER] : {};
  }
  get user() {
    return this.loggedIn ? this.$store.getters[USER_GETTER] : {};
  }

  truncate(profile) {
    const profileTokenLimit = 25;
    const profileString = profile.firstName.concat(" ", profile.lastName);
    return profileString.length > profileTokenLimit
      ? profileString.slice(0, profileTokenLimit - 4) + "..."
      : profileString;
  }

  logout() {
    if (localStorage.getItem(LOCAL_ACCESS_KEY)) {
      localStorage.removeItem(LOCAL_ACCESS_KEY);
    }
    if (localStorage.getItem(LOCAL_REFRESH_KEY)) {
      localStorage.removeItem(LOCAL_REFRESH_KEY);
    }
    this.$store.dispatch(RESET_STORE_ACTION);
    if (this.$route.name === "Home") {
      location.reload();
    } else {
      this.$router.push({ name: "Home" });
    }
  }
  goToCreate() {
    this.$router.push({ name: "Create" });
  }
  get modalElement() {
    return document.querySelector("#refer-a-friend");
  }
  openReferModal() {
    api
      .get(REFERRAL_LINK_API)
      .then(response => {
        this.referLink = response.data.link;
      })
      .catch(error => {
        console.log(error);
      });

    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }
}
