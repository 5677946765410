
import { Vue } from "vue-class-component";
import { Links, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";

export default class Footer extends Vue {
  // Imported commons
  Links = Links;

  // Data
  icons: { [id: string]: string } = {
    thoth: STATIC_ASSET_ICONS_BASE_URL + "/footer-thoth-logo-golden.svg",
    instagram: STATIC_ASSET_ICONS_BASE_URL + "/footer-instagram.svg",
    linkedin: STATIC_ASSET_ICONS_BASE_URL + "/footer-linkedin.svg",
    x: STATIC_ASSET_ICONS_BASE_URL + "/X_white.svg",
  };
  linkLists = [
    {
      header: "Product",
      links: [
        { text: "Pricing", link: "/pricing" },
        { text: "Log In", link: "/login" },
        { text: "Join Waitlist", link: "/waitlist" }
      ]
    },
    {
      header: "Company",
      links: [
        { text: "About Us", link: "/about" },
        { text: "Careers", link: "/careers" }
      ]
    },
    {
      header: "Support",
      links: [
        { text: "Terms of Service", link: "/tos" },
        { text: "Privacy Policy", link: "/privacy" },
        { text: "License", link: "/license" }
      ]
    }
  ];
  socialMediaIcons = [
    { image: this.icons.linkedin, link: Links.LINKEDIN },
    { image: this.icons.x, link: Links.TWITTER },
    { image: this.icons.instagram, link: Links.INSTAGRAM },
  ];
}
