
import { Options, Vue } from "vue-class-component";
import { STATIC_ASSET_ICONS_BASE_URL, BrandLinks, SocialType } from "@/commons";
import { UPDATE_BRANDLINK_ACTION } from "@/store/constants";
import { PropType } from "@vue/runtime-core";
import BrandLink from "@/components/product/brandkit/BrandLink.vue";

@Options({
  props: {
    brandLinks: {
      type: Object as PropType<BrandLinks>,
      required: true
    }
  },
  components: {
    BrandLink
  }
})
export default class BrandLinksSection extends Vue {
  // Imported commons
  brandLinks!: BrandLinks;

  // States
  socials = {
    website: "Website URL",
    linkedin: "LinkedIn",
    twitter: "Twitter",
    instagram: "Instagram",
    facebook: "Facebook"
  };
  icons = {
    facebook: STATIC_ASSET_ICONS_BASE_URL + "/facebook.svg",
    linkedin: STATIC_ASSET_ICONS_BASE_URL + "/linkedin.svg",
    website: STATIC_ASSET_ICONS_BASE_URL + "/website.svg",
    twitter: STATIC_ASSET_ICONS_BASE_URL + "/twitter.svg",
    instagram: STATIC_ASSET_ICONS_BASE_URL + "/instagram.svg",
    facebookGreyed: STATIC_ASSET_ICONS_BASE_URL + "/facebook-greyed.svg",
    linkedinGreyed: STATIC_ASSET_ICONS_BASE_URL + "/linkedin-greyed.svg",
    websiteGreyed: STATIC_ASSET_ICONS_BASE_URL + "/website-greyed.svg",
    twitterGreyed: STATIC_ASSET_ICONS_BASE_URL + "/twitter-greyed.svg",
    instagramGreyed: STATIC_ASSET_ICONS_BASE_URL + "/instagram-greyed.svg"
  };

  get dropdownElement() {
    return document.querySelector("#brand-links__selector");
  }

  // Methods
  closeDropdown() {
    if (this.dropdownElement) {
      const addEl = document.querySelector(".brand-links__add") as HTMLElement;
      addEl.click();
    }
  }
  async addBrandLink(social: SocialType) {
    this.closeDropdown();
    if (this.brandLinks[social]) {
      return;
    }

    const payload = {
      social: social,
      url: ""
    };
    try {
      this.$store.dispatch(UPDATE_BRANDLINK_ACTION, payload);
    } catch (err) {
      console.log(err);
    }
  }
  async deleteBrandLink(payload: { social: SocialType; url: string }) {
    try {
      await this.$store.dispatch(UPDATE_BRANDLINK_ACTION, payload);
    } catch (err) {
      console.log(err);
    }
  }
  async updateBrandLink(payload: { social: SocialType; url: string }) {
    try {
      await this.$store.dispatch(UPDATE_BRANDLINK_ACTION, payload);
    } catch (err) {
      console.log(err);
    }
  }
  getIconSrc(social: SocialType): string {
    const key = social + (this.brandLinks[social] !== null ? "Greyed" : "");
    return this.icons[key];
  }
}
