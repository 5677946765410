<template>
  <div id="app">
    <NavBar />
    <Day :dayName="$route.params.day" :eventName="$route.params.eventName" />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import Day from "./components/Day.vue";

export default {
  name: "DayPage",
  components: {
    NavBar,
    Footer,
    Day
  }
};
</script>

<style scoped>
@import "./components/webflow.css";
@import "./components/normalize.css";
@import "./components/sffnotes.webflow.css";
</style>
