<template>
  <div :class="$style.content">
    <div :class="$style.meshGradParent">
      <img v-if="eventConfig.backgroundPattern" :class="$style.meshGradIcon" alt=""
        :src="eventConfig.backgroundPattern" />
      <div :class="$style.frameParent">
        <div :class="$style.descriptionParent">
          <div :class="$style.description">About the collaboration</div>
          <img v-if="eventConfig.about.partnerLockup" :class="$style.logoIcon" alt=""
            :src="eventConfig.about.partnerLockup" />
          <div :class="$style.aCollaborationBetween24finParent">
            <div :class="$style.aCollaborationBetweenContainer">
              <p :class="$style.aCollaborationBetween">A collaboration between </p>
              <p :class="$style.aCollaborationBetween">{{ eventConfig.about.eventName }} and Thoth.ai</p>
            </div>
            <div :class="$style.discoverAPlatform">{{ eventConfig.about.collaborationDescription }}</div>
          </div>
        </div>
        <img v-if="eventConfig.about.collabImage" :class="$style.frameItem" alt=""
          :src="eventConfig.about.collabImage" />
      </div>
    </div>
    <div :class="$style.meshGradGroup">
      <img v-if="eventConfig.backgroundPattern" :class="$style.meshGradIcon1" alt=""
        :src="eventConfig.backgroundPattern" />
      <div :class="$style.frameParent">
        <img v-if="eventConfig.about.eventImage" :class="$style.frameItem" alt="" :src="eventConfig.about.eventImage" />
        <div :class="$style.frameContainer">
          <div :class="$style.descriptionGroup">
            <div :class="$style.description">About the event</div>
            <img :class="$style.logoIcon" alt="" :src="eventConfig.logo" />
            <div :class="$style.beyondBordersBeyondTimeParent">
              <div :class="$style.beyondBordersBeyond">{{ eventConfig.eventSubtitle }}</div>
              <div :class="$style.discoverAPlatform">{{ eventConfig.about.eventDesctiption }}</div>
            </div>
          </div>
          <div v-for="(button, index) in eventConfig.about.eventButtons" :key="index" :class="$style.buttonParent">
            <div :class="[button.primary ? $style.button : $style.button2]" @click="goToWebsite(button.link)">
              <div :class="$style.manageCookies">{{ button.name }}</div>
              <img v-if="button.icon" :class="$style.icon" alt="" :src="button.icon" />
              <div v-else>
                <img v-if="button.primary" :class="$style.icon" alt="" src='../images/goto_black.svg' />
                <img v-else :class="$style.icon" alt="" src='../images/goto.svg' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.contentInner">
      <div :class="$style.frameDiv">
        <div :class="$style.frameParent1">
          <div :class="$style.descriptionGroup">
            <div :class="$style.description">About Visual summaries</div>
            <img :class="$style.clipPathGroup" alt="" src="../images/thothai_blue-p-500.png" />
            <div :class="$style.aCollaborationBetween24finParent">
              <div :class="$style.aCollaborationBetweenContainer">
                <p :class="$style.aCollaborationBetween">Turn long text to 1-page visuals, </p>
                <p :class="$style.aCollaborationBetween">in just 3 steps.</p>
              </div>
              <div :class="$style.discoverAPlatform">
                <p :class="$style.aCollaborationBetween">Thoth.ai automates our hand-drawn craft into a fun and
                  fuss-free digital service.</p>
                <p :class="$style.aCollaborationBetween">‍</p>
                <p :class="$style.aCollaborationBetween">Curious? Try our demo. </p>
                <p :class="$style.aCollaborationBetween">&nbsp;</p>
                <p :class="$style.aCollaborationBetween">Even better, sign up for our exclusive waitlist now.</p>
              </div>
            </div>
          </div>
          <div :class="$style.buttonGroup">
            <div :class="$style.button4" @click="goToWebsite('https://www.thoth.art')">
              <div :class="$style.manageCookies">Visit website</div>
              <img :class="$style.icon" alt="" src="../images/goto.svg" />
            </div>
            <div :class="$style.button6" @click="goToWebsite('https://form.typeform.com/to/Tbyclmz4')">
              <div :class="$style.manageCookies">Join waitlist</div>
              <img :class="$style.icon" alt="" src="../images/goto_black.svg" />
            </div>
          </div>
        </div>
        <img :class="$style.frameInner" alt="" src="../images/thothAboutImage.png" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "About",
  inject: ["eventConfig"],
  props: {
    eventName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      svgContent: null,
    };
  },
  methods: {
    goToWebsite(link) {
      window.open(link, "_blank");
    },
  },
};
</script>


<style module>
.meshGradIcon {
  width: 1013px;
  position: absolute;
  margin: 0 !important;
  top: -266px;
  left: -166.5px;
  height: 807px;
  object-fit: cover;
  opacity: 0.2;
  z-index: 0;
}

.description {
  position: relative;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 600;
}

.logoIcon {
  position: relative;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
}

.aCollaborationBetween {
  margin: 0;
}

.aCollaborationBetweenContainer {
  align-self: stretch;
  position: relative;
  line-height: 130%;
}

.discoverAPlatform {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 130%;
  font-family: 'DM Sans';
}

.aCollaborationBetween24finParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 48px;
  color: #2c2c2c;
  font-family: 'Instrument Serif';
}

.descriptionParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 0px;
  gap: 24px;
}

.frameChild {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 40px;
  background-color: var(--primary-color);
  overflow: hidden;
}

.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  z-index: 1;
}

.meshGradParent {
  position: relative;
  top: 80px;
  left: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 80px;
  box-sizing: border-box;
}

.meshGradIcon1 {
  width: 1013px;
  position: absolute;
  margin: 0 !important;
  top: -152px;
  left: 471px;
  height: 807px;
  object-fit: cover;
  opacity: 0.2;
  z-index: 0;
}

.frameItem {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.beyondBordersBeyond {
  width: 80%;
  max-width: 700px;
  flex-wrap: wrap;
  position: relative;
  line-height: 130%;
  display: inline-block;
}

.beyondBordersBeyondTimeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 48px;
  color: #fff;
  font-family: 'Instrument Serif';
}

.descriptionGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.icon {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.button {
  border-radius: 12px;
  background-color: #fff;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  box-sizing: border-box;
  gap: 8px;
  cursor: pointer;
}

.button2 {
  border-radius: 12px;
  border: 1px solid #fff;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  gap: 8px;
  color: #fff;
  cursor: pointer;
}

.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 16px;
  color: #111;
}

.frameContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 0px;
  gap: 40px;
}

.meshGradGroup {
  position: relative;
  top: 80px;
  left: 0px;
  background-color: #111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 80px;
  box-sizing: border-box;
  color: var(--tertiary-color);
}

.clipPathGroup {
  width: 200px;
  position: relative;
  height: 40px;
}

.button4 {
  border-radius: 12px;
  background-color: var(--primary-color);
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  box-sizing: border-box;
  gap: 8px;
  cursor: pointer;
}

.button6 {
  border-radius: 12px;
  border: 1px solid #111;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  gap: 7px;
  color: #111;
  cursor: pointer;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 16px;
  color: #fff;
}

.frameParent1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.frameInner {
  flex: 1;
  position: relative;
  border-radius: 40px;
  max-width: 100%;
  overflow: hidden;
  height: 435px;
  object-fit: contain
}

.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.contentInner {
  position: relative;
  top: 80px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 80px;
  box-sizing: border-box;
}

.button8 {
  border-radius: 12px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  box-sizing: border-box;
  gap: 8px;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.leftHandPanel {
  flex: 1;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}

.manageCookies {
  position: relative;
  line-height: 130%;
}

.manageCookiesParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #d9d9d9;
}

.leftHandPanelParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.thoth2 {
  text-decoration: underline;
}

.thoth {
  color: inherit;
}

.span {
  font-weight: 600;
}

.allImagesAnd {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 130%;
}

.copyright2024ThothParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #d9d9d9;
}


.content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  text-align: left;
  font-size: 14px;
  color: var(--primary-color);
  font-family: 'DM Sans';
}

@media (max-width: 768px) {
  .meshGradParent {
    padding: 40px
  }

  .meshGradGroup {
    padding: 40px
  }

  .meshGradIcon {
    left: 0px
  }

  .meshGradIcon1 {
    left: 0px;
  }

  .aCollaborationBetween24finParent {
    font-size: 30px;
  }

  .frameParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    z-index: 1;
  }

  .beyondBordersBeyond {
    font-size: 30px;
  }

  .manageCookies {
    font-size: 12px;
  }

  .contentInner {
    padding: 40px;
  }

  .frameDiv {
    flex-direction: column;
  }
}
</style>
