
import { Options, Vue } from "vue-class-component";

@Options({
  name: 'ImageSlideshow',
  props: {
    images: {
      type: Array as () => string[],
      required: true
    }
  }
})
export default class ImageSlideshow extends Vue {
  images!: string[];

  currentIndex = 0;

  get currentImage(): string {
    return this.images[this.currentIndex];
  }

  nextImage(): void {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
  }

  prevImage(): void {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
  }
}
