
import { Options, Vue } from "vue-class-component";
import { ColourPalette, STATIC_ASSET_ICONS_BASE_URL } from "@/commons";
import { PropType } from "@vue/runtime-core";
import ColourPaletteDialog from "@/components/product/brandkit/ColourPaletteDialog.vue";
import {
  ADD_PALETTE_ACTION,
  EDIT_PALETTE_NAME_ACTION,
  DELETE_PALETTE_ACTION,
  LIST_USER_ACTION
} from "@/store/constants";
import UIkit from "uikit";
import { ButtonColour } from "@/components/common/ThothButton.vue";
import Modal from "@/components/common/Modal.vue";
import Toast from "@/components/common/Toast.vue";

@Options({
  props: {
    colourPalettes: {
      type: Array as PropType<ColourPalette[]>,
      required: true
    }
  },
  components: {
    ColourPaletteDialog,
    Modal,
    Toast
  }
})
export default class ColourPalettes extends Vue {
  // Imported commons
  ButtonColour = ButtonColour;

  // Props
  colourPalettes!: ColourPalette[];

  // locals
  activeDropdown = false;
  paletteToDelete: string | null = null;
  extractLoading = false;
  toastProps = { message: "" };
  // States
  icons = {
    eyedropper:
      STATIC_ASSET_ICONS_BASE_URL + "/brandkit-colour-palette-eyedropper.svg",
    plusSign: STATIC_ASSET_ICONS_BASE_URL + "/brandkit-colour-palette-plus.svg"
  };
  updateColourErrorCounter = 0;

  // Methods
  get newPaletteName(): string {
    const nums = this.colourPalettes
      .map(({ paletteName }) => paletteName.match(/Untitled Palette (.*)/)?.[1])
      .map(str => (str ? parseInt(str) : null))
      .filter(Boolean)
      .sort();
    const highestNum = nums.pop() ?? 0;
    return `Untitled Palette ${highestNum > 0 ? highestNum + 1 : 1}`;
  }

  get modalText() {
    return `You're about to delete <b>${this.paletteToDelete}</b>. You will not be able to recover it if you change your mind.`;
  }

  get modalElement() {
    return document.querySelector("#colour-palette-dialog__modal");
  }
  async addPalette() {
    try {
      const payload = { paletteName: this.newPaletteName };
      await this.$store.dispatch(ADD_PALETTE_ACTION, payload);
      this.hideDropdown();
    } catch (err) {
      throw err.response.data;
    }
  }

  async deletePalette() {
    try {
      await this.$store.dispatch(DELETE_PALETTE_ACTION, this.paletteToDelete);
      this.closeModal();
    } catch (err) {
      throw err.response.data;
    }
  }

  hideDropdown() {
    UIkit.dropdown("#colour-palettes__dropdown").hide();
  }

  openDeletePaletteModal(paletteToDelete: string) {
    this.paletteToDelete = paletteToDelete;
    if (this.modalElement) {
      UIkit.modal(this.modalElement).show();
    }
  }

  closeModal() {
    if (this.modalElement) {
      UIkit.modal(this.modalElement).hide();
    }
  }

  async safeCheck(oriColourPalette: string) {
    try {
      await this.$store.dispatch(LIST_USER_ACTION);
      if (oriColourPalette === JSON.stringify(this.colourPalettes)) {
        this.updateColourErrorCounter += 1;
        throw new Error("User still not updated");
      } else {
        this.extractLoading = false;
        this.updateColourErrorCounter = 0;
      }
    } catch (err) {
      await new Promise(resolve => setTimeout(resolve, 5000));
      if (this.updateColourErrorCounter >= 12) {
        this.updateColourErrorCounter = 0;
        this.extractLoading = false;
        this.toggleToast("Error: Please refresh the page");
        throw new Error("Extraction Error");
      } else {
        return this.safeCheck(oriColourPalette);
      }
    }
  }

  async extractColourPalette(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    const uploadedFile = inputEl.files?.item(0);
    if (!uploadedFile) {
      this.toggleToast("Error: Upload file.");
    } else {
      this.hideDropdown();
      const form = new FormData();
      form.append("image", uploadedFile);
      const payload = { paletteName: uploadedFile.name, form };
      const oriColourPalette = JSON.stringify(this.colourPalettes);
      try {
        this.extractLoading = true;
        await this.$store.dispatch(ADD_PALETTE_ACTION, payload);
        this.extractLoading = false;
      } catch (err) {
        //refresh user action to show hidden palette in BE
        try {
          await this.safeCheck(oriColourPalette);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  async updatePaletteName(payload: {
    paletteName: string;
    newPaletteName: string;
  }) {
    if (!this.isUnique(payload.newPaletteName)) {
      throw new Error("Palette name must be unique");
    }

    try {
      await this.$store.dispatch(EDIT_PALETTE_NAME_ACTION, payload);
    } catch (err) {
      throw err.response.data;
    }
  }

  isUnique(newPaletteName: string): boolean {
    return !this.colourPalettes.some(
      ({ paletteName: name }) => name === newPaletteName
    );
  }

  toggleToast(message?: string) {
    const element = document.querySelector("#toast") as HTMLElement;
    if (message) {
      this.toastProps.message = message;
    }
    if (element) {
      UIkit.toggle(element).toggle();
      setTimeout(() => {
        UIkit.toggle(element).toggle();
      }, 2400);
    }
  }
}
