<template>
  <div id="app">
    <NavBar />
    <Talk :talkName="$route.params.name" :eventName="$route.params.eventName" />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Talk from "./components/Talk.vue";

export default {
  name: "TalkPage",
  components: {
    NavBar,
    Talk
  }
};
</script>

<style scoped>
@import "./components/webflow.css";
@import "./components/normalize.css";
@import "./components/sffnotes.webflow.css";
</style>
